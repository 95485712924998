<template>
  <modal-buttons-layout
    ref="modalButtons"
    @scroll="onScroll"
  >
    <template #top>
      <slot name="top" />
    </template>
    <template #action-buttons>
      <slot name="action-buttons" />
    </template>
    <template #bottom>
      <slot name="bottom" />
    </template>
    <template #before-content="{ scrollPixel, scrollPercentage }">
      <div
        ref="submenuContainer"
        :class="[
          scrollPercentage > 0 ? 'bg-gray-100' : 'border-transparent bg-white',
        ]"
        class="flex shrink-0 gap-4 overflow-x-auto border-b px-8 py-4 transition-colors"
      >
        <button
          v-for="section in sections"
          :key="section.value"
          :class="[
            activeValue === section.value
              ? 'text-dark-grey'
              : 'text-gray-500 hover:text-gray-700',
          ]"
          :data-name="section.value"
          class="whitespace-nowrap text-sm leading-none transition-colors"
          @click="scrollToSection(section.value)"
        >
          {{ section.label }}
        </button>
      </div>
    </template>
    <template #default="{ scrollPixel, scrollPercentage }">
      <div
        ref="container"
        class="flex flex-col gap-6 pt-6"
      >
        <template
          v-for="section in sections"
          :key="section.value"
        >
          <form-input-group-layout :data-name="section.value">
            <slot :name="section.value">
              <div class="h-10 bg-red">
                <b>{{ section.value }}</b>
                section to be implemented
              </div>
            </slot>
          </form-input-group-layout>
          <hr />
        </template>
      </div>
    </template>
  </modal-buttons-layout>
</template>

<script setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import { ref } from 'vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'

const props = defineProps({
  sections: {
    type: Array,
    default: () => [
      {
        label: 'Section 1',
        value: 'section-1',
      },
      {
        label: 'Section 2',
        value: 'section-2',
      },
    ],
  },
})

const modalButtons = ref(null)
const container = ref(null)
const submenuContainer = ref(null)
const activeValue = ref(props.sections[0].value)

function onScroll(e) {
  const children = container.value.children
  for (let i = children.length - 1; i >= 0; i--) {
    const child = children[i]
    if (e.target.scrollTop >= child.offsetTop - 60) {
      if (activeValue.value !== child.dataset.name && child.dataset.name) {
        scrollToSubmenu(child.dataset.name)
      }
      activeValue.value = child.dataset.name
      break
    }
  }
}

function scrollToSubmenu(name) {
  const element = submenuContainer.value.querySelector(`[data-name="${name}"]`)
  submenuContainer.value.scrollTo({
    left: element.offsetLeft - 120,
    behavior: 'smooth',
  })
}

function scrollToSection(name) {
  const element = container.value.querySelector(`[data-name="${name}"]`)
  modalButtons.value.container.scrollTo({
    top: element.offsetTop,
    behavior: 'smooth',
  })
}
</script>

<style scoped></style>
