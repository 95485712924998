<template>
  <div class="">
    <!-- Center marker -->
    <div
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
    >
      <svg
        class="h-6 w-6 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4v16m8-8H4"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
    </div>

    <div
      ref="mapRef"
      class="map-container"
      style="width: 100%; height: 100%"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import 'ol/ol.css'
import { Feature, Map, View } from 'ol'
import { fromLonLat, transform } from 'ol/proj'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, Vector as VectorSource } from 'ol/source'
import { Circle, Icon, Style } from 'ol/style'
import { Point } from 'ol/geom'
import { MouseWheelZoom } from 'ol/interaction'

// Variable for tracking the state of pin installation

const emit = defineEmits(['update'])
const props = defineProps({
  longitude: {
    type: [Number, String],
    default: 0,
  },
  latitude: {
    type: [Number, String],
    default: 0,
  },
  unlimitedZoom: {
    type: Boolean,
    default: false,
  },
})
const mapRef = ref(null)
const pinSetted = ref(false)
watch(
  () => props.longitude,
  (newValue, oldValue) => {
    console.log(newValue, oldValue)
    updatePosition()
  }
)
watch(
  () => props.latitude,
  (newValue) => {
    updatePosition()
  }
)

let map

function updatePosition() {
  if (map) {
    if (
      props.latitude >= -90 &&
      props.latitude <= 90 &&
      props.longitude >= -180 &&
      props.longitude <= 180
    ) {
      console.log('Updating position')
      map.getView().animate({
        center: fromLonLat([props.longitude, props.latitude]),
        duration: 300,
      })
    } else {
      console.error('Invalid coordinates')
    }
  }
}

onMounted(() => {
  map = new Map({
    target: mapRef.value,
    layers: [
      new TileLayer({
        source: new OSM(),
      }),
    ],
    view: new View({
      zoom: 16,
      minZoom: props.unlimitedZoom ? 0 : 15,
      maxZoom: 20,
      center: fromLonLat([props.longitude, props.latitude]),
      constrainResolution: true,
    }),
  })

  // Adjusting the zoom speed:
  const mouseWheelZoom = new MouseWheelZoom({
    duration: 200, // adjust the duration as needed (milliseconds)
    timeout: 400, // adjust the timeout as needed (milliseconds)
    useAnchor: true,
    constrainResolution: true,
    maxDelta: 1,
  })
  map.addInteraction(mouseWheelZoom)

  let vectorLayer = new VectorLayer({
    source: new VectorSource(),
  })

  map.addLayer(vectorLayer)

  // Event handler for clicking on the map
  map.on('click', function (evt) {
    vectorLayer.getSource().clear()
    let marker = new Feature(new Point(evt.coordinate))
    marker.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="%231613BD" xmlns="http://www.w3.org/2000/svg"><path d="M12 14L12 22" stroke="rgba(0,0,0,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><circle cx="12" cy="8" r="6" stroke="rgba(0,0,0,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle></svg>',
        }),
      })
    )
    vectorLayer.getSource().addFeature(marker)

    requestAnimationFrame(() => {
      map.getView().animate({
        center: evt.coordinate,
        duration: 300,
      })
    })

    let lonlat = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326')
    emit('update', {
      longitude: lonlat[0],
      latitude: lonlat[1],
      pinSetted: true,
    })
  })

  // If the pin coordinates are already set, add the pin to the map.
  if (props.longitude && props.latitude) {
    let marker = new Feature(
      new Point(fromLonLat([props.longitude, props.latitude]))
    )
    marker.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="%231613BD" xmlns="http://www.w3.org/2000/svg"><path d="M12 14L12 22" stroke="rgba(0,0,0,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><circle cx="12" cy="8" r="6" stroke="rgba(0,0,0,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle></svg>',
        }),
      })
    )
    vectorLayer.getSource().addFeature(marker)
  }

  // We watch for changes in the coordinates of the pin
  watch(
    () => [props.longitude, props.latitude],
    ([longitude, latitude]) => {
      if (!pinSetted.value && longitude && latitude) {
        // If the pin has not yet been set and there are new coordinates, add the pin to the map
        let marker = new Feature(new Point(fromLonLat([longitude, latitude])))
        marker.setStyle(
          new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: 'data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="%231613BD" xmlns="http://www.w3.org/2000/svg"><path d="M12 14L12 22" stroke="rgba(0,0,0,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><circle cx="12" cy="8" r="6" stroke="rgba(0,0,0,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle></svg>',
            }),
          })
        )
        vectorLayer.getSource().addFeature(marker)
      }
    }
  )
})
</script>

<style scoped>
.map-container {
  border-radius: 8px;
  overflow: hidden;
}

.map-container div canvas {
  border-radius: 8px;
}
</style>