<template>
  <div class="flex min-h-[200px] flex-col bg-white">
    <div
      v-if="!hideHeader"
      :class="{
        'border-b': !hideBorder,
      }"
      class="bg-white"
    >
      <slot name="header">
        <div
          class="grid items-center px-4"
          style="grid-template-columns: 1fr auto 1fr"
        >
          <div class="text-md py-2 font-semibold">
            {{ title }}
          </div>
          <div class="text-center">
            <slot name="header-content" />
          </div>
          <div class="text-end">
            <button>
              <font-awesome-icon
                icon="times"
                @click="closeModal"
              />
            </button>
          </div>
        </div>
      </slot>
    </div>
    <div
      ref="content"
      class="max-h-full min-h-0 grow overflow-auto"
      @scroll="updateScrollState"
    >
      <slot />
    </div>
    <div
      v-if="$slots.footer"
      :class="{
        'border-t': !hideBorder,
      }"
      class="bg-white"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['scroll'])

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  hideBorder: {
    type: Boolean,
    default: false,
  },
})
const closeModal = inject('close')
const content = ref<HTMLElement | null>(null)

const scrollState = ref(0)
const maxScroll = ref(0)

function updateScrollState(event) {
  scrollState.value = event.target.scrollTop
  emit('scroll', { scroll: scrollState.value, maxScroll: maxScroll.value })
}

onMounted(() => {
  if (content.value) {
    maxScroll.value = content.value.scrollHeight - content.value.clientHeight
    emit('scroll', { scroll: scrollState.value, maxScroll: maxScroll.value })
  }
})
</script>

<style scoped></style>
