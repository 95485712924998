<template>
  <input-wrapper
    ref="inputWrapper"
    :disabled="disabled"
    :empty="isEmpty === undefined ? !modelValue.length : isEmpty"
    :invalid="!!inject('input-error', null)?.value"
    :mini-mode="miniMode"
    class="group/dropdown"
    :variant="variant"
    @focusin="onFocusIn"
    @focusout="onFocusOut"
  >
    <span
      ref="invisibleInput"
      class="sr-only"
      tabindex="-1"
      @focus="focusOnInput"
    />
    <app-input
      v-if="!miniMode"
      ref="input"
      :autofocus="autofocus"
      :disabled="disabled"
      :model-value="modelValue"
      :placeholder="placeholder"
      @keydown.down="highlightAt($event, 1)"
      @keydown.up="highlightAt($event, -1)"
      @keydown.enter="selectHighlighted"
      @update:model-value="emit('update:modelValue', $event)"
    />
    <font-awesome-icon
      v-if="!hideIcon && !miniMode"
      class="absolute bottom-0 right-0 top-0 m-auto mr-3 text-gray-400 transition-transform group-focus-within/dropdown:rotate-180"
      icon="chevron-down"
    />
    <div
      :class="[
        miniMode ? '!left-[-110px] min-w-[212px]' : 'left-0',
        position === 'bottom' ? 'top-full' : 'bottom-full',
      ]"
      class="absolute z-30 hidden w-full flex-col overflow-hidden rounded border bg-gray-100 bg-opacity-95 shadow backdrop-blur group-focus-within/dropdown:flex"
      tabindex="-1"
    >
      <div
        v-if="miniMode"
        class="bg-slate-150 h-fit w-full border-b p-2"
      >
        <app-input
          ref="input"
          :disabled="disabled"
          :model-value="modelValue"
          :placeholder="placeholder"
          input-class="!h-[20px] w-full"
          @keydown.down="highlightAt($event, 1)"
          @keydown.up="highlightAt($event, -1)"
          @keydown.enter="selectHighlighted"
          @update:model-value="emit('update:modelValue', $event)"
        />
      </div>
      <dropdown-items-list
        :allow-create="allowCreate"
        :highlighted-key="highlightedKeyGetter"
        :items="filteredSuggestions"
        :key-by="keyBy"
        :loading="loading"
        :use-grouping="useGrouping"
        @create="emit('create')"
        @select="onSelect"
      >
        <template #item="{ item }">
          <slot
            :item="item"
            name="item"
          >
            {{ item[searchBy] }}
          </slot>
        </template>
        <template #create>
          <slot name="create">
            <div class="flex items-center gap-2 px-3 py-2 hover:bg-gray-100">
              <font-awesome-icon
                class="h-3 w-3"
                icon="plus"
              />
              <p class="flex gap-1 whitespace-nowrap text-base">
                <span class="text-blue-neon">Create</span>
                <b>
                  {{ modelValue }}
                </b>
              </p>
            </div>
          </slot>
        </template>
      </dropdown-items-list>
    </div>
  </input-wrapper>
</template>

<script setup>
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import InputWrapper from '@/components/ui/InputWrapper.vue'
import AppInput from '@/components/input/AppInput.vue'
import DropdownItemsList from '@/components/widgets/DropdownItemsList.vue'
import getScrollParent from '@/utils/scroll'

/**
 * Input field with a dropdown list of suggestions
 * @usage <form-field-select-text v-model="modelValue" :suggestions="suggestions" />
 * @example {
 *   "title": "Default",
 *   "description": "",
 *   "attrs": {
 *   "modelValue": "text",
 *   "suggestions": [
 *    {
 *     "name": "Some text",
 *     "value": "Some text"
 *    },
 *    {
 *     "name": "Some other text",
 *     "value": "Some other text"
 *    }
 *   ]
 *  }
 * }
 */

const emit = defineEmits([
  'update:modelValue',
  'update:highlightedKey',
  'select',
  'blur',
  'focus',
  'create',
])

defineOptions({
  name: 'FormFieldSelectText',
})

const props = defineProps({
  variant: {
    type: String,
    default: 'default',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  /**
   * The value of the input
   * @model
   */
  modelValue: {
    type: String,
    default: null,
  },
  /**
   * The suggestions to show in the dropdown
   */
  suggestions: {
    type: Array,
    default: () => [],
  },
  /**
   * The property to search by
   */
  searchBy: {
    type: String,
    default: 'name',
  },
  /**
   * The property to use as the key
   */
  keyBy: {
    type: String,
    default: 'value',
  },
  /**
   * Whether to disable native filtering
   */
  disableFiltering: {
    type: Boolean,
    default: false,
  },
  /**
   * The highlighted key
   */
  highlightedKey: {
    type: String,
    default: null,
  },
  /**
   * Whether the input is invalid
   */
  invalid: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the input is loading
   */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * The classes of the wrapper
   */
  wrapperClass: {
    type: String,
    default: '',
  },
  /**
   * The classes of the input
   */
  inputClass: {
    type: String,
    default: '',
  },
  /**
   * Whether to allow creating new items
   */
  allowCreate: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether to hide the icon
   */
  hideIcon: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },

  isEmpty: {
    type: Boolean,
  },
  useGrouping: {
    type: Boolean,
    default: false,
  },
  miniMode: {
    type: Boolean,
    default: false,
  },
})

const input = ref(null)
const invisibleInput = ref(null)
const inputWrapper = ref(null)

const filteredSuggestions = computed(() => {
  if (props.disableFiltering) {
    return props.suggestions
  }
  return props.suggestions.filter((suggestion) => {
    return suggestion[props.searchBy]
      ?.toLowerCase()
      .includes(props.modelValue.toLowerCase())
  })
})

const _highlightedKey = ref(null)

const highlightedKeyGetter = computed({
  get() {
    if (props.highlightedKey) {
      return props.highlightedKey
    }
    return _highlightedKey.value
  },
  set(value) {
    emit('update:highlightedKey', value)
    _highlightedKey.value = value
  },
})

function onFocusOut(e) {
  if (!e.currentTarget.contains(e.relatedTarget)) {
    emit('blur', e)
    scrollParent.value?.removeEventListener('scroll', updateDropdownPosition)
  }
}

function onFocusIn(e) {
  if (!scrollParent.value) {
    console.debug(
      'Could not find scroll parent, for dropdown',
      inputWrapper.value.$el
    )
    return
  }

  scrollParent.value?.addEventListener('scroll', updateDropdownPosition)
  requestAnimationFrame(() => {
    updateDropdownPosition()
  })
  emit('focus', e)
}

function onSelect(e) {
  emit('select', e)
  requestAnimationFrame(() => {
    updateDropdownPosition()
  })
}

function focus() {
  // focus on the invisible input to trigger dropdown open
  // then instantly focus on the actual input
  invisibleInput.value?.focus()
  updateDropdownPosition()
}

function focusOnInput() {
  requestAnimationFrame(() => {
    input.value?.focus()
  })
}

function blur() {
  input.value?.blur()
}

const scrollParent = ref(null)
const position = ref('bottom')

function highlightAt(e, offset) {
  e.preventDefault()
  if (!highlightedKeyGetter.value) {
    highlightedKeyGetter.value = filteredSuggestions.value?.at(
      offset < 0 ? filteredSuggestions.value.length - 1 : 0
    )?.[props.keyBy]
  } else {
    const index = filteredSuggestions.value.findIndex(
      (item) => item[props.keyBy] === highlightedKeyGetter.value
    )
    if (index === -1) {
      highlightedKeyGetter.value = filteredSuggestions.value?.at(
        offset < 0 ? filteredSuggestions.value.length - 1 : 0
      )?.[props.keyBy]
    } else {
      highlightedKeyGetter.value =
        filteredSuggestions.value[
          (index + filteredSuggestions.value.length + offset) %
            filteredSuggestions.value.length
        ][props.keyBy]
    }
  }
}

function selectHighlighted() {
  if (highlightedKeyGetter.value) {
    const item = filteredSuggestions.value.find(
      (item) => item[props.keyBy] === highlightedKeyGetter.value
    )
    if (item) {
      onSelect(item)
    }
  }
}

function updateDropdownPosition() {
  if (scrollParent.value && inputWrapper.value) {
    const { top } = inputWrapper.value.$el.getBoundingClientRect()
    const { height } = scrollParent.value.getBoundingClientRect()
    if (top < height / 2) {
      position.value = 'bottom'
    } else {
      position.value = 'top'
    }
  }
}

onMounted(() => {
  scrollParent.value = getScrollParent(inputWrapper.value.$el)
})

onBeforeUnmount(() => {
  scrollParent.value?.removeEventListener('scroll', updateDropdownPosition)
})

defineExpose({ focus, blur })
</script>

<style scoped></style>
