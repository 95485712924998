<template>
  <picture :class="props.class">
    <link
      v-if="
        loading &&
        props.image?.path &&
        props.image?.preload_url &&
        props.image?.path !== props.image?.preload_url &&
        !is_cached(props.image.path) &&
        !is_cached(props.image.preload_url)
      "
      :href="props.image.preload_url"
      as="image"
      rel="preload"
    />
    <slot
      v-if="
        !forceLoading &&
        ((!loading && success) ||
          (props.image?.preload_url &&
            props.image?.preload_url !== props.image?.path &&
            (loading || success)))
      "
    >
      <img
        :alt="alt"
        :class="props.class"
        :src="imageSrc"
        :style="[
          props.image && loading && !forceLoading
            ? {
                backgroundImage: `url('${props.image.preload_url}')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: props.image.dimensions?.width + 'px',
                filter: 'blur(4px)',
              }
            : {},
        ]"
        class="transition duration-300 ease-in-out"
        @error="handleError"
        @load="handleLoad"
      />
    </slot>
    <slot
      v-else-if="loading || forceLoading"
      name="preloader"
    >
      <img
        :class="[
          props.class,
          {
            skeleton: !props.image,
          },
        ]"
        :src="props.image?.preload_url"
        :style="[
          props.image
            ? {
                filter: 'blur(5px)',
                width: props.image.dimensions?.width + 'px',
              }
            : {},
        ]"
        alt="hidden"
        aria-hidden="true"
      />
      <img
        v-if="preload"
        :src="imageSrc"
        alt="hidden"
        aria-hidden="true"
        class="absolute -z-50 h-0 w-0 bg-transparent opacity-0"
        loading="eager"
        @error="handleError"
        @load="handleLoad"
      />
    </slot>
    <slot
      v-else-if="!success"
      name="fallback"
    >
      <img
        v-if="fallbackSrc"
        :class="props.class"
        :src="fallbackSrc"
        class="h-full w-full"
      />
      <div
        v-else
        :class="props.class"
        class="flex items-center justify-center overflow-hidden bg-slate-100"
      >
        <slot name="fallback-icon">
          <svg
            class="h-2/3 w-2/3 text-gray-400"
            fill="none"
            stroke="currentColor"
            stroke-width="{1.5}"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </slot>
      </div>
    </slot>
  </picture>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import ImageModel from '@/models/Image'
import { is_cached } from '@/utils/composables/utils'

const props = defineProps({
  image: {
    type: [ImageModel, Object],
    default: () => undefined,
  },
  src: {
    type: String,
    default: null,
  },
  alt: {
    type: String,
    default: null,
  },
  class: {
    type: String,
    default: null,
  },
  timeout: {
    type: Number,
    default: 0,
  },
  fallbackSrc: {
    type: String,
    default: null,
  },
  fallbackSize: {
    type: String,
    default: '50%',
  },
  forceLoading: {
    type: Boolean,
    default: false,
  },
})

const logger = useLogger()
const preload = ref(false)
const loading = ref(true)
const success = ref(false)

const imageSrc = computed(() => props.src ?? props.image?.path ?? null)

onMounted(() => {
  if (imageSrc.value && is_cached(imageSrc.value)) {
    loading.value = false
    success.value = true
  } else {
    if (!imageSrc.value) {
      loading.value = false
      preload.value = false
      success.value = false
      return
    }
    nextTick(() => {
      if (props.timeout > 0) {
        setTimeout(() => {
          preload.value = true
        }, props.timeout)
      } else {
        preload.value = true
      }
    })
  }
})

watch(imageSrc, (value) => {
  if (!value) {
    loading.value = true
    preload.value = true
    success.value = false
  } else {
    preload.value = false
    loading.value = true
    success.value = false
    nextTick(() => {
      preload.value = true
    })
  }
})

function handleLoad() {
  if (imageSrc.value) {
    loading.value = false
    success.value = true
  } else {
    loading.value = false
    success.value = false
  }
}

function handleError(e) {
  loading.value = false
  success.value = false
  logger.debug('Image load error', e.target.src, e.target)
}
</script>

<style scoped>
.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
</style>
