<template>
  <div
    class="flex h-full min-h-[400px] w-[90vw] max-w-[760px] flex-col gap-8 bg-text-white px-4 py-8 md:px-8"
  >
    <div class="flex items-start justify-between">
      <h2 class="mb-4 text-2xl font-bold">{{ post.title }}</h2>
      <dropdown-button
        v-if="enableActions"
        rounded
        variant="gray"
      >
        <template #button>
          <font-awesome-icon
            class="h-6 w-6"
            icon="ellipsis"
          />
        </template>
        <template #buttons>
          <ul>
            <li
              class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
              @click="reportAbuse"
            >
              Report Abuse
            </li>
            <li
              v-if="post.author?.id === user.id"
              class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
              @click="deletePost"
            >
              Delete Post
            </li>
          </ul>
        </template>
      </dropdown-button>
    </div>

    <template v-if="post.type === 'images'">
      <div
        v-if="post.images?.[currentIndex]"
        class="relative"
      >
        <div class="relative w-full px-2 md:px-4">
          <image-component
            :image="post.images[currentIndex]"
            class="h-[300px] w-full rounded object-cover md:h-[400px]"
          />
          <div class="mt-2 flex w-full items-start justify-between px-2">
            <p
              v-if="post.images[currentIndex]?.caption"
              class="text-sm text-slate-400"
            >
              {{ post.images[currentIndex]?.caption }}
            </p>

            <p class="text-sm text-slate-400">
              Posted by
              <span class="font-semibold">
                {{ post.author?.name }}
              </span>
            </p>
          </div>
        </div>

        <button
          v-if="currentIndex > 0"
          class="absolute -left-2 top-1/2 z-10 h-12 w-12 -translate-y-1/2 transform rounded-full bg-black/50 p-2 text-white md:left-0"
          @click="prevImage"
        >
          <font-awesome-icon icon="chevron-left" />
        </button>
        <button
          v-if="currentIndex < post.images.length - 1"
          class="absolute -right-2 top-1/2 z-10 h-12 w-12 -translate-y-1/2 transform rounded-full bg-black/50 p-2 text-white md:right-0"
          @click="nextImage"
        >
          <font-awesome-icon icon="chevron-right" />
        </button>
      </div>
    </template>

    <!-- Default comments section -->
    <div class="mt-4">
      <h3 class="mb-2 text-lg font-semibold">Comments</h3>
      <app-chat
        :api-url="`/api/conventions/${selectedEvent.id}/posts/${post.id}/comments`"
        :channel="`thread-comments-${post.id}`"
        class="grow gap-10"
        comment-replies
        reverse-order
      >
        <template #empty-messages>
          <div
            class="flex flex-col items-center justify-center gap-4 py-10 text-gray-500"
          >
            <font-awesome-icon
              class="text-6xl text-blue-neon"
              icon="comments"
            />
            <div class="text-center">
              <h4>No comments yet</h4>
              <p>Be the first to comment on this activity!</p>
            </div>
          </div>
        </template>
        <template #message="{ message }">
          <activity-comment :comment="message" />
        </template>
        <template #input="{ sending, sendFunction }">
          <comment-form
            v-model:text="commentText"
            :sending="sending"
            @send="sendComment(sendFunction, $event)"
          />
        </template>
      </app-chat>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, PropType, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import DropdownButton from '@/components/buttons/DropdownButton.vue'
import ThreadPost from '@/models/ThreadPost'
import axios from 'axios'
import useConventionList from '@/utils/composables/conventionList'
import AppChat from '@/components/widgets/AppChat.vue'
import ActivityComment from '@/components/ui/ActivityComment.vue'
import CommentForm from '@/components/input/CommentForm.vue'
import useAuth from '@/utils/composables/auth'
import { useModal } from '@/utils/modal'

const emit = defineEmits(['delete', 'close'])
const props = defineProps({
  post: {
    type: Object as PropType<ThreadPost>,
    default: () => {},
  },
  enableActions: {
    type: Boolean,
    default: false,
  },
})

const modalHandler = useModal()
const { selected: selectedEvent } = useConventionList()
const { user } = useAuth()

const currentIndex = ref(0)
const commentText = ref('')

const sendComment = (sendFunction: Function, text: string) => {
  sendFunction(text)
  commentText.value = ''
}

function nextImage() {
  if (currentIndex.value < props.post.images.length - 1) {
    currentIndex.value++
  }
}

function prevImage() {
  if (currentIndex.value > 0) {
    currentIndex.value--
  }
}

function deletePost() {
  modalHandler.openType('confirm-dialog', {
    props: {
      title: 'Delete Post',
      subtitle: 'Are you sure you want to delete this post?',
      primary: 'Delete',
      secondary: 'Cancel',
    },
    events: {
      onSecondary: (payload, close) => {
        close()
      },
      onPrimary: (payload, close) => {
        axios
          .delete(
            `/api/conventions/${selectedEvent.value.id}/posts/${props.post.id}`
          )
          .then(() => {
            close()
            emit('delete')
            emit('close')
          })
      },
    },
  })
}

onMounted(() => {
  axios
    .get(`/api/conventions/${selectedEvent.value.id}/posts/${props.post.id}`)
    .then((response) => {
      Object.assign(props.post, response.data)
    })
})
</script>

<style scoped></style>
