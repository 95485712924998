<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.744 18.8C17.984 19.104 18.272 19.448 18.272 19.448C20.04 19.392 20.72 18.232 20.72 18.232C20.72 15.656 19.568 13.568 19.568 13.568C18.416 12.704 17.32 12.728 17.32 12.728L17.208 12.856C18.568 13.272 19.2 13.872 19.2 13.872C18.368 13.416 17.552 13.192 16.792 13.104C16.216 13.04 15.664 13.056 15.176 13.12C15.1341 13.12 15.0983 13.1261 15.0579 13.133L15.04 13.136C14.76 13.16 14.08 13.264 13.224 13.64C12.928 13.776 12.752 13.872 12.752 13.872C12.752 13.872 13.416 13.24 14.856 12.824L14.776 12.728C14.776 12.728 13.68 12.704 12.528 13.568C12.528 13.568 11.376 15.656 11.376 18.232C11.376 18.232 12.048 19.392 13.816 19.448C13.816 19.448 14.112 19.088 14.352 18.784C13.336 18.48 12.952 17.84 12.952 17.84C12.952 17.84 13.032 17.896 13.176 17.976C13.184 17.984 13.192 17.992 13.208 18C13.22 18.008 13.232 18.014 13.244 18.02C13.256 18.026 13.268 18.032 13.28 18.04C13.48 18.152 13.68 18.24 13.864 18.312C14.192 18.44 14.584 18.568 15.04 18.656C15.64 18.768 16.344 18.808 17.112 18.664C17.488 18.6 17.872 18.488 18.272 18.32C18.552 18.216 18.864 18.064 19.192 17.848C19.192 17.848 18.792 18.504 17.744 18.8ZM13.736 16.6C13.736 16.112 14.096 15.712 14.552 15.712C15.008 15.712 15.376 16.112 15.368 16.6C15.368 17.088 15.008 17.488 14.552 17.488C14.104 17.488 13.736 17.088 13.736 16.6ZM16.656 16.6C16.656 16.112 17.016 15.712 17.472 15.712C17.928 15.712 18.288 16.112 18.288 16.6C18.288 17.088 17.928 17.488 17.472 17.488C17.024 17.488 16.656 17.088 16.656 16.6Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM9 10.648C9 9.736 9.736 9 10.64 9H21.36C22.264 9 23 9.736 23 10.648V25L21.28 23.48L20.312 22.584L19.288 21.632L19.712 23.112H10.64C9.736 23.112 9 22.376 9 21.464V10.648Z"
      fill="currentColor"
    />
  </svg>
</template>
