<template>
  <form-field-input
    type="textarea"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<script setup>
import FormFieldInput from '@/components/input/FormFieldInput.vue'
/**
 * Alias for generic input field with textarea type.
 */


const emit = defineEmits(['update:modelValue'])


















































defineOptions({
  name: 'FormFieldInputTextarea',
})


















































const props = defineProps({
  /**
   * The value of the input field.
   * @model
   */
  modelValue: {
    type: String,
    default: null,
  },
})
</script>

<style scoped></style>