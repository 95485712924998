import { Model } from '@/models/Model'

export default class TicketSource extends Model {
  id: number | null = null
  name: string = ''
  description: string = ''
  starting_tickets: number = 0
  points_per_ticket: number = 0
  repeat_limit_count: number = 0
  expires_at: string = ''
}
