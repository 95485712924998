export const buttonVariants = [
  'primary',
  'secondary',
  'gray-secondary',
] as const
export type ButtonVariant = (typeof buttonVariants)[number]

export const inputVariants = [
  'default',
  'outline',
  'minimal',
  'gray',
  'light-gray',
  'dark-gray',
  'minimal-gray',
  'searchbar',
  'searchbar-white',
  'searchbar-gray',
] as const
export const inputLayouts = ['row', 'col', 'grid'] as const

export type InputVariant = (typeof inputVariants)[number]
export type InputLayout = (typeof inputLayouts)[number]
