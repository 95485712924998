import User from '@/models/User'
import { computed, ComputedRef, inject, Ref } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import useCachedResource, {
  CachedResourceWithCallback,
} from '@/utils/composables/cache'
import axios from 'axios'

export default function useAuth(): CachedResourceWithCallback<User> & {
  id: number | null
  user: Ref<User | null>
  isAuthenticated: ComputedRef<boolean>
  logout: () => void
} {
  const auth = inject('auth')
  const page = usePage()
  auth.id = page.props.uid as number
  const isAuthenticated = computed(() => page.props.uid !== null)

  const resource = useCachedResource<User>(
    'user',
    () => axios.get<User>('/api/user'),
    {
      enabled: isAuthenticated,
    }
  )

  const logout = () => {
    console.log('🚓 Logging out')
    resource.data.value = null
    resource.save(null)
    console.log('🧹 Clearing local storage')
    localStorage.clear()
    indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        console.log('🧹 Clearing indexedDB', db.name)
        indexedDB.deleteDatabase(db.name)
      })
    })
    auth.id = null
    router.post('/logout')
    // router.visit('/', {
    //   onFinish: () => {
    //   }
    // })
  }
  auth.logout = logout

  return {
    ...resource,
    id: (page.props.uid as number) ?? null,
    isAuthenticated,
    user: resource.data,
    logout,
  }
}
