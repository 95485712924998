import { Model } from '@/models/Model'
import TicketSource from '@/models/TicketSource'
import RafflePrize from "@/models/RafflePrize";
import Image from "@/models/Image";

export default class Raffle extends Model {
  id: number | null = null
  name: string = ''
  short_name: string = ''
  description: string = ''
  starts_at: string = ''
  ends_at: string = ''
  announces_at: string = ''
  is_open: boolean = false
  is_global_sources_enabled: boolean = false
  ticket_sources: TicketSource[] = []
  prizes: RafflePrize[] = []

  thumbnail: Image | null = null
  banner: Image | null = null
  social: Image | null = null
}
