<template>
  <modal-buttons-layout
    :title="null"
    buttons-bold
    buttons-without-border
    primary-button="Create"
    secondary-button="Cancel"
    @save="emit('primary')"
  >
    <meetup-form
      :form="form"
    />
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import { useModal } from '@/utils/modal'
import useConventionList from '@/utils/composables/conventionList'
import MeetupForm from '@/components/forms/activity/MeetupForm.vue'
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import { PropType } from 'vue'
import { Form, ModelForm } from '@/utils/form'
import { EventActivity } from '@/models/EventActivity'

const emit = defineEmits(['primary'])

const props = defineProps({
  form: {
    type: Object as PropType<ModelForm<EventActivity>>,
    required: true
  }
})
</script>

<style scoped></style>
