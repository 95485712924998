<template>
  <component
    :is="is ?? 'button'"
    type="button"
    class="border border-dashed text-gray-500 rounded hover:bg-gray-100 duration-100 hover:text-gray-600"
    @click="emit('click', $event)"
  >
    <slot>
      <font-awesome-icon icon="plus" />
      <slot name="text" />
    </slot>
  </component>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['click'])

const props = defineProps({
  is: {
    type: String,
    default: 'button',
  },
})
</script>

<style scoped></style>