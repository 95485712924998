<template>
  <slide-modal
    class="left-1/2 top-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform items-center sm:max-w-[400px]"
    :is-popup-open="isPopupOpen"
    backdrop-class="backdrop-blur"
    animation="fade"
    direction="custom"
    @close="emit('close')"
  >
    <div
      class="flex min-h-[300px] w-full overflow-hidden rounded-lg bg-white px-4 py-5 md:min-h-0"
    >
      <transition
        name="fade-in"
        mode="out-in"
      >
        <div
          v-if="!loading"
          class="flex grow flex-col justify-evenly"
        >
          <div
            v-if="!hideCross"
            class="flex justify-between"
          >
            <slot name="title">
              <div />
            </slot>
            <font-awesome-icon
              icon="xmark"
              class="cursor-pointer p-2"
              @click="emit('close')"
            />
          </div>
          <slot />
          <div
            class="mt-4 flex gap-2"
            :class="{
              'flex-col-reverse justify-start md:flex-row-reverse':
                buttonsReverse,
              'flex-col justify-end md:flex-row': !buttonsReverse,
            }"
          >
            <button
              :disabled="cancelDisabled"
              :class="{
                'bg-blue-800 text-white hover:bg-blue-900 disabled:bg-blue-500':
                  danger,
                'bg-gray-300 text-black hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400':
                  !danger,
              }"
              class="rounded-lg px-4 py-2 transition-colors duration-100"
              @click="emit('cancel')"
            >
              {{ secondaryButtonText }}
            </button>
            <button
              :disabled="confirmDisabled"
              :class="{
                'bg-red-800 hover:bg-red-700 disabled:bg-red-500': danger,
                'bg-blue-800 hover:bg-blue-900 disabled:bg-blue-500': !danger,
              }"
              class="rounded-lg px-4 py-2 text-white transition-colors duration-100"
              @click="emit('confirm')"
            >
              {{ primaryButtonText }}
            </button>
          </div>
        </div>
        <div
          v-else
          class="flex grow items-center justify-center"
        >
          <svg
            class="origin-center animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path
              d="M136,32V64a8,8,0,0,1-16,0V32a8,8,0,0,1,16,0Zm88,88H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.09,47.6a8,8,0,0,0-11.31,11.31l22.62,22.63a8,8,0,0,0,11.32-11.32ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184ZM77.09,167.6,54.46,190.22a8,8,0,0,0,11.32,11.32L88.4,178.91A8,8,0,0,0,77.09,167.6ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM65.78,54.46A8,8,0,0,0,54.46,65.78L77.09,88.4A8,8,0,0,0,88.4,77.09Z"
            />
          </svg>
        </div>
      </transition>
    </div>
  </slide-modal>
</template>

<script setup>
import SlideModal from '@/components/deprecated/SlideModal.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['close', 'confirm', 'cancel'])

const props = defineProps({
  isPopupOpen: {
    type: Boolean,
    default: false,
  },
  hideCross: {
    type: Boolean,
    default: false,
  },
  buttonsReverse: {
    type: Boolean,
    default: false,
  },
  primaryButtonText: {
    type: String,
    default: 'Confirm',
  },
  secondaryButtonText: {
    type: String,
    default: 'Cancel',
  },
  danger: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  confirmDisabled: {
    type: Boolean,
    default: false,
  },
  cancelDisabled: {
    type: Boolean,
    default: false,
  },
})
</script>

<style scoped>
.fade-in-enter-active,
.fade-in-leave-active {
  transition: 0.1s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>
