<template>
  <form-field-input
    :model-value="focused ? modelValue : displayValue"
    @blur="onBlur"
    @focus="onFocus"
    @update:model-value="emit('update:modelValue', $event)">
  </form-field-input>
</template>

<script lang="ts" setup>
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import { computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: '',
  },
  displayUnits: {
    type: String,
    default: 'hour',
    validator: (value: string) => ['hour', 'minute', 'second'].includes(value),
  },
  inputUnits: {
    type: String,
    default: 'minute',
    validator: (value: string) => ['hour', 'minute', 'second'].includes(value),
  },
})

const focused = ref(false)

const seconds = computed(() => {
  switch (props.inputUnits) {
    case 'hour':
      return +props.modelValue * 60 * 60
    case 'minute':
      return +props.modelValue * 60
    case 'second':
      return +props.modelValue
  }
  return NaN
})

const displayValue = computed(() => {
  if (isNaN(seconds.value) || seconds.value === 0) {
    return ''
  }
  if (seconds.value < 0) {
    return props.modelValue
  }

  const h = Math.floor(seconds.value / 60 / 60)
  const m = Math.floor((seconds.value - h * 60 * 60) / 60)
  const s = seconds.value - h * 60 * 60 - m * 60

  switch (props.displayUnits) {
    case 'hour':
      if (m > 0) {
        return `${h}h ${m}m`
      }
      return `${h}h`
    case 'minute':
      if (s > 0) {
        return `${m}m ${s}s`
      }
      return `${m}m`
    case 'second':
      return `${s}s`
  }

  return ''
})

function onFocus() {
  focused.value = true
}

function onBlur() {
  focused.value = false
}
</script>

<style scoped></style>