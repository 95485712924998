import { Model } from "@/models/Model";
import Address from "@/models/Address";
import Venue from "@/models/Venue";
import VenueSpace from "@/models/VenueSpace";
import VenueStation from "@/models/VenueStation";

export default class Location extends Model {
  id: number | null = null
  type: string = 'map_point'
  address: Address | null = null
  venue: Venue | null = null
  venue_space: VenueSpace | null = null
  venue_station: VenueStation | null = null
  latitude: number = 0
  longitude: number = 0
}