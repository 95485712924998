<template>
  <footer :class="footerClass">
    <div
      class="mx-auto flex max-w-7xl flex-col-reverse items-center justify-between gap-4 px-4 sm:px-6 lg:flex-row lg:px-8"
    >
      <div class="flex flex-col items-center md:flex-row">
        <fankind-logo
          v-if="showLogo"
          :class="logoClass"
          class="mr-4 h-10 w-auto max-w-[8rem]"
        />
        <p
          :class="textClass"
          class="text-sm"
        >
          &copy;{{ new Date().getFullYear() }} Hidden Planet. All rights
          reserved.
        </p>
      </div>

      <hr class="background-text-white mt-3 w-full md:hidden" />
      <div class="mx-auto flex items-center gap-[30px] lg:mr-4">
        <a
          aria-label="Fankind Instagram"
          href="https://www.instagram.com/wearefankind/"
          rel="noopener noreferrer"
          target="_blank"
          class="cursor-pointer text-text-white hover:text-blue-primary"
        >
          <instagram-icon class="h-6 w-6" />
        </a>
        <a
          aria-label="Fankind Twitter/X"
          href="https://twitter.com/wearefankind"
          rel="noopener noreferrer"
          target="_blank"
          class="text-text-white hover:text-blue-primary"
        >
          <twitter-icon class="h-6 w-6" />
        </a>
        <a
          aria-label="Fankind Facebook"
          href="https://www.facebook.com/wearefankind"
          rel="noopener noreferrer"
          target="_blank"
          class="text-text-white hover:text-blue-primary"
        >
          <facebook-icon class="h-6 w-6" />
        </a>
      </div>
      <div
        class="flex w-full items-center justify-between gap-3 text-sm sm:justify-center md:w-fit"
      >
        <a
          :class="linkClass"
          href="/legal/terms"
        >
          Terms of Use
        </a>
        <a
          :class="linkClass"
          href="/legal/privacy"
        >
          Privacy Policy
        </a>
        <a
          :class="linkClass"
          href="https://forms.gle/qh7MyzZK89veLe2o8"
          rel="noopener noreferrer"
          target="_blank"
        >
          Contact Us
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'

import FankindLogo from '@/icons/FankindLogo.vue'

import TwitterIcon from '@/icons/TwitterIcon.vue'
import FacebookIcon from '@/icons/FacebookIcon.vue'
import InstagramIcon from '@/icons/InstagramIcon.vue'

const props = defineProps({
  showLogo: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    default: 'light',
    validator: (value) => ['light', 'dark'].includes(value),
  },
})

const footerClass = computed(() => {
  return props.variant === 'dark'
    ? 'py-4 w-full relative bg-very-dark-blue'
    : 'py-4 w-full relative bg-light-gray-gradient'
})

const logoClass = computed(() => {
  return props.variant === 'dark' ? 'text-text-white' : ''
})

const textClass = computed(() => {
  return props.variant === 'dark' ? 'text-text-white' : 'text-gray-500'
})

const linkClass = computed(() => {
  return props.variant === 'dark'
    ? 'text-text-white hover:text-gray-900'
    : 'text-gray-500 hover:text-gray-900'
})
</script>

<style scoped>
.bg-light-gray-gradient {
  background-image: linear-gradient(180deg, #e2e8f0 10%, #ffffff 60%);
}
</style>
