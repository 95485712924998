import { Model } from '@/models/Model'
import Venue from '@/models/Venue'
import VenueStation from '@/models/VenueStation'
import ILocation from '@/models/ILocation'

export default class VenueSpace extends Model implements ILocation {
  id: number | null = null
  convention_event_id: number | null = null
  convention_event: unknown | null = null
  venue_id: number | null = null
  venue: Venue | null = null
  name: string = ''
  slug: string = ''
  type: string = ''
  description: string = ''
  location_info: string = ''
  latitude: number = 0
  longitude: number = 0
  stations: VenueStation[] = []

  get locationType(): string {
    return 'venue_space'
  }

  get parentId(): number | null {
    return this.venue_id
  }
}
