import { Model } from './Model'
import Image from '@/models/Image'
import User from '@/models/User'
import Tag from '@/models/Tag'
import CombinedLocation from '@/models/CombinedLocation'
import EventDay from '@/models/EventDay'
import { DefaultApiParams } from '@/utils/api'
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query'
import axios from 'axios'

export class EventActivity extends Model {
  id: number | null = null
  convention_event_id: number | null = null

  // general
  name: string = ''
  short_name: string = ''
  description: string = ''
  short_description: string = ''

  attendees: User[] = []
  attendee_count: number = 0
  comments_count: number = 0

  // tags
  attributes: any | null = null
  type: Tag | null = null
  tags: Tag[] = []
  topic_tags: Tag[] = []
  fandom_tags: Tag[] = []

  // time
  day_id: number | null = null
  event_day: EventDay | null = null
  starts_at: string | null = null
  ends_at: string | null = null

  // location
  location_id: number | null = null
  location_type: string = ''
  latitude: number | null | undefined = undefined
  longitude: number | null | undefined = undefined
  location: CombinedLocation | null = null
  location_directions: string = ''

  // visibility
  state: string = ''
  publish_at: string | null = null
  is_public: number = 0

  organiser: User | null = null
  assignee: User | null = null
  status: string = ''

  // TODO: this will be changed to SpecialGuest later on
  guests: Array<User> = []
  groups: Array<unknown> = []

  banner: Image | null = null

  // TODO: remove this
  banner_image: unknown = null
  social_image: unknown = null

  created_at: string = ''
  updated_at: string = ''
  created_by: User | null = null
  updated_by: User | null = null

  tasks: Array<unknown> = []
}
