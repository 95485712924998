<template>
  <modal-buttons-layout
    title="Merge Records"
    disable-bottom
    primary-button="Continue"
    secondary-button="Cancel"
    @cancel="emit('close')"
    @save="merge">
    <div v-if="loading" class="flex grow items-center justify-center">
      <app-spinner />
    </div>
    <div v-else class="flex flex-col gap-5 px-5">
      <p class="text-sm">
        Select the fields you want to keep. Duplicates Hidden
      </p>
      <table>
        <tr>
          <th></th>
          <th class="text-start">
            <h3 class="text-base font-semibold">{{ sourceTitle }}</h3>
          </th>
          <th class="text-start">
            <h3 class="text-base font-semibold">{{ targetTitle }}</h3>
          </th>
        </tr>
        <template v-for="(value, key) in diff" :key="key">
          <tr v-if="value.source !== value.target" class="border-y">
            <td
              class="whitespace-nowrap bg-gray-100 px-5 py-3 text-base font-bold">
              {{ value.name }}
            </td>
            <td
              :class="{
                'bg-green-100': result[key] === value.source,
                'bg-red-300': result[key] === value.target,
              }"
              @click="result[key] = value.source">
              <p class="line-clamp-3">
                {{ value.source }}
              </p>
            </td>
            <td
              :class="{
                'bg-green-100': result[key] === value.target,
                'bg-red-300': result[key] === value.source,
              }"
              @click="result[key] = value.target">
              <p class="line-clamp-3">
                {{ value.target }}
              </p>
            </td>
          </tr>
        </template>
      </table>

      <template v-if="Object.keys(relations).length">
        <div>
          <h3 class="text-base font-semibold">Relations</h3>
          <p class="text-sm">
            Select the relations you want to keep. Duplicates Hidden
          </p>
        </div>
        <template v-for="(relation, key) in relations" :key="key">
          <div v-if="relation.models.length" class="grid grid-cols-input-field">
            <div>
              <h4 class="text-sm font-semibold">
                {{ relation.name }}
              </h4>
              <template v-if="relation.type === 'many'">
                <button
                  v-if="result[key]?.length !== relation.models.length"
                  class="whitespace-nowrap text-xs text-blue-primary"
                  @click="result[key] = relation.models.map((m) => m.id)">
                  Select all
                </button>
                <button
                  v-else
                  class="whitespace-nowrap text-xs text-blue-primary"
                  @click="result[key] = []">
                  Deselect all
                </button>
              </template>
            </div>
            <div class="flex grow items-start gap-2 overflow-x-auto">
              <div
                v-for="model in relation.models"
                :key="model.id"
                :class="{
                  'border-red-300 bg-red-100':
                    (relation.type === 'one' &&
                      result[key]?.id !== model.id &&
                      result[key]?.type !== model.type) ||
                    (relation.type === 'many' &&
                      !result[key]?.includes(model.id)),
                  'border-green-300 bg-green-100':
                    (relation.type === 'one' &&
                      result[key]?.id === model.id &&
                      result[key]?.type === model.type) ||
                    (relation.type === 'many' &&
                      result[key]?.includes(model.id)),
                }"
                class="flex shrink-0 flex-col rounded-lg border px-3 py-2"
                @click="selectRelation(key, model)">
                <image-component
                  v-if="model.type === 'App\\Models\\Image'"
                  :src="model.title"
                  class="h-20" />
                <h5 v-else class="text-xs font-semibold">
                  {{ model.title }}
                </h5>
                <small>
                  {{ model.subtitle }}
                </small>
              </div>
              <v-button
                v-if="
                  relation.type === 'one' &&
                  relation.models.every(
                    (m) => m.type === relation.models[0].type
                  )
                "
                class="rounded-xl px-3"
                size="sm"
                variant="secondary"
                @click="
                  modalHandler.open(ModelDiffModal, {
                    onClosed: () => {
                      loadDiff()
                    },
                    props: {
                      position: 'center',
                      size: 'lg',
                      type: relation.key,
                      sourceId: relation.models[0].id,
                      targetId: relation.models[1].id,
                    },
                  })
                ">
                Merge
              </v-button>
            </div>
          </div>
        </template>
      </template>
    </div>
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import axios from 'axios'
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import AppSpinner from '@/components/ui/AppSpinner.vue'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import VButton from '@/components/buttons/VButton.vue'
import { useModal } from '@/utils/modal'
import ModelDiffModal from '@/components/modal/ModelDiffModal.vue'

const emit = defineEmits(['close'])

const props = defineProps({
  sourceId: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  targetId: {
    type: Number,
    required: true,
  },
  resourceModalType: {
    type: String,
    default: () => undefined,
  },
})

const modalHandler = useModal()
const resourceModalHandler = useResourceModal()

const loading = ref(true)
const name = ref('')
const sourceTitle = ref('')
const targetTitle = ref('')
const diff = ref(null)
const relations = ref({})
const result = ref({})

function selectRelation(key: string, relation) {
  if (relations.value[key].type === 'many') {
    if (result.value[key]) {
      if (result.value[key].includes(relation.id)) {
        result.value[key] = result.value[key].filter((i) => i !== relation.id)
      } else {
        result.value[key].push(relation.id)
      }
    } else {
      result.value[key] = [relation.id]
    }
  } else {
    result.value[key] = {
      id: relation.id,
      type: relation.type,
    }
  }
}

function merge() {
  axios
    .post(
      `/api/partner/merge/${props.type}/${props.sourceId}/${props.targetId}`,
      {
        result: result.value,
      }
    )
    .then((response) => {
      emit('close')
      if (props.resourceModalType)
        resourceModalHandler.openEdit(props.resourceModalType, response.data)
    })
}

function loadDiff() {
  axios
    .get(
      `/api/partner/compare/${props.type}/${props.sourceId}/${props.targetId}`
    )
    .then((response) => {
      name.value = response.data.name
      sourceTitle.value = response.data.source_title
      targetTitle.value = response.data.target_title
      diff.value = response.data.diff
      relations.value = response.data.relations_diff
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  loadDiff()
})
</script>

<style scoped></style>
