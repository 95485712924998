import { computed, ComputedRef, Ref, ref, watch } from 'vue'
import ConventionEvent from '@/models/ConventionEvent'
import axios, { AxiosRequestConfig } from 'axios'
import { DateTime } from 'luxon'
import { usePage } from '@inertiajs/vue3'
import useCachedResource, {
  CachedResourceWithCallback,
} from '@/utils/composables/cache'

const lastSelected = ref<number | null>(
  localStorage.getItem('lastSelectedConvention')
    ? JSON.parse(localStorage.getItem('lastSelectedConvention') as string)
    : null
)

function select(convention: ConventionEvent | null | undefined) {
  if (convention) {
    localStorage.setItem(
      'lastSelectedConvention',
      JSON.stringify(convention.id)
    )
  } else {
    localStorage.removeItem('lastSelectedConvention')
  }
}

function parseUrlSlug(url: string) {
  if (url.startsWith('/events')) {
    return url.match(/\/events\/([^/]+)/)?.[1]
  }
  return null
}

export default function useConventionList(): CachedResourceWithCallback<
  ConventionEvent[]
> & {
  list: Ref<ConventionEvent[] | null>
  selected: ComputedRef<ConventionEvent | undefined>
  select: (convention: ConventionEvent | null | undefined) => void
  upcoming: ComputedRef<ConventionEvent[] | undefined>
  past: ComputedRef<ConventionEvent[] | undefined>
  current: ComputedRef<ConventionEvent[] | undefined>
  featured: ComputedRef<ConventionEvent | undefined>
  fetch: (id: number, options?: AxiosRequestConfig) => Promise<any>
} {
  const page = usePage()

  const resource = useCachedResource<ConventionEvent[]>(
    'conventions',
    () => axios.get(`/api/conventions`),
    { force: true, baseClass: ConventionEvent }
  )

  const sortByStartDate = (a: ConventionEvent, b: ConventionEvent) => {
    return a.startsAt.toMillis() - b.startsAt.toMillis()
  }

  const upcoming = computed<Array<ConventionEvent> | undefined>(() => {
    return resource.data.value
      ?.filter((convention) => {
        return convention.startsAt > DateTime.now()
      })
      .sort(sortByStartDate)
  })

  const past = computed<Array<ConventionEvent> | undefined>(() => {
    return resource.data.value
      ?.filter((convention) => {
        return convention.endsAt < DateTime.now()
      })
      .sort(sortByStartDate)
  })

  const current = computed<Array<ConventionEvent> | undefined>(() => {
    return resource.data.value
      ?.filter((convention) => {
        return (
          convention.startsAt < DateTime.now() &&
          convention.endsAt > DateTime.now()
        )
      })
      .sort(sortByStartDate)
  })

  // next upcoming convention or ongoing convention, if none found, return last past convention
  const featured = computed<ConventionEvent | undefined>(() => {
    return current.value?.[0] ?? upcoming.value?.[0] ?? past.value?.[0]
  })

  const urlSlug = computed(() => {
    return parseUrlSlug(page.url)
  })

  const selected = computed<ConventionEvent | undefined>(() => {
    return (
      resource.data.value?.find((convention: ConventionEvent) => {
        // if we cannot access selected convention, return last selected convention
        if (urlSlug.value === null) {
          if (page.url === '/' || !lastSelected.value) {
            return convention.id === featured.value?.id
          }
          return convention.id === lastSelected.value
        }
        return convention.slug === urlSlug.value || convention.id === +urlSlug.value
      }) ?? featured.value
    )
  })

  watch(
    () => selected.value,
    (convention) => {
      if (convention) {
        localStorage.setItem(
          'lastSelectedConvention',
          JSON.stringify(convention.id)
        )
      }
    },
    { immediate: true }
  )

  return {
    ...resource,
    list: resource.data,
    selected,
    select,
    upcoming,
    past,
    current,
    featured,
    fetch(id: number, options: AxiosRequestConfig = {}) {
      return axios.get(`/api/conventions/${id}`, options).then((response) => {
        if (resource.data.value) {
          const convention = resource.data.value.find((c) => c.id === id)
          if (convention) {
            convention.fillFromResponse(response.data)
          } else {
            resource.data.value.push(ConventionEvent.fromData(response.data) as ConventionEvent)
          }
        } else {
          resource.data.value = [ConventionEvent.fromData(response.data) as ConventionEvent]
        }
        resource.save(resource.data.value)
        return response
      })
    },
  }
}
