<template>
  <modal
    :open="open"
    position="center"
    @closed="emit('closed')"
    @update:open="emit('update:open', $event)"
  >
    <modal-list-layout
      :items="searchQuery?.length === 0 ? [] : foundTags"
      :loading="searchQuery?.length === 0 ? false : loading"
      back-button
      disable-bottom
      primary-button="Save"
      secondary-button="Cancel"
      title="Fandoms & Topics"
      @cancel="emit('update:open', false)"
      @save="
        () => {
          emit('save')
          emit('update:open', false)
        }
      "
      @update:search="searchQuery = $event"
    >
      <template
        v-if="searchQuery?.length === 0"
        #list
      >
        <div class="flex grow flex-col px-2">
          <div
            class="grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-3 md:gap-x-4 md:gap-y-8"
          >
            <div
              v-for="tag in highlightedTags"
              :key="tag.id"
              class="flex cursor-pointer flex-col items-center justify-center gap-10 hover:shadow-lg"
              @click="toggleTag(tag)"
            >
              <div
                :class="[
                  isSelected(tag.id) ? 'border-[#100da8]' : 'border-gray-200',
                ]"
                class="border-px relative h-full w-full overflow-hidden rounded-lg text-center shadow transition-colors duration-100"
              >
                <transition
                  enter-active-class="duration-100"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <div
                    v-if="isSelected(tag.id)"
                    class="absolute right-0 top-0 m-1 flex h-8 w-8 items-center justify-center rounded-full bg-blue-primary"
                  >
                    <font-awesome-icon
                      class="text-white"
                      icon="check"
                    />
                  </div>
                </transition>
                <background-image-component
                  :src="tag.image_path"
                  class="flex aspect-video w-full flex-shrink-0 bg-cover bg-center"
                  fallback-src="https://via.placeholder.com/600"
                />
                <!--                <image-component-->
                <!--                  :src="tag.image?.path"-->
                <!--                  class="h-28 w-40 object-cover md:h-28 md:w-40" />-->
                <span class="py-2 text-center text-xs">{{ tag.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #before-list>
        <div
          class="sticky top-0 z-[6] flex flex-col gap-2 bg-white bg-opacity-75 pb-5 pt-6 backdrop-blur"
        >
          <div class="flex items-center justify-between px-3">
            <h3 class="font-heading text-sm font-bold text-gray-400">
              <span v-if="searchQuery?.length === 0">Popular topics</span>
              <span v-else>Search results</span>
            </h3>
            <div
              v-if="max"
              ref="maxPill"
              class="rounded-full bg-black px-2 text-sm text-white"
            >
              {{ modelValue?.length }} / {{ max }}
            </div>
          </div>
          <div
            v-if="modelValue?.length"
            class="flex flex-row gap-4 overflow-x-scroll px-3 text-space-blue"
          >
            <h5
              v-for="tag in modelValue"
              :key="tag.id"
              class="cursor-pointer select-none gap-1 whitespace-nowrap rounded bg-gray-100 px-2 py-1 font-heading text-sm hover:bg-gray-200"
              @click.stop="toggleTag(tag)"
            >
              {{ tag.name }}
              <font-awesome-icon
                class="text-black"
                icon="xmark"
              />
            </h5>
          </div>
        </div>
      </template>
      <template #list-item="{ item }">
        <div
          class="flex select-none items-center gap-2 p-2 duration-100 hover:bg-gray-100"
          @click="toggleTag(item)"
        >
          <div
            :class="[
              isSelected(item.id) ? 'border-[#100da8]' : 'border-gray-200',
            ]"
            class="flex h-5 w-5 select-none items-center justify-center rounded border"
          >
            <transition
              enter-active-class="duration-100"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <font-awesome-icon
                v-if="isSelected(item.id)"
                class="text-blue-primary"
                icon="check"
              />
            </transition>
          </div>
          {{ item.name }}
          <small
            class="rounded-full bg-black px-2 py-1 text-xs leading-none text-white"
          >
            {{ item.type }}
          </small>
        </div>
      </template>
    </modal-list-layout>
  </modal>
</template>

<script setup>
import Modal from '@/components/modal/Modal.vue'
import { useDebounceFn } from '@vueuse/core'
import axios from 'axios'
import { onMounted, ref, watch } from 'vue'
import ModalListLayout from '@/components/modal/layout/ModalListLayout.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'

const emit = defineEmits(['update:open', 'update:modelValue', 'closed', 'save'])

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  max: {
    type: Number,
    default: null,
  },
})

const searchQuery = ref('')
const searchTagsDebounce = useDebounceFn(search, 300)
const empty = ref('')
const loading = ref(false)
const foundTags = ref([])
const maxPill = ref(null)
const highlightedTags = ref([])

function isSelected(tagId) {
  return props.modelValue.map((tag) => tag.id).includes(tagId)
}

function toggleTag(tag) {
  if (isSelected(tag.id)) {
    emit(
      'update:modelValue',
      props.modelValue.filter((t) => t.id !== tag.id)
    )
  } else {
    if (props.max === null || props.modelValue.length < props.max) {
      emit('update:modelValue', [...props.modelValue, tag])
    } else {
      maxPill.value?.animate(
        [
          { transform: 'translateX(0)', backgroundColor: 'red' },
          { transform: 'translateX(-3px)' },
          { transform: 'translateX(3px)' },
          { transform: 'translateX(-3px)' },
          { transform: 'translateX(3px)' },
          { transform: 'translateX(-3px)' },
          { transform: 'translateX(3px)' },
          { transform: 'translateX(-3px)' },
          { transform: 'translateX(0)' },
        ],
        {
          duration: 300,
        }
      )
    }
  }
}

function search() {
  loading.value = true
  return axios
    .get('/api/autocomplete/tags', {
      params: {
        query: searchQuery.value,
      },
    })
    .then((response) => {
      foundTags.value = response.data
      return response
    })
    .finally(() => {
      loading.value = false
    })
}

watch(
  () => searchQuery.value,
  (value) => {
    if (value !== '') {
      searchTagsDebounce()
    }
  }
)

onMounted(() => {
  search().then((res) => {
    highlightedTags.value = res.data
  })
})
</script>
