<template>
  <component
    :is="type === 'textarea' ? 'textarea' : 'input'"
    :id="forId ?? inject('input-id', $.uid)"
    :key="type"
    ref="input"
    :class="{
      'placeholder:text-gray-800': variant === 'dark-gray',
      'py-3': variant === 'default',
    }"
    :disabled="props.disabled"
    :max="props.max"
    :min="props.min"
    :placeholder="placeholder"
    :rows="rows"
    :step="step"
    :type="type"
    :value="modelValue"
    autocomplete="off"
    class="w-full resize-none appearance-none bg-transparent focus:outline-none"
    @blur="emit('blur', $event)"
    @focus="emit('focus', $event)"
    @input="
      ($event) => {
        emit('update:modelValue', $event.target.value)
        resizeTextarea($event)
      }
    "
  />
</template>

<script lang="ts" setup>
import { inject, onMounted, ref } from 'vue'

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  forId: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: [String, Number, Boolean],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  step: {
    type: String,
    default: 'any',
  },
  min: {
    type: String,
    default: null,
  },
  max: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  rows: {
    type: [Number, String],
    default: 3,
  },
  variant: {
    type: String,
    default: 'default', // default, outline, minimal, gray, searchbar
  },
})

const input = ref<HTMLInputElement | null>(null)
onMounted(async () => {
  requestAnimationFrame(() => {
    if (input.value) input.value.style.height = `${input.value.scrollHeight}px`
    if (props.autofocus) input.value?.focus()
  })
})

const resizeTextarea = (event) => {
  const textarea = event.target
  textarea.style.height = 'auto'
  textarea.style.height = `${textarea.scrollHeight}px`
}

function focus() {
  input.value?.focus()
}

function blur() {
  input.value?.blur()
}

defineExpose({
  focus,
  blur,
})
</script>

<style scoped></style>
