<template>
  <form-field-select-item
    :search-function="
      (value) =>
        axios
          .get(`/api/autocomplete/events/${eventId}/locations`, {
            params: {
              query: value,
            },
          })
          .then((res) => {
            res.data = res.data.map((location) => {
              return {
                id: location.id,
                name: location.name,
                type: location.type,
                venue: {
                  name: location.venue,
                },
                venue_space: {
                  name: location.space,
                },
                venue_station: {
                  name: location.station,
                },
              }
            })
            return res
          })
    "
    :selected="selected"
    key-by="id"
    labels="top"
    placeholder="Select a location"
    search-by="name"
    single-select
    @update:selected="emit('update:selected', $event)"
  >
    <template #item="{ item }">
      <div class="flex flex-col py-1">
        <div class="flex items-center">
          <p class="line-clamp-1">
            {{ item.name }}
          </p>
          <small
            class="ml-auto shrink-0 rounded-xl bg-black px-1 text-xs text-white"
          >
            {{ item.type }}
          </small>
        </div>
        <small
          v-if="item.type !== 'venue' && item.venue"
          class="text-xs text-gray-700"
        >
          {{ item.venue.name }}
          <span v-if="item.type !== 'venue_space' && item.venue_space">
            - {{ item.venue_space }}
          </span>
        </small>
      </div>
    </template>
    <template #selected-item="{ item, remove }">
      <div
        class="rounded px-2 py-1 hover:bg-gray-100"
        @click="remove"
      >
        <p class="line-clamp-1">
          {{
            item.venue_station?.name ??
            item.venue_space?.name ??
            item.venue?.name
          }}
        </p>
        <small
          v-if="item.type !== 'venue' && item.venue"
          class="text-xs text-gray-700"
        >
          {{ item.venue.name }}
          <span v-if="item.type !== 'venue_space' && item.venue_space">
            - {{ item.venue_space.name }}
          </span>
        </small>
      </div>
    </template>
  </form-field-select-item>
</template>

<script setup>
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import axios from 'axios'

/**
 * Location select form field. Can be used to select a venue, space or station.
 * Provide either venues, spaces and stations or locations. Locations array should contain type of location and parent id.
 * @usage <form-field-select-location v-model="selectedLocation" :venues="venues" :spaces="spaces" :stations="stations" layout="grid"/>
 * @usage <form-field-select-location v-model="selectedLocation" :locations="locations" variant="gray"/>
 * @example {
 *    "title": "Default",
 *    "description": "",
 *    "attrs": {
 *     "variant": "gray",
 *     "locations": [
 *         {
 *           "id": 1,
 *           "type": "venue",
 *           "name": "Venue 1"
 *         },
 *         {
 *         "id": 2,
 *         "type": "space",
 *         "name": "Space 1",
 *         "venue_id": 1
 *         },
 *         {
 *         "id": 3,
 *         "type": "station",
 *         "name": "Station 1",
 *         "space_id": 2
 *         }
 *       ]
 *    }
 * }
 */

const emit = defineEmits(['update:selected'])

defineOptions({
  name: 'FormFieldSelectLocation',
})

const props = defineProps({
  eventId: {
    type: [Number, String],
    default: null,
  },
  selected: {
    type: [Object, Array],
    default: null,
  },
})
</script>

<style scoped></style>
