<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.1719 22.25L13.6745 11.3221L13.6873 11.3324L20.4473 3.5H18.1883L12.6814 9.875L8.3082 3.5H2.3836L9.38316 13.7026L9.38232 13.7017L2 22.25H4.25902L10.3814 15.1574L15.2473 22.25H21.1719ZM7.41313 5.20454L17.9325 20.5455H16.1424L5.61446 5.20454H7.41313Z"
      fill="currentColor"
    />
  </svg>
</template>
