<template>
  <div class="flex min-w-full flex-nowrap gap-2 overflow-x-scroll">
    <div
      v-for="option in options"
      :key="option[keyBy]"
      :class="[
        itemClass,
        isSelected(option)
          ? {
            'bg-blue-secondary-btn text-text-dark-100':
              variant === 'secondary',
            'bg-blue-primary text-white': variant === 'primary',
          }
          : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-700',
        {
          'rounded px-3 py-2 text-sm': size === 'sm',
          'rounded-lg px-4 py-2 min-w-[120px] max-w-[200px]': size === 'md',
        },
      ]"
      class="flex cursor-pointer flex-col items-center gap-1 border transition-colors duration-75"
      @click="emit('update:modelValue', option[keyBy])"
    >
      <slot
        name="item"
        :item="option"
      >
        <span>{{ option.label }}</span>
      </slot>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  options: {
    type: Array,
    default: ()=>([
      {
        label: 'Option 1',
        value: 1,
      },
      {
        label: 'Option 2',
        value: 2,
      },
      {
        label: 'Option 3',
        value: 3,
      },
    ]),
  },
  modelValue: {
    type: [String, Number, Array],
    default: null,
  },
  itemClass: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: 'secondary',
    validator: (value) => ['primary', 'secondary'].includes(value),
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value),
  },
  keyBy: {
    type: String,
    default: 'value',
  },
})

function isSelected(option) {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(option[props.keyBy])
  }

  return props.modelValue === option[props.keyBy]
}
</script>

<style scoped></style>
