<template>
  <header class="bg-dark-blue-gradient z-fixed w-full">
    <!-- higher z index to disable clicks -->
    <div
      v-if="disabled"
      class="absolute inset-0 z-topmost-overlay"
    />
    <div
      class="relative mx-auto flex w-full max-w-screen-2xl flex-row flex-nowrap items-center justify-between gap-2 px-2 py-4 md:gap-8 md:px-4"
    >
      <div class="flex w-full flex-row items-center justify-between @md:gap-4">
        <font-awesome-icon
          class="mobile_menu_icon cursor-pointer px-2 text-lg text-white @md:hidden"
          icon="bars"
          @click="openMobileMenu = true"
        />
        <div
          v-show="openMobileMenu && !disabled"
          class="mobile_menu bg-dark-blue-gradient fixed inset-0 z-modal flex w-full text-center text-white"
        >
          <div class="flex w-full flex-col gap-10">
            <div
              class="flex w-full flex-col items-end justify-end bg-slate-900 py-5 text-white"
            >
              <font-awesome-icon
                class="mb-8 cursor-pointer px-4 text-lg text-white"
                icon="times"
                @click="openMobileMenu = false"
              />
              <div class="flex w-full flex-col justify-center">
                <fankind-logo class="h-4 text-slate-100" />
                <div class="text-slate-400">menu</div>
              </div>
            </div>
            <div class="flex flex-col justify-center gap-4">
              <div class="flex items-center justify-center gap-2">
                <div
                  v-if="
                    selectedConvention &&
                    selectedConvention.startsAt < DateTime.now() &&
                    selectedConvention.endsAt > DateTime.now()
                  "
                  class="animate-pulse rounded-full bg-red-800 px-3"
                >
                  <p class="text-sm font-bold text-white">LIVE</p>
                </div>
                <Link
                  :class="[
                    $page.url.startsWith(`/events/${selectedConvention?.slug}`)
                      ? 'opacity-100'
                      : 'opacity-75 hover:opacity-100',
                  ]"
                  :href="`/events/${selectedConvention?.slug}`"
                  class="text-xl text-white"
                >
                  {{ selectedConvention?.name }}
                </Link>
                <button
                  @click="conventionSelectorOpen = !conventionSelectorOpen"
                >
                  <svg
                    :class="{ 'rotate-180': conventionSelectorOpen }"
                    fill="none"
                    height="24"
                    viewBox="0 0 13 12"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.133 3.83818C10.3681 3.99609 10.3958 4.33142 10.1898 4.52581L6.37773 8.12298C6.21727 8.2744 5.96655 8.2744 5.80608 8.12298L1.99398 4.52581C1.78798 4.33142 1.81571 3.99609 2.05083 3.83818C2.22128 3.72371 2.44814 3.74184 2.59824 3.88193L6.09191 7.14269L9.58558 3.88193C9.73568 3.74184 9.96254 3.7237 10.133 3.83818Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <Link
                v-for="(link, index) in links"
                :key="index"
                :href="link.path"
                class="whitespace-nowrap text-xl text-white opacity-75 hover:opacity-100"
                @click="openMobileMenu = false"
              >
                {{ link.name }}
              </Link>
              <a
                class="whitespace-nowrap text-xl text-white opacity-75 hover:opacity-100"
                href="https://forms.gle/bR9uvtptq4t6VX948"
                rel="noopener noreferrer"
                target="_blank"
              >
                Leave Feedback
              </a>
            </div>
          </div>
        </div>
        <div class="flex flex-1 items-center gap-14">
          <Link href="/">
            <fankind-logo class="h-4 text-white" />
          </Link>
          <div
            v-if="conventionsLoading"
            class="h-6 w-56 animate-pulse rounded bg-gray-100 bg-opacity-25"
          />
          <div
            v-else
            class="group hidden cursor-pointer items-center gap-2 @md:flex"
          >
            <button
              class="flex items-center gap-2 rounded-lg px-2 py-1 duration-100 hover:bg-gray-100 hover:bg-opacity-25"
              @click="router.visit(`/events/${selectedConvention?.slug}`)"
            >
              <div
                v-if="
                  selectedConvention &&
                  selectedConvention.startsAt < DateTime.now() &&
                  selectedConvention.endsAt > DateTime.now()
                "
                class="animate-pulse-bg h-4 w-4 rounded-full bg-red-800 content-[''] @lg:h-auto @lg:w-auto @lg:px-3"
              >
                <p class="hidden text-sm font-bold text-white @lg:block">
                  LIVE
                </p>
              </div>
              <p
                :class="[
                  $page.url.startsWith(`/events/${selectedConvention?.slug}`)
                    ? 'opacity-100'
                    : 'opacity-75 hover:opacity-100',
                ]"
                class="select-none text-base leading-none text-white"
              >
                {{ selectedConvention?.name }}
              </p>
            </button>
            <div
              class="h-4 w-[1px] rounded-full bg-white opacity-75 duration-100 group-hover:opacity-100"
            />
            <button
              class="text-white opacity-75 duration-100 hover:opacity-100"
              @click="conventionSelectorOpen = !conventionSelectorOpen"
            >
              <svg
                :class="{ 'rotate-180': conventionSelectorOpen }"
                class="transform duration-200"
                fill="none"
                height="24"
                viewBox="0 0 13 12"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.133 3.83818C10.3681 3.99609 10.3958 4.33142 10.1898 4.52581L6.37773 8.12298C6.21727 8.2744 5.96655 8.2744 5.80608 8.12298L1.99398 4.52581C1.78798 4.33142 1.81571 3.99609 2.05083 3.83818C2.22128 3.72371 2.44814 3.74184 2.59824 3.88193L6.09191 7.14269L9.58558 3.88193C9.73568 3.74184 9.96254 3.7237 10.133 3.83818Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          class="@md:relative"
          @click.stop
        >
          <button
            :class="{ 'bg-dark-blue': notificationsOpen }"
            class="relative rounded-full px-2"
            @click="notificationsOpen = !notificationsOpen"
          >
            <font-awesome-icon
              :class="[notificationsOpen ? 'opacity-100' : 'opacity-75']"
              class="text-white hover:opacity-100"
              icon="bell"
            />
            <div
              v-if="notifications.length"
              class="absolute -top-1/4 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-800 text-xs text-white"
            >
              {{ notifications.length }}
            </div>
          </button>
          <transition name="fade-appear">
            <div
              v-if="notificationsOpen"
              class="@sm:bg-glass-without-shadow fixed bottom-0 left-0 right-0 top-0 z-popup flex h-screen w-full flex-col overflow-y-auto bg-white text-slate-800 shadow-xl @sm:absolute @sm:bottom-auto @sm:left-auto @sm:top-full @sm:max-h-96 @sm:w-1/2 @sm:rounded-lg @md:w-80"
            >
              <div
                class="bg-glass-without-shadow sticky top-0 flex items-center justify-between border-b border-b-slate-200 px-4 py-2"
              >
                <h1 class="">Notifications</h1>
                <button
                  class="flex aspect-square h-6 items-center justify-center rounded-full p-1 hover:bg-gray-400"
                  @click="notificationsOpen = false"
                >
                  <font-awesome-icon
                    class="h-full w-full"
                    icon="times"
                  />
                </button>
              </div>
              <template v-if="notifications.length === 0">
                <div
                  class="flex flex-col items-center justify-center gap-2 p-4"
                >
                  <h1 class="text-lg font-semibold">No Notifications</h1>
                  <p class="text-center text-sm">
                    You have no notifications at this time.
                  </p>
                </div>
              </template>
              <template v-else>
                <Link
                  v-for="notification in notifications.sort(
                    (a, b) => b.created_at - a.created_at
                  )"
                  :key="notification.id"
                  :href="`/notifications/${notification.id}/read`"
                  class="flex w-full cursor-pointer flex-row items-center gap-2 border-b border-b-slate-400 p-1 last:border-b-0 hover:backdrop-brightness-90"
                >
                  <font-awesome-icon
                    v-if="notification.type === 'achievement.unlocked'"
                    class="h-10 w-10 p-2"
                    icon="star"
                  />
                  <image-component
                    v-else
                    :src="notification.image_path"
                    class="h-16 w-16 shrink-0 rounded-full bg-white object-cover"
                  />
                  <div class="flex w-full flex-col justify-start">
                    <h1 class="text-sm font-semibold">
                      {{ notification.title }}
                    </h1>
                    <p class="line-clamp-3 grow text-xs">
                      {{ notification.body }}
                    </p>
                  </div>
                </Link>
              </template>
            </div>
          </transition>
        </div>
      </div>
      <user-dropdown-menu dropdown-class="top-2" />
    </div>
    <modal
      v-model:open="conventionSelectorOpen"
      position="center"
      size="lg"
    >
      <event-selector-dropdown @close="conventionSelectorOpen = false" />
    </modal>
  </header>
</template>

<script setup>
import FankindLogo from '../../icons/FankindLogo.vue'
import { ref, watch } from 'vue'
import { Link, router } from '@inertiajs/vue3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import useConventionList from '@/utils/composables/conventionList'
import EventSelectorDropdown from '@/components/widgets/EventSelectorDropdown.vue'
import Modal from '@/components/modal/Modal.vue'
import { DateTime } from 'luxon'
import useNotifications from '@/utils/composables/notifications'
import useAuth from '@/utils/composables/auth'
import UserDropdownMenu from '@/components/buttons/UserDropdownMenu.vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const { user, logout } = useAuth()
const { selected: selectedConvention, loading: conventionsLoading } =
  useConventionList()
const { notifications } = useNotifications()

let openMobileMenu = ref(false)

const conventionSelectorOpen = ref(false)

const links = [
  {
    name: 'Raffles',
    path: '/raffles',
  },
]

const notificationsOpen = ref(false)
watch(notificationsOpen, (value) => {
  if (value) {
    requestAnimationFrame(() => {
      document.addEventListener('click', closeNotifications)
    })
  } else {
    document.removeEventListener('click', closeNotifications)
  }
})

function closeNotifications() {
  document.removeEventListener('click', closeNotifications)
  notificationsOpen.value = false
}
</script>

<style scoped>
.bg-dark-blue-gradient {
  background-image: linear-gradient(180deg, #1f1bdb, #100da8);
}
</style>
