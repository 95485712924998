<template>
  <div class="flex flex-col gap-2">
    <div class="grid h-8 grid-cols-input-field items-center gap-3">
      <h6 class="text-xs font-medium">
        {{ label }}
      </h6>
      <button-plus
        v-if="!modelValue.find((d) => d.value === '')"
        class="h-8 w-8"
        @click="
          modelValue.push({
            _id: Math.random().toString(36).substr(2, 9),
            name: defaultLabels[0],
            value: '',
          })
        "
      />
    </div>
    <transition-group
      tag="ul"
      move-class="transition-all"
      enter-active-class="transition-all duration-300"
      leave-active-class="transition-all duration-300"
      enter-from-class="opacity-0 translate-x-[-1rem]"
      enter-to-class="opacity-100 translate-x-0"
      leave-from-class="opacity-100 translate-x-0"
      leave-to-class="opacity-0 translate-x-[-1rem]"
    >
      <div
        v-for="(detail, index) in modelValue"
        :key="detail.id ?? detail._id"
        class="grid grid-cols-input-field items-start gap-3"
      >
        <form-field-select-text
          v-model="detail.name"
          input-class="text-end text-gray-600 capitalize text-sm mt-1"
          hide-icon
          variant="gray"
          :suggestions="
            defaultLabels.map((label) => ({ name: label, value: label }))
          "
          key-by="value"
          search-by="name"
          @select="detail.name = $event.value"
        >
          <template
            v-if="type === 'social'"
            #before-inner
          >
            <brand-icon
              class="h-4 w-4"
              :brand="detail.name"
            >
              <template #fallback>
                <div class="h-4 w-4 shrink-0" />
              </template>
            </brand-icon>
          </template>
          <template #item="{ item }">
            <div class="flex items-center gap-2 whitespace-nowrap">
              <template v-if="type === 'social'">
                <brand-icon
                  class="h-4 w-4"
                  :brand="item.name"
                >
                  <template #fallback>
                    <div class="h-4 w-4 shrink-0" />
                  </template>
                </brand-icon>
                {{ item.name }}
              </template>
              <template v-else>
                {{ item.name }}
              </template>
            </div>
          </template>
        </form-field-select-text>
        <div class="group/contact-detail flex items-start gap-3">
          <form-field-input
            v-model="detail.value"
            :rules="getValidationRules(detail.name)"
            class="grow"
            variant="gray"
            @blur="onBlurContactDetailValue(detail)"
          />
          <div
            class="flex h-11 w-0 items-center justify-center overflow-hidden transition-all group-focus-within/contact-detail:w-8"
            @click="modelValue.splice(index, 1)"
          >
            <trash-button class="h-8 w-8 rounded-full" />
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldSelectText from '@/components/input/FormFieldSelectText.vue'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BrandIcon from '@/components/ui/BrandIcon.vue'
import { computed } from 'vue'
import { brands } from '@/utils/brands'
import TrashButton from '@/components/buttons/TrashButton.vue'
defineOptions({
  name: 'FormFieldInputContactDetail',
})


















































/**
 * Form field for contact details
 * @example {
 *   "label": "Phone",
 *   "attrs": {
 *     "type": "phone",
 *     "label": "Phone",
 *     "modelValue": [
 *      { "name": "Mobile", "value": "1234567890" },
 *      { "name": "Home", "value": "1234567890" },
 *      { "name": "Work", "value": "1234567890" },
 *      { "name": "Fax", "value": "1234567890" },
 *      { "name": "Satellite", "value": "1234567890" },
 *      { "name": "Pager", "value": "1234567890" }
 *     ]
 *   }
 * }
 * @example {
 *   "label": "Email",
 *   "attrs": {
 *    "type": "email",
 *    "label": "Email",
 *    "modelValue": [
 *     { "name": "Personal", "value": "test@example.com" },
 *     { "name": "Work", "value": "test@example.com" },
 *     { "name": "Home", "value": "test@example.com" }
 *    ]
 *   }
 * }
 * @example {
 *  "label": "Social",
 *  "attrs": {
 *   "type": "social",
 *   "label": "Social",
 *   "modelValue": [
 *    { "name": "Website", "value": "https://example.com" },
 *    { "name": "Facebook", "value": "https://facebook.com" },
 *    { "name": "Twitter", "value": "https://twitter.com" },
 *    { "name": "Instagram", "value": "https://instagram.com" },
 *    { "name": "LinkedIn", "value": "https://linkedin.com" }
 *   ]
 *  }
 * }
 */


const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
})

const defaultLabels = computed(() => {
  if (props.type === 'social') {
    const lables = brands.slice().map((brand) => brand.name)
    lables.unshift('Website')
    return lables
  } else if (props.type === 'phone') {
    return ['Mobile', 'Home', 'Work', 'Fax', 'Satellite', 'Pager']
  } else if (props.type === 'email') {
    return ['Personal', 'Work', 'Home']
  }
  return ['']
})

function getValidationRules(type) {
  if (props.type === 'phone') {
    return { phone: true }
  } else if (props.type === 'email') {
    return { email: true }
  } else if (props.type === 'social') {
    const brandConfig = brands.find((brand) => brand.name === type)
    if (brandConfig) {
      return {
        url: true,
        regex: {
          regex: brandConfig.url_regex,
          message: `Please enter a valid ${type} URL.`,
        },
      }
    }
    return {
      url: true,
    }
  }
}

function onBlurContactDetailValue(detail) {
  if (props.type === 'social') {
    // remove the protocol from the url
    detail.value = detail.value.replace(/(^\w+:|^)\/\//, '')

    // clean up url
    detail.value = detail.value.replace(/\/$/, '')

    if (detail.name?.toLowerCase() === 'website') {
      // try to match the url to a brand
      const brand = brands.find((brand) => {
        const regex = new RegExp(brand.url_regex)
        return regex.test(detail.value)
      })
      if (brand) {
        detail.name = brand.name
      }
    }
  }
}
</script>

<style scoped></style>