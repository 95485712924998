<template>
  <div class="flex flex-col items-start justify-between gap-2">
    <slot
      v-for="group in selectedArray"
      :key="group.id"
      name="selected-user"
      :group="group"
    >
      <div class="flex items-center gap-3">
        <avatar-image
          square
          :src="group.avatar?.path"
          :alt="group.name"
          class="h-8 w-8"
        />
        <slot
          name="user-info"
          :group="group"
        >
          <h1 class="text-base font-normal">
            {{ group.name }}
          </h1>
        </slot>
        <slot name="selected-user-buttons" />
        <button
          @click="
            updateSelected(selectedArray.filter((s) => s.id !== group.id))
          "
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>
    </slot>
    <form-field-select-item
      v-model="search"
      search-by="name"
      key-by="id"
      :selected="selected"
      :loading="searching"
      :single-select="singleSelect"
      :suggestions="foundOptions"
      :allow-create="allowCreate"
      :show-button-by-default="showButtonByDefault"
      :labels="labels"
      @create="emit('create', search)"
      @update:selected="updateSelected"
    >
      <template #button="{ click }">
        <button
          class="flex items-center gap-3 text-base text-gray-500"
          @click="click"
        >
          <button-plus
            is="div"
            class="flex h-8 w-8 items-center justify-center rounded-full"
          />
          <template v-if="label">
            Add Group
          </template>
        </button>
      </template>
    </form-field-select-item>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AvatarImage from '@/components/ui/AvatarImage.vue'
import { computed, onMounted, ref, watch } from 'vue'
import axios from 'axios'
import { useDebounceFn } from '@vueuse/core/index'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
/**
 * Field to search for user groups and select them.
 * @figma https://www.figma.com/file/NycgmenOdWjRDMhseKR9VA/Fankind?type=design&node-id=3593-5146&mode=design&t=iEbMoQl7vAx1llCi-4
 * @usage <form-field-select-user-group v-model:selected="selected"/>
 * @example {
 *   "title": "Default",
 *   "description": "Default form-field-select-user-group",
 *   "attrs": {
 *    "selected": [
 *     {"id":1, "name": "John Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *     {"id":2, "name": "Jane Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *     {"id":3, "name": "John Smith", "avatar": {"path": "https://picsum.photos/200"}},
 *     {"id":4, "name": "Jane Smith", "avatar": {"path": "https://picsum.photos/200"}}
 *    ]
 *   }
 * }
 * @example {
 *  "title": "Single select",
 *  "description": "Single select form-field-select-user-group",
 *  "attrs": {
 *   "selected": {"id":1, "name": "John Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *   "singleSelect": true
 *  }
 * }
 * @example {
 * "title": "With collapse button",
 * "description": "With collapse button form-field-select-user-group",
 *  "attrs": {
 *   "selected": [
 *    {"id":1, "name": "John Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *    {"id":2, "name": "Jane Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *    {"id":3, "name": "John Smith", "avatar": {"path": "https://picsum.photos/200"}},
 *    {"id":4, "name": "Jane Smith", "avatar": {"path": "https://picsum.photos/200"}}
 *   ],
 *   "toggleCollapseButton": true
 *  }
 * }
 */


const emit = defineEmits(['update:selected', 'create'])


















































defineOptions({
  name: 'FormFieldSelectUserGroup',
})


















































const props = defineProps({
  /**
   * Array (or single object) of selected user groups.
   */
  selected: {
    type: [Object, Array],
    default: () => [],
  },
  /**
   * Override the default search suggestions.
   */
  suggestions: {
    type: Array,
    default: () => undefined,
  },
  /**
   * Whether to allow selecting multiple users.
   */
  singleSelect: {
    type: Boolean,
    default: false,
  },
  /**
   * Is the field expanded by default.
   * @deprecated
   */
  expanded: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether to show the toggle collapse button.
   */
  toggleCollapseButton: {
    type: Boolean,
    default: false,
  },
  /**
   * Function to compare two users. Used for selecting the correct option.
   */
  compareFunction: {
    type: Function,
    default: (a, b) => a.id === b.id,
  },
  allowCreate: {
    type: Boolean,
    default: false,
  },
  showButtonByDefault: {
    type: Boolean,
    default: false,
  },
  label: {
    type: Boolean,
    default: true,
  },
  labels: {
    type: String,
    validator: (value) => {
      return ['top', 'inline', 'none'].includes(value)
    },
    default: 'none',
  },
})

const toggleExpanded = ref(true)
const addingNew = ref(false)
const addNewInputField = ref(null)
const foundOptions = ref([])
const search = ref('')
const searching = ref(false)

const selectedArray = computed(() => {
  if (Array.isArray(props.selected)) return props.selected
  else return [props.selected].filter(Boolean)
})

function searchGuests() {
  searching.value = true
  axios
    .get(`/api/autocomplete/groups`, {
      params: { query: search.value },
    })
    .then((response) => {
      foundOptions.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      searching.value = false
    })
}

onMounted(searchGuests)

const searchDebounce = useDebounceFn(searchGuests, 200)

watch(search, () => {
  searchDebounce()
  searching.value = true
})
const selectedOptions = computed(() => {
  if (props.options) return props.options
  else return foundOptions.value
})

const isExpanded = computed(() => {
  return toggleExpanded.value || props.expanded
})

const filteredOptions = computed(() => {
  return selectedOptions.value.filter((option) => {
    return option.name.toLowerCase().includes(search.value.toLowerCase())
  })
})

function focusInAddNew(e) {
  addingNew.value = true
  requestAnimationFrame(() => {
    if (addNewInputField.value) {
      addNewInputField.value?.focus()
    } else {
      addingNew.value = false
    }
  })
}

function focusOutAddNew(e) {
  if (e.currentTarget.contains(e.relatedTarget)) {
    return
  }

  addingNew.value = false
}

function updateSelected(value) {
  emit('update:selected', value)
}

function remove(item) {
  if (props.singleSelect) {
    updateSelected(null)
  } else {
    updateSelected(
      props.selected.filter((i) => !props.compareFunction(i, item))
    )
  }
}

function select(item) {
  if (props.singleSelect) {
    console.log('single select', item)
    updateSelected(item)
  } else {
    updateSelected([...props.selected, item])
  }
}

function toggle(item) {
  console.log('toggle', item)
  console.log(props.singleSelect)
  if (props.singleSelect) {
    select(item)
  } else {
    if (props.selected.find((i) => props.compareFunction(i, item))) {
      remove(item)
    } else {
      select(item)
    }
  }
}
</script>

<style scoped></style>
