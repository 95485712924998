import { Model } from '@/models/Model'
import ConventionEvent from '@/models/ConventionEvent'
import {EventActivity} from "@/models/EventActivity";
import Image from "@/models/Image";

export default class Game extends Model {
  // general info
  id: number | null = null
  slug: string = ''
  name: string = ''
  description: string = ''

  parent_game: Game | null = null
  release_date: string | null = null

  genre_tags: Array<unknown> = []

  min_minutes: number | null = null
  max_minutes: number | null = null

  min_players: number | null = null
  max_players: number | null = null

  min_age: number | null = null

  thumbnail: Image | null = null
  banner: Image | null = null
  social: Image | null = null

  event_sessions: Array<unknown> = []
  events: Array<ConventionEvent> = []
  event_activities: Array<EventActivity> = []
}
