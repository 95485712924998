<template>
  <component
    :is="href ? (isHrefExternal ? 'a' : Link) : 'button'"
    :class="[
      variantClass,
      sizeClass,
      'rounded-' + props.rounding,
      bold ? 'font-semibold' : '',
    ]"
    :disabled="disabled"
    :href="href"
    :rel="isHrefExternal ? 'noopener noreferrer' : undefined"
    :target="isHrefExternal ? '_blank' : undefined"
    :type="type"
    class="select-none duration-75 active:border-opacity-75 active:bg-opacity-75 disabled:cursor-not-allowed"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { ButtonVariant, buttonVariants } from '@/types/designVariants'
import { Link } from '@inertiajs/vue3'

const emit = defineEmits(['click'])

/**
 * Button with dropdown menu
 */
defineOptions({
  name: 'VButton',
})

const props = defineProps({
  href: {
    type: String,
    default: () => undefined,
  },
  /**
   * One of html button types
   * added here for better type checking
   */
  type: {
    type: String as () => 'button' | 'submit' | 'reset',
    default: 'button',
    validator: (value: string) => {
      return ['button', 'submit', 'reset'].includes(value)
    },
  },
  /**
   * Whether the button is disabled
   * added here for better type checking
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Button style variant
   */
  variant: {
    type: String as PropType<ButtonVariant>,
    default: () => 'primary',
    validator: (value: ButtonVariant) => {
      return buttonVariants.includes(value)
    },
  },
  /**
   * Button size
   */
  size: {
    type: String as () => 'xs' | 'sm' | 'md' | 'lg',
    default: 'md',
    validator: (value: string) => {
      return ['xs', 'sm', 'md', 'lg'].includes(value)
    },
  },
  /**
   * Whether to remove the border on specific variants
   */
  withoutBorder: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  /**
   * Highly discouraged to use this prop. All our buttons use lg rounding by default.
   * Use this prop only if you have a good reason to do so.
   */
  rounding: {
    type: String as () => 'none' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
    default: 'lg',
    validator: (value: string) => {
      return ['none', 'sm', 'md', 'lg', 'xl', 'full'].includes(value)
    },
  },
  bold: {
    type: Boolean,
    default: false,
  },
})

const isHrefExternal = computed(() => {
  return props.href?.startsWith('http') ?? false
})

const variantClass = computed(() => {
  switch (props.variant) {
    case 'primary':
      return (
        'bg-[#1613BD] hover:bg-[#121092] disabled:bg-[#A0A0B9] text-white text-center' +
        (props.withoutBorder
          ? ' border-0'
          : ' border-[1.5px] hover:border-[#121092] disabled:border-[#A0A0B9] border-[#1613BD]')
      )
    case 'secondary':
      return (
        'text-[#1613BD] hover:text-[#121092] disabled:text-[#A0A0B9] bg-transparent text-center' +
        (props.withoutBorder ? ' border-0' : ' border-[1.5px] border-current')
      )
    case 'gray-secondary':
      return (
        'text-gray-500 hover:text-gray-800 disabled:text-gray-300 bg-transparent text-center' +
        (props.withoutBorder ? ' border-0' : ' border-[1.5px] border-gray-200')
      )
    // case 'tertiary':
    //   return 'bg-transparent text-blue-primary text-center'
    // case 'gray':
    //   return 'duration-100 bg-gray-200 disabled:bg-gray-100 bg-opacity-0 hover:bg-opacity-100 text-gray-500 hover:text-gray-800 text-center'
    // case 'danger':
    //   return 'bg-red-800 disabled:bg-red-500 text-white text-center'
    default:
      throw new Error('Invalid button variant ' + props.variant)
  }
})

const sizeClass = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'text-xs ' + (props.noPadding ? '' : 'py-2 px-3')
    case 'sm':
      return 'text-sm ' + (props.noPadding ? '' : 'py-[10px] px-6')
    case 'md':
      return 'text-base ' + (props.noPadding ? '' : 'py-[11px] px-7')
    case 'lg':
      return 'text-lg ' + (props.noPadding ? '' : 'py-3 px-8')
    default:
      throw new Error('Invalid button size')
  }
})
</script>

<style scoped></style>
