<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <merge-selector-modal
        v-model:open="merge"
        :form-field-select-item-component="FormFieldSelectTag"
        :select-attrs="{
          type: form.type,
        }"
        :source-id="form.id"
        resource-modal-type="tag"
        source-type="tag"
      />
      <form-field-header-input
        allow-merge
        class="w-full"
        @delete="emit('delete')"
        @merge="merge = true"
      >
        <template #input>
          <input-field
            v-model="form.name"
            class="grow"
            placeholder="Add Venue Name"
            type="text"
            variant="gray"
            @update:model-value="emit('search', $event)"
          />
        </template>
      </form-field-header-input>
    </template>
    <form-input-group-layout>
      <input-layout
        label="Type"
        layout="grid"
        variant="gray"
      >
        <form-field-select-item
          :selected="
            form.type
              ? {
                label: form.type,
                value: form.type,
              }
              : null
          "
          :suggestions="types"
          key-by="value"
          search-by="label"
          single-select
          @update:selected="form.type = $event?.value"
        />
      </input-layout>

      <form-field-input-color
        :color-h-e-x="form.color"
        :color-name="form.color_name"
        variant="gray"
        @update:color-name="form.color_name = $event"
        @update:color-value="form.color = $event"
      />
      <input-layout
        label="Image"
        layout="grid"
        variant="gray"
      >
        <form-field-image
          v-model="form.image"
          class="h-32 grow"
          placeholder="Add Image"
          type="text"
          variant="gray"
        />
      </input-layout>
    </form-input-group-layout>
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import InputField from '@/components/input/InputField.vue'
import Tag from '@/models/Tag'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import FormFieldInputColor from '@/components/input/FormFieldInputColor.vue'
import FormFieldImage from '@/components/input/FormFieldImage.vue'
import MergeSelectorModal from '@/components/modal/MergeSelectorModal.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import { ref } from 'vue'

const props = defineProps({
  form: {
    type: [Object, Tag],
    required: true,
  },
})

const merge = ref(false)

const types = [
  { label: 'Activity', value: 'activity' },
  { label: 'Company', value: 'company' },
  { label: 'Fandom', value: 'fandom' },
  { label: 'Food', value: 'food' },
  { label: 'Food Type', value: 'food_type' },
  { label: 'Game', value: 'game' },
  { label: 'Genre', value: 'genre' },
  { label: 'Highlight', value: 'highlight' },
  { label: 'Language', value: 'language' },
  { label: 'Menu Item Type', value: 'menu_item_type' },
  { label: 'Product Type', value: 'product_type' },
  { label: 'Profession', value: 'profession' },
  { label: 'Topic', value: 'topic' },
  { label: 'Vendor Type', value: 'vendor_type' },
]
</script>

<style scoped></style>
