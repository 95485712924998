<template>
  <label class="relative inline-flex cursor-pointer items-center">
    <input
      :id="inject('input-id', $.uid)"
      :checked="modelValue"
      :disabled="disabled"
      :value="modelValue"
      class="peer sr-only"
      type="checkbox"
      @input="emit('update:modelValue', !!$event.target.checked)" />
    <div
      :class="{
        'bg-ultra-light-blue after:bg-blue-primary peer-checked:bg-blue-primary peer-checked:after:border-white peer-checked:after:bg-white peer-disabled:bg-gray-200 peer-disabled:after:bg-gray-700 dark:border-gray-600':
          variant === 'primary',
      }"
      class="peer h-6 w-11 rounded-full after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-focus:outline-none peer-focus:ring-0" />
  </label>
</template>

<script lang="ts" setup>
import { inject } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'primary',
    validator(value: unknown): boolean {
      return ['default', 'primary', 'gray'].includes(value as string)
    },
  },
})
</script>

<style scoped></style>