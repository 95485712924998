import { Model } from '@/models/Model'
import User from '@/models/User'
import Image from '@/models/Image'

export default class ThreadPost extends Model {
  id: number | null = null
  type: string = ''
  author: User | null = null
  image: string | null = null
  title: string = ''
  images: Image[] = []
}