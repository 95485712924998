<template>
  <input-wrapper
    :class="wrapperClass"
    :empty="!query.length"
    :invalid="invalid || !!inject('input-error', null)?.value"
    :variant="_variant"
    class="group/dropdown relative"
    @focusout="onFocusOut"
  >
    <slot name="before-inner" />
    <app-input
      ref="inputElement"
      :class="inputClass"
      :model-value="query"
      :placeholder="placeholder"
      @focus="onFocus"
      @keydown.down="highlightAt($event, 1)"
      @keydown.up="highlightAt($event, -1)"
      @keydown.enter="selectHighlighted"
      @update:model-value="query = $event"
    />
    <font-awesome-icon
      v-if="!hideIcon"
      :class="{
        'transition-transform group-focus-within/dropdown:rotate-180':
          icon === 'chevron-down',
      }"
      :icon="icon"
      class="absolute bottom-0 right-0 top-0 m-auto mr-3 text-gray-400"
    />
    <div
      ref="dropdown"
      :class="{
        'top-full': position === 'top',
        'bottom-full': position === 'bottom',
        'max-w-sm rounded border bg-gray-100 bg-opacity-95 shadow backdrop-blur':
          _variant !== 'default',
        'rounded-b-lg border-1.5 border-t-0 border-gray-400 bg-slate-100':
          _variant === 'default',
      }"
      class="absolute left-0 z-30 hidden w-full flex-col overflow-hidden group-focus-within/dropdown:flex"
      tabindex="-1"
    >
      <dropdown-items-list
        :highlighted-key="highlightedKeyGetter"
        :items="filteredSuggestions"
        :key-by="keyBy"
        :loading="loading"
        :use-grouping="useGrouping"
        @select="onSelect"
      >
        <template #item="{ item }">
          <slot
            :item="item"
            name="item"
          >
            {{ item[searchBy] }}
          </slot>
        </template>
      </dropdown-items-list>
    </div>
  </input-wrapper>
</template>

<script lang="ts" setup>
import InputWrapper from '@/components/ui/InputWrapper.vue'
import AppInput from '@/components/input/AppInput.vue'
import DropdownItemsList from '@/components/widgets/DropdownItemsList.vue'
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import getScrollParent from '@/utils/scroll'
import { InputVariant } from '@/types/designVariants'
const emit = defineEmits([
  'update:modelValue',
  'select',
  'blur',
  'focus',
  'update:highlightedKey',
])

/**
 * Input field with a dropdown list of suggestions
 * @usage <form-field-select-text v-model="modelValue" :suggestions="suggestions" />
 * @example {
 *   "title": "Default",
 *   "description": "",
 *   "attrs": {
 *   "modelValue": "text",
 *   "suggestions": [
 *    {
 *     "name": "Some text",
 *     "value": "Some text"
 *    },
 *    {
 *     "name": "Some other text",
 *     "value": "Some other text"
 *    }
 *   ]
 *  }
 * }
 */

defineOptions({
  name: 'FormFieldSelectText',
})

const props = defineProps({
  /**
   * The value of the input
   * @model
   */
  modelValue: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: () => 'chevron-down',
  },
  /**
   * The suggestions to show in the dropdown
   */
  suggestions: {
    type: Array as () => any[],
    default: () => [],
  },
  /**
   * The property to search by
   */
  searchBy: {
    type: String,
    default: 'name',
  },
  /**
   * The property to use as the key
   */
  keyBy: {
    type: String,
    default: 'value',
  },
  /**
   * The variant of the input
   * @values default, gray, outline
   */
  variant: {
    type: String,
    default: null,
  },
  /**
   * Whether to disable native filtering
   */
  disableFiltering: {
    type: Boolean,
    default: false,
  },
  /**
   * The highlighted key
   */
  highlightedKey: {
    type: String,
    default: null,
  },
  /**
   * Whether the input is invalid
   */
  invalid: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the input is loading
   */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * The classes of the wrapper
   */
  wrapperClass: {
    type: String,
    default: '',
  },
  /**
   * The classes of the input
   */
  inputClass: {
    type: String,
    default: '',
  },
  /**
   * Whether to add a custom value if the input is not in the suggestions
   */
  addCustom: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether to hide the icon
   */
  hideIcon: {
    type: Boolean,
    default: false,
  },
  useGrouping: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
})

const _modelValue = ref(props.modelValue ?? '')
const query = computed<string>({
  get() {
    return props.modelValue ?? _modelValue.value
  },
  set(value: string) {
    _modelValue.value = value
    emit('update:modelValue', value)
  },
})

const _highlightedKey = ref<string | number | null>(null)
const highlightedKeyGetter = computed<string | number | null>({
  get() {
    if (props.highlightedKey) {
      return props.highlightedKey
    }
    return _highlightedKey.value
  },
  set(value) {
    emit('update:highlightedKey', value)
    _highlightedKey.value = value
  },
})

const injectedVariant = inject('input-variant', null) as InputVariant | null
const _variant = computed(() => {
  return props.variant ?? injectedVariant ?? 'default'
})

const filteredSuggestions = computed(() => {
  if (props.disableFiltering) {
    return props.suggestions
  }
  return props.suggestions.filter((suggestion) => {
    return suggestion[props.searchBy]
      ?.toLowerCase()
      .includes(query.value?.toLowerCase() ?? '')
  })
})

function onFocus(e) {
  highlightedKeyGetter.value = null
  emit('focus', e)
}

function onFocusOut(e) {
  if (!e.currentTarget.contains(e.relatedTarget)) emit('blur', e)
}

const inputElement = ref<InstanceType<typeof AppInput> | null>(null)
const scrollParent = ref<HTMLElement | null>(null)
const dropdown = ref<HTMLElement | null>(null)
const position = ref('top')

function resizeHandler() {
  if (!inputElement.value) return

  const { top, height } = inputElement.value.$el.getBoundingClientRect()
  const { innerHeight } = window

  if (top + height > innerHeight) {
    position.value = 'bottom'
  } else {
    position.value = 'top'
  }
}

function highlightAt(e, offset) {
  e.preventDefault()
  if (!highlightedKeyGetter.value) {
    highlightedKeyGetter.value = filteredSuggestions.value?.at(
      offset < 0 ? filteredSuggestions.value.length - 1 : 0
    )?.[props.keyBy]
  } else {
    const index = filteredSuggestions.value.findIndex(
      (item) => item[props.keyBy] === highlightedKeyGetter.value
    )
    if (index === -1) {
      highlightedKeyGetter.value = filteredSuggestions.value?.at(
        offset < 0 ? filteredSuggestions.value.length - 1 : 0
      )?.[props.keyBy]
    } else {
      highlightedKeyGetter.value =
        filteredSuggestions.value[
          (index + filteredSuggestions.value.length + offset) %
            filteredSuggestions.value.length
        ][props.keyBy]
    }
  }
}

function selectHighlighted() {
  if (highlightedKeyGetter.value) {
    const item = filteredSuggestions.value.find(
      (item) => item[props.keyBy] === highlightedKeyGetter.value
    )
    if (item) {
      onSelect(item)
    }
  }
}

function onSelect(item: { [key: string]: any }) {
  query.value = item[props.searchBy]
  highlightedKeyGetter.value = item[props.keyBy]
  emit('select', item)
  inputElement.value?.blur()
  dropdown.value?.blur()
}

onMounted(() => {
  scrollParent.value = getScrollParent(inputElement.value.$el)
  window.addEventListener('resize', resizeHandler)
  scrollParent.value?.addEventListener('scroll', resizeHandler)
  resizeHandler()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler)
  scrollParent.value?.removeEventListener('scroll', resizeHandler)
})
</script>

<style scoped></style>
