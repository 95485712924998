<template>
  <div class="flex select-none flex-col gap-2">
    <div class="flex items-center px-3 py-2">
      <h2 class="flex-auto text-sm font-semibold text-gray-900">
        {{ displayMonth.toFormat('LLLL yyyy') }}
      </h2>
      <button
        class="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-600 hover:text-gray-500"
        type="button"
        @click="monthOffset--"
      >
        <span class="sr-only">Previous month</span>
        <font-awesome-icon
          aria-hidden="true"
          class="h-4 w-4"
          icon="chevron-left"
        />
      </button>
      <button
        class="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-600 hover:text-gray-500"
        type="button"
        @click="monthOffset++"
      >
        <span class="sr-only">Next month</span>
        <font-awesome-icon
          aria-hidden="true"
          class="h-4 w-4"
          icon="chevron-right"
        />
      </button>
    </div>
    <div class="grid grid-cols-7 text-center text-xs leading-6 text-gray-700">
      <div
        v-for="d in weekdays"
        :key="d"
      >
        {{ d }}
      </div>
    </div>
    <div class="grid grow grid-cols-7 pb-2 text-sm">
      <div
        v-for="(day, dayIdx) in days"
        :key="day.date"
        :class="[dayIdx > 6 && 'border-t border-gray-200', '']"
      >
        <button
          :class="[
            day.isSelected && 'text-white',
            !day.isSelected && day.isToday && 'text-indigo-600',
            !day.isSelected &&
              !day.isToday &&
              day.isCurrentMonth &&
              'text-gray-900',
            !day.isSelected &&
              !day.isToday &&
              !day.isCurrentMonth &&
              'text-gray-600',
            day.isSelected && day.isToday && 'bg-indigo-600',
            day.isSelected && !day.isToday && 'bg-gray-900',
            !day.isSelected && 'hover:bg-gray-400',
            (day.isSelected || day.isToday) && 'font-semibold',
            'mx-auto flex aspect-square w-8 items-center justify-center rounded-full',
          ]"
          type="button"
          @click="selectDay(day)"
        >
          <time :datetime="day.date">
            {{ day.date.split('-').pop().replace(/^0/, '') }}
          </time>
        </button>
      </div>
    </div>
    <form-field-select-time
      v-if="withTime"
      :model-value="DateTime.fromISO(props.modelValue).toFormat('HH:mm')"
      :variant="variant"
      @update:model-value="updateTime($event)"
    />
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import FormFieldSelectTime from '@/components/input/FormFieldSelectTime.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: String, // ISO date string
    required: true,
  },
  weekStartsOn: {
    type: Number,
    default: 0, // 0 = Monday, 6 = Sunday
  },
  withTime: {
    type: Boolean,
    default: false,
  },
  /**
   * The variant of the input field.
   * @values default, outline, minimal, gray
   */
  variant: {
    type: String,
    default: null, // default, outline, minimal, gray
  },
})

const monthOffset = ref(0)
const displayMonth = computed(() => {
  return DateTime.fromISO(props.modelValue).plus({ months: monthOffset.value })
})

// array of weekdays, starting with the first day of the week
const weekdays = computed(() => {
  const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  const firstDay = days.splice(props.weekStartsOn)
  return [...firstDay, ...days]
})

const days = computed(() => {
  const selectedDate = DateTime.fromISO(props.modelValue)
  const today = DateTime.now().toISODate()
  const month = DateTime.fromISO(props.modelValue).plus({
    months: monthOffset.value,
  })
  const firstDay = month
    .startOf('month')
    .startOf('week')
    .plus({ days: props.weekStartsOn })
  const lastDay = month
    .endOf('month')
    .endOf('week')
    .plus({ days: props.weekStartsOn })
  const days = []
  for (let day = firstDay; day <= lastDay; day = day.plus({ days: 1 })) {
    days.push({
      date: day.toISODate(),
      isToday: day.toISODate() === today,
      isCurrentMonth: day.month === month.month,
      isSelected: day.toISODate() === selectedDate.toISODate(),
    })
  }
  return days
})

function selectDay(day) {
  const time = DateTime.fromISO(props.modelValue)
  const date = DateTime.fromFormat(day.date, 'yyyy-MM-dd')
  const newDate = date.set({ hour: time.hour, minute: time.minute })

  emit('update:modelValue', newDate.toISO())
}

function updateTime(event) {
  const time = DateTime.fromFormat(event, 'HH:mm')
  const date = DateTime.fromISO(props.modelValue)
  const newDate = date.set({ hour: time.hour, minute: time.minute })
  emit('update:modelValue', newDate.toISO())
}
</script>

<style scoped></style>
