<template>
  <div class="flex flex-col gap-3">
    <div
      v-for="(role, index) in modelValue"
      :key="index"
      class="flex gap-3"
    >
      <form-field-select-tag
        class="min-w-[120px]"
        single-select
        variant="gray"
        type="profession"
        :selected="role.name ? { name: role.name } : null"
        :suggestions="optionsList"
        @update:selected="
          (selectedRole) => {
            updateRoleName(index, selectedRole)
          }
        "
      />

      <form-field-select-user v-model:selected="role.users">
        <template #user-info="{ user }">
          <slot
            name="user-info"
            :user="user"
          />
        </template>
      </form-field-select-user>
    </div>
    <button-plus
      v-if="!modelValue?.find((r) => !r.name)"
      key="add-button"
      class="h-8 w-8"
      @click="addRole"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import FormFieldSelectUser from '@/components/input/FormFieldSelectUser.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  typeOfUsers: {
    type: String,
    default: 'representatives',
  },
})

function updateRoleName(index, selectedRole) {
  const updatedRoles = [...props.modelValue]

  if (!selectedRole || !selectedRole.name) {
    updatedRoles[index].name = null
  } else {
    updatedRoles[index].name = selectedRole.name
  }
  emit('update:modelValue', updatedRoles)
}

function addRole() {
  emit('update:modelValue', [...props.modelValue, { name: '', users: [] }])
}

const optionsList = computed(() => {
  const options =
    props.typeOfUsers === 'representatives'
      ? [
          { name: 'Agents', type: 'profession', id: 0, image_path: null },
          { name: 'Lawyers', type: 'profession', id: 1, image_path: null },
          { name: 'Managers', type: 'profession', id: 2, image_path: null },
          { name: 'Producers', type: 'profession', id: 3, image_path: null },
        ]
      : [
          { name: 'Agent', type: 'profession', id: 4, image_path: null },
          { name: 'Lawyer', type: 'profession', id: 5, image_path: null },
          { name: 'Manager', type: 'profession', id: 6, image_path: null },
          { name: 'Producer', type: 'profession', id: 7, image_path: null },
        ]
  return options
})
</script>

<style scoped></style>
