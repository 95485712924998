<template>
  <component
    :is="is"
    :class="[
      props.class,
      forceLoading || (loading && !props.image)
        ? 'skeleton before:content-[]'
        : '',
      !loading && !forceLoading && !success && fallbackClass
        ? fallbackClass
        : '',
      {
        'bg-cover bg-center bg-no-repeat': !disableDefaultStyling,
      },
    ]"
    :style="styles"
    class="transition duration-300 ease-in-out"
  >
    <!-- hidden image to catch error -->
    <link
      v-if="
        props.image &&
        props.image?.path &&
        props.image.preload_url &&
        !is_cached(props.image?.path)
      "
      :href="props.image.preload_url"
      as="image"
      rel="preload"
    />
    <img
      v-if="preload"
      :src="imageSrc"
      alt="hidden"
      class="position-absolute -z-50 h-0 w-0 bg-transparent opacity-0"
      @error="handleError"
      @load="handleLoad"
    />
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { is_cached } from '@/utils/composables/utils'
import ImageModel from '@/models/Image'

const props = defineProps({
  is: {
    type: String,
    default: 'div',
  },
  image: {
    type: [ImageModel, Object],
    default: () => undefined,
  },
  src: {
    type: String,
    default: null,
  },
  alt: {
    type: String,
    default: null,
  },
  fallbackSrc: {
    type: String,
    default:
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z'/%3E%3C/svg%3E",
  },
  fallbackClass: {
    type: String,
    default: null,
  },
  fallbackHidden: {
    type: Boolean,
    default: false,
  },
  fallbackSize: {
    type: String,
    default: '100%',
  },
  class: {
    type: [Array, Object, String],
    default: () => undefined,
  },
  timeout: {
    type: Number,
    default: 0,
  },
  disableDefaultStyling: {
    type: Boolean,
    default: false,
  },
  forceLoading: {
    type: Boolean,
    default: false,
  },
})

const imageSrc = computed(() => {
  if (props.src) return props.src
  else if (props.image?.path) return props.image?.path
  else return props.src
})

const styles = computed(() => {
  if (!loading.value && !props.forceLoading && success.value) {
    return {
      backgroundImage: `url("${imageSrc.value}")`,
    }
  } else if (props.forceLoading) {
    return {}
  } else if (loading.value) {
    if (props.image) {
      return {
        backgroundImage: `url("${props.image.preload_url}")`,
        // backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        backdropFilter: 'blur(10px)',
      }
    }
  } else if (!loading.value && !success.value && !props.fallbackHidden) {
    return {
      backgroundImage: `url("${props.fallbackSrc}")`,
      backgroundSize: props.fallbackSize,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }
  }
  return {}
})

const logger = useLogger()
const preload = ref(false)
const loading = ref(true)
const success = ref(false)

onMounted(() => {
  nextTick(() => {
    startLoader()
  })
})

watch(imageSrc, () => {
  preload.value = false
  loading.value = true
  success.value = false
  startLoader()
})

onMounted(() => {
  if (imageSrc.value && is_cached(imageSrc.value)) {
    loading.value = false
    success.value = true
  } else {
    if (!imageSrc.value) {
      loading.value = false
      preload.value = false
      success.value = false
      return
    }
    nextTick(() => {
      setTimeout(() => {
        startLoader()
      }, 10)
    })
  }
})

function startLoader() {
  if (!imageSrc.value) {
    loading.value = false
    success.value = false
    preload.value = false
    return
  }
  if (props.timeout > 0) {
    setTimeout(() => {
      preload.value = true
    }, props.timeout)
  } else {
    preload.value = true
  }
}

function handleLoad() {
  loading.value = false
  success.value = true
}

function handleError(e) {
  loading.value = false
  success.value = false
  logger.debug('Image load error', e.target.src)
}
</script>

<style scoped>
.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
</style>
