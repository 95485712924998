<template>
  <template v-for="modal in modalHandler.modals" :key="modal.id">
    <component
      :is="modal.component"
      v-if="modal.type === 'raw'"
      v-bind="modal.props" />
    <modal-dialog
      v-else-if="modal.type === 'dialog'"
      :open="modal.open"
      v-bind="modal.props"
      @close="modalHandler.close(modal.id, 'backdrop')"
      @closed="modalHandler.remove(modal.id)"
      @click:primary="handleDialogClick(modal.id, modal.onPrimary)"
      @click:secondary="handleDialogClick(modal.id, modal.onSecondary)">
      <component
        :is="modal.component"
        v-bind="modal.props"
        @close="modalHandler.close(modal.id, $event)" />
    </modal-dialog>
    <modal
      v-else
      :backdrop-disabled="!modal.backdrop"
      :open="modal.open"
      v-bind="modal.props"
      @close="modalHandler.close(modal.id, 'close')"
      @closed="modalHandler.remove(modal.id)">
      <component
        :is="modal.component"
        v-bind="{
          ...modal.props,
          ...updateCallbacks(modal.id, modal.events, modal.props),
        }"
        @close="modalHandler.close(modal.id, $event ?? 'close')" />
    </modal>
  </template>
</template>

<script lang="ts" setup>
import Modal from '@/components/modal/Modal.vue'
import { ModalEventCallback, useModal } from '@/utils/modal'
import ModalDialog from '@/components/modal/ModalDialog.vue'
import {inject} from "vue";

const modalHandler = useModal()
const modalStore = inject('modalStore')

function updateCallbacks(modalId: number, events: Record<string, ModalEventCallback>, props: Record<string, any>) {
  const updatedEvents = {} as Record<string, any>

  // change callbacks and provide close method for each callback
  Object.keys(events).forEach((key) => {
    updatedEvents[key] = (...payload: any) => {
      events[key](payload, (reason = 'close') =>
        modalHandler.close(modalId, reason),
        props
      )
    }
  })

  return updatedEvents
}

function handleDialogClick(
  modalId: number,
  callback?: () => boolean | Promise<unknown>
) {
  if (!callback) {
    modalHandler.close(modalId, 'primary')
  }
  const result = callback()
  if (result instanceof Promise) {
    result.then((res) => {
      modalHandler.close(modalId, 'primary')
    })
  } else if (result) {
    modalHandler.close(modalId, 'primary')
  }
}
</script>

<style scoped></style>