<template>
  <form
    class="w-full max-w-prose self-center rounded-lg border border-gray-200 bg-gray-50 focus-within:ring-2 focus-within:ring-blue-200"
    @submit.prevent="sendComment"
  >
    <div class="rounded-t-lg bg-white px-4 py-3">
      <label
        class="sr-only"
        for="comment"
      >
        Add a comment
      </label>
      <rich-text
        :autofocus="autofocus"
        :class="isFocused ? 'min-h-[100px]' : 'items-center'"
        :model-value="text"
        class="flex w-full resize-none border-0 bg-white px-0 text-sm text-gray-900 focus:outline-none focus:ring-0"
        placeholder="Add a comment"
        @focus="isFocused = true"
        @update:model-value="emit('update:text', $event)"
      >
        <template #users="{ text: userText, replace }">
          <div
            class="w-full overflow-hidden rounded-lg bg-gray-200 bg-opacity-75 outline-1 outline-gray-300 backdrop-blur md:w-96"
          >
            <user-selector
              :query="userText"
              @select="replace('@' + $event.username)"
            />
          </div>
        </template>
      </rich-text>
    </div>
    <div
      v-if="isFocused"
      class="flex items-start justify-start gap-2 border-t px-3 py-2"
    >
      <button
        :disabled="sending"
        class="inline-flex items-center rounded-lg bg-blue-primary px-4 py-2.5 text-center text-xs font-medium text-white hover:bg-dark-blue focus:ring-4 focus:ring-blue-200 disabled:opacity-50"
        type="submit"
      >
        Post comment
      </button>
      <button
        v-if="showCancel"
        :disabled="sending"
        class="inline-flex items-center rounded-lg bg-gray-100 px-4 py-2.5 text-center text-xs font-medium text-gray-500 hover:bg-gray-200 focus:ring-4 focus:ring-blue-200 disabled:opacity-50"
        type="button"
        @click="emit('cancel')"
      >
        Cancel
      </button>
    </div>
    <div>
      <!-- TODO: images and other stuff -->
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import RichText from '@/components/input/RichText.vue'
import UserSelector from '@/components/input/UserSelector.vue'

const emit = defineEmits(['send', 'cancel', 'update:text'])

const props = defineProps({
  sending: {
    type: Boolean,
    required: true,
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  showCancel: {
    type: Boolean,
    required: false,
    default: false,
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const isFocused = ref(false)

function sendComment() {
  if (props.text.trim().length === 0) return
  emit('send', {
    body: props.text,
  })
}
</script>

<style scoped></style>
