export const brands = [
  {
    name: 'Instagram',
    icon: 'instagram',
    description: 'Share photos and videos with your friends and followers.',
    url_regex: '^(https?://)?(www\\.)?instagram\\.com/.*$',
  },
  {
    name: 'Tumblr',
    icon: 'tumblr',
    description: 'A microblogging and social networking website.',
    url_regex: '^(https?://)?(www\\.)?tumblr\\.com/.*$',
  },
  {
    name: 'Twitter',
    icon: 'twitter',
    description: 'Connect with people and express yourself on social media.',
    url_regex: '^(https?://)?(www\\.)?twitter\\.com/.*$',
  },
  {
    name: 'Facebook',
    icon: 'facebook',
    description: 'Connect with friends and the world around you on Facebook.',
    url_regex: '^(https?://)?(www\\.)?facebook\\.com/.*$',
  },
  {
    name: 'TikTok',
    icon: 'tiktok',
    description: 'Create and share short videos on TikTok.',
    url_regex: '^(https?://)?(www\\.)?tiktok\\.com/.*$',
  },
  {
    name: 'YouTube',
    icon: 'youtube',
    description: 'Watch and share videos on YouTube.',
    url_regex: '^(https?://)?(www\\.)?youtube\\.com/.*$',
  },
  {
    name: 'Rumble',
    description: 'A video platform for creators.',
    url_regex: '^(https?://)?(www\\.)?rumble\\.com/.*$',
  },
  {
    name: 'Etsy',
    icon: 'etsy',
    description: 'Buy and sell unique handmade and vintage items.',
    url_regex: '^(https?://)?(www\\.)?etsy\\.com/.*$',
  },
  {
    name: 'LinkedIn',
    icon: 'linkedin',
    description:
      'Connect with professionals and build your network on LinkedIn.',
    url_regex: '^(https?://)?(www\\.)?linkedin\\.com/.*$',
  },
  {
    name: 'Pinterest',
    icon: 'pinterest',
    description: 'Discover and save creative ideas on Pinterest.',
    url_regex: '^(https?://)?(www\\.)?pinterest\\.com/.*$',
  },
  {
    name: 'Snapchat',
    icon: 'snapchat',
    description: 'Share moments with friends on Snapchat.',
    url_regex: '^(https?://)?(www\\.)?snapchat\\.com/.*$',
  },
  {
    name: 'Vimeo',
    icon: 'vimeo',
    description: 'Upload, share, and discover videos on Vimeo.',
    url_regex: '^(https?://)?(www\\.)?vimeo\\.com/.*$',
  },
  {
    name: 'Dailymotion',
    icon: 'dailymotion',
    description: 'Watch, publish, and share videos on Dailymotion.',
    url_regex: '^(https?://)?(www\\.)?dailymotion\\.com/.*$',
  },
  {
    name: 'Behance',
    icon: 'behance',
    description: 'Showcase and discover creative work on Behance.',
    url_regex: '^(https?://)?(www\\.)?behance\\.net/.*$',
  },
  {
    name: 'Dribbble',
    icon: 'dribbble',
    description: 'Show and tell, promote, discover, and explore design.',
    url_regex: '^(https?://)?(www\\.)?dribbble\\.com/.*$',
  },
  {
    name: 'Medium',
    icon: 'medium',
    description: 'A place to read and write big ideas and important stories.',
    url_regex: '^(https?://)?(www\\.)?medium\\.com/.*$',
  },
  {
    name: 'Reddit',
    icon: 'reddit',
    description: 'The front page of the internet.',
    url_regex: '^(https?://)?(www\\.)?reddit\\.com/.*$',
  },
  {
    name: 'Blogspot/Blogger',
    icon: 'blogger',
    description: 'Create and share your blog with the world.',
    url_regex: '^(https?://)?(www\\.)?blogger\\.com/.*$',
  },
  {
    name: 'WordPress',
    icon: 'wordpress',
    description: 'Create a beautiful website, blog, or app with WordPress.',
    url_regex: '^(https?://)?(www\\.)?wordpress\\.com/.*$',
  },
  {
    name: 'SoundCloud',
    icon: 'soundcloud',
    description: "Listen to the world's music and audio on SoundCloud.",
    url_regex: '^(https?://)?(www\\.)?soundcloud\\.com/.*$',
  },
  {
    name: 'Bandcamp',
    icon: 'bandcamp',
    description:
      'Discover amazing music and directly support the artists who make it.',
    url_regex: '^(https?://)?(www\\.)?bandcamp\\.com/.*$',
  },
  {
    name: 'Spotify',
    icon: 'spotify',
    description: 'Listen to music and podcasts on Spotify.',
    url_regex: '^(https?://)?(www\\.)?spotify\\.com/.*$',
  },
  {
    name: 'Apple Music',
    icon: 'apple',
    description: 'Stream and download music on Apple Music.',
    url_regex: '^(https?://)?(www\\.)?music\\.apple\\.com/.*$',
  },
  {
    name: 'DeviantArt',
    icon: 'deviantart',
    description:
      "The world's largest online social community for artists and art enthusiasts.",
    url_regex: '^(https?://)?(www\\.)?deviantart\\.com/.*$',
  },
  {
    name: 'ArtStation',
    icon: 'artstation',
    description:
      'Showcase your art and connect with other artists on ArtStation.',
    url_regex: '^(https?://)?(www\\.)?artstation\\.com/.*$',
  },
  {
    name: 'VSCO',
    description: 'Create, discover, and connect on VSCO.',
    url_regex: '^(https?://)?(www\\.)?vsco\\.co/.*$',
  },
  {
    name: 'Mixcloud',
    icon: 'mixcloud',
    description:
      'Listen to great radio shows, DJ mix sets, and podcasts on Mixcloud.',
    url_regex: '^(https?://)?(www\\.)?mixcloud\\.com/.*$',
  },
  {
    name: 'Twitch',
    icon: 'twitch',
    description: 'Live streaming and gamer community.',
    url_regex: '^(https?://)?(www\\.)?twitch\\.tv/.*$',
  },
  {
    name: 'Discord',
    icon: 'discord',
    description: 'A communication platform for gamers.',
    url_regex: '^(https?://)?(www\\.)?discord\\.com/.*$',
  },
  {
    name: 'Clubhouse',
    description: 'Join the conversation on Clubhouse.',
    url_regex: '^(https?://)?(www\\.)?joinclubhouse\\.com/.*$',
  },
  {
    name: 'Telegram',
    icon: 'telegram',
    description: 'Fast, secure, and private messaging on Telegram.',
    url_regex: '^(https?://)?(www\\.)?telegram\\.org/.*$',
  },
  {
    name: 'WhatsApp',
    icon: 'whatsapp',
    description: 'Simple, secure messaging and calling on WhatsApp.',
    url_regex: '^(https?://)?(www\\.)?whatsapp\\.com/.*$',
  },
  {
    name: 'Signal',
    description: 'A private messenger for simple, secure conversations.',
    url_regex: '^(https?://)?(www\\.)?signal\\.org/.*$',
  },
  {
    name: 'WeChat',
    icon: 'weixin',
    description: 'The all-in-one communication and social media app.',
    url_regex: '^(https?://)?(www\\.)?wechat\\.com/.*$',
  },
  {
    name: 'Line',
    icon: 'line',
    description:
      'A new communication app that allows you to make free voice calls and send free messages whenever and wherever you are, 24 hours a day!',
    url_regex: '^(https?://)?(www\\.)?line\\.me/.*$',
  },
  {
    name: 'Kik',
    description: 'Connect with friends and the world around you on Kik.',
    url_regex: '^(https?://)?(www\\.)?kik\\.com/.*$',
  },
  {
    name: 'Flickr',
    icon: 'flickr',
    description: 'Share your photos with the world on Flickr.',
    url_regex: '^(https?://)?(www\\.)?flickr\\.com/.*$',
  },
  {
    name: 'Periscope',
    icon: 'periscope',
    description: 'Live video streaming on Periscope.',
    url_regex: '^(https?://)?(www\\.)?periscope\\.tv/.*$',
  },
  {
    name: 'Anchor',
    description: 'The easiest way to make a podcast. Ever.',
    url_regex: '^(https?://)?(www\\.)?anchor\\.fm/.*$',
  },
  {
    name: 'Trello',
    icon: 'trello',
    description:
      'Trello helps teams work more collaboratively and get more done.',
    url_regex: '^(https?://)?(www\\.)?trello\\.com/.*$',
  },
  {
    name: 'OnlyFans',
    description: 'A subscription-based platform for creators and their fans.',
    url_regex: '^(https?://)?(www\\.)?onlyfans\\.com/.*$',
  },
  {
    name: 'Patreon',
    icon: 'patreon',
    description: 'A membership platform for artists and creators.',
    url_regex: '^(https?://)?(www\\.)?patreon\\.com/.*$',
  },
  {
    name: 'Ko-fi',
    description: 'Support creators and artists on Ko-fi.',
    url_regex: '^(https?://)?(www\\.)?ko-fi\\.com/.*$',
  },
  {
    name: 'Byte',
    description: 'Create and share looping videos on Byte.',
    url_regex: '^(https?://)?(www\\.)?byte\\.co/.*$',
  },
  {
    name: 'Webtoon',
    description: 'The global home for comics on the web.',
    url_regex: '^(https?://)?(www\\.)?webtoon\\.com/.*$',
  },
  {
    name: 'AO3',
    description:
      'Archive of Our Own - a fan-created, fan-run, nonprofit, noncommercial archive for transformative fanworks.',
    url_regex: '^(https?://)?(www\\.)?archiveofourown\\.org/.*$',
  },
  {
    name: 'Goodreads',
    icon: 'goodreads',
    description:
      "Find and read more books you'll love, and keep track of the books you want to read.",
    url_regex: '^(https?://)?(www\\.)?goodreads\\.com/.*$',
  },
  {
    name: 'Quora',
    icon: 'quora',
    description:
      'A place to gain and share knowledge, empowering people to learn from others and better understand the world.',
    url_regex: '^(https?://)?(www\\.)?quora\\.com/.*$',
  },
  {
    name: 'Baidu Tieba',
    description: 'A Chinese online community and forum platform.',
    url_regex: '^(https?://)?(tieba\\.baidu\\.com/).*',
  },
  {
    name: 'QQ',
    icon: 'qq',
    description: 'A widely used Chinese social media platform.',
    url_regex: '^(https?://)?(www\\.)?qq\\.com/.*$',
  },
  {
    name: 'VK',
    icon: 'vk',
    description: 'A Russian online social media and social networking service.',
    url_regex: '^(https?://)?(www\\.)?vk\\.com/.*$',
  },
  {
    name: 'Weibo',
    icon: 'weibo',
    description: 'A Chinese microblogging website.',
    url_regex: '^(https?://)?(www\\.)?weibo\\.com/.*$',
  },
  {
    name: 'Yelp',
    icon: 'yelp',
    description: 'Find and read reviews about great local businesses near you.',
    url_regex: '^(https?://)?(www\\.)?yelp\\.com/.*$',
  },
  {
    name: 'TripAdvisor',
    description:
      'Find the best places to eat, drink, shop, or visit in any city in the world.',
    url_regex: '^(https?://)?(www\\.)?tripadvisor\\.com/.*$',
  },
  {
    name: 'OpenTable',
    description: 'Book restaurants and discover great places to eat.',
    url_regex: '^(https?://)?(www\\.)?opentable\\.com/.*$',
  },
  {
    name: 'Zomato',
    description:
      'Discover great places to eat and order food online from restaurants near you.',
    url_regex: '^(https?://)?(www\\.)?zomato\\.com/.*$',
  },
  {
    name: 'Foursquare/Swarm',
    icon: 'foursquare',
    description:
      'Check into your favorite places and keep up with your friends on Foursquare and Swarm.',
    url_regex: '^(https?://)?(www\\.)?foursquare\\.com/.*$',
  },
  {
    name: 'Bitbucket',
    icon: 'bitbucket',
    description: 'Collaboration software for code and code review.',
    url_regex: '^(https?://)?(www\\.)?bitbucket\\.org/.*$',
  },
  {
    name: 'Houzz',
    icon: 'houzz',
    description: 'The new way to design your home.',
    url_regex: '^(https?://)?(www\\.)?houzz\\.com/.*$',
  },
  {
    name: 'Issuu',
    description:
      'Publishing platform for digital magazines, interactive publications, and online catalogs.',
    url_regex: '^(https?://)?(www\\.)?issuu\\.com/.*$',
  },
  {
    name: 'Meetup',
    icon: 'meetup',
    description:
      'Meet new people, learn new things, find support, or just get out of your comfort zone on Meetup.',
    url_regex: '^(https?://)?(www\\.)?meetup\\.com/.*$',
  },
  {
    name: 'Viber',
    icon: 'viber',
    description:
      'Connect with your friends and family around the world with Viber.',
    url_regex: '^(https?://)?(www\\.)?viber\\.com/.*$',
  },
  {
    name: 'Zoom',
    description:
      'Video conferencing, web conferencing, and webinars with Zoom.',
    url_regex: '^(https?://)?(www\\.)?zoom\\.us/.*$',
  },
  {
    name: 'Podbean',
    description: 'A podcast hosting platform and monetization service.',
    url_regex: '^(https?://)?(www\\.)?podbean\\.com/.*$',
  },
  {
    name: 'Amazon',
    icon: 'amazon',
    description:
      'Shop online for electronics, apparel, books, and more on Amazon.',
    url_regex: '^(https?://)?(www\\.)?amazon\\.com/.*$',
  },
]

export function getBrandUrlRegex(brand) {}

import {
  faInstagram,
  faTumblr,
  faTwitter,
  faFacebook,
  faTiktok,
  faYoutube,
  faEtsy,
  faLinkedin,
  faPinterest,
  faSnapchat,
  faDailymotion,
  faBehance,
  faDribbble,
  faMedium,
  faReddit,
  faBlogger,
  faWordpress,
  faSoundcloud,
  faBandcamp,
  faSpotify,
  faApple,
  faDeviantart,
  faArtstation,
  faVimeo,
  faMixcloud,
  faTwitch,
  faDiscord,
  faTelegram,
  faWhatsapp,
  faLine,
  faFlickr,
  faPeriscope,
  faTrello,
  faPatreon,
  faGoodreads,
  faQuora,
  faQq,
  faVk,
  faWeibo,
  faYelp,
  faFoursquare,
  faBitbucket,
  faHouzz,
  faMeetup,
  faViber,
  faAmazon,
  faWeixin,
} from '@fortawesome/free-brands-svg-icons'

// Create an array with brand icons
export const brandIcons = [
  faWeixin,
  faInstagram,
  faTumblr,
  faTwitter,
  faFacebook,
  faTiktok,
  faYoutube,
  faEtsy,
  faLinkedin,
  faPinterest,
  faSnapchat,
  faVimeo,
  faDailymotion,
  faBehance,
  faDribbble,
  faMedium,
  faReddit,
  faBlogger,
  faWordpress,
  faSoundcloud,
  faBandcamp,
  faSpotify,
  faApple,
  faDeviantart,
  faArtstation,
  faVimeo,
  faMixcloud,
  faTwitch,
  faDiscord,
  faTelegram,
  faWhatsapp,
  faLine,
  faFlickr,
  faPeriscope,
  faTrello,
  faPatreon,
  faGoodreads,
  faQuora,
  faQq,
  faVk,
  faWeibo,
  faYelp,
  faFoursquare,
  faBitbucket,
  faHouzz,
  faMeetup,
  faViber,
  faAmazon,
]
