<template>
  <div class="flex flex-col gap-3">
    <input-layout :error="!!(innerError.name ?? errors?.name)">
      <form-field-input
        v-if="!hideName"
        v-model="form.name"
        class="w-1/2"
        placeholder="Name"
        variant="gray"
      />
    </input-layout>
    <input-layout :error="!!(innerError.address ?? errors?.address)">
      <form-field-input
        v-model="form.address"
        class="w-3/4"
        placeholder="Street"
        variant="gray"
      />
    </input-layout>
    <div class="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
      <input-layout :error="!!(innerError.city ?? errors?.city)">
        <form-field-input
          v-model="form.city"
          placeholder="City"
          variant="gray"
        />
      </input-layout>
      <input-layout :error="!!(innerError.state ?? errors?.state)">
        <form-field-input
          v-model="form.state"
          placeholder="State"
          variant="gray"
        />
      </input-layout>
      <input-layout :error="!!(innerError.country ?? errors?.country)">
        <form-field-input
          v-model="form.country"
          placeholder="Province/country"
          variant="gray"
        />
      </input-layout>
    </div>
    <input-layout :error="!!(innerError.zip ?? errors?.zip)">
      <form-field-input
        v-model="form.zip"
        class="w-1/4"
        placeholder="Postal Code"
        variant="gray"
      />
    </input-layout>
    <div
      v-if="props.coordinates"
      class="col-span-6 row-start-5 gap-3"
    >
      <form-field-select-map
        v-model:latitude="form.latitude"
        v-model:longitude="form.longitude"
        :errors="{
          latitude: !!(innerError.latitude ?? errors?.latitude),
          longitude: !!(innerError.longitude ?? errors?.longitude),
        }"
      />
    </div>
    <div
      v-if="!hideButtons"
      :class="props.coordinates ? 'row-start-6' : 'row-start-5'"
      class="col-span-6 grid h-10 grid-cols-2 gap-3"
    >
      <button
        :class="{
          'sr-only': disableDelete && disableClose,
        }"
        class="rounded bg-gray-100 duration-100 hover:bg-gray-200"
        @click="emit('delete')"
      >
        <font-awesome-icon
          v-if="!disableDelete"
          class="text-red"
          icon="trash"
        />
        <font-awesome-icon
          v-else
          class="text-gray-700"
          icon="times"
        />
      </button>
      <button
        :class="{
          'col-span-2': disableDelete && disableClose,
        }"
        :disabled="
          form.name?.length === 0 ||
            form.address?.length === 0 ||
            form.city?.length === 0 ||
            form.state?.length === 0 ||
            form.country?.length === 0 ||
            form.zip?.length === 0
        "
        class="rounded bg-gray-100 text-blue-primary duration-100 hover:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50"
        @click="save"
      >
        <font-awesome-icon icon="floppy-disk" />
      </button>
    </div>
    <validation-error
      v-if="errors"
      :error="Object.values(errors)?.[0]"
      class="col-span-6 row-start-7"
    />
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FormFieldSelectMap from '@/components/input/FormFieldSelectMap.vue'
import { ref } from 'vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import ValidationError from '@/components/ui/ValidationError.vue'

const emit = defineEmits(['save', 'delete'])
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  coordinates: {
    type: Boolean,
    default: false,
  },
  disableDelete: {
    type: Boolean,
    default: false,
  },
  disableClose: {
    type: Boolean,
    default: false,
  },
  hideName: {
    type: Boolean,
    default: false,
  },
  hideButtons: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: [Object],
    default: null,
  },
})

const innerError = ref({})

function save() {
  if (
    props.form.address?.length &&
    props.form.city?.length &&
    props.form.state?.length &&
    props.form.country?.length &&
    props.form.zip?.length
  ) {
    if (props.form.name?.length === 0) {
      props.form.name = props.form.address
    }
    emit('save')
  } else {
    innerError.value = {
      address: props.form.address?.length ? '' : 'Address is required',
      city: props.form.city?.length ? '' : 'City is required',
      state: props.form.state?.length ? '' : 'State is required',
      country: props.form.country?.length ? '' : 'Country is required',
      zip: props.form.zip?.length ? '' : 'Postal Code is required',
    }
    console.log(innerError.value)
  }
}
</script>

<style scoped></style>