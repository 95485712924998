<template>
  <div
    v-if="getUsers && getUsers.length"
    class="flex w-full items-center justify-between gap-4"
  >
    <div class="flex -space-x-6">
      <div
        v-for="(user, index) in getUsers"
        :key="user.id"
        class="flex-shrink-0 rounded-full border-2 border-white shadow-md"
      >
        <image-component
          class="rounded-full bg-indigo-100 bg-center object-cover"
          :class="getSize"
          :src="getUrl(user.avatar)"
        >
          <template #fallback>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12 rounded-full bg-gray-200 p-1 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M12 14s-5 2-5 5v1h10v-1c0-3-5-5-5-5z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M12 12a3 3 0 100-6 3 3 0 000 6z"
              />
            </svg>
          </template>
        </image-component>
      </div>
    </div>
    <div v-if="showUserCounts">
      <button
        v-if="clickableUser"
        class="text-base text-blue-primary"
        @click="$emit('open-popup')"
      >
        View all {{ getUserCount }}
      </button>
      <div
        v-else
        class="align-self-center justify-self-center text-base text-slate-400"
      >
        {{ getUserCount }} Fans
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
const emits = defineEmits(['open-popup'])




















































defineOptions({
  name: 'UserAvatarGroup',
})




















































const props = defineProps({
  users: {
    type: Array,
    default: () => [],
  },
  size: {
    type: String,
    default: 'md',
  },
  showUserCounts: {
    type: Boolean,
    default: true,
  },
  max: {
    type: Number,
    default: 4,
  },
  clickableUser: {
    type: Boolean,
    default: true,
  },
})

const getUsers = computed(() => {
  return props.users.slice(0, props.max)
})

const getUserCount = computed(() => {
  return props.users.length
})

const getSize = computed(() => {
  return props.size === 'md'
    ? 'h-12 w-12'
    : props.size === 'lg'
    ? 'h-16 w-16'
    : 'h-8 w-8'
})

function getUrl(avatar) {
  return avatar && avatar.path
    ? avatar.path
    : '/images/avatars/fiona_staples_01.jpg'
}
</script>


<style scoped>
.shadow-md {
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
}
</style>

<style scoped></style>