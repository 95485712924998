<template>
  <div
    class="relative flex h-full w-[90vw] max-w-[760px] flex-col gap-2 px-6 pb-3 pt-8"
    @touchstart="startSwipe"
    @touchmove="swipe"
    @touchend="endSwipe"
  >
    <button
      class="absolute right-2 top-2"
      @click="closeModal"
    >
      <font-awesome-icon
        icon="xmark"
        class="h-[18px] w-[18px] shrink-0 text-slate-800"
      />
    </button>
    <div class="relative h-fit w-full">
      <dropdown-button
        v-if="enableActions"
        class="!absolute right-2 top-2"
        rounded
        variant="gray"
      >
        <template #button>
          <font-awesome-icon
            class="h-6 w-6"
            icon="ellipsis"
          />
        </template>
        <template #buttons>
          <button
            class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
            @click="emit('delete')"
          >
            Delete
          </button>
        </template>
      </dropdown-button>
      <image-component
        :src="currentImage?.image?.path"
        class="h-[40vh] w-full rounded-lg rounded-t-lg object-cover"
      />
    </div>

    <div class="flex w-full items-center justify-between">
      <span class="text-sm text-slate-400">{{ currentImage.author }}</span>
      <div class="flex items-center gap-3 text-sm text-slate-500">
        <button
          :class="hasPreviousImage ? '' : 'invisible'"
          class="cursor-pointer text-sm text-slate-400"
          @click="prevImage"
        >
          <chevron-right class="h-4 w-4 rotate-180" />
        </button>
        {{ `${currentImage.id + 1} of ${images?.length}` }}
        <button
          :class="hasNextImage ? '' : 'invisible'"
          class="cursor-pointer text-sm text-slate-400"
          @click="nextImage"
        >
          <chevron-right class="h-4 w-4" />
        </button>
      </div>

      <span class="text-sm text-slate-400">{{ currentImage.date }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useModal } from '@/utils/modal'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ChevronRight from '@/icons/ChevronRight.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import DropdownButton from '@/components/buttons/DropdownButton.vue'

const emit = defineEmits(['delete'])
const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
  images: {
    type: Array,
    default: () => [],
  },
  enableActions: {
    type: Boolean,
    default: false,
  },
})

const modalHandler = useModal()

const closeModal = () => {
  modalHandler.closeAll()
}

let touchStartX = 0
let touchEndX = 0
const currentImage = ref(props.image)
const currentIndex = computed(() => {
  return props.images.findIndex((img) => img.id === currentImage.value.id)
})

const hasPreviousImage = computed(() => {
  return currentIndex.value > 0
})

const hasNextImage = computed(() => {
  return currentIndex.value < props.images.length - 1
})

const prevImage = () => {
  if (hasPreviousImage.value) {
    currentImage.value = props.images[currentIndex.value - 1]
  }
}

const nextImage = () => {
  if (hasNextImage.value) {
    currentImage.value = props.images[currentIndex.value + 1]
  }
}

const startSwipe = (event: TouchEvent) => {
  touchStartX = event.touches[0].clientX
}

const swipe = (event: TouchEvent) => {
  touchEndX = event.touches[0].clientX
}

const endSwipe = () => {
  if (touchStartX - touchEndX > 100) {
    nextImage()
  } else if (touchEndX - touchStartX > 100) {
    prevImage()
  }
}
</script>

<style scoped></style>
