<template>
  <div
    class="flex h-full min-w-[90vw] flex-col items-center bg-text-white pt-10"
  >
    <h3 class="mb-8 px-4 text-center text-2xl font-bold text-black md:px-10">
      Rules And Terms
    </h3>
    <div
      class="flex max-h-[70vh] w-full flex-col items-center gap-4 overflow-scroll px-4 py-4 pb-8 md:px-10"
    >
      <div
        v-for="(item, index) in form.rules"
        :key="index"
        class="relative flex w-full flex-col gap-2 rounded-lg bg-white p-4"
      >
        <v-button
          class="absolute right-0 top-0 flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center"
          no-padding
          rounding="full"
          variant="primary"
          @click="form.rules.splice(index, 1)"
        >
          <font-awesome-icon icon="times" />
        </v-button>
        <input-layout
          hint="Max 40 characters"
          label="Title"
        >
          <form-field-input
            v-model="item.title"
            :error="form.errors?.[`rules.${index}.title`]"
            :rules="{ max: 40 }"
            variant="default"
          />
        </input-layout>
        <input-layout
          hint="Max 245 characters"
          label="Description"
        >
          <form-field-input-textarea
            v-model="item.description"
            :error="form.errors?.[`rules.${index}.description`]"
            :rules="{ max: 245 }"
            variant="default"
          />
        </input-layout>
      </div>

      <div v-if="!form.rules?.length">
        You have not added any rules yet.
        <v-button
          no-padding
          variant="secondary"
          without-border
          @click="seedRules"
        >
          Add example rules.
        </v-button>
      </div>

      <v-button
        class="ml-0 mr-auto flex w-fit items-center gap-2"
        variant="secondary"
        @click="addMore"
      >
        <font-awesome-icon
          class="h-4 w-4 text-blue-primary"
          icon="plus"
        />
        Add more
      </v-button>
    </div>
    <div
      class="flex w-full items-center justify-between gap-4 border-t border-gray-300 bg-text-white px-4 py-4 md:px-10"
      style="box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1)"
    >
      <button
        class="w-fit shrink-0 text-base text-blue-primary"
        @click="closeModal()"
      >
        Cancel
      </button>
      <v-button
        class="w-fit shrink-0"
        variant="primary"
        @click="form.patch(`/api/groups/${form.id}`).then(() => emit('close'))"
      >
        Save changes
      </v-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { useModal } from '@/utils/modal'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VButton from '@/components/buttons/VButton.vue'
import { ModelForm } from '@/utils/form'
import UserGroup from '@/models/UserGroup'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'

const emit = defineEmits(['close'])

const props = defineProps({
  form: {
    type: Object as PropType<ModelForm<UserGroup>>,
    required: true,
  },
})

const modalHandler = useModal()

const closeModal = () => {
  modalHandler.closeAll()
}

function addMore(
  {
    title,
    description,
  }: {
    title: string
    description: string
  } = { title: '', description: '' }
) {
  console.log(title, description)
  if (!props.form.rules) props.form.rules = []
  props.form?.rules.push({ title, description })
}

function seedRules() {
  ;[
    {
      title: 'Be respectful',
      description: 'Respect others and their opinions.',
    },
    {
      title: 'No spamming',
      description: 'Do not spam the group with unnecessary messages.',
    },
    {
      title: 'No hate speech',
      description: 'Do not use hate speech or promote violence.',
    },
  ].forEach((rule) => addMore(rule))
}
</script>

<style scoped></style>
