<template>
  <form-field-select-item
    :resource-key="`autocomplete-roles-${selected?.id}`"
    :search-url="`/api/partner/organisations/${selected?.id}/roles`"
    key-by="id"
    labels="inline"
    search-by="title"
    single-select
  />
</template>

<script lang="ts" setup>
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import useOrganisation from '@/utils/composables/organisation'

const { selected } = useOrganisation()
</script>

<style scoped></style>
