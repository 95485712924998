<template>
  <div class="flex flex-col gap-2">
    <input-layout
      label="Color Name"
      layout="grid"
      variant="gray"
    >
      <form-field-select-text
        v-model="selectedColor.name"
        :variant="variant"
        :suggestions="colorOptions"
        @select="onSelectName"
      />
    </input-layout>
    <input-layout
      v-model="selectedColor.value"
      label="Choose color"
      layout="grid"
      variant="gray"
    >
      <div class="flex items-center gap-2">
        <input
          id="hs-color-input"
          v-model="selectedColor.value"
          type="color"
          class="block h-10 w-10 cursor-pointer rounded-lg bg-white p-1 pl-2 disabled:pointer-events-none disabled:opacity-50"
          title="Choose your color"
          @input="onSelectColor"
        />
        <span>{{ selectedColor.value }}</span>
      </div>
    </input-layout>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldSelectText from '@/components/input/FormFieldSelectText.vue'

const emit = defineEmits(['update:colorName', 'update:colorValue'])

const props = defineProps({
  /**
   * The values of the input
   * @model
   */
  colorHEX: {
    type: String,
    default: null,
  },
  colorName: {
    type: String,
    default: null,
  },
  /**
   * The suggestions to show in the dropdown
   */
  suggestions: {
    type: Array,
    default: () => [],
  },
  default: {
    type: String,
    default: null,
  },
  /**
   * The variant of the input
   * @values default, gray, outline
   */
  variant: {
    type: String,
    default: null,
  },
})

const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#fafafa',
  tangerine: '#ff390e',
  'very-light-blue': '#5D78FE',
  'ultra-light-blue': '#D2DAFF',
  'dark-blue': '#050425',
  'dark-menu': '#1F1E36',
  'light-menu': '#eceeef',
  'space-blue': '#31305D',
  'light-grey': '#5F6775',
  'light-grey-100': '#878D97',
  'dark-grey': '#1B1B21',
  'neutral-300': '#BFC6CD',
  paragraph: '#47474F',
  slate: 'slate',
  rose: 'rose',
  amber: 'amber',
  emerald: 'emerald',
  fuchsia: 'fuchsia',
  purple: 'purple',
  'purple-space': '#85144B',
  'black-space': '#070707',
  gold: '#FFD700',
  brown: {
    dark: '#654321',
  },
  gray: {
    50: '#f9f9fa',
    100: '#eceeef',
    200: '#dee1e3',
    300: '#cfd3d7',
    400: '#bfc5c9',
    500: '#adb4b9',
    600: '#98a1a8',
    700: '#7f8b93',
    800: '#606e79',
    900: '#374047',
    950: '#16181c',
    dark: '#4e4e52',
    DEFAULT: '#808080',
  },
  blue: {
    50: '#f6fafd',
    100: '#e2eff9',
    200: '#cce4f5',
    300: '#b5d8f0',
    400: '#9bcaeb',
    500: '#7dbae5',
    600: '#5aa7de',
    700: '#2d8fd5',
    800: '#006fbe',
    900: '#004170',
    950: '#001c3c',
    DEFAULT: '#1613BD',
    primary: '#1613BD',
    muted: '#302E85',
    neon: '#797FFF',
    hero: '#0055FF',
    space: '#001F3F',
    'secondary-btn': '#D2DAFF',
  },
  indigo: {
    50: '#f9f9fe',
    100: '#ebedfb',
    200: '#dddff8',
    300: '#cdd1f5',
    400: '#bcc0f2',
    500: '#a9aeee',
    600: '#929ae9',
    700: '#7780e4',
    800: '#525edc',
    900: '#0516cd',
    950: '#000882',
    DEFAULT: '#4F46E5',
  },
  violet: {
    50: '#fbf8fe',
    100: '#f1ebfb',
    200: '#e8dcf8',
    300: '#ddccf5',
    400: '#d1baf1',
    500: '#c4a6ed',
    600: '#b48ee8',
    700: '#a172e3',
    800: '#864adb',
    900: '#4c00b8',
    950: '#23006a',
    DEFAULT: '#7C3AED',
  },
  fuschia: {
    50: '#fdf8fe',
    100: '#f9e8fa',
    200: '#f5d8f7',
    300: '#f0c5f3',
    400: '#eab0ef',
    500: '#e498ea',
    600: '#dc7be5',
    700: '#d153dd',
    800: '#bb01cc',
    900: '#72007c',
    950: '#390040',
    DEFAULT: '#A855F7',
  },
  pink: {
    50: '#fef8fb',
    100: '#fbe9f3',
    200: '#f7d8ea',
    300: '#f4c6e1',
    400: '#f0b2d6',
    500: '#eb9ac9',
    600: '#e57dba',
    700: '#dd57a5',
    800: '#cf0d7e',
    900: '#7f004a',
    950: '#3f0026',
    neon: '#ff6ac1',
    DEFAULT: '#EC4899',
  },
  red: {
    50: '#fef8f9',
    100: '#fbe9eb',
    200: '#f8dadc',
    300: '#f4c8cc',
    400: '#f0b5ba',
    500: '#ec9ea5',
    600: '#e6838b',
    700: '#df5f6a',
    800: '#d2202f',
    900: '#85000b',
    950: '#420005',
    hero: '#FF2600',
    DEFAULT: '#F43F5E',
  },
  orange: {
    50: '#fdf9f5',
    100: '#f9ebe1',
    200: '#f5dccb',
    300: '#f0ccb3',
    400: '#eaba98',
    500: '#e4a679',
    600: '#dd8d54',
    700: '#d36d24',
    800: '#b54c00',
    900: '#6b2d00',
    950: '#351800',
    space: '#FF851B',
    DEFAULT: '#FB923C',
  },
  yellow: {
    50: '#fbfaec',
    100: '#f3efc4',
    200: '#eae398',
    300: '#e0d666',
    400: '#d5c72c',
    500: '#c5b500',
    600: '#b0a200',
    700: '#978b00',
    800: '#776e00',
    900: '#464000',
    950: '#232000',
    hero: '#FFD100',
    DEFAULT: '#F59E0B',
  },
  lime: {
    50: '#f6fcee',
    100: '#e2f4c8',
    200: '#cbec9e',
    300: '#b2e26e',
    400: '#94d736',
    500: '#75c800',
    600: '#68b300',
    700: '#599900',
    800: '#477900',
    900: '#294700',
    950: '#142300',
    DEFAULT: '#84CC16',
  },
  green: {
    50: '#f2fcf1',
    100: '#d6f6d3',
    200: '#b6efb1',
    300: '#90e788',
    400: '#61dd56',
    500: '#1fcf0f',
    600: '#0fb900',
    700: '#0d9f00',
    800: '#0b7e00',
    900: '#064a00',
    950: '#032500',
    neon: '#39ff14',
    forest: '#228B22',
    DEFAULT: '#22C55E',
  },
  teal: {
    50: '#f1fcf6',
    100: '#d2f6e1',
    200: '#afefc9',
    300: '#85e7ae',
    400: '#52dc8b',
    500: '#0ace5c',
    600: '#00b84d',
    700: '#009e42',
    800: '#007a34',
    900: '#004a1f',
    950: '#002510',
    DEFAULT: '#10B981',
  },
  cyan: {
    50: '#f0fcfb',
    100: '#cef5f2',
    200: '#a8eee8',
    300: '#7ae4dc',
    400: '#40d9cc',
    500: '#00c9b8',
    600: '#00b3a4',
    700: '#009a8d',
    800: '#007a70',
    900: '#004842',
    950: '#002421',
    DEFAULT: '#06B6D4',
  },
}

const selectedColor = ref({ name: props.colorName, value: props.colorHEX })

const colorOptions = computed(() => {
  const options = []

  const addColorOption = (name, value, prefix = '') => {
    // Create a unique ID by combining the color name and its value
    const id = `${name}-${value}`
    options.push({ id, name, value })
  }

  const processColorObject = (colorObject, prefix) => {
    for (const key in colorObject) {
      const value = colorObject[key]
      const prefixedKey = prefix ? `${prefix}-${key}` : key
      if (typeof value === 'object' && value !== null) {
        // Process nested objects recursively
        processColorObject(value, prefixedKey)
      } else {
        // Add the color option with a unique ID using the prefixed key
        addColorOption(prefixedKey, value)
      }
    }
  }

  if (props.colors) {
    processColorObject(props.colors, '')
  } else {
    processColorObject(colors, '')
  }
  return options
})

function onSelectColor(event) {
  selectedColor.value.value = event.target.value
  emit('update:colorValue', selectedColor.value.value)
}

function onSelectName(color) {
  if (color.name === 'transparent') {
    selectedColor.value.value = 'transparent'
    emit('update:colorValue', 'transparent')
  } else {
    selectedColor.value.value = color.value
  }
  selectedColor.value.name = color.name
  emit('update:colorName', color.name)
}

onMounted(() => {
  colorOptions
})
</script>

<style scoped></style>
