<template>
  <transition-group
    ref="list"
    class="max-h-[300px] overflow-y-auto"
    enter-active-class="transition ease-in-out duration-150"
    enter-from-class="transform translate-x-2 opacity-0"
    enter-to-class="transform translate-x-0 opacity-100"
    leave-active-class="transition ease-in-out duration-100 absolute"
    leave-from-class="transform translate-x-0 opacity-100"
    leave-to-class="transform translate-x-2 opacity-0"
    move-class="transition-transform duration-150"
    tag="ul"
  >
    <template v-if="useGrouping">
      <li
        v-for="(group, index) in groupedItems"
        :key="index"
      >
        <h6
          v-if="group.name !== null"
          class="sticky top-0 bg-gray-100 bg-opacity-75 px-2 text-xs text-gray-800 backdrop-blur"
        >
          {{ group.name }}
        </h6>
        <ul class="flex flex-col gap-1">
          <li
            v-for="item in group.items"
            :key="item[keyBy]"
            :class="{
              'bg-blue-200': highlightedKey && item[keyBy] === highlightedKey,
            }"
            :data-id="item[keyBy]"
            class="line-clamp-1 cursor-pointer px-2 duration-75 hover:bg-gray-200"
            @click="emit('select', item)"
          >
            <slot
              :is-selected="!!selected.find((i) => compareFunction(i, item))"
              :item="item"
              name="item"
            >
              <font-awesome-icon
                v-if="showCheck"
                :class="[
                  selected.find((i) => compareFunction(i, item))
                    ? 'text-blue-primary'
                    : 'text-gray-400',
                ]"
                icon="check"
              />
              {{ item.name }}
            </slot>
          </li>
        </ul>
      </li>
    </template>
    <template v-else>
      <li
        v-for="item in items"
        :key="item[keyBy]"
        :class="{
          'bg-gray-200': highlightedKey && item[keyBy] === highlightedKey,
        }"
        :data-id="item[keyBy]"
        class="line-clamp-1 cursor-pointer px-2 duration-75 hover:bg-gray-200"
        @click="emit('select', item)"
      >
        <slot
          :is-selected="!!selected.find((i) => compareFunction(i, item))"
          :item="item"
          name="item"
        >
          <font-awesome-icon
            v-if="showCheck"
            :class="[
              selected.find((i) => compareFunction(i, item))
                ? 'text-blue-primary'
                : 'text-gray-400',
            ]"
            icon="check"
          />
          {{ item.name }}
        </slot>
      </li>
    </template>
    <li
      v-if="loading"
      :key="'loading-' + $.uid"
      class="flex w-full justify-center"
    >
      <app-spinner class="h-8 w-8 text-gray-400" />
    </li>
    <template
      v-if="items.length === 0 && !loading"
      :key="'empty-' + $.uid"
    >
      <li
        v-if="allowCreate"
        class="cursor-pointer bg-white"
        @click="emit('create')"
      >
        <slot name="create">override me :)</slot>
      </li>
      <li class="w-full bg-gray-100 px-2 py-3 text-gray-800">
        <span>No results found</span>
      </li>
    </template>
  </transition-group>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AppSpinner from '@/components/ui/AppSpinner.vue'
import { computed, ref, watch } from 'vue'

const emit = defineEmits(['select', 'create'])

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  compareFunction: {
    type: Function,
    default: (a, b) => {
      return a.id === b.id
    },
  },
  keyBy: {
    type: String,
    default: 'id',
  },
  selected: {
    type: [Array, Object],
    default: () => [],
  },
  showCheck: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  highlightedKey: {
    type: [String, Number],
    default: null,
  },
  allowCreate: {
    type: Boolean,
    default: false,
  },
  useGrouping: {
    type: Boolean,
    default: false,
  },
})

const list = ref(null)

watch(
  () => props.highlightedKey,
  (value) => {
    if (value) {
      const el = list.value.$el
      const highlighted = el.querySelector(`[data-id="${value}"]`)
      if (highlighted) {
        // scroll element to center of dropdown
        el.scrollTop = highlighted.offsetTop - el.offsetHeight / 2
      }
    }
  }
)

/**
 * Create groups of sticky elements with items inside
 * @type {ComputedRef<unknown>}
 */
const groupedItems = computed(() => {
  const groups = []
  let currentGroup = null
  props.items.forEach((item) => {
    if (item.sticky) {
      currentGroup = {
        name: item.name,
        items: [],
      }
      groups.push(currentGroup)
    } else {
      if (!currentGroup)
        groups.push({
          name: null,
          items: [item],
        })
      else currentGroup.items.push(item)
    }
  })
  return groups
})
</script>

<style scoped></style>
