import {ModalOptions, useModal} from './modal'
import {AxiosResponse} from 'axios'
import {Model} from '@/models/Model'
import {useModelForm} from './form'
import {Component, computed} from 'vue'

interface ModalFormOptions<TModel extends Model> {
    /**
     * @deprecated
     */
    fetch?: Promise<AxiosResponse>
    fill?: Partial<TModel>

    /**
     * Component to render in the modal
     */
    component?: () => Component

    onOpen?: (form: any) => void

    /**
     * Callback to delete the resource. This may be called manually or automatically when using touch: true.
     * @param form
     */
    onDelete?: (form: any) => Promise<AxiosResponse>

    /**
     * Callback to save the form. This is called when form is submitted.
     * TODO: add type
     * @param form
     */
    onSave?: (form: any) => Promise<AxiosResponse>

    /**
     * Called when the modal is saved successfully
     * @param response
     */
    onSaved?: (response: AxiosResponse) => void
}

export type ModalFormOptionsType<TModel extends Model> =
    ModalFormOptions<TModel> & ModalOptions

export default function useModalForm<TModel extends Model>(
    model: TModel,
    options: ModalFormOptionsType<TModel> = {}
) {
    const modalHandler = useModal()

    const form = useModelForm(model)
    const isOpen = computed(() => form.isFormOpen)
    const isPreloading = computed(() => form.isPreloading)
    const isPreloadError = computed(() => form.isPreloadError)
    const preloadError = computed(() => form.preloadError)
    const preloadErrorCode = computed(() => form.preloadCode)

    options.props = {
        form,
        ...options.props,
        loading: isPreloading,
        error: isPreloadError,
        errorMessage: preloadError,
        errorCode: preloadErrorCode,
    }

    const onClose = options.onClose
    const onCloseWrapped = (reason: string) => {
        console.log(form.isDirty)
        if (!form.isDirty) {
            console.log('form is not dirty, close modal')
            return true
        }
        const promise = onClose?.(reason)
        if (promise instanceof Promise) {
            promise.catch((err) => {
                form.clearErrors()
                form.setError(
                    Object.fromEntries(
                        Object.entries(err.response?.data?.errors ?? {}).map(
                            ([key, value]) => {
                                return [key, value[0]]
                            }
                        )
                    )
                )
                console.log(form.errors)
            })
        }
        if (!promise) {
            return false
        }
        return promise
    }

    const onClosed = options.onClosed
    options.onClosed = () => {
        onClosed?.()
        form.isFormOpen = false
    }

    const component = options.component?.()
    if (!component) throw new Error('No component provided for modal form')

    return {
        /**
         * Open modal form with prefill data, if prefill is a promise, it will be resolved first
         * @param prefill
         * @param touch indicates if the form is touched or not. If true, the model will be deleted if the form is closed without saving
         */
        open(
            prefill?: Promise<AxiosResponse> | Partial<TModel>,
            touch: boolean = false
        ) {
            form.reset() // reset form to initial
            form.clearErrors() // clear any errors from previous form

            if (touch) {
                if (!options.onDelete) {
                    throw new Error(
                        'onDelete callback is required when touch is true. Please provide it to delete the model when the form is closed without saving'
                    )
                }
                options.onClose = (reason) => {
                    if (!form.isDirty) {
                        options.onDelete?.()
                    } else {
                        return onCloseWrapped(reason)
                    }
                    return true
                }
            } else {
                options.onClose = onCloseWrapped
            }

            return new Promise<void>((resolve) => {
                // isOpen.value = true
                modalHandler.open(component, options)
                if (prefill instanceof Promise) {
                    form.openEditForm(prefill).then(() => {
                        resolve()
                    })
                } else {
                    form.fillData(prefill)
                    resolve()
                }
            })
        },
        form,
        modalHandler,
        isOpen,
        isPreloading,
    }
}

export function useDetachedModalForm() {
    // modal handler controls every modal in app
    const modalHandler = useModal()

    return {
        open<TModel extends Model>(
            model: TModel,
            options: ModalFormOptionsType<TModel> = {},
            prefill?: Promise<AxiosResponse> | Partial<TModel>,
            touch: boolean = false,
            discardOnClose: boolean = true
        ) {
            // create form instance from data
            const form = useModelForm(model)
            const isOpen = computed(() => form.isFormOpen)
            const isPreloading = computed(() => form.isPreloading)
            const isPreloadError = computed(() => form.isPreloadError)
            const preloadError = computed(() => form.preloadError)
            const preloadErrorCode = computed(() => form.preloadCode)
            let deleted = false

            const component = options.component?.()
            if (!component) throw new Error('No component provided for modal form')

            // check if touch is true, if so, add a callback to delete the resource when the form is closed without saving
            if (touch) {
                if (!options.onDelete) {
                    throw new Error(
                        'onDelete callback is required when touch is true. Please provide it to delete the model when the form is closed without saving'
                    )
                }

                options.onClose = (reason) => {
                    console.log('modal form inner close')
                    console.log('deleted', deleted)
                    if (deleted) {
                        console.log('form is deleted, close modal')
                        return true
                    }
                    if (!form.isDirty && discardOnClose) {
                        console.log('form is not dirty, delete resource')
                        return options.onDelete?.(form) ?? true
                    } else {
                        console.log('form is dirty, save resource and close')
                        if (options.onSave) {
                            const promise = options.onSave?.(form)

                            if (options.onSaved) {
                                // call onSaved callback once the resource is saved
                                promise?.then(options.onSaved)
                            }
                            console.log('register error handler')
                            // catch any errors and set them to form
                            promise?.catch((err) => {
                                console.log('error', err)
                                form.clearErrors()
                                form.setError(
                                    Object.fromEntries(
                                        Object.entries(err.response?.data?.errors ?? {}).map(
                                            ([key, value]) => {
                                                return [key, value[0]]
                                            }
                                        )
                                    )
                                )
                            })

                            return promise
                        } else {
                            console.log('no onSave callback, close modal')
                            return true
                        }
                    }
                }
            } else {
                options.onClose = (reason) => {
                    console.log('modal form inner close')
                    if (deleted) {
                        console.log('form is deleted, close modal')
                        return true
                    }
                    if (form.isDirty) {
                        console.log('form is dirty, save resource and close')
                        if (options.onSave) {
                            const promise = options.onSave?.(form)

                            if (options.onSaved) {
                                // call onSaved callback once the resource is saved
                                promise?.then(options.onSaved)
                            }
                            console.log('register error handler')
                            // catch any errors and set them to form
                            promise?.catch((err) => {
                                console.log('error', err)
                                form.clearErrors()
                                form.setError(
                                    Object.fromEntries(
                                        Object.entries(err.response?.data?.errors ?? {}).map(
                                            ([key, value]) => {
                                                return [key, value[0]]
                                            }
                                        )
                                    )
                                )
                            })

                            return promise
                        } else {
                            console.log('no onSave callback, close modal')
                            return true
                        }
                    }
                    console.log('form is not dirty, close modal')
                    return true
                }
            }

            if (prefill instanceof Promise) {
                form.openEditForm(prefill).then(() => {
                    options.onOpen?.(form)
                })
            } else if (prefill) {
                form.fillData(prefill)
            }

            if (!options.events) {
                options.events = {}
            }

            if (options.events.onDelete) {
                throw new Error(
                    'onDelete is a reserved callback, please use ModalFormOptions.onDelete instead.'
                )
            }

            options.events.onDelete = (payload: any[], close: () => void) => {
                options.onDelete?.(form).then(() => {
                    deleted = true
                    close()
                })
            }

            // pass form instance along with form state to modal component
            const modalOptions: ModalOptions = {
                props: {
                    form,
                    ...options.props,
                    loading: isPreloading,
                    error: isPreloadError,
                    errorMessage: preloadError,
                    errorCode: preloadErrorCode,
                },
                events: options.events,
                backdrop: options.backdrop,
                onClose: options.onClose,
                onClosed: options.onClosed,
            }

            return modalHandler.open(component, modalOptions)
        },
    }
}

export function useFiltersModalForm(
    filters: object,
    component: () => Component
) {
    const modalHandler = useModal()

    return {
        open() {
            modalHandler.open(component(), {
                props: {
                    state: 'filters',
                    form: {},
                },
            })
        },
    }
}
