import useAxiosForm, { Form } from '@/utils/form'
import 'reflect-metadata'

interface FormOptions {
  only?: string[]
  except?: string[]
  always?: string[]
}

/**
 * Base model class
 * Extend this class with your model properties.
 */
export class Model {
  /**
   * Create a new form instance from the model.
   * @param only
   * @param except
   * @param always
   */
  form(
    { only, except, always }: FormOptions = {
      only: undefined,
      except: undefined,
      always: undefined,
    }
  ): Form<Partial<this>> {
    let keys = Object.keys(this)

    if (always === undefined) always = ['id']

    if (only) {
      keys = keys.filter((key) => only.includes(key) || always?.includes(key))
    }
    if (except) {
      keys = keys.filter(
        (key) => !except.includes(key) || always?.includes(key)
      )
    }

    return useAxiosForm(
      Object.fromEntries(keys.map((key) => [key, this[key]])) as Partial<this>
    )
  }

  /**
   * Update the model with new data.
   * @param data
   */
  update(data: Partial<this>) {
    for (const key in data) {
      // check if key has metadata
      const metadata = Reflect.getMetadata('propertyType', this, key)
      const type = metadata?.type
      if (type) {
        if (metadata.isArray) {
          // @ts-expect-error key is a valid property
          this[key] = data[key].map((item: any) => {
            return type.make(item)
          })
        } else {
          this[key] = type.make(data[key])
        }
      } else {
        // @ts-expect-error key is a valid property
        this[key] = data[key]
      }
    }
    return this
  }

  /**
   * Create a new model instance from an object.
   * Check if object has decorator and apply it.
   * @param from
   */
  static make<T extends Model>(from: any = {}): T {
    const instance = new this() as T
    Object.assign(instance, from)

    for (const key in instance) {
      const metadata = Reflect.getMetadata('propertyType', instance, key)
      const type = metadata?.type
      if (type) {
        if (metadata.isArray) {
          instance[key] = instance[key].map((item: any) => type.make(item))
        } else if (Object.prototype.hasOwnProperty.call(instance, 'make')) {
          instance[key] = type.make(instance[key])
        } else {
          console.warn('no make', key)
        }
      }
    }

    return instance
  }

  id: number | string | null = null

  /**
   * Create a new model instance and hydrate it with data.
   * @param data
   * @param baseClass
   */
  static fromData<T extends Model>(
    data: Partial<T>,
    baseClass: { new (): T } | null = null
  ) {
    if (baseClass) {
      const instance = new baseClass()
      return instance.update(data)
    } else {
      return this.make(data)
    }
  }

  static fromDataArray<T extends Model>(
    data: Partial<T>[],
    baseClass: { new (): T } | null = null
  ) {
    return data.map((item) => this.fromData<T>(item, baseClass))
  }

  /**
   * Fill the model with data from a response object.
   * @param data
   */
  fillFromResponse(data: any) {
    this.update(data)
    return this
  }

  /**
   * Save model decorator for parsing response data.
   * @param type
   * @param options
   */
  static propertyType(
    type: any,
    options?: {
      isArray?: boolean
    }
  ) {
    return Reflect.metadata('propertyType', {
      type,
      isArray: options?.isArray || false,
    })
  }
}
