<template>
  <div
    class="relative flex items-start justify-between gap-2"
    :class="miniMode ? 'flex-row' : 'flex-col'"
  >
    <slot
      v-for="(user, index) in selectedArray"
      :key="index"
      name="selected-user"
      :user="user"
    >
      <div
        class="flex shrink-0 items-center gap-3"
        @click="changeUser(user)"
      >
        <avatar-image
          :src="user.avatar?.path"
          :alt="user.name"
          :class="[
            'min-w-8 h-8 shrink-0  rounded-full ',
            { 'cursor-pointer': miniMode && singleSelect },
          ]"
        />
        <slot
          v-if="!miniMode"
          name="user-info"
          :user="user"
        >
          <h1 class="text-base font-normal">
            {{ user.name }}
          </h1>
        </slot>
        <slot
          v-if="!miniMode"
          name="selected-user-buttons"
        />
        <button
          v-if="!miniMode"
          @click="
            updateSelected(selectedArray.filter((s) => s.id !== user.id))
          "
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>
    </slot>
    <form-field-select-item
      v-if="!(selectedArray.length && singleSelect)"
      v-model="search"
      search-by="name"
      key-by="id"
      :selected="selected"
      placeholder="Find user"
      :single-select="singleSelect"
      :loading="searching"
      :suggestions="foundOptions"
      :allow-create="allowCreate"
      :show-button-by-default="showButtonByDefault"
      :labels="labels"
      :mini-mode="miniMode"
      @update:selected="updateSelected"
      @create="emit('create', search)"
    >
      <template #button="{ click }">
        <button
          class="flex items-center gap-3 text-base text-gray-500"
          @click="click"
        >
          <button-plus
            is="div"
            class="flex h-8 w-8 items-center justify-center rounded-full"
          />
          <template v-if="label">
            Add User
          </template>
        </button>
      </template>
    </form-field-select-item>
  </div>
</template>

<script setup>
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core/index'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import AvatarImage from '@/components/ui/AvatarImage.vue'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
/**
 * Field to search for users and select them.
 * @figma https://www.figma.com/file/NycgmenOdWjRDMhseKR9VA/Fankind?type=design&node-id=3593-5146&mode=design&t=iEbMoQl7vAx1llCi-4
 * @usage <form-field-select-user v-model:selected="selected"/>
 * @example {
 *   "title": "Default",
 *   "description": "Default form-field-select-user",
 *   "attrs": {
 *    "selected": [
 *     {"id":1, "name": "John Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *     {"id":2, "name": "Jane Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *     {"id":3, "name": "John Smith", "avatar": {"path": "https://picsum.photos/200"}},
 *     {"id":4, "name": "Jane Smith", "avatar": {"path": "https://picsum.photos/200"}}
 *    ]
 *   }
 * }
 * @example {
 *  "title": "Single select",
 *  "description": "Single select form-field-select-user",
 *  "attrs": {
 *   "selected": {"id":1, "name": "John Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *   "singleSelect": true
 *  }
 * }
 * @example {
 * "title": "With collapse button",
 * "description": "With collapse button form-field-select-user",
 *  "attrs": {
 *   "selected": [
 *    {"id":1, "name": "John Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *    {"id":2, "name": "Jane Doe", "avatar": {"path": "https://picsum.photos/200"}},
 *    {"id":3, "name": "John Smith", "avatar": {"path": "https://picsum.photos/200"}},
 *    {"id":4, "name": "Jane Smith", "avatar": {"path": "https://picsum.photos/200"}}
 *   ],
 *   "toggleCollapseButton": true
 *  }
 * }
 */


const emit = defineEmits(['update:selected', 'create'])


















































defineOptions({
  name: 'FormFieldSelectUser',
})


















































const props = defineProps({
  /**
   * Array (or single object) of selected users.
   */
  selected: {
    type: [Object, Array],
    default: () => [],
  },
  /**
   * Override the default search suggestions.
   */
  suggestions: {
    type: Array,
    default: () => undefined,
  },
  /**
   * Whether to allow selecting multiple users.
   */
  singleSelect: {
    type: Boolean,
    default: false,
  },
  /**
   * Is the field expanded by default.
   * @deprecated
   */
  expanded: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether to show the toggle collapse button.
   */
  toggleCollapseButton: {
    type: Boolean,
    default: false,
  },
  /**
   * Function to compare two users. Used for selecting the correct option.
   */
  compareFunction: {
    type: Function,
    default: (a, b) => a.id === b.id,
  },

  allowCreate: {
    type: Boolean,
    default: false,
  },
  showButtonByDefault: {
    type: Boolean,
    default: false,
  },
  label: {
    type: Boolean,
    default: true,
  },
  labels: {
    type: String,
    validator: (value) => {
      return ['top', 'inline', 'none'].includes(value)
    },
    default: 'none',
  },
  miniMode: {
    type: Boolean,
    default: false,
  },
})

const toggleExpanded = ref(true)
const addingNew = ref(false)
const addNewInputField = ref(null)
const foundOptions = ref([])
const search = ref('')
const searching = ref(false)

const selectedArray = computed(() => {
  if (Array.isArray(props.selected)) return props.selected
  else return [props.selected].filter(Boolean)
})

function searchGuests() {
  searching.value = true
  axios
    .get(`/api/autocomplete/partner/users`, {
      params: { query: search.value },
    })
    .then((response) => {
      foundOptions.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      searching.value = false
    })
}

onMounted(searchGuests)

const searchDebounce = useDebounceFn(searchGuests, 200)

watch(search, () => {
  searchDebounce()
  searching.value = true
})
const selectedOptions = computed(() => {
  if (props.options) return props.options
  else return foundOptions.value
})

const isExpanded = computed(() => {
  return toggleExpanded.value || props.expanded
})

const filteredOptions = computed(() => {
  return selectedOptions.value.filter((option) => {
    return option.name.toLowerCase().includes(search.value.toLowerCase())
  })
})

function focusInAddNew(e) {
  addingNew.value = true
  requestAnimationFrame(() => {
    if (addNewInputField.value) {
      addNewInputField.value?.focus()
    } else {
      addingNew.value = false
    }
  })
}

function focusOutAddNew(e) {
  if (e.currentTarget.contains(e.relatedTarget)) {
    return
  }

  addingNew.value = false
}

function updateSelected(value) {
  console.log('update selected', value)
  emit('update:selected', value)
}

function remove(item) {
  if (props.singleSelect) {
    updateSelected(null)
  } else {
    updateSelected(
      props.selected.filter((i) => !props.compareFunction(i, item))
    )
  }
}

function select(item) {
  if (props.singleSelect) {
    console.log('single select', item)
    updateSelected(item)
  } else {
    updateSelected([...props.selected, item])
  }
}

function toggle(item) {
  console.log('toggle', item)
  console.log(props.singleSelect)
  if (props.singleSelect) {
    select(item)
  } else {
    if (props.selected.find((i) => props.compareFunction(i, item))) {
      remove(item)
    } else {
      select(item)
    }
  }
}

const changeUser = (user) => {
  if (props.miniMode && props.singleSelect) {
    console.log('update')
    updateSelected([])
  }
}
</script>

<style scoped></style>
