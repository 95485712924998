<template>
  <background-image-component
    is="button"
    :fallback-src="null"
    :image="ctaAlwaysVisible ? modelValue : null"
    class="relative overflow-hidden rounded-lg border border-dashed border-gray-300 text-sm font-normal text-slate-400"
    type="button"
    @click="openFileInput"
  >
    <template v-if="modelValue && !ctaAlwaysVisible">
      <image-component
        v-if="imageType"
        :image="modelValue"
        class="mx-auto h-full"
      />
      <div v-else>
        <!--not an image, show file preview or smth-->
      </div>
    </template>
    <slot
      v-else
      name="content"
    >
      <div
        :class="{
          'bg-black/50': modelValue,
        }"
        class="z-topmost flex h-full flex-col items-center justify-center gap-2"
      >
        <image-upload-icon
          :class="iconClass"
          class="h-7 w-7 text-slate-200"
        />
        <template v-if="!hideHint">
          <span>Drag & Drop image here</span>
          <span>or</span>
          <span class="underline">Browse</span>
        </template>
      </div>
    </slot>

    <input
      ref="input"
      :accept="accept || (imageType ? 'image/*' : undefined)"
      class="hidden"
      type="file"
      @change="onFileChange"
      @keydown.enter.prevent
    />
  </background-image-component>
</template>

<script lang="ts" setup>
import ImageUploadIcon from '@/icons/ImageUploadIcon.vue'
import { ref } from 'vue'
import { useModal } from '@/utils/modal'
import ImageCropDialog from '@/components/view/ImageCropDialog.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  accept: {
    type: String,
    default: () => undefined,
  },
  imageType: {
    type: Boolean,
    default: false,
  },
  imageResize: {
    type: Boolean,
    default: false,
  },
  imageAspectRatio: {
    type: [Number, String],
    default: () => undefined,
  },
  ctaAlwaysVisible: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Object, File],
    default: null,
  },
  iconClass: {
    type: String,
    default: '',
  },
  hideHint: {
    type: Boolean,
    default: false,
  },
})

const input = ref<HTMLInputElement | null>(null)
const modalHandler = useModal()

function openFileInput() {
  input.value?.click()
}

function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]

  // clear the input value
  target.value = ''

  if (props.imageType && props.imageResize && file) {
    modalHandler.open(ImageCropDialog, {
      props: {
        position: 'center-center',
        file: file,
        ratio: +props.imageAspectRatio,
      },
      events: {
        onSave: ([{ file: croppedFile, blobUrl: url }]: any) => {
          console.log({
            file: croppedFile,
            path: url,
          })
          emit('update:modelValue', {
            file: croppedFile,
            path: url,
          })
        },
      },
    })
  } else {
    emit(
      'update:modelValue',
      props.imageType
        ? {
            file,
            path: URL.createObjectURL(file),
          }
        : file
    )
  }
}
</script>

<style scoped></style>
