<template>
  <app-chat
    comment-replies
    class="grow gap-10"
    :class="wrapperClass"
    :api-url="`/api/partner/conventions/${convention.id}/tasks/${task.id}/comments`"
    :channel="`task-${task.id}`"
    reverse-order
  >
    <template #empty-messages>
      <div
        class="flex flex-col items-center justify-center gap-4 py-10 text-gray-500"
      >
        <font-awesome-icon
          class="text-6xl text-blue-neon"
          icon="comments"
        />
        <div class="text-center">
          <h4>No comments yet</h4>
          <p>Be the first to comment on this activity!</p>
        </div>
      </div>
    </template>
    <template #message="{ message }">
      <activity-comment :comment="message" />
    </template>
    <template #input="{ sending, sendFunction }">
      <comment-form
        v-model:text="commentText"
        :sending="sending"
        @send="sendComment(sendFunction, $event)"
      />
    </template>
  </app-chat>
</template>

<script setup>
import { ref } from 'vue'
import AppChat from '@/components/widgets/AppChat.vue'
import ActivityComment from '@/components/ui/ActivityComment.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CommentForm from '@/components/input/CommentForm.vue'
defineOptions({
  name: 'TaskComments',
})




















































const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
  convention: {
    type: Object,
    required: true,
  },
  wrapperClass: {
    type: String,
    default: '',
  },
})

const commentText = ref('')

function sendComment(sendFunction, text) {
  sendFunction(text)
  commentText.value = ''
}
</script>

<style scoped></style>
