import { DateTime } from 'luxon'

export function toISOStringWithTimezone(date, tzOffset) {
  if (tzOffset === undefined) tzOffset = -date.getTimezoneOffset()
  // if tzOffset is string, timezone of type 'Asia/Tokyo' is assumed
  if (typeof tzOffset === 'string') {
    const tz = tzOffset
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: tz }))
    tzOffset = tzDate.getTimezoneOffset()
  }
  const diff = tzOffset >= 0 ? '+' : '-'
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0')
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    diff +
    pad(tzOffset / 60) +
    ':' +
    pad(tzOffset % 60)
  )
}

export function timeToMinutes(date: string, timezone = 'UTC') {
  const dateTime = DateTime.fromISO(date, { zone: timezone })
  return dateTime.hour * 60 + dateTime.minute
}

export function minutesToTime(date: string, minutes: int, timezone = 'UTC') {
  const dateTime = DateTime.fromISO(date, { zone: timezone })
  return dateTime.set({ hour: Math.floor(minutes / 60), minute: minutes % 60 })
}

export function dateRange(
  start_date: string,
  end_date: string,
  timezone = 'UTC',
  appendYear = true
) {
  // Parse the dates
  const start = DateTime.fromISO(start_date, { setZone: true })
  const end = DateTime.fromISO(end_date, { setZone: true })

  // Format the dates
  const formattedStartDate = start.toFormat('MMMM d')
  const formattedEndDate =
    end.toFormat('d') + (appendYear ? `, ${end.toFormat('yyyy')}` : '')

  // Determine if the dates are in the same month
  const sameMonth = start.month === end.month

  // Construct the final formatted string
  let formattedDate
  if (sameMonth) {
    formattedDate = `${formattedStartDate}-${formattedEndDate}`
  } else {
    const formattedEndMonth = start.toFormat('MMMM')
    formattedDate = `${formattedStartDate} - ${formattedEndMonth} ${formattedEndDate}`
  }

  return formattedDate
}

export function decodeTime(
  encodedTime: number,
  date: DateTime,
  span = 15
): DateTime {
  return date.plus({ minutes: encodedTime * span })
}

export function roundTime(
  date: DateTime,
  span = 15,
  highestDecimal = false
): DateTime {
  if (date) {
    if (highestDecimal)
      return date.set({ minute: Math.round(date.minute / span) * span })
    else return date.set({ minute: Math.floor(date.minute / span) * span })
  }
  return null
}
