import { Model } from '@/models/Model'
import VenueSpace from '@/models/VenueSpace'
import ConventionEvent from '@/models/ConventionEvent'
import Venue from '@/models/Venue'
import ILocation from '@/models/ILocation'

export default class VenueStation extends Model implements ILocation {
  id: number | null = null
  venue_space_id: number | null = null
  venue_space: VenueSpace | null = null
  venue: Venue | null = null
  name: string = ''
  description: string = ''

  latitude: number = 0
  longitude: number = 0

  convention_event: ConventionEvent | null = null

  get locationType(): string {
    return 'venue_station'
  }

  get parentId(): number | null {
    return this.venue_space_id
  }
}
