<template>
  <div
    :class="{ 'bg-blue-primary': viewingEvent }"
    class="relative flex flex-col gap-6 rounded-lg border-[1px] border-gray-700 px-4 py-4 sm:flex-row"
  >
    <div class="flex w-full grow flex-col gap-4 sm:gap-6 md:flex-row">
      <background-image-component
        :alt="event.name"
        :class="[
          {
            grayscale:
              DateTime.fromISO(event.ends_at, { setZone: true }) <
              DateTime.local(),
          },
          'shrink-0',
        ]"
        :src="event?.thumbnail?.path ?? '/images/placeholder_convention.png'"
        class="aspect-video w-full min-w-[114px] overflow-hidden rounded-lg sm:w-40 sm:max-w-full md:block md:aspect-square lg:block"
      />
      <div
        class="flex w-full max-w-[316px] shrink select-none flex-col justify-center gap-4"
      >
        <h2
          :class="viewingEvent ? 'text-white' : 'text-dark-grey'"
          class="font-heading text-base font-medium sm:text-2xl"
        >
          {{ event.name }}
        </h2>
        <div class="text-normal flex flex-col gap-3 font-sans text-base">
          <p
            :class="viewingEvent ? 'text-violet-100' : 'text-light-grey'"
            class="flex items-center gap-x-3 font-sans font-normal"
          >
            <svg
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="viewingEvent ? '#EAEAF5' : '#878D97'"
                d="M1.25 17.8118C1.25 18.0604 1.34877 18.2989 1.52459 18.4747C1.7004 18.6505 1.93886 18.7493 2.1875 18.7493H17.8125C18.0611 18.7493 18.2996 18.6505 18.4754 18.4747C18.6512 18.2989 18.75 18.0604 18.75 17.8118V7.49927H1.25V17.8118ZM18.75 3.42778C18.7498 3.30513 18.7254 3.18373 18.6782 3.07055C18.6309 2.95738 18.5617 2.85468 18.4745 2.76838C18.3874 2.68207 18.284 2.61386 18.1704 2.56767C18.0568 2.52148 17.9351 2.49824 17.8125 2.49927H15.6281V1.24927H13.7531V2.49927H6.24687V1.24927H4.37187V2.49927H2.1875C2.06485 2.49824 1.94322 2.52148 1.8296 2.56767C1.71597 2.61386 1.61261 2.68207 1.52547 2.76838C1.43833 2.85468 1.36912 2.95738 1.32185 3.07055C1.27457 3.18373 1.25015 3.30513 1.25 3.42778V5.62427H18.75V3.42778Z"
              />
            </svg>
            <template
              v-if="event.starts_at === 'tba' || event.ends_at === 'tba'"
            >
              Dates will be announced soon
            </template>
            <template v-else>
              {{
                dateRange(event.starts_at, event.ends_at, event.timezone, false)
              }}
            </template>
          </p>
          <p
            v-if="event.location"
            :class="viewingEvent ? 'text-violet-100' : 'text-light-grey'"
            class="flex items-center gap-x-3 font-sans font-normal"
          >
            <svg
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="viewingEvent ? '#EAEAF5' : '#878D97'"
                d="M10.4165 1.66675C6.96494 1.66675 4.1665 4.46519 4.1665 7.91675C4.1665 9.27586 4.61208 10.523 5.3527 11.5444C5.36598 11.5688 5.36807 11.5962 5.38317 11.6196L9.54984 17.8696C9.74307 18.1595 10.0686 18.3334 10.4165 18.3334C10.7644 18.3334 11.0899 18.1595 11.2832 17.8696L15.4498 11.6196C15.4652 11.5962 15.467 11.5688 15.4803 11.5444C16.2209 10.523 16.6665 9.27586 16.6665 7.91675C16.6665 4.46519 13.8681 1.66675 10.4165 1.66675ZM10.4165 10.0001C9.26598 10.0001 8.33317 9.06727 8.33317 7.91675C8.33317 6.76623 9.26598 5.83342 10.4165 5.83342C11.567 5.83342 12.4998 6.76623 12.4998 7.91675C12.4998 9.06727 11.567 10.0001 10.4165 10.0001Z"
              />
              <path
                :fill="viewingEvent ? '#EAEAF5' : '#9AA4B6'"
                d="M10.4165 1.66675C6.96494 1.66675 4.1665 4.46519 4.1665 7.91675C4.1665 9.27586 4.61208 10.523 5.3527 11.5444C5.36598 11.5688 5.36807 11.5962 5.38317 11.6196L9.54984 17.8696C9.74307 18.1595 10.0686 18.3334 10.4165 18.3334C10.7644 18.3334 11.0899 18.1595 11.2832 17.8696L15.4498 11.6196C15.4652 11.5962 15.467 11.5688 15.4803 11.5444C16.2209 10.523 16.6665 9.27586 16.6665 7.91675C16.6665 4.46519 13.8681 1.66675 10.4165 1.66675ZM10.4165 10.0001C9.26598 10.0001 8.33317 9.06727 8.33317 7.91675C8.33317 6.76623 9.26598 5.83342 10.4165 5.83342C11.567 5.83342 12.4998 6.76623 12.4998 7.91675C12.4998 9.06727 11.567 10.0001 10.4165 10.0001Z"
              />
            </svg>
            {{ event.location?.address?.city }},
            {{ event.location?.address?.state }}
          </p>
          <p
            v-if="viewingEvent"
            class="flex items-center gap-x-3 font-sans font-normal text-violet-100"
          >
            <svg
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 10C12.0625 10 13.75 8.3125 13.75 6.25C13.75 4.1875 12.0625 2.5 10 2.5C7.9375 2.5 6.25 4.1875 6.25 6.25C6.25 8.3125 7.9375 10 10 10ZM10 10.9375C6.15625 10.9375 2.5 13.375 2.5 15.625C2.5 17.5 10 17.5 10 17.5C10 17.5 17.5 17.5 17.5 15.625C17.5 13.375 13.8438 10.9375 10 10.9375Z"
                fill="#EAEAF5"
              />
              <path
                d="M10 10C12.0625 10 13.75 8.3125 13.75 6.25C13.75 4.1875 12.0625 2.5 10 2.5C7.9375 2.5 6.25 4.1875 6.25 6.25C6.25 8.3125 7.9375 10 10 10ZM10 10.9375C6.15625 10.9375 2.5 13.375 2.5 15.625C2.5 17.5 10 17.5 10 17.5C10 17.5 17.5 17.5 17.5 15.625C17.5 13.375 13.8438 10.9375 10 10.9375Z"
                fill="#EAEDF5"
              />
            </svg>
            $20 - 40
          </p>
        </div>
        <div
          v-if="!viewingEvent && featureFlag"
          class="hidden w-fit flex-row flex-wrap gap-x-4 font-sans text-sm font-normal sm:flex"
        >
          <button
            :class="
              interestStatus
                ? 'bg-indigo-200 text-blue-primary'
                : 'bg-[#EAEDF5] text-light-grey-100'
            "
            class="flex items-center gap-x-2 rounded-full py-2 pl-5 pr-6"
          >
            <svg
              fill="none"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :stroke="interestStatus ? '#1613BD' : '#47474F'"
                d="M3.3335 8.66675L6.00016 11.3334L12.6668 4.66675"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2603_2184"
                  gradientUnits="userSpaceOnUse"
                  x1="8.00016"
                  x2="8.00016"
                  y1="4.66675"
                  y2="11.3334"
                >
                  <stop
                    offset="0.21875"
                    stop-color="#1F1BDB"
                  />
                  <stop
                    offset="1"
                    stop-color="#100DA8"
                  />
                </linearGradient>
              </defs>
            </svg>
            I’m going
          </button>
          <button
            :class="!interestStatus ? 'text-blue-neon' : 'text-light-grey-100'"
            class="flex items-center gap-x-2 rounded-full py-2 pl-5 pr-6"
          >
            <svg
              fill="none"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :stroke="!interestStatus ? '#797FFF' : '#5F6775'"
                d="M11.5 4.50098L4.5 11.501M11.5 11.501L4.5 4.50098L11.5 11.501Z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                :stroke="!interestStatus ? '#797FFF' : '#5F6775'"
                d="M11.5 4.50098L4.5 11.501M11.5 11.501L4.5 4.50098L11.5 11.501Z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>
            Not Interested
          </button>
        </div>
      </div>
    </div>

    <div class="hidden h-auto w-[1px] bg-light-grey-100 md:block" />
    <div
      v-if="!viewingEvent && featureFlag"
      class="flex w-fit flex-row flex-wrap gap-x-4 font-sans text-sm font-normal sm:hidden"
    >
      <button
        :class="
          interestStatus
            ? 'bg-indigo-200 text-blue-primary'
            : 'bg-[#EAEDF5] text-light-grey-100'
        "
        class="flex items-center gap-x-2 rounded-full py-2 pl-5 pr-6"
      >
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :stroke="interestStatus ? '#1613BD' : '#47474F'"
            d="M3.3335 8.66675L6.00016 11.3334L12.6668 4.66675"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2603_2184"
              gradientUnits="userSpaceOnUse"
              x1="8.00016"
              x2="8.00016"
              y1="4.66675"
              y2="11.3334"
            >
              <stop
                offset="0.21875"
                stop-color="#1F1BDB"
              />
              <stop
                offset="1"
                stop-color="#100DA8"
              />
            </linearGradient>
          </defs>
        </svg>
        I’m going
      </button>
      <button
        :class="!interestStatus ? 'text-blue-neon' : 'text-light-grey-100'"
        class="flex items-center gap-x-2 rounded-full py-2 pl-5 pr-6"
      >
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :stroke="!interestStatus ? '#797FFF' : '#5F6775'"
            d="M11.5 4.50098L4.5 11.501M11.5 11.501L4.5 4.50098L11.5 11.501Z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            :stroke="!interestStatus ? '#797FFF' : '#5F6775'"
            d="M11.5 4.50098L4.5 11.501M11.5 11.501L4.5 4.50098L11.5 11.501Z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </svg>
        Not Interested
      </button>
    </div>
    <div
      v-if="!viewingEvent"
      class="flex w-full items-center sm:max-w-[160px]"
    >
      <button
        class="w-full cursor-pointer select-none rounded-lg bg-blue-primary bg-opacity-90 px-4 py-3 text-center font-heading text-sm text-white hover:bg-opacity-100 hover:shadow-lg"
        @click.prevent.stop="emit('view-event', event)"
      >
        View Event
      </button>
    </div>
    <div
      v-else
      class="m-auto w-full select-none text-center font-heading text-lg text-white sm:max-w-[160px]"
    >
      Currently Viewing
    </div>
    <div
      v-if="!liveHidden && isLive"
      class="absolute -right-2 -top-2 rounded-full bg-red-800 px-3 text-sm font-bold text-white"
    >
      LIVE
    </div>
  </div>
</template>

<script lang="ts" setup>
import { dateRange } from '@/utils/datetime'
import { computed } from 'vue'
import { DateTime } from 'luxon'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import ConventionEvent from '@/models/ConventionEvent'

const emit = defineEmits(['view-event'])

const props = defineProps({
  viewingEvent: {
    type: Boolean,
    default: false,
  },
  event: {
    type: ConventionEvent,
    required: true,
  },
  liveHidden: {
    type: Boolean,
    default: false,
  },
})

const interestStatus = true
const featureFlag = false

const isLive = computed(() => {
  return props.event.live
  // const start = DateTime.fromISO(props.event.schedule[0].starts_at, {zone: props.event.timezone});
  // const end = DateTime.fromISO(props.event.schedule.at(-1).ends_at, {zone: props.event.timezone});
  // const now = DateTime.local().setZone(props.event.timezone);
  //
  // return now >= start && now <= end;
})
</script>

<style scoped></style>
