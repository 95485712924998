<template>
  <header
    class="z-50 w-full px-8 py-[23px] lg:px-[120px]"
    style="background: rgba(6, 7, 28, 0.64)"
  >
    <div
      class="mx-auto flex w-full max-w-screen-2xl items-center justify-between"
    >
      <div class="flex w-full gap-4">
        <fankind-logo class="h-[22px] text-text-white" />
        <div
          v-if="conventionsLoading"
          class="h-6 w-56 animate-pulse rounded bg-gray-100 bg-opacity-25"
        />
        <div
          v-else
          class="group flex cursor-pointer items-center gap-2"
        >
          <Link
            :href="isAuth ? `/events/${selectedConvention?.slug}` : 'login'"
            class="flex hidden items-center gap-1 rounded-lg px-2 py-1 duration-100 hover:bg-gray-100 hover:bg-opacity-25 md:flex"
          >
            <div
              v-if="
                selectedConvention &&
                selectedConvention.startsAt < DateTime.now() &&
                selectedConvention.endsAt > DateTime.now()
              "
              class="animate-pulse-bg h-4 w-4 rounded-full bg-red-800 content-[''] @lg:h-auto @lg:w-auto @lg:px-3"
            >
              <p class="hidden text-sm font-bold text-white @lg:block">LIVE</p>
            </div>
            <p
              :class="[
                $page.url.startsWith(`/events/${selectedConvention?.slug}`)
                  ? 'opacity-100'
                  : 'opacity-75 hover:opacity-100',
              ]"
              class="select-none text-base leading-none text-white"
            >
              {{ selectedConvention?.name }}
            </p>
          </Link>
          <div
            class="h-4 w-[1px] rounded-full bg-white opacity-75 duration-100 group-hover:opacity-100"
          />
          <button
            class="text-white opacity-75 duration-100 hover:opacity-100"
            @click="conventionSelectorOpen = !conventionSelectorOpen"
          >
            <svg
              :class="{ 'rotate-180': conventionSelectorOpen }"
              class="transform duration-200"
              fill="none"
              height="24"
              viewBox="0 0 13 12"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.133 3.83818C10.3681 3.99609 10.3958 4.33142 10.1898 4.52581L6.37773 8.12298C6.21727 8.2744 5.96655 8.2744 5.80608 8.12298L1.99398 4.52581C1.78798 4.33142 1.81571 3.99609 2.05083 3.83818C2.22128 3.72371 2.44814 3.74184 2.59824 3.88193L6.09191 7.14269L9.58558 3.88193C9.73568 3.74184 9.96254 3.7237 10.133 3.83818Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>

      <Link
        v-if="!props.isAuth"
        class="select-none text-lg font-bold text-text-white hover:text-accent-orange"
        href="/login"
      >
        Login
      </Link>
      <div
        v-else
        class="relative"
      >
        <user-dropdown-menu dropdown-class="top-0" />
      </div>
    </div>
    <modal
      v-model:open="conventionSelectorOpen"
      position="center"
      size="lg"
    >
      <event-selector-dropdown @close="conventionSelectorOpen = false" />
    </modal>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import { DateTime } from 'luxon'
import { Link } from '@inertiajs/vue3'
import FankindLogo from '../../icons/FankindLogo.vue'
import useConventionList from '@/utils/composables/conventionList'

import Modal from '@/components/modal/Modal.vue'
import EventSelectorDropdown from '@/components/widgets/EventSelectorDropdown.vue'
import UserDropdownMenu from '@/components/buttons/UserDropdownMenu.vue'

const props = defineProps({
  isAuth: {
    type: Boolean,
    required: true,
  },
})

const { selected: selectedConvention, loading: conventionsLoading } =
  useConventionList()

const conventionSelectorOpen = ref(false)
</script>

<style lang="scss" scoped></style>
