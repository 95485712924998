<template>
  <svg
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50002 12.5009L12.5 7.50091M9.16669 5.00091L9.55252 4.55424C10.334 3.77285 11.3939 3.33391 12.4991 3.33398C13.6042 3.33406 14.664 3.77315 15.4454 4.55466C16.2268 5.33616 16.6658 6.39606 16.6657 7.5012C16.6656 8.60634 16.2265 9.66618 15.445 10.4476L15 10.8342M10.8334 15.0009L10.5025 15.4459C9.71191 16.2277 8.64486 16.6662 7.53295 16.6662C6.42105 16.6662 5.354 16.2277 4.56337 15.4459C4.17367 15.0606 3.86428 14.6018 3.65313 14.096C3.44198 13.5903 3.33325 13.0477 3.33325 12.4997C3.33325 11.9516 3.44198 11.409 3.65313 10.9033C3.86428 10.3976 4.17367 9.93874 4.56337 9.55341L5.00004 9.16757"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>
