<template>
  <div class="w-[85vw] md:w-[593px]">
    <div
      :class="edit ? 'h-[252px]' : 'h-[486px]'"
      class="mx-auto flex w-full flex-col items-center gap-8 overflow-scroll px-4 py-10 sm:px-6 md:max-w-[454px] lg:px-8"
    >
      <template v-if="!edit">
        <h4 class="text-2xl font-bold text-black">Create New Album</h4>
        <div class="flex w-full flex-col gap-2">
          <input-field
            v-model="createNew.name"
            :error="createNew.errors.name"
            max="245"
            label="Title"
            variant="default"
            class="w-full border-gray-500 text-gray-500"
          />
          <span class="text-sm font-normal text-gray-500">
            Max 245 characters
          </span>
        </div>

        <div class="flex w-full flex-col items-start gap-6">
          <label class="text-base font-bold text-paragraph">
            Upload Pictures
          </label>
          <form-drop-file-input
            v-model="createNew.pictures"
            class="h-40 w-full"
            image-aspect-ratio="1"
            image-resize
            multiple
            image-type
          />
          {{ createNew.image }}
        </div>
      </template>
      <template v-else>
        <h4 class="text-2xl font-bold text-black">Create New Album</h4>
        <div class="flex w-full flex-col gap-2">
          <input-field
            v-model="editedAlbum.name"
            :error="editedAlbum.errors.name"
            max="245"
            label="Title"
            variant="default"
            class="w-full border-gray-500 text-gray-500"
          />
          <span class="text-sm font-normal text-gray-500">
            Max 245 characters
          </span>
        </div>
      </template>
    </div>

    <div class="w-full border-t border-gray-500">
      <div
        class="mx-auto my-4 flex w-full justify-between px-4 sm:px-6 md:max-w-[406px] md:px-0"
      >
        <button
          class="py-4 text-base font-bold text-blue-primary"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          class="rounded-lg bg-blue-primary px-8 py-4 text-base font-bold text-text-white md:px-12"
        >
          <template v-if="!edit">Create album</template>
          <template v-if="edit">Save Changes</template>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3'
import { useModal } from '@/utils/modal'

import InputField from '@/components/input/InputField.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'

const props = defineProps({
  edit: {
    type: Boolean,
    default: false,
  },
})

const modalHandler = useModal()

const closeModal = () => {
  modalHandler.closeAll()
}

const createNew = useForm({
  name: '',
  pictures: null,
})

const editedAlbum = useForm({
  name: '',
})
</script>

<style lang="scss" scoped></style>
