<template>
  <modal-buttons-layout
    :title="null"
    buttons-bold
    buttons-without-border
    primary-button="Save Changes"
    secondary-button="Cancel"
  >
    <div class="relative flex h-full w-full flex-col gap-16">
      <div class="bg-slate-200 p-2">
        <form-drop-file-input
          v-model="form.banner"
          :error="data.errors.banner"
          class="mx-auto h-[176px] w-full cursor-pointer border-text-white bg-slate-200 text-slate-400"
          cta-always-visible
          icon-class="text-slate-400"
          image-type
        />
      </div>
      <div
        class="absolute left-[20px] top-[105px] h-[127px] w-[127px] shrink-0 rounded-lg border border-text-white"
      >
        <form-drop-file-input

          v-model="form.avatar"
          :error="data.errors.avatar"
          class="h-[127px] w-full cursor-pointer border-text-white bg-slate-200 text-slate-400"
          cta-always-visible
          hide-hint
          icon-class="text-slate-400"
          image-type
        />
      </div>

      <div class="flex flex-col px-4 md:px-8 lg:px-16">
        <h3 class="text-bold mx-auto mb-8 text-center text-2xl text-black">
          Edit Bio
        </h3>

        <input-layout
          class="mb-14 flex w-full max-w-[270px] flex-col gap-2"
          hint="Max 245 characters"
          label="Name"
          variant="default"
        >
          <form-field-input
            v-model="form.name"
            :error="form.errors.name"
            class="w-full border-gray-500 text-gray-500"
            max="245"
          />
        </input-layout>

        <div
          class="mb-14 flex w-full flex-col items-center justify-between gap-14 md:flex-row"
        >
          <input-layout
            class="mb-14 flex grow flex-col gap-2"
            hint="Max 245 characters"
            label="Description"
            variant="default"
          >
            <form-field-input-textarea
              v-model="form.description"
              :error="form.errors.description"
              class="w-full border-gray-500 text-gray-500"
              label="Description"
              max="245"
              variant="default"
            />
          </input-layout>
          <form-drop-file-input
            v-model="form.about_image"
            :error="form.errors.about_image"
            class="mx-auto h-[200px] w-[238px] cursor-pointer border-text-white bg-slate-200"
            cta-always-visible
            hide-hint
            icon-class="text-slate-400"
            image-type
          />
        </div>

        <div class="flex flex-col gap-8">
          <h6 class="-mb-2 text-base font-bold text-paragraph">Links</h6>

          <div
            v-for="(social, socialID) in socials"
            :key="socialID"
            class="flex w-full items-center justify-between gap-4 border-b border-slate-300 pb-4"
          >
            <div class="flex items-center gap-4">
              <component
                :is="social.icon"
                class="h-8 w-8 shrink-0 text-slate-500"
              />
              <span class="text-base font-bold text-slate-500">
                {{ social.title }}
              </span>
            </div>

            <div class="flex w-fit shrink-0 gap-4">
              <v-button
                v-if="!social.addLink"
                class="right-0 flex w-fit shrink-0 items-center gap-2 !rounded px-8 py-1"
                no-padding
                size="sm"
                variant="primary"
                without-border
                @click="social.addLink = true"
              >
                Link
              </v-button>
              <template v-else>
                <input
                  v-model="socials[socialID].link"
                  class="h-[10px] min-h-[30px] w-full rounded border border-double border-blue-primary p-2 text-gray-500"
                  placeholder="Paste Link"
                />
                <button class="text-sm font-bold text-blue-primary">
                  Submit
                </button>
                <button @click="social.addLink = false">
                  <font-awesome-icon
                    class="text-black"
                    icon="xmark"
                  />
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import { PropType, reactive } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { useModal } from '@/utils/modal'

import VButton from '@/components/buttons/VButton.vue'
import TwitterCircleIcon from '@/icons/TwitterCircleIcon.vue'
import DiscordIcon from '@/icons/DiscordIcon.vue'
import YouTubeIcon from '@/icons/YouTubeIcon.vue'
import FacebookCircleIcon from '@/icons/FacebookCircleIcon.vue'
import PinterestIcon from '@/icons/PinterestIcon.vue'
import InstagramCircleIcon from '@/icons/InstagramCircleIcon.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'
import UserGroup from '@/models/UserGroup'
import { ModelForm } from '@/utils/form'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'

const props = defineProps({
  form: {
    type: Object as PropType<ModelForm<UserGroup>>,
    required: true,
  },
})

const data = useForm({
  avatar: null,
  banner: null,
  about_image: null,

  name: '',
  description: '',
})

const socials = [
  {
    icon: TwitterCircleIcon,
    title: 'Twitter',
    link: '',
    addLink: false,
  },
  {
    icon: InstagramCircleIcon,
    title: 'Instagram',
    link: '',
    addLink: false,
  },
  {
    icon: FacebookCircleIcon,
    title: 'Facebook',
    link: '',
    addLink: false,
  },
  {
    icon: YouTubeIcon,
    title: 'YouTube',
    link: '',
    addLink: false,
  },
  {
    icon: PinterestIcon,
    title: 'Pinterest',
    link: '',
    addLink: false,
  },
  {
    icon: DiscordIcon,
    title: 'Discord',
    link: '',
    addLink: false,
  },
]

const modalHandler = useModal()
</script>

<style scoped></style>
