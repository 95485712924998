<template>
  <modal-buttons-layout ref="container">
    <template #action-buttons>
      <slot name="action-buttons" />
    </template>
    <div class="flex grow flex-col pb-5">
      <div class="sticky top-0 h-0 bg-red">
        <div
          v-if="container?.scrollPixel < 39"
          class="absolute flex inset-x-0 m-2 items-center rounded bg-gray-100 ring-slate-300 transition-colors focus-within:ring-1"
          :style="{
            height: 40 - Math.max(0, container?.scrollPixel) + 'px',
          }"
          :class="[
            container?.scrollPixel > 5 ? 'text-transparent' : 'text-slate-500',
          ]"
        >
          <font-awesome-icon
            icon="search"
            class="ml-2"
          />
          <input
            v-model="search"
            :class="[container?.scrollPixel > 5 ? 'opacity-0' : 'opacity-100']"
            class="h-full w-full bg-transparent px-2 text-black placeholder-slate-500 outline-none transition-opacity duration-100"
            :placeholder="placeholder"
            @input="emit('update:search', $event.target.value)"
          />
          <transition name="fade">
            <button
              v-if="search !== ''"
              class="p-2"
              @click="
                search = '';
                emit('update:search', '')
              "
            >
              <font-awesome-icon icon="times" />
            </button>
          </transition>
        </div>
      </div>
      <!-- imitation of search field in layout -->
      <div class="h-10 m-2" />

      <div
        v-if="items.length > 0 && filters.length > 0"
        class="sticky top-0 z-[5] flex flex-col items-start bg-white bg-opacity-75 px-2 pb-2 pt-2 backdrop-blur"
      >
        <template v-for="(filter, index) in filters" :key="filter.name">
          <collapse
            :when="
              index === 0 || selectedFilters[filters[index - 1].name] != null
            "
            class="transition-all duration-200 ease-linear"
          >
            <div class="mb-2 flex overflow-hidden rounded bg-gray-100">
              <button
                v-for="option in getAllFilterOptions(filter)"
                :key="option.value"
                class="px-2.5 py-2 text-sm font-medium duration-100"
                :class="[
                  selectedFilters[filter.name] === option.value
                    ? 'bg-black text-white'
                    : 'text-gray-500 hover:text-black',
                ]"
                @click="selectFilter(index, filter.name, option.value)"
              >
                {{ option.label }}
              </button>
            </div>
          </collapse>
        </template>
      </div>
      <slot name="before-list" />
      
      <div
        v-if="filteredItems.length === 0 && loading"
        class="flex grow flex-col items-center justify-center"
      >
        <app-spinner />
      </div>
      <div
        v-else-if="items.length === 0 && !$slots.list"
        class="flex grow flex-col items-center justify-center gap-2"
      >
        <slot name="empty">
          <font-awesome-icon
            icon="exclamation-triangle"
            class="text-4xl text-gray-500"
          />
          <h3 class="text-xl text-gray-500">
            {{ emptyStateText }}
          </h3>
        </slot>
      </div>
      <template v-else>
        <slot name="list">
          <transition-group
            name="list"
            tag="ul"
            class="relative mx-2 mt-2 grow"
          >
            <li
              v-for="(item, index) in groups.length
                ? groupedItems
                : filteredItems"
              :key="item._type_group ? item._uid : item.id ?? 'item-' + index"
              :style="{
                paddingLeft: item._type_group ? '0' : groups.length * 4 + 'px',
              }"
              class="z-[3] mb-2 w-full"
            >
              <div v-if="item._type_group">
                <div class="flex gap-2 text-sm text-gray-700">
                  <slot
                    :name="'group-icon-' + item.group"
                    :group="item"
                  />
                  <div
                    class="flex cursor-pointer gap-2"
                    @click="toggleCollapse(item._uid)"
                  >
                    <slot
                      :name="'group-label-' + item.group"
                      :group="item"
                    >
                      {{ item.label }}
                    </slot>
                    <button class="">
                      <font-awesome-icon
                        class="transition-transform duration-100"
                        :class="{
                          '-rotate-180': collapsedGroups.includes(item._uid),
                        }"
                        icon="chevron-down"
                      />
                    </button>
                  </div>
                  <div class="flex grow justify-end">
                    <slot
                      :name="'group-actions-' + item.group"
                      :group="item"
                    />
                  </div>
                </div>
              </div>
              <slot
                v-else
                name="list-item"
                :item="item"
              >
                {{ item }}
              </slot>
            </li>
            <li
              v-if="search !== '' && filteredItems.length === 0"
              key="no-results-found"
              class="py-5 text-center"
            >
              <small class="text-gray-500">No results found</small>
            </li>
            <li
              v-if="otherSearchItems.length"
              key="other-categories-found"
            >
              <small class="text-gray-500">Found in other categories</small>
            </li>
            <li
              v-for="(item, index) in otherSearchItems"
              :key="'other-search-item-' + (item.id ?? index)"
              class="z-[3] mb-2 w-full"
            >
              <slot
                name="list-item"
                :item="item"
              >
                {{ item }}
              </slot>
            </li>
          </transition-group>
        </slot>
        <slot />
      </template>
    </div>
  </modal-buttons-layout>
</template>

<script setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import { computed, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Collapse } from 'vue-collapsed'
import AppSpinner from '@/components/ui/AppSpinner.vue'

const emit = defineEmits(['update:search'])

const props = defineProps({
  disableSearch: {
    type: Boolean,
    default: false,
  },
  disableFiltering: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Search',
  },
  selectedFilters: {
    type: Object,
    default: () => ({}),
  },
  filters: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },
  emptyStateText: {
    type: String,
    default: 'No items to show',
  },
  groups: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const container = ref(null)
const search = ref('')

const filteredItems = computed(() => {
  return props.items.filter((item) => {
    if (search.value !== '') {
      if (!item.name?.toLowerCase().includes(search.value?.toLowerCase())) {
        return false
      }
    }
    for (const filter of props.filters) {
      if (
        props.selectedFilters[filter.name] != null &&
        filter.filter(item, props.selectedFilters[filter.name]) === false
      ) {
        return false
      }
    }
    return true
  })
})

const groupedItems = computed(() => {
  if (props.groups.length === 0) {
    return filteredItems.value
  }
  return groupItems(filteredItems.value, props.groups)
})

const collapsedGroups = ref([])

function toggleCollapse(key) {
  if (collapsedGroups.value.includes(key)) {
    collapsedGroups.value = collapsedGroups.value.filter((k) => k !== key)
  } else {
    collapsedGroups.value.push(key)
  }
}

function groupItems(items, groups) {
  if (groups.length === 0 || items.length === 0) {
    return items
  }
  const group = groups[0]
  const grouped = groupItemsBy(items, group.filter)
  const groupedItems = []
  for (const [key, value] of Object.entries(grouped)) {
    const g = {
      id: key,
      _uid: group.name + '-' + key,
      _type_group: true,
      group: group.name,
      label: key,
      items: value,
    }
    groupedItems.push(g)

    if (!collapsedGroups.value.includes(g._uid))
      groupedItems.push(...groupItems(value, groups.slice(1)))
  }
  return groupedItems
}

function groupItemsBy(items, groupFunction) {
  const groups = {}
  for (const item of items) {
    const group = groupFunction(item)
    if (!groups[group]) {
      groups[group] = []
    }
    groups[group].push(item)
  }
  return groups
}

const otherSearchItems = computed(() => {
  if (search.value === '') {
    return []
  }
  return props.items.filter((item) => {
    if (search.value !== '') {
      if (!item.name?.toLowerCase().includes(search.value?.toLowerCase())) {
        return false
      }
    }
    for (const filter of props.filters) {
      if (
        props.selectedFilters[filter.name] !== null &&
        filter.filter(item, props.selectedFilters[filter.name]) === false
      ) {
        return true
      }
    }
    return false
  })
})

function selectFilter(index, name, value) {
  props.selectedFilters[name] = value
  if (index < props.filters.length - 1) {
    for (let i = index + 1; i < props.filters.length; i++) {
      props.selectedFilters[props.filters[i].name] = null
    }
  }
}

function getAllFilterOptions(filter) {
  const list = filter.options.slice()
  if (filter.null_option) {
    list.unshift({
      label: filter.null_option,
      value: filter.null_option_value,
    })
  }
  if (filter.unselected) {
    list.unshift({
      label: filter.unselected,
      value: filter.unselected_value,
    })
  }
  return list
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.25s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  z-index: -2;
  position: absolute;
}
</style>