<template>
  <div
    ref="element"
    class="text-form select-text whitespace-pre-wrap break-words"
    role="textbox"
    spellcheck="true"
    :data-placeholder="placeholder"
    contenteditable="true"
    @keydown="handleKeydown"
    @click="handleClick"
    @input="handleInput"
  >
    <teleport to="body">
      <div
        v-if="activeDropdown"
        class="fixed z-[10000]"
        :style="{ top: dropdownTop + 'px', left: dropdownLeft + 'px' }"
      >
        <slot
          :name="activeDropdown"
          :text="activeElementText"
          :replace="replaceCurrentElementText"
        />
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: false,
  },
  rules: {
    type: Array,
    required: false,
    default: () => [
      {
        regex: /@([a-zA-Z0-9_.-]+)/g,
        class: 'text-blue-neon',
        dropdown: 'users',
      },
    ],
  },
})

const element = ref(null)

watch(
  () => props.modelValue,
  (text) => {
    updateText(text)
  }
)

onMounted(() => {
  if (props.autofocus) {
    nextTick(() => {
      element.value.focus()
      // set caret position to end of text
      const range = document.createRange()
      const selection = window.getSelection()
      range.selectNodeContents(element.value)
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    })
  }
})

function getStrings(text) {
  if (text === '') return []
  // Parse text into array of strings based on rules
  const strings = []
  let last = 0
  for (const rule of props.rules) {
    const matches = text.matchAll(rule.regex)
    for (const match of matches) {
      if (text.slice(last, match.index) !== '')
        strings.push({
          text: text.slice(last, match.index),
          class: '',
        })
      strings.push({
        text: match[0],
        class: rule.class,
        replace: rule.replace,
        dropdown: rule.dropdown,
      })
      last = match.index + match[0].length
    }
  }
  if (last !== text.length)
    strings.push({
      text: text.slice(last),
      class: '',
    })
  return strings
}

function updateText(text) {
  let start = getCaretPosition()
  nextTick(() => {
    renderStrings(getStrings(text))

    nextTick(() => {
      const range = document.createRange()
      const selection = window.getSelection()

      if (element.value.children.length === 0) {
        // if there are no child nodes, set caret position to 0
        range.setStart(element.value, 0)
      }

      // find anchorNode by counting each character until start is reached
      for (let index = 0; index < element.value.children.length; index++) {
        let node = element.value.children[index]
        // if node is not a text node, it's a span or something else
        // we need to work only with text nodes
        if (node.nodeType !== Node.TEXT_NODE) {
          node = node.firstChild
        }

        // if start is less than the length of the current node, we found it
        if (start <= node.textContent.length) {
          // set caret position to this node and offset
          range.setStart(node, start)
          break
        } else {
          // otherwise, subtract the length of the current node from start
          start -= node.textContent.length
        }
      }

      // collapse range to the start of the range
      // remove all ranges from selection
      // add range to selection
      range.collapse(true)
      selection.removeAllRanges()
      selection.addRange(range)
    })
  })
}

function renderStrings(strings) {
  element.value.innerHTML = ''
  // Render strings into HTML elements but prevent XSS
  for (const string of strings) {
    let el = document.createElement('span')
    el.textContent = string.text
    if (string.class) el.classList.add(string.class)
    if (string.replace) el.innerHTML = string.replace
    if (string.dropdown) el.dataset.dropdown = string.dropdown
    element.value.appendChild(el)
  }
}

function getCaretPosition() {
  const selection = window.getSelection()
  let start = 0
  // if anchorNode is the first child, anchorOffset is the caret position
  if (selection.anchorNode === element.value.childNodes[0]) {
    start = selection.anchorOffset
  } else {
    // otherwise, count each character until anchorNode is found
    if (!element.value.contains(selection.anchorNode))
      throw new Error('anchorNode not in element')

    for (let i = 0; i < element.value.childNodes.length; i++) {
      let node = element.value.childNodes[i]
      // if node is not a text node, it's a span or something else
      // we need to work only with text nodes
      if (node.nodeType !== Node.TEXT_NODE) {
        node = node.firstChild
      }
      if (node === selection.anchorNode) {
        start += selection.anchorOffset
        break
      } else {
        start += element.value.childNodes[i].textContent.length
      }
    }
  }

  return start
}

function getIndexAt(caretPosition) {
  let index = 0
  for (let i = 0; i < element.value.childNodes.length; i++) {
    let node = element.value.childNodes[i]
    // if node is not a text node, it's a span or something else
    // we need to work only with text nodes
    if (node.nodeType !== Node.TEXT_NODE) {
      node = node.firstChild
    }
    if (index + node.textContent.length >= caretPosition) {
      return i
    } else {
      index += element.value.childNodes[i].textContent.length
    }
  }
  return index
}

function handleInput(event) {
  // update modelValue
  emit('update:modelValue', element.value.textContent)
}

const activeDropdown = ref(null)
const activeElementText = ref('')
const activeNode = ref(null)
const activeNodeIndex = ref(0)
const dropdownTop = ref(0)
const dropdownLeft = ref(0)

function replaceCurrentElementText(text) {
  const isLast = activeNodeIndex.value === element.value.childNodes.length - 1
  // add space if last
  activeNode.value.textContent = text + (isLast ? ' ' : '')
  activeDropdown.value = null
  activeElementText.value = ''
  dropdownTop.value = 0
  dropdownLeft.value = 0

  // update modelValue
  renderStrings(getStrings(element.value.textContent))

  // set caret position to the end of this node
  element.value.focus()
  nextTick(() => {
    const range = document.createRange()
    const selection = window.getSelection()
    let targetNode = element.value.childNodes[activeNodeIndex.value]
    range.setStart(
      element.value.childNodes[activeNodeIndex.value + 1].firstChild,
      1
    )

    // collapse range to the start of the range
    // remove all ranges from selection
    // add range to selection
    range.collapse(true)
    selection.removeAllRanges()
    selection.addRange(range)

    emit('update:modelValue', element.value.textContent)
  })
}

function updateActiveElement() {
  requestAnimationFrame(() => {
    // handle empty text
    if (element.value.children.length === 0) {
      activeDropdown.value = null
      return
    }

    const index = getIndexAt(getCaretPosition())
    const node = element.value.childNodes[index]
    activeNodeIndex.value = index
    activeNode.value = node
    if (node.dataset.dropdown) {
      activeDropdown.value = node.dataset.dropdown
      const rect = node.getBoundingClientRect()
      dropdownTop.value = rect.top + rect.height
      dropdownLeft.value = rect.left
      activeElementText.value = node.textContent
    } else {
      activeDropdown.value = null
    }
  })
}

function handleClick(event) {
  updateActiveElement()
}

function handleKeydown(event) {
  updateActiveElement()
}
</script>

<style scoped>
.text-form:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: #a0aec0;
}
</style>
