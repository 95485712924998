<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <merge-selector-modal
        v-model:open="merge"
        :form-field-select-item-component="FormFieldSelectVenue"
        :source-id="form.id"
        resource-modal-type="venue"
        source-type="venue"
      />
      <form-field-header-input
        allow-merge
        class="w-full"
        @merge="merge = true"
        @delete="emit('delete')"
      >
        <template #input>
          <input-field
            v-model="form.name"
            class="grow"
            placeholder="Add Venue Name"
            type="text"
            variant="gray"
            @update:model-value="emit('search', $event)"
          />
        </template>
      </form-field-header-input>
    </template>
    <div class="flex flex-col gap-4 pt-6">
      <!-- Addresses -->
      <form-input-group-layout label="Address">
        <address-form
          v-if="editAddress"
          :form="form.address"
          :errors="form.errors.address"
          coordinates
          disable-close
          disable-delete
          @save="editAddress = false"
        />
        <input-layout
          v-else
          :label="
            form.address?.name?.length
              ? form.address?.name
              : form.address?.address
          "
          layout="grid"
          variant="gray"
          @click="editAddress = true"
        >
          <div
            class="grid grid-cols-2 gap-1 rounded p-1 duration-100 hover:bg-gray-100"
          >
            <address-presenter :address="form.address" />
            <map-preview
              :latitude="form.address?.latitude"
              :longitude="form.address?.longitude"
              class="grow"
              disable-controls
            />
          </div>
        </input-layout>
      </form-input-group-layout>

      <hr/>

      <form-input-group-layout label="Contact Person">
        <input-layout
          label="Contacts"
          layout="grid"
          variant="gray"
        >
          <form-field-select-user v-model:selected="form.contacts" />
        </input-layout>
      </form-input-group-layout>

      <hr/>

      <!--      <form-input-group-layout label="Events">-->
      <!--        <form-field-select-convention v-model:selected="form.events" labels="none" disable-button/>-->
      <!--      </form-input-group-layout>-->
      <!-- TODO: add and refactor other fields -->
    </div>
    <!--    &lt;!&ndash; Events &ndash;&gt;-->
    <!--    <VenueEventsForm :form="props.form.events" />-->

    <!--    &lt;!&ndash; Levels &ndash;&gt;-->
    <!--    <section class="flex flex-col gap-4 px-8 py-6">-->
    <!--      <div class="relative w-fit">-->
    <!--        <h2-->
    <!--            class="flex items-center gap-2 font-heading text-base font-bold text-dark-grey">-->
    <!--          Levels for-->
    <!--          <span class="text-blue-primary">{{ levelsForEvent }}</span>-->
    <!--          <svg-->
    <!--              @click="showEventsDropdown = !showEventsDropdown"-->
    <!--              :class="showEventsDropdown ? 'rotate-180' : ''"-->
    <!--              xmlns="http://www.w3.org/2000/svg"-->
    <!--              width="20"-->
    <!--              height="20"-->
    <!--              viewBox="0 0 20 20"-->
    <!--              fill="none">-->
    <!--            <path-->
    <!--                d="M15.8332 7.5L9.99984 13.3333L4.1665 7.5"-->
    <!--                stroke="#1613BD"-->
    <!--                stroke-width="1.5"-->
    <!--                stroke-linecap="round"-->
    <!--                stroke-linejoin="round" />-->
    <!--          </svg>-->
    <!--        </h2>-->
    <!--        <div-->
    <!--            v-if="showEventsDropdown"-->
    <!--            class="absolute left-12 top-9 w-[145px] rounded border border-solid border-[#EAEAF5] bg-slate-100 font-heading text-sm text-paragraph shadow-md">-->
    <!--          <option-->
    <!--              v-for="event in eventsList"-->
    <!--              @click="levelsForEvent = event"-->
    <!--              class="cursor-pointer border-b border-solid border-[#EAEAF5] px-3 py-2 hover:text-blue-neon">-->
    <!--            {{ event }}-->
    <!--          </option>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <VenueLevelsForm-->
    <!--          :levels="props.form.levels"-->
    <!--          :floors="props.form.floors" />-->
    <!--    </section>-->
  </modal-buttons-layout>
</template>

<script setup>
import { ref, watch } from 'vue'

import InputField from '@/components/input/InputField.vue'
import AddressForm from '@/components/input/AddressForm.vue'
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldSelectUser from '@/components/input/FormFieldSelectUser.vue'
import AddressPresenter from '@/components/ui/AddressPresenter.vue'
import MapPreview from '@/components/ui/MapPreview.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldSelectVendor from "@/components/input/FormFieldSelectVendor.vue";
import MergeSelectorModal from "@/components/modal/MergeSelectorModal.vue";
import FormFieldSelectVenue from "@/components/input/FormFieldSelectVenue.vue";

const emit = defineEmits(['search', 'delete'])

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
})

const merge = ref(false)
const addressForm = ref(props.form.address ?? {})
const editAddress = ref(!props.form.address)
const showEventsDropdown = ref(false)

//todo: replace with dynamic value
const levelsForEvent = ref('SDCC-2023')

const events = ref([])

// todo
const showHint = ref(true)

watch(
  () => props.form.address,
  (newValue, oldValue) => {
    if (newValue) {
      addressForm.value = newValue
      if (newValue.name || newValue.address || newValue.city || newValue.country) {
        editAddress.value = false
      }
    }
  }
)
</script>

<style scoped></style>