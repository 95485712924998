<template>
  <div
    :class="[
      'group',
      {
        'flex flex-row items-start gap-3': layout === 'row',
        'flex flex-col gap-1': layout === 'col',
        'grid grid-rows-1 gap-3': layout === 'grid',
      },
      layout === 'grid' &&
      (hideLabel || (label === null && $slots.label === undefined))
        ? 'grid-cols-1'
        : 'grid-cols-input-field',
    ]"
  >
    <form-label
      v-if="!hideLabel && (label || $slots.label)"
      :class="{
        'my-auto': centerLabel,
      }"
      :for="uid"
      :label="label"
      :optional="optional"
      :variant="variant"
    >
      <template #default>
        <slot name="label" />
      </template>
    </form-label>
    <slot name="wrapper">
      <div
        :class="{
          'flex flex-col': layout === 'col' || layout === 'grid',
          'flex flex-grow flex-col': layout === 'row',
        }"
        class="relative h-full"
      >
        <slot />
        <slot name="hint">
          <small
            v-if="hint"
            class="text-sm text-gray-600"
          >
            {{ hint }}
          </small>
        </slot>
        <validation-error
          v-if="error"
          :error="error"
        />
      </div>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import FormLabel from '@/components/ui/FormLabel.vue'
import { computed, PropType, provide } from 'vue'
import ValidationError from '@/components/ui/ValidationError.vue'
import {
  InputLayout,
  inputLayouts,
  InputVariant,
  inputVariants,
} from '@/types/designVariants'

const props = defineProps({
  /**
   * Optional unique identifier for the input.
   * Used to link the label to the input.
   * If not provided, a random id will be generated.
   */
  forId: {
    type: String,
    default: () => null,
  },
  layout: {
    type: String as PropType<InputLayout>,
    default: 'col',
    validator: (value: InputLayout) => {
      return inputLayouts.includes(value)
    },
  },
  variant: {
    type: String as PropType<InputVariant>,
    default: 'default',
    validator: (value: InputVariant) => {
      return inputVariants.includes(value)
    },
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  centerLabel: {
    type: Boolean,
    default: false,
  },
  error: {
    type: [String, Boolean],
    default: null,
  },
  hint: {
    type: String,
    default: null,
  },
  optional: {
    type: Boolean,
    default: false,
  },
})

const uid = props.forId ?? 'form-input-' + Math.random() * 10000

const computedError = computed(() => props.error)

provide('input-id', uid)
provide('input-error', computedError)
provide('input-variant', props.variant)
</script>

<style scoped></style>
