type GetIDFunction = (item: any) => string | null

/**
 * Create a tree from a flat array
 * @author https://stackoverflow.com/a/40732240
 * @param dataset
 * @param parentID
 * @param ID
 * @param children
 * @param sort
 * @param map
 */
export function createTree(
  dataset: any[],
  parentID: string | GetIDFunction = 'parent_id',
  ID: string | GetIDFunction = 'id',
  children: string = 'children',
  sort: ((a: any, b: any) => number) | null = null,
  map: ((a: any) => any) | null = null
) {
  const hashTable = Object.create(null)
  dataset.forEach((aData) => {
    const aDataID = typeof ID === 'function' ? ID(aData) : aData[ID]
    hashTable[aDataID] = aData
    hashTable[aDataID][children] = []
  })

  function buildTree(root: string | null) {
    const nodes = dataset
        .filter((aData) => {
          const aDataParentID =
              typeof parentID === 'function' ? parentID(aData) : aData[parentID]

          // if parent id is null, then it is a root node
          // so we need to filter out all nodes that do not have a parent id
          if (!root) {
            // element has no parent
            if (!aDataParentID) {
              return true
            }
            // element has parent, but parent is not in the list
            return !hashTable[aDataParentID]
          }
          return aDataParentID === root
        })
        .map((aData) => {
          const aDataID = typeof ID === 'function' ? ID(aData) : aData[ID]
          if (!aDataID) {
            console.error('Node ID is null', aData)
            return null
            // throw new Error('Node ID is null', aData)
          }
          const node = hashTable[aDataID]
          node[children] = buildTree(aDataID).filter((n) => n)
          return node
        })

    if (sort)
      nodes.sort(sort)
    if (map)
      return nodes.map(map)

    return nodes
  }

  return buildTree(null).filter((n) => n)
}

/**
 * Sorts an array by appearance in a list. Items not in the list are sorted first.
 * @param a
 * @param b
 * @param list
 */
export function sortNotInList<T>(
  a: T,
  b: T,
  list: T[],
  advanceSort?: ((a: T, b: T) => number) | undefined | null = undefined
) {
  const aIndex = list.indexOf(a)
  const bIndex = list.indexOf(b)

  if (aIndex === -1 && bIndex === -1) return 0
  if (aIndex === -1) return -1
  if (bIndex === -1) return 1

  return advanceSort ? advanceSort(a, b) : 0
}
