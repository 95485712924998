import { ModalFormOptionsType, useDetachedModalForm } from '@/utils/modalForm'
import { EventActivity } from '@/models/EventActivity'
import Venue from '@/models/Venue'
import VenueSpace from '@/models/VenueSpace'
import VenueStation from '@/models/VenueStation'
import Vendor from '@/models/Vendor'
import VenueFormView from '@/components/view/VenueFormView.vue'
import axios, { AxiosResponse } from 'axios'
import { useDebounceFn } from '@vueuse/core'
import VenueSpaceFormView from '@/components/view/VenueSpaceFormView.vue'
import VenueStationFormView from '@/components/view/VenueStationFormView.vue'
import SpecialGuest from '@/models/SpecialGuest'
import SpecialGuestFormView from '@/components/view/SpecialGuestFormView.vue'
import { Model } from '@/models/Model'
import { Component } from 'vue'
import VendorFormView from '@/components/view/VendorFormView.vue'
import UserFormView from '@/components/view/UserFormView.vue'
import User from '@/models/User'
import UserGroup from '@/models/UserGroup'
import UserGroupFormView from '@/components/view/UserGroupFormView.vue'
import GameFormView from '@/components/view/GameFormView.vue'
import Game from '@/models/Game'
import ActivityFormView from '@/components/view/ActivityFormView.vue'
import Tag from '@/models/Tag'
import TagFormView from '@/components/view/TagFormView.vue'
import VendorProduct from '@/models/VendorProduct'
import ProductFormView from '@/components/view/ProductFormView.vue'
import Raffle from '@/models/Raffle'
import RaffleFormView from '@/components/view/RaffleFormView.vue'
import TicketSource from '@/models/TicketSource'
import TicketSourceFormView from '@/components/view/TicketSourceFormView.vue'
import RafflePrize from '@/models/RafflePrize'
import RafflePrizeFormView from '@/components/view/RafflePrizeFormView.vue'
import { Team } from '@/models/Team'
import TeamFormView from '@/components/view/TeamFormView.vue'
import Task from '@/models/Task'
import TaskForm from '@/components/forms/task/TaskForm.vue'
import useConventionList from '@/utils/composables/conventionList'
import useOrganisation from '@/utils/composables/organisation'
import Category from '@/models/Category'
import CategoryFormView from '@/components/view/CategoryFormView.vue'

interface ResourceData {
  /**
   * The unique resource key. This is used to identify the resource.
   */
  key: string
  /**
   * The resource name to display in the modal form.
   */
  displayName: string
  /**
   * Model name of the resource.
   * @deprecated
   */
  modelName?: string
  /**
   * The model instance of the resource.
   */
  modelInstance: Model
  /**
   * The component to use for the modal form.
   */
  component: () => Component
  /**
   * The base url of the resource.
   * @param data - The data to pass to url. Example: { convention_event_id: 1 }
   */
  baseUrl?: (data: any) => string

  getUrlParamsFromModel?: (model: Model | any) => any
}

interface Transformers {
  /**
   * The function to transform the response data.
   * @param data
   */
  responseTransformer?: (data: AxiosResponse) => any
}

type ResourceDataWithTransformers = ResourceData & Transformers

export const modalResources: ResourceDataWithTransformers[] = [
  {
    key: 'activity',
    displayName: 'Activity',
    modelInstance: { ...new EventActivity() },
    component: () => ActivityFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/activities`,
    responseTransformer: (response: AxiosResponse): any => {
      if (!response.data.location)
        response.data.location = {
          id: null,
          type: null,
        }
      return response
    },
  },
  // {
  //   key: 'event',
  //   displayName: 'Event',
  //   modelName: 'ConventionEvent',
  //   modelInstance: new EventActivity(),
  // },
  // {
  //   key: 'event-day',
  //   displayName: 'Event Day',
  //   modelName: 'EventDay',
  //   modelInstance: new EventActivity(),
  // },
  {
    key: 'team',
    displayName: 'Team',
    modelInstance: { ...new Team() },
    component: () => TeamFormView,
    baseUrl: (data: any): string =>
      `/api/partner/organisations/${data.organisation_id}/teams`,
  },
  {
    key: 'game',
    displayName: 'Game',
    modelInstance: { ...new Game() },
    component: () => GameFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/games`,
  },
  {
    key: 'venue',
    displayName: 'Venue',
    modelInstance: { ...new Venue() },
    component: () => VenueFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/venues`,
  },
  {
    key: 'venue-space',
    displayName: 'Venue Space',
    modelInstance: { ...new VenueSpace() },
    component: () => VenueSpaceFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/spaces`,
  },
  {
    key: 'venue-station',
    displayName: 'Venue Station',
    modelInstance: { ...new VenueStation() },
    component: () => VenueStationFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/stations`,
  },
  {
    key: 'product',
    displayName: 'Product',
    modelInstance: { ...new VendorProduct() },
    component: () => ProductFormView,
    baseUrl: (data: any): string =>
      `/api/partner/vendors/${data.vendor_id}/products`,
    getUrlParamsFromModel(model: VendorProduct) {
      return {
        vendor_id: model.vendor_id,
      }
    },
  },
  {
    key: 'raffle',
    displayName: 'Raffle',
    modelInstance: { ...new Raffle() },
    component: () => RaffleFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/raffles`,
  },
  {
    key: 'ticket-source',
    displayName: 'Ticket Source',
    modelInstance: { ...new TicketSource() },
    component: () => TicketSourceFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/raffles/${data.raffle_id}/sources`,
  },
  {
    key: 'raffle-prize',
    displayName: 'Raffle Prize',
    modelInstance: { ...new RafflePrize() },
    component: () => RafflePrizeFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/raffles/${data.raffle_id}/prizes`,
  },
  {
    key: 'special-guest',
    displayName: 'Special Guest',
    modelInstance: { ...new SpecialGuest() },
    component: () => SpecialGuestFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/guests`,
  },
  // {
  //   key: 'sponsor',
  //   displayName: 'Sponsor',
  //   modelName: 'Sponsor',
  //   modelInstance: new EventActivity(),
  // },
  {
    key: 'user',
    displayName: 'User',
    modelInstance: new User(),
    component: () => UserFormView,
    baseUrl: (): string => `/api/partner/users`,
  },
  {
    key: 'user-group',
    displayName: 'User Group',
    modelInstance: new UserGroup(),
    component: () => UserGroupFormView,
    baseUrl: (): string => `/api/partner/groups`,
  },
  {
    key: 'vendor',
    displayName: 'Vendor',
    modelInstance: { ...new Vendor() },
    component: () => VendorFormView,
    baseUrl: (data: any): string =>
      `/api/partner/conventions/${data.convention_event_id}/vendors`,
  },
  {
    key: 'tag',
    displayName: 'Tag',
    modelInstance: { ...new Tag() },
    component: () => TagFormView,
    baseUrl: (): string => `/api/partner/tags`,
  },
  {
    key: 'category',
    displayName: 'Category',
    modelInstance: { ...new Category() },
    component: () => CategoryFormView,
    baseUrl: (): string => `/api/partner/categories`,
  },
  {
    key: 'task',
    displayName: 'Task',
    modelInstance: { ...new Task() },
    component: () => TaskForm,
    baseUrl: (): string => `/api/partner/tasks`,
  },
]

type ResourceModalFormOptionsType = ModalFormOptionsType<any> & {
  urlParams?: any
  onFetch?: (url: string, data?: any) => Promise<AxiosResponse>

  /**
   * The payload to pass to api when fetching data.
   */
  payload?: any
}

/**
 * Use this composable to open a modal form for a resource. Contains a list of pre-defined resources.
 */
export default function useResourceModal() {
  // create instance of detached modal form to control every modal form
  const modalForm = useDetachedModalForm()
  const { selected: selectedConvention } = useConventionList()
  const { selected: selectedOrganisation } = useOrganisation()

  return {
    /**
     * Open modal form by resource identifier.
     * @param resource
     * @param options
     */
    open(
      resource: string,
      options: ResourceModalFormOptionsType &
        Transformers & {
          touch?: boolean
          discardOnClose?: boolean
        } = {}
    ) {
      // find resource data identified by resource name
      const resourceData = modalResources.find((r) => r.key === resource)
      if (!resourceData) throw new Error(`Resource ${resource} not found`)

      // set default options

      // if component is provided, use it, otherwise use the default component
      if (!options.component) options.component = resourceData.component
      if (!options.component)
        throw new Error(
          `No component provided for modal resource form ${resource}`
        )

      const defaultParams = {
        convention_event_id: selectedConvention.value?.id,
        organisation_id: selectedOrganisation.value?.id,
      }

      // save base url for resource
      const baseUrl = resourceData.baseUrl?.({
        ...defaultParams,
        ...options.urlParams,
      })
      if (!baseUrl)
        throw new Error(
          `No base url provided for modal resource form ${resource}`
        )

      console.log('Base url: ' + baseUrl)

      // set default callbacks
      if (!options.onDelete) {
        // TODO: add to interface

        // call axios delete to default REST endpoint
        // override this callback if you want to use a different endpoint
        options.onDelete = (form: any) => {
          return axios.delete(`${baseUrl}/${form.id}`)
        }
      }

      if (!options.onSave) {
        // TODO: add to interface

        // call axios post to default REST endpoint
        // override this callback if you want to use a different endpoint
        options.onSave = (form: any) => {
          return axios.post(
            `${baseUrl}/${form.id}?_method=PUT`,
            form.formData()
          )
        }
      }

      if (!options.onFetch) {
        // TODO: add to interface

        // call axios post to default REST endpoint
        // override this callback if you want to use a different endpoint
        options.onFetch = (url) => {
          return axios.post(`${url}`, options.payload)
        }
      }

      const debounce = useDebounceFn((value: string) => {
        console.log('search', value)
      }, 1000)

      // set transformers
      if (!options.responseTransformer) {
        options.responseTransformer = resourceData.responseTransformer
      }

      options.events = {
        ...options.events,
        onSearch(payload: any[], close) {
          const [value] = payload
          debounce(value)
        },
      }

      // open modal form
      return modalForm.open(
        // set model instance data
        { ...resourceData.modelInstance },
        options,
        // create new resource
        options.onFetch(baseUrl).then(options.responseTransformer),
        options.touch,
        options.discardOnClose
      )
    },

    /**
     * Open a new resource form, with option to pre-fill some fields or create a new instance of the resource.
     * @param resource - The resource name from the list of all resources.
     * @param options
     */
    openNew(
      resource: string,
      options: ResourceModalFormOptionsType & {
        touch?: boolean
        discardOnClose?: boolean
      } = {}
    ) {
      this.open(resource, {
        ...options,
        touch: options.touch === undefined ? true : options.touch,
        discardOnClose:
          options.discardOnClose === undefined ? true : options.discardOnClose,
        onFetch: (url) => {
          return axios.post(`${url}`, options.payload)
        },
      })
    },

    /**
     * Open an edit form for an existing resource.
     * @param resource - The resource name from the list of all resources.
     * @param idOrModel
     * @param options
     */
    openEdit(
      resource: string,
      idOrModel: number | string | object,
      options: ResourceModalFormOptionsType = {}
    ) {
      const id =
        typeof idOrModel === 'number' || typeof idOrModel === 'string'
          ? idOrModel
          : idOrModel.id

      if (!id) {
        throw new Error('No id provided for edit resource form')
      }

      if (typeof idOrModel === 'object') {
        const resourceData = modalResources.find((r) => r.key === resource)
        options.urlParams = {
          ...options.urlParams,
          ...resourceData.getUrlParamsFromModel?.(idOrModel),
        }
      }

      this.open(resource, {
        ...options,
        touch: false,
        onFetch: (url) => {
          return axios.get(`${url}/${id}`)
        },
      })
    },
  }
}
