<template>
  <font-awesome-icon
    v-if="brandConfig?.icon"
    :class="props.class"
    :icon="['fab', brandConfig.icon]"
  />
  <slot
    v-else
    name="fallback"
  />
</template>

<script setup>
import { computed } from 'vue'
import { brands } from '@/utils/brands'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  brand: {
    type: String,
    required: true,
  },
  class: {
    type: String,
    default: '',
  },
})

const brandConfig = computed(() => {
  return brands.find(
    (brand) => brand.name.toLowerCase() === props.brand.toLowerCase()
  )
})
</script>

<style scoped></style>