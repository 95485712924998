import { Model } from '@/models/Model'
import Image from '@/models/Image'
import { TODO } from '@/types/todo'
import Tag from '@/models/Tag'

interface RoleUsers {
  name: string
  users: User[]
}

export default class User extends Model {
  id: number | null = null
  username: string = ''
  name: string = ''
  first_name: string = ''
  middle_name: string = ''
  last_name: string = ''
  avatar: Image | null = null
  description: string = ''
  caption: string = ''
  role: TODO = null
  phone_numbers: TODO[] = []
  emails: TODO[] = []
  socials: TODO[] = []
  profession_tags: Tag[] = []
  activity_tags: Tag[] = []
  topic_tags: Tag[] = []
  fandom_tags: Tag[] = []
  representatives: RoleUsers[] = []
  clients: RoleUsers[] = []
}
