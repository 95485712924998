<template>
  <modal-submenu-layout
    :sections="sections"
    disable-bottom
  >
    <template #top>
      <!--      <confirm-modal-->
      <!--        :is-popup-open="showDeleteConfirmation"-->
      <!--        :loading="deleteProcessing"-->
      <!--        danger-->
      <!--        @cancel="showDeleteConfirmation = false"-->
      <!--        @close="showDeleteConfirmation = false"-->
      <!--        @confirm="handleDeleteActivity">-->
      <!--        <template #title>-->
      <!--          <h1 class="text-lg font-normal">Delete Activity</h1>-->
      <!--        </template>-->
      <!--        <p class="text-base">Are you sure you want to delete this activity?</p>-->
      <!--      </confirm-modal>-->

      <form-field-header-input
        class="w-full"
        @delete="emit('delete')"
      >
        <template #input>
          <form-field-input
            v-model="form.name"
            :error="form.errors.name"
            class="grow"
            input-class="font-bold"
            placeholder="Untitled Activity"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <template #general>
      <input-layout
        :error="form.errors.description"
        label="Description"
        layout="grid"
        variant="gray"
      >
        <form-field-input-textarea
          v-model="form.description"
          rows="2"
        />
      </input-layout>
      <input-layout
        :error="form.errors.short_description"
        label="Short Description (max 170 char)"
        layout="grid"
        variant="gray"
      >
        <form-field-input-textarea
          v-model="form.short_description"
          rows="2"
        />
      </input-layout>
    </template>
    <template #guests>
      <form-input-group-heading>Guests & Hosts</form-input-group-heading>
      <input-layout
        :error="form.errors.guests"
        label="Users"
        layout="grid"
        variant="gray"
      >
        <form-field-select-user v-model:selected="form.guests" />
      </input-layout>
      <input-layout
        :error="form.errors.groups"
        label="Groups"
        layout="grid"
        variant="gray"
      >
        <form-field-select-user-group v-model:selected="form.groups" />
      </input-layout>
    </template>
    <template #preferences>
      <form-input-group-heading>Preferences</form-input-group-heading>
      <input-layout
        :error="form.errors.type?.id"
        label="Activity type"
        layout="grid"
        variant="gray"
      >
        <form-field-select-category
          v-model:selected="form.type"
          placeholder="Select activity type"
          type="activity"
        />
      </input-layout>
      <form-field-input-category-attributes
        v-model="form.attributes"
        :category-id="form.type?.id"
      />
      <input-layout
        :error="form.errors.topic_tags"
        label="Topics"
        layout="grid"
        variant="gray"
      >
        <form-field-select-tag
          v-model:selected="form.topic_tags"
          type="topic"
        />
      </input-layout>
      <input-layout
        :error="form.errors.fandom_tags"
        label="Fandoms"
        layout="grid"
        variant="gray"
      >
        <form-field-select-tag
          v-model:selected="form.fandom_tags"
          type="fandom"
        />
      </input-layout>
    </template>
    <template #time-and-location>
      <form-input-group-heading>Time & Place</form-input-group-heading>
      <input-layout
        :error="form.errors.event_day"
        label="Event Day"
        layout="grid"
        variant="gray"
      >
        <form-field-select-event-day
          v-model="form.event_day"
          :event-id="form.event?.id"
          @update:model-value="
            (event) => {
              const date = DateTime.fromISO(event.date, { setZone: true })
              form.starts_at = DateTime.fromISO(form.starts_at, {
                setZone: true,
              })
                .set({
                  year: date.year,
                  month: date.month,
                  day: date.day,
                })
                .toISO()
              form.ends_at = DateTime.fromISO(form.ends_at, { setZone: true })
                .set({
                  year: date.year,
                  month: date.month,
                  day: date.day,
                })
                .toISO()
            }
          "
        />
      </input-layout>
      <input-layout
        :error="form.errors.starts_at ?? form.errors.ends_at"
        label="Start - End Time"
        layout="grid"
        variant="gray"
      >
        <div class="flex items-center gap-3">
          <form-field-select-time
            v-model:model-value="form.starts_at"
            :max-time="
              String(
                DateTime.fromISO(form.event_day?.date, {
                  setZone: true,
                })
                  .set({
                    hour: 24,
                    minute: 0,
                    second: 0,
                  })
                  .plus({ days: 1 })
              )
            "
            :min-time="
              String(
                DateTime.fromISO(form.event_day?.date, {
                  setZone: true,
                })
                  .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                  })
                  .minus({ days: 1 })
              )
            "
            :target-time="form.event_day?.date"
            format="datetime"
          />
          -
          <form-field-select-time
            v-model:model-value="form.ends_at"
            :max-time="
              String(
                DateTime.fromISO(form.event_day?.date, {
                  setZone: true,
                })
                  .set({
                    hour: 24,
                    minute: 0,
                    second: 0,
                  })
                  .plus({ days: 1 })
              )
            "
            :min-time="
              String(
                DateTime.fromISO(form.event_day?.date, {
                  setZone: true,
                })
                  .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                  })
                  .minus({ days: 1 })
              )
            "
            :target-time="form.event_day?.date"
            format="datetime"
          />
        </div>
      </input-layout>
      <input-layout
        :error="form.errors.location?.type"
        label=" Location
          "
        layout="grid"
        variant="gray"
      >
        <form-field-select-group
          :model-value="
            form.location_type === 'map_point' ? 'map_point' : 'on_site'
          "
          :options="[
            {
              label: 'Map Point',
              value: 'map_point',
            },
            {
              label: 'Venue Space',
              value: 'on_site',
            },
          ]"
          size="sm"
          @update:model-value="
            (event) => {
              if (event !== form.location_type) {
                form.location = {
                  type: event,
                }
                form.location_type = event
              }
            }
          "
        />
      </input-layout>
      <input-layout
        v-if="form.location_type === 'map_point'"
        label="Map Point"
        layout="grid"
        variant="gray"
      >
        <form-field-select-map
          v-model:latitude="form.location.latitude"
          v-model:longitude="form.location.longitude"
        />
      </input-layout>
      <input-layout
        v-else
        label="Venue Space"
        layout="grid"
        variant="gray"
      >
        <form-field-select-location
          :event-id="form.event?.id"
          :selected="form.location?.id === undefined ? null : form.location"
          @update:selected="
            ($event) => {
              form.location_id = $event?.id
              form.location_type = $event?.type
              form.location = $event
            }
          "
        />
      </input-layout>
      <input-layout
        label="Attendees"
        layout="grid"
        variant="gray"
      >
        {{ form.attendee_count }}
      </input-layout>
    </template>
    <template #branding>
      <form-input-group-heading>Branding</form-input-group-heading>
      <form-field-image-type-collection
        :config="[
          {
            label: 'Banner',
            name: 'banner',
            width: 1500,
            height: 500,
          },
          {
            label: 'Social',
            name: 'social',
            width: 1200,
            height: 630,
          },
        ]"
        :images="{
          banner: form.banner_image,
          social: form.social_image,
        }"
        @update:images="
          ({ banner, social }) => {
            form.banner_image = banner
            form.social_image = social
          }
        "
      />
    </template>
    <template #visibility>
      <form-input-group-heading>Visibility</form-input-group-heading>
      <input-layout
        label="Audience"
        layout="grid"
        variant="gray"
      >
        <form-field-select-item
          :selected="
            form.is_public === undefined
              ? null
              : form.is_public
                ? { label: 'Public', value: 1 }
                : { label: 'Internal', value: 0 }
          "
          :suggestions="[
            {
              label: 'Public',
              value: 1,
            },
            {
              label: 'Internal',
              value: 0,
            },
          ]"
          key-by="value"
          search-by="label"
          single-select
          size="sm"
          @update:selected="form.is_public = $event?.value"
          @update:model-value="form.is_public = $event"
        />
      </input-layout>
      <input-layout
        label="Publish State"
        layout="grid"
        variant="gray"
      >
        <form-field-select-item
          :selected="
            form.state === undefined
              ? null
              : {
                label:
                  form.state === 'ready'
                    ? 'Published'
                    : form.state === 'draft'
                      ? 'Draft'
                      : 'Scheduled',
                value: form.state,
              }
          "
          :suggestions="[
            {
              label: 'Draft',
              value: 'draft',
            },
            {
              label: 'Scheduled',
              value: 'scheduled',
            },
            {
              label: 'Published',
              value: 'ready',
            },
          ]"
          key-by="value"
          search-by="label"
          single-select
          size="sm"
          @update:selected="
            ($event) => {
              form.state = $event?.value
              if (form.state === 'scheduled' && !form.publish_at) {
                form.publish_at = DateTime.now().plus({ days: 1 }).toISO()
              }
            }
          "
        />
      </input-layout>
      <input-layout
        v-if="form.state === 'scheduled'"
        label="Publish Date"
        layout="grid"
        variant="gray"
      >
        <div class="flex">
          <div class="flex flex-col text-sm">
            <span class="capitalize text-paragraph">
              {{ DateTime.fromISO(form.publish_at).toRelativeCalendar() }}
            </span>
            <div class="flex items-center gap-2 text-gray-500">
              <span>
                {{ DateTime.fromISO(form.publish_at).toFormat('EEE, LLL d') }}
              </span>
              <div class="h-[3px] w-[3px] rounded-full bg-gray-500" />
              <span>
                {{
                  DateTime.fromISO(form.publish_at)
                    .toFormat('h:mma')
                    .toLowerCase()
                    .slice(0, -1)
                }}
              </span>
            </div>
          </div>
          <dropdown-button class="ml-auto">
            <template #button>
              <button class="text-blue-neon underline">
                Change publish date
              </button>
            </template>
            <template #content>
              <div class="rounded bg-gray-200">
                <calendar-selector
                  v-model="form.publish_at"
                  class="w-[300px] p-2"
                  with-time
                />
              </div>
            </template>
          </dropdown-button>
        </div>
      </input-layout>
    </template>
    <template #management>
      <form-input-group-heading>Management</form-input-group-heading>
      <input-layout
        label="Created by"
        layout="grid"
        variant="gray"
      >
        <div class="flex items-center gap-2 text-paragraph">
          <template v-if="form.created_by">
            <avatar-image
              :src="form.created_by?.avatar?.path"
              class="h-[30px] w-[30px]"
            />
            <span>
              {{ form.created_by.name }}
            </span>
          </template>
          <div v-else>
            Organisation
          </div>
          <div class="h-[3px] w-[3px] rounded-full bg-gray-700" />
          <span>
            {{ DateTime.fromISO(form.created_at).toFormat('LLL d, yyyy') }}
          </span>
          <span>@</span>
          <span>
            {{
              DateTime.fromISO(form.created_at)
                .toFormat('h:mma')
                .toLowerCase()
                .slice(0, -1)
            }}
          </span>
        </div>
      </input-layout>
      <input-layout
        label="Las Modified by"
        layout="grid"
        variant="gray"
      >
        <div class="flex items-center gap-2 text-paragraph">
          <template v-if="form.updated_by">
            <avatar-image
              :src="form.updated_by?.avatar?.path"
              class="h-[30px] w-[30px]"
            />
            <span>
              {{ form.updated_by.name }}
            </span>
          </template>
          <div v-else>
            Organisation
          </div>
          <div class="h-[3px] w-[3px] rounded-full bg-gray-700" />
          <span>
            {{ DateTime.fromISO(form.updated_at).toFormat('LLL d, yyyy') }}
          </span>
          <span>@</span>
          <span>
            {{
              DateTime.fromISO(form.updated_at)
                .toFormat('h:mma')
                .toLowerCase()
                .slice(0, -1)
            }}
          </span>
        </div>
      </input-layout>
    </template>
    <template #tasks>
      <h3 class="text-sm font-bold">
        Tasks
      </h3>
      <div>
        <task-list
          v-model:tasks="form.tasks"
          class="py-2"
        />
        <button
          class="flex w-fit items-center gap-1 self-start rounded border border-dashed p-2 text-gray-500"
          @click="
            form.tasks.push({
              name: '',
              subtasks: [],
              showSubtasks: false,
              completed: false,
              selected: false,
            })
          "
        >
          <font-awesome-icon
            class="h-5 w-5"
            icon="plus"
          />
          <span class="text-sm">Add Task</span>
        </button>
      </div>
    </template>
    <template #comments>
      <task-comments
        v-if="form.task"
        :convention="form.event"
        :task="form.task"
        wrapper-class="mb-10 ml-6 mr-14 mt-4"
      />
    </template>
  </modal-submenu-layout>
</template>

<script setup>
import { DateTime } from 'luxon'

import { EventActivity } from '@/models/EventActivity'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import TaskList from '@/components/forms/task/TaskList.vue'

import ModalSubmenuLayout from '@/components/modal/layout/ModalSubmenuLayout.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import FormFieldSelectUser from '@/components/input/FormFieldSelectUser.vue'
import FormFieldSelectUserGroup from '@/components/input/FormFieldSelectUserGroup.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import FormFieldSelectEventDay from '@/components/input/FormFieldSelectEventDay.vue'
import FormFieldSelectTime from '@/components/input/FormFieldSelectTime.vue'
import FormFieldSelectGroup from '@/components/input/FormFieldSelectGroup.vue'
import FormFieldSelectMap from '@/components/input/FormFieldSelectMap.vue'
import FormFieldSelectLocation from '@/components/input/FormFieldSelectLocation.vue'
import FormFieldImageTypeCollection from '@/components/input/FormFieldImageTypeCollection.vue'
import FormInputGroupHeading from '@/components/ui/FormInputGroupHeading.vue'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import DropdownButton from '@/components/buttons/DropdownButton.vue'
import CalendarSelector from '@/components/widgets/CalendarSelector.vue'
import AvatarImage from '@/components/ui/AvatarImage.vue'
import TaskComments from '@/components/widgets/TaskComments.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInputCategoryAttributes from '@/components/input/FormFieldInputCategoryAttributes.vue'
import FormFieldSelectCategory from '@/components/input/FormFieldSelectCategory.vue'

const emit = defineEmits('delete')

const props = defineProps({
  form: {
    type: [Object, EventActivity],
    required: true,
  },
})

// function handleDeleteActivity() {
//   deleteProcessing.value = true
//   axios
//     .delete(
//       `/api/partner/conventions/${props.event.id}/activities/${props.form.id}`
//     )
//     .finally(() => {
//       deleteProcessing.value = false
//       showDeleteConfirmation.value = false
//       emit('close', 'deleted')
//     })
// }

const sections = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Guests',
    value: 'guests',
  },
  {
    label: 'Preferences',
    value: 'preferences',
  },
  {
    label: 'Time & Location',
    value: 'time-and-location',
  },
  {
    label: 'Branding',
    value: 'branding',
  },
  {
    label: 'Visibility',
    value: 'visibility',
  },
  {
    label: 'Management',
    value: 'management',
  },
  {
    label: 'Tasks',
    value: 'tasks',
  },
  {
    label: 'Comments',
    value: 'comments',
  },
]

// const activeSections = computed(() => {
//   return sections.filter((section) => {
//     if (section.value === 'comments' && !props.form.task) return false
//     return props.sections.includes(section.value)
//   })
// })
</script>

<style scoped></style>
