<template>
  {{ displayValue }}
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  duration: {
    type: Number,
    default: 1000,
  },
})

const displayValue = ref(props.value)

watch(
  () => props.value,
  (newValue, oldValue) => {
    let start = null
    const step = (timestamp) => {
      if (!start) start = timestamp
      const progress = timestamp - start
      displayValue.value = Math.floor(
        oldValue + (newValue - oldValue) * (progress / props.duration)
      )
      if (progress < props.duration) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
  }
)
</script>

<style scoped></style>