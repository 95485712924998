import ConventionEvent from '@/models/ConventionEvent'
import VendorProduct from '@/models/VendorProduct'
import { Model } from './Model'
import Image from '@/models/Image'

export default class Vendor extends Model {
  // general info
  id: number | null = null
  name: string = ''
  description: string = ''
  allow_editing: boolean = false

  type: object | null = null
  activity_tags: Array<unknown> = []
  fandom_tags: Array<unknown> = []
  topic_tags: Array<unknown> = []
  thumbnail: Image | null = null
  logo: Image | null = null
  banner: Image | null = null
  social: Image | null = null
  members: Array<unknown> = []
  phone_numbers: Array<unknown> = []
  emails: Array<unknown> = []
  socials: Array<unknown> = []
  addresses: Array<unknown> = []
  events: Array<ConventionEvent> = []
  categories: Array<unknown> = []
  products: Array<VendorProduct> = []
  recommended_products: VendorProduct[] | undefined = undefined
}
