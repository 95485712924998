import { Model } from '@/models/Model'
import Image from '@/models/Image'
import { DateTime } from 'luxon'
import { EventActivity } from '@/models/EventActivity'
import User from '@/models/User'

export default class UserGroup extends Model {
  id: number | null = null
  is_member: boolean = false
  request_sent: boolean = false
  role: string = ''
  privacy: string = ''

  name: string = ''
  description: string = ''
  caption: string = ''
  types: unknown[] = []
  rules: { title: string; description: string }[] = []
  founded_at: string | null = DateTime.now().toISODate()
  avatar: Image | null = null
  thumbnail: Image | null = null
  logo: Image | null = null
  banner: Image | null = null
  social: Image | null = null
  about_image: Image | null = null
  activity_tags: unknown[] = []
  topic_tags: unknown[] = []
  fandom_tags: unknown[] = []
  members: User[] = []
  members_count: number = 0
  blocked_count: number = 0
  pending_count: number = 0
  upcoming_count: number = 0
  past_count: number = 0
  ongoing_count: number = 0
  conventions_count: number = 0
  representatives: unknown[] = []
  upcoming_activities: EventActivity[] = []

  get canEdit(): boolean {
    return this.is_member && this.role === 'owner'
  }

  get isPrivate(): boolean {
    return this.privacy === 'private'
  }
}
