<template>
  <label class="relative inline-flex cursor-pointer items-center">
    <input
      type="checkbox"
      :value="modelValue"
      :checked="modelValue"
      class="peer sr-only"
      :disabled="disabled"
      @input="emit('update:modelValue', $event.target.checked)"
    />
    <div
      class="peer h-6 w-11 rounded-full bg-ultra-light-blue after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:bg-blue-primary after:transition-all after:content-[''] peer-checked:bg-blue-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-primary peer-disabled:bg-gray-200 peer-disabled:after:bg-gray-700 dark:border-gray-600 dark:peer-focus:ring-blue-primary"
    />
    <span class="ml-3 text-sm font-medium">
      {{ label }}
    </span>
  </label>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
</script>

<style scoped></style>
