<template>
  <div
    :class="classes"
    class="rounded-full px-4 py-2 font-heading text-sm capitalize text-muted-blue"
  >
    {{ tag.name ?? tag.value }}
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  tag: {
    type: Object,
    required: true,
  },
})

const classes = computed(() => {
  return [background.value, color.value]
})

const color = computed(() => {
  return ''
})

const background = computed(() => {
  // if tag has a color, use that
  if (props.tag.color) {
    return `bg-[${props.tag.color}]`
  }
  return 'bg-[#E0F0FF]'

  // else use the default color for each tag type
  // eslint-disable-next-line no-unreachable
  switch (props.tag.type.toLowerCase()) {
    case 'activity':
      return 'bg-indigo-950'
    case 'company':
      return 'bg-green-950'
    case 'food':
    case 'menu_item_type':
      return 'bg-yellow-950'
    case 'franchise':
      return 'bg-red-950'
    case 'game':
      return 'bg-blue-950'
    case 'genre':
      return 'bg-purple-950'
    case 'highlight':
      return 'bg-pink-950'
    case 'language':
      return 'bg-gray-950'
    case 'product_type':
      return 'bg-orange-950'
    case 'profession':
      return 'bg-teal-950'
    case 'topic':
      return 'bg-cyan-950'
    case 'user':
      return 'bg-gray-950'
    default:
      return 'bg-[#E0F0FF]'
  }
})
</script>

<style scoped></style>
