<template>
  <div
    :class="[
      'group',
      {
        'flex flex-row items-start gap-3': layout === 'row',
        'flex flex-col': layout === 'col',
        'grid grid-rows-1 gap-3': layout === 'grid',
      },
      layout === 'grid' &&
      (hideLabel || (label === null && $slots.label === undefined))
        ? 'grid-cols-1'
        : 'grid-cols-input-field',
    ]"
  >
    <form-label
      v-if="!hideLabel && (label || $slots.label)"
      :class="labelClass"
      :for="forId ?? $.uid"
      :label="label"
      :variant="variant"
      @click="focus"
    >
      <template #default>
        <slot name="label" />
      </template>
    </form-label>
    <slot name="wrapper">
      <div
        ref="wrapper"
        :class="{
          'flex flex-col': layout === 'col' || layout === 'grid',
          'flex flex-grow flex-col': layout === 'row',
        }"
        class="h-full"
      >
        <slot name="before" />
        <input-wrapper
          ref="inputWrapper"
          :empty="!modelValue || modelValue?.length === 0"
          :variant="variant"
          @click="onWrapperClick"
        >
          <slot name="before-inner" />
          <slot name="input">
            <app-input
              :id="$.uid"
              :disabled="disabled"
              :max="max"
              :min="min"
              :model-value="modelValue"
              :placeholder="placeholder"
              :rows="rows"
              :step="step"
              :type="type"
              @blur="onBlur"
              @focus="onFocus"
              @input="onInput"
              @update:model-value="$emit('update:modelValue', $event)"
            />
          </slot>
          <slot name="inner" />
        </input-wrapper>
        <slot />
        <validation-error
          v-if="innerValidationError || error"
          :error="innerValidationError ?? error"
        />
      </div>
    </slot>

    <div @click="onWrapperClick">
      <slot name="btns" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ValidationError from '@/components/ui/ValidationError.vue'
import InputWrapper from '@/components/ui/InputWrapper.vue'
import { onMounted, ref } from 'vue'
import FormLabel from '../ui/FormLabel.vue'
import AppInput from '@/components/input/AppInput.vue'
const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'input'])


















































defineOptions({
  name: 'InputField',
})


















































/**
 * The InputField component is a flexible Vue 3 component designed to wrap input fields and provide various layout options,
 * label support, and validation error display. It offers dynamic layout choices, allowing you to use it in different scenarios,
 * such as horizontal rows, vertical columns, or grid layouts.
 * @deprecated Use FormFieldInput instead
 * @figma https://www.figma.com/file/NycgmenOdWjRDMhseKR9VA/Fankind?type=design&node-id=3121-13994&mode=design&t=FY2zKRL8YvDjeQIY-4
 * @usage <InputField v-model="myValue" label="My Label" />
 * @usage <InputField v-model="myValue" label="My Label" layout="col" />
 * @example {
 *   "title": "Default",
 *   "description": "The default input field layout.",
 *   "attrs": {
 *     "label": "My Custom Label",
 *     "modelValue": "",
 *     "variant": "gray"
 *   }
 * }
 * @example {
 *  "title": "Row",
 *  "description": "The row layout with outline.",
 *  "attrs": {
 *  "label": "My Custom Label",
 *  "modelValue": "",
 *  "layout": "row",
 *  "variant": "outline"
 *  }
 * }
 */


const props = defineProps({
  /**
   * The ID of the input field. If not provided, a unique ID will be generated.
   */
  forId: {
    type: [String, Number],
    default: null,
  },

  /**
   * Label for the input field.
   */
  label: {
    type: String,
    default: null,
  },
  labelClass: {
    type: String,
    default: '',
  },

  /**
   * The value of the input field.
   * @model
   */
  modelValue: {
    type: [String, Number],
    required: true,
  },
  /**
   * The validation error message to display.
   */
  error: {
    type: String,
    default: null,
  },
  /**
   * Autofocus the input field on mount.
   */
  autofocus: {
    type: Boolean,
    default: false,
  },
  /**
   * Focus the input field when the wrapper is clicked.
   * @deprecated
   */
  focusOnWrapper: {
    type: Boolean,
    default: false,
  },
  /**
   * The layout of the input field.
   * @values row, col, grid
   */
  layout: {
    type: String,
    default: 'col', // row, col, grid
  },
  /**
   * The variant of the input field.
   * @values default, outline, minimal, gray
   */
  variant: {
    type: String,
    default: 'default', // default, outline, minimal, gray
  },
  /**
   * Hide the label.
   */
  hideLabel: {
    type: Boolean,
    default: false,
  },

  /**
   * The type of the input field.
   * @values text, number, email, password, date, datetime-local, time, url, search, tel, textarea
   */
  type: {
    type: String,
    default: 'text',
  },
  /**
   * Disable the input field.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * The step of the input field (for number inputs).
   */
  step: {
    type: String,
    default: 'any',
  },
  /**
   * The min and max values of the input field (for number inputs).
   */
  min: {
    type: String,
    default: null,
  },
  /**
   * The min and max values of the input field (for number inputs).
   */
  max: {
    type: String,
    default: null,
  },
  /**
   * The placeholder of the input field.
   */
  placeholder: {
    type: String,
    default: null,
  },
  /**
   * The number of rows of the input field (for textarea inputs).
   */
  rows: {
    type: Number,
    default: 3,
  },

  /**
   * The validation rules for the input field.
   * @example { required: true, max: 10 }
   */
  rules: {
    type: Object,
    default: () => ({}),
  },
  /**
   * Disable validation on blur.
   */
  disableBlurValidation: {
    type: Boolean,
    default: false,
  },
  /**
   * Disable the shake animation on validation error.
   */
  disableShakeAnimation: {
    type: Boolean,
    default: false,
  },
})

const wrapper = ref<HTMLElement|null>(null)
const inputWrapper = ref(null)
const innerValidationError = ref(null)

function validate() {
  let valid = true
  for (const ruleKey of Object.keys(props.rules)) {
    if (!valid) break
    switch (ruleKey) {
      case 'required':
        if (props.rules[ruleKey] && !props.modelValue.length) {
          innerValidationError.value = 'This field is required'
          valid = false
        }
        break
      case 'max':
        if (
          props.rules[ruleKey] &&
          props.modelValue.length > props.rules[ruleKey]
        ) {
          innerValidationError.value = `This field must be less than ${props.rules[ruleKey]} characters`
          valid = false
        }
        break
      case 'email':
        if (
          props.rules[ruleKey] &&
          !props.modelValue.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        ) {
          innerValidationError.value =
            'This field must be a valid email address'
          valid = false
        }
        break
      case 'phone':
        if (
          props.rules[ruleKey] &&
          !props.modelValue.match(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
          )
        ) {
          innerValidationError.value = 'This field must be a valid phone number'
          valid = false
        }
        break
      case 'url':
        if (
          props.rules[ruleKey] &&
          !props.modelValue.match(
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
          )
        ) {
          innerValidationError.value = 'This field must be a valid URL'
          valid = false
        }
        break
      case 'regex':
        if (
          props.rules[ruleKey] &&
          !props.modelValue.match(props.rules[ruleKey].regex)
        ) {
          innerValidationError.value = props.rules[ruleKey].message
          valid = false
        }
        break
      default:
        console.warn(
          `Unknown rule key '${ruleKey}', please add it to rules or use regex validation`
        )
        break
    }
  }

  if (valid) innerValidationError.value = null
  else if (!props.disableShakeAnimation) {
    // shake animation
    inputWrapper.value?.element.animate(
      [
        { transform: 'translateX(0)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(10px)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(10px)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(10px)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(0)' },
      ],
      {
        duration: 500,
        easing: 'ease-in-out',
      }
    )
  }
  return valid
}

function onFocus() {
  emit('focus')
}

function onInput(event) {
  innerValidationError.value = null
  emit('input', event)
}

function onWrapperClick() {
  focus()
}

function onBlur() {
  requestAnimationFrame(() => {
    if (!wrapper.value.matches(':focus-within')) {
      if (!props.disableBlurValidation) validate()
      emit('blur')
    }
  })
}

function focus() {
  const input = wrapper.value?.querySelector('input,textarea')
  if (input) {
    if (input.type === 'file') {
      input.click()
    } else input.focus()
  } else emit('focus')
}

function blur() {
  wrapper.value?.querySelector('input,textarea')?.blur()
}

onMounted(() => {
  if (props.autofocus) {
    focus()
  }
})

defineExpose({
  focus,
  blur,
  validate,
  innerValidationError,
})
</script>
