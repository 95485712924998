import { Model } from "@/models/Model";

export default class Address extends Model {
  id: number | null = null
  name: string = ''
  address: string = ''
  street_address_1: string = ''
  street_address_2: string = ''
  city: string = ''
  state: string = ''
  postal_code: string = ''
  country: string = ''
  zip: string = ''
  latitude: number = 0
  longitude: number = 0
}