<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <form-field-header-input class="w-full">
        <template #input>
          <form-field-input
            v-model="form.name"
            :error="form.errors?.name"
            class="grow"
            input-class="font-bold"
            placeholder="Enter Location Name"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <div class="flex flex-col gap-6">
      <form-input-group-layout>
        <input-layout
          :error="form.errors?.location_type"
          label="Location type"
          layout="grid"
          variant="gray"
        >
          <form-field-select-group
            :model-value="
              form.location?.type === 'map_point'
                ? 'map_point'
                : form.location?.type === 'address'
                  ? 'address'
                  : 'venue_space'
            "
            :options="[
              { label: 'Map Pin', value: 'map_point' },
              { label: 'Address', value: 'address' },
              { label: 'Venue Space', value: 'venue_space' },
            ]"
            size="sm"
            variant="primary"
            @update:model-value="onChangeLocationType"
          />
        </input-layout>
      </form-input-group-layout>
      <hr />
      <form-input-group-layout>
        <input-layout
          :error="form.errors?.convention_event"
          label="Event"
          layout="grid"
          variant="gray"
        >
          <form-field-select-convention
            v-model:selected="form.convention_event"
            single-select
            variant="gray"
          />
        </input-layout>
        <template v-if="form.location?.type === 'map_point'">
          <input-layout
            :error="
              form.errors?.location?.latitude ||
              form.errors?.location?.longitude
            "
            label="Coordinates"
            layout="grid"
            variant="gray"
          >
            <div class="flex gap-6">
              <form-field-input
                :model-value="form.location.latitude + ''"
                placeholder="Latitude"
                variant="gray"
                @update:model-value="(v) => (form.location.latitude = +v)"
              />
              <form-field-input
                :model-value="form.location.longitude + ''"
                placeholder="Longitude"
                variant="gray"
                @update:model-value="(v) => (form.location.longitude = +v)"
              />
            </div>
          </input-layout>
          <map-selector
            :latitude="form.location.latitude"
            :longitude="form.location.longitude"
            class="relative h-40"
            unlimited-zoom
            @update="
              ({ latitude, longitude }) => {
                form.location.latitude = latitude
                form.location.longitude = longitude
              }
            "
          />
        </template>
        <template v-else-if="form.location?.type === 'address'">
          <form-input-group-heading>Address</form-input-group-heading>
          <address-form
            :errors="form.errors?.location?.address"
            :form="form.location.address"
            hide-buttons
          />
        </template>
        <template v-else-if="form.location?.type === 'venue_space'">
          <input-layout
            label="Venue"
            layout="grid"
            variant="gray"
          >
            <form-field-select-venue
              v-if="form.convention_event"
              v-model:selected="form.location.venue"
              :convention-event-id="form.convention_event?.id"
              variant="gray"
            />
          </input-layout>
          <input-layout
            label="Station / Booth"
            layout="grid"
            variant="gray"
          >
            <form-field-select-venue-space
              v-if="form.location.venue"
              v-model:selected="form.location.venue_space"
              :convention-event-id="form.convention_event?.id"
              :venue-id="form.location.venue?.id"
              variant="gray"
            />
          </input-layout>
        </template>
      </form-input-group-layout>
      <hr />
      <form-input-group-layout>
        <div
          v-for="day in eventDays"
          :key="day.id"
          class="grid grid-cols-input-field gap-3"
        >
          <form-label variant="gray">
            {{ DateTime.fromISO(day.date).toFormat('cccc') }}
          </form-label>
          <div class="flex flex-col items-baseline gap-1">
            <div
              v-for="(schedule, index) in form.schedule.filter(
                (s) => s.day_id === day.id
              )"
              :key="index"
              class="flex items-center gap-3"
            >
              <form-field-select-time
                :model-value="
                  DateTime.fromISO(schedule.starts_at, {
                    setZone: true,
                  }).toFormat('HH:mm')
                "
                variant="gray"
                @update:model-value="
                  (v) =>
                    (schedule.starts_at = DateTime.fromISO(schedule.starts_at, {
                      setZone: true,
                    }).set({
                      hour: +v.split(':')[0],
                      minute: +v.split(':')[1],
                    }))
                "
              />
              -
              <form-field-select-time
                :model-value="
                  DateTime.fromISO(schedule.ends_at, {
                    setZone: true,
                  }).toFormat('HH:mm')
                "
                variant="gray"
                @update:model-value="
                  (v) =>
                    (schedule.starts_at = DateTime.fromISO(schedule.ends_at, {
                      setZone: true,
                    }).set({
                      hour: +v.split(':')[0],
                      minute: +v.split(':')[1],
                    }))
                "
              />

              <font-awesome-icon
                class="cursor-pointer"
                icon="times"
                @click="
                  form.schedule = form.schedule.filter((s) => s !== schedule)
                "
              />
            </div>
            <button-plus
              class="flex w-[300px] items-center justify-center gap-2 px-2 py-1 text-base"
              @click="
                form.schedule.push({
                  day_id: day.id,
                  starts_at: DateTime.fromISO(day.date, { setZone: true })
                    .set({
                      hour: 12,
                      minute: 0,
                    })
                    .toISO(),
                  ends_at: DateTime.fromISO(day.date, { setZone: true })
                    .set({
                      hour: 20,
                      minute: 0,
                    })
                    .toISO(),
                })
              "
            >
              <template #text>Add Open/Close Time</template>
            </button-plus>
          </div>
        </div>
      </form-input-group-layout>
    </div>
  </modal-buttons-layout>
</template>

<script setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldSelectGroup from '@/components/input/FormFieldSelectGroup.vue'
import MapSelector from '@/components/input/MapSelector.vue'
import { VendorSpot } from '@/models/VendorSpot'
import { onMounted, ref, watch } from 'vue'
import Address from '@/models/Address'
import AddressForm from '@/components/input/AddressForm.vue'
import FormFieldSelectConvention from '@/components/input/FormFieldSelectConvention.vue'
import axios from 'axios'
import { DateTime } from 'luxon'
import FormFieldSelectTime from '@/components/input/FormFieldSelectTime.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FormLabel from '@/components/ui/FormLabel.vue'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormInputGroupHeading from '@/components/ui/FormInputGroupHeading.vue'
import FormFieldSelectVenue from '@/components/input/FormFieldSelectVenue.vue'
import FormFieldSelectVenueSpace from '@/components/input/FormFieldSelectVenueSpace.vue'

const props = defineProps({
  form: {
    type: [VendorSpot, Object],
    default: () => ({}),
  },
})

const eventDays = ref([])
const locations = ref([])
const eventSearchQuery = ref('')
const venueSearchQuery = ref('')
const stationSearchQuery = ref('')

function loadEvent(v) {
  axios.get(`/api/autocomplete/events/${v}/locations`).then((res) => {
    locations.value = res.data
  })
  axios.get(`/api/autocomplete/events/${v}/days`).then((res) => {
    eventDays.value = res.data

    props.form.schedule = res.data.map((d) => {
      return {
        day_id: d.id,
        starts_at: d.start_time,
        ends_at: d.end_time,
      }
    })
  })
}

watch(
  () => props.form.convention_event,
  (v) => {
    locations.value = []
    eventDays.value = []
    if (v) loadEvent(v.id)
  }
)

function onChangeLocationType(e) {
  if (!props.form.location) {
    props.form.location = {}
  }

  switch (e) {
    case 'map_point':
      props.form.location.latitude = 0
      props.form.location.longitude = 0
      props.form.location.type = 'map_point'
      props.form.location.id = null
      break
    case 'address':
      props.form.location.type = 'address'
      props.form.location.id = null
      props.form.location.address = new Address()
      break
    case 'venue_space':
      props.form.location.type = 'venue_space'
      props.form.location.id = null
      break
  }
}

onMounted(() => {
  if (props.form.convention_event) {
    eventSearchQuery.value = props.form.convention_event.name
    loadEvent(props.form.convention_event.id)
  }
})
</script>

<style scoped></style>
