import { useEcho } from '@/utils/echo'
import useAuth from '@/utils/composables/auth'
import { Ref, ref } from 'vue'
import axios from 'axios'
import { useModal } from '@/utils/modal'
import ImportResultsView from '@/components/view/import/ImportResultsView.vue'

const echo = useEcho()
const notifications = ref<any[]>([])
const latestNotification = ref<any>(null)
const notificationsCount = ref<number>(0)
const unreadNotificationsCount = ref<number>(0)
let channel: any = null

export default function useNotifications(): {
  notifications: Ref<any[]>
  latestNotification: Ref<any>
  count: Ref<number>
  unreadCount: Ref<number>
  loadReadNotifications: () => Promise<void>
  read: (notification: any) => void
} {
  const modalHandler = useModal()
  const { user, onLoaded } = useAuth()

  onLoaded().then(() => {
    // unauthorized
    if (!user.value) {
      throw new Error('unauthorized')
    }

    if (!channel) {
      channel = echo
        .private('App.Models.User.' + user.value.id)
        .notification((notification: any) => {
          if (!notification.created_at)
            notification.created_at = new Date().toISOString()

          latestNotification.value = notification
          if (notification.type === 'data-import.finished') {
            const index = notifications.value.findIndex(
              (n) => n.import_id === notification.import_id
            )

            if (index !== -1) {
              notifications.value[index] = notification
            } else {
              notifications.value.unshift(notification)
              notificationsCount.value++
            }
          } else {
            notifications.value.unshift(notification)
            notificationsCount.value++
          }
        })

      axios
        .get('/api/user/notifications')
        .then((response) => {
          notifications.value = response.data.notifications
          notificationsCount.value = response.data.count
          unreadNotificationsCount.value = response.data.unread_count
        })
        .catch((error) => {
          console.log(error)
        })
    }
  })

  return {
    notifications,
    latestNotification,
    count: notificationsCount,
    unreadCount: unreadNotificationsCount,
    loadReadNotifications() {
      return axios
        .get('/api/user/notifications?all=true')
        .then((response) => {
          notifications.value = response.data.notifications
          notificationsCount.value = response.data.count
          unreadNotificationsCount.value = response.data.unread_count
        })
        .catch((error) => {
          console.log(error)
        })
    },
    read(notification: any) {
      if (notification.type?.startsWith('data-import')) {
        console.log('import')
        modalHandler.open(ImportResultsView, {
          props: {
            size: 'lg',
            position: 'center-center',
            dataImportId: notification.import_id,
          },
        })
      }
    },
  }
}
