/**
 * Pluralize a word based on a count
 * @param count The count to base the pluralization on
 * @param word The word to pluralize
 * @param plural The plural form of the word (if not provided, 's' will be appended to the word)
 */
export function pluralize(
  count: number,
  word: string,
  plural: string = ''
): string {
  return count === 1 ? word : plural || `${word}s`
}

/**
 * Divides a string into segments of a specified width, replacing spaces with a specified string.
 * If a segment exceeds the specified width, it searches for the last space or hyphen to break the string.
 * If a hyphen is found, it is included at the end of the segment.
 *
 * @param {string} str - The string to be divided.
 * @param {number} width - The maximum width of each segment.
 * @param {string} spaceReplacer - The string to replace spaces with.
 * @returns {string} The divided string.
 */
export function stringDivider(
  str: string,
  width: number,
  spaceReplacer: string
): string {
  if (str.length > width) {
    let p = width
    for (; p > 0 && str[p] != ' ' && str[p] != '-'; p--) { /* empty */ }
    if (p > 0) {
      let left
      if (str.substring(p, p + 1) == '-') {
        left = str.substring(0, p + 1)
      } else {
        left = str.substring(0, p)
      }
      const right = str.substring(p + 1)
      return left + spaceReplacer + stringDivider(right, width, spaceReplacer)
    }
  }
  return str
}
