<template>
  <div class="relative w-[90vw] md:w-[710px]">
    <button @click="closeModal">
      <font-awesome-icon
        icon="xmark"
        class="absolute right-8 top-8 h-6 w-6 shrink-0 text-slate-800"
      />
    </button>
    <div class="flex max-h-[75vh] flex-col gap-10 overflow-scroll p-4 md:p-10">
      <avatar-image
        :image="form.avatar"
        class="mx-auto flex h-[184px] w-[184px] shrink-0 rounded-full"
      />
      <div class="flex flex-col gap-5">
        <input-field
          v-model="form.name"
          :readonly="readonly"
          :error="form.errors.name"
          label="Name"
          variant="default"
          class="w-full border-gray-500 text-gray-500"
        />

        <input-field
          v-model="form.location"
          :readonly="readonly"
          :error="form.errors.location"
          label="Location"
          variant="default"
          class="w-full border-gray-500 text-gray-500"
        />

        <input-field
          v-model="form.whyJoin"
          :aria-readonly="readonly"
          :error="form.errors.whyJoin"
          label="Why join"
          variant="default"
          class="w-full border-gray-500 text-gray-500"
        />
        <tag-selector
          v-model="form.tags"
          title="Favourite fandoms"
          :highlighted-tags="highlightedTags"
        />
      </div>

      <div class="z-[2] flex items-center gap-2">
        <button
          v-if="!readonly"
          :class="
            form.rulesAgreement
              ? 'border border-slate-200 bg-blue-primary'
              : 'border border-slate-200 bg-text-white'
          "
          class="flex h-6 w-6 shrink-0 items-center justify-center rounded"
          @click="form.rulesAgreement = !form.rulesAgreement"
        >
          <check-icon class="text-text-white" />
        </button>
        <button
          v-if="readonly"
          :class="
            form.rulesAgreement
              ? 'border border-slate-200 bg-blue-primary'
              : 'border border-slate-200 bg-text-white'
          "
          class="flex h-6 w-6 shrink-0 items-center justify-center rounded"
        >
          <check-icon class="text-text-white" />
        </button>
        <p>
          {{
            form.name.length && !readonly
              ? `${form.name} has read and agreed to the group's rules`
              : `You have read and agreed to the group's rules`
          }}
        </p>
      </div>
    </div>

    <div
      class="flex w-full items-center gap-4 border-t border-gray-600 bg-text-white px-4 py-4 md:px-10"
    >
      <v-button
        variant="secondary"
        class="w-full max-w-[160px]"
        @click="closeModal()"
      >
        Decline
      </v-button>
      <v-button
        variant="primary"
        class="w-full max-w-[160px]"
      >
        Accept
      </v-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'
import { useModal } from '@/utils/modal'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import CheckIcon from '@/icons/CheckIcon.vue'

import VButton from '@/components/buttons/VButton.vue'
import AvatarImage from '@/components/ui/AvatarImage.vue'
import InputField from '@/components/input/InputField.vue'
import TagSelector from '@/components/input/TagSelector.vue'

const props = defineProps({
  avatar: {
    type: Object,
    default: () => {},
  },
  name: {
    type: String,
    default: '',
  },
  location: {
    type: String,
    default: '',
  },
  whyJoin: {
    type: String,
    default: '',
  },
  tags: {
    type: Array,
    default: () => [],
  },
  rulesAgreement: {
    type: Boolean,
    default: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
})
const highlightedTags = ['tag1, tag2']
const form = useForm({
  avatar: { path: '' },
  name: '',
  location: '',
  whyJoin: '',
  tags: [],
  rulesAgreement: false,
})

const modalHandler = useModal()

const closeModal = () => {
  modalHandler.closeAll()
}
</script>

<style scoped></style>
