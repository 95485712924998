<template>
  <div class="flex flex-col">
    <div class="flex items-center mb-2">
      <div class="flex gap-3 text-sm">
        <div v-if="addresses.length">
          {{ addresses.length }}
          {{ pluralize(addresses.length, 'Address', 'Addresses') }}
        </div>
        <div v-if="mapPoints.length">
          {{ mapPoints.length }}
          {{ pluralize(mapPoints.length, 'Map Points', 'Map Points') }}
        </div>
        <div v-if="venueSpaces.length">
          {{ venueSpaces.length }}
          {{ pluralize(venueSpaces.length, 'Venue Space', 'Venue Spaces') }}
        </div>
      </div>
      <button-plus
        v-if="event?.vendor_spots?.length"
        class="ml-auto flex items-center gap-2 p-2 text-sm"
        @click="expanded = !expanded"
      >
        {{ expanded ? 'Simplify' : 'Expand' }}
      </button-plus>
      <button-plus
        v-else
        class="flex items-center gap-2 p-2 text-sm"
        @click="emit('create')"
      >
        <template #text>
          Add location
        </template>
      </button-plus>
    </div>
    <template v-if="expanded">
      <div
        v-for="spot in event.vendor_spots"
        :key="spot.id"
        class="hover:bg-gray-100 px-2 py-3 rounded duration-100"
        @click="emit('open', spot.id)"
      >
        <h4>{{ spot.name }}</h4>
        <div v-if="spot.location?.type === 'map_point'">
          <map-preview
            class="h-32"
            disable-controls
            :latitude="+spot.location.latitude"
            :longitude="+spot.location.longitude"
          />
        </div>
        <div v-else-if="spot.location?.type === 'address'">
          <address-presenter :address="spot.location.address" />
        </div>
        <div v-else-if="spot.location?.type === 'venue_space'">
          <p class="text-sm">
            {{ spot.location.venue.name }} {{ spot.location.venue_space.name }}
          </p>
          <address-presenter
            :address="spot.location.address"
            class="text-gray-dark"
          />
        </div>
        <div v-else>
          <pre>{{ spot }}</pre>
        </div>
      </div>
      <div class="mt-3">
        <button-plus
          class="flex items-center gap-2 p-2 text-sm"
          @click="emit('create')"
        >
          <template #text>
            Add location
          </template>
        </button-plus>
      </div>
    </template>
  </div>
</template>

<script setup>
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import MapPreview from '@/components/ui/MapPreview.vue'
import AddressPresenter from '@/components/ui/AddressPresenter.vue'
import { computed, ref } from 'vue'
import { pluralize } from '@/utils/string'

const emit = defineEmits(['open', 'create'])

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
})

const expanded = ref(false)

const addresses = computed(() => {
  return props.event?.vendor_spots?.filter((s) => s.location.type === 'address') ?? []
})

const mapPoints = computed(() => {
  return props.event?.vendor_spots?.filter((s) => s.location.type === 'map_point') ?? []
})

const venueSpaces = computed(() => {
  return props.event?.vendor_spots?.filter(
    (s) => s.location.type === 'venue_space'
  ) ?? []
})
</script>

<style scoped></style>