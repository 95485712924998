import { Model } from './Model'
import User from '@/models/User'
import ConventionEvent from '@/models/ConventionEvent'
import axios from 'axios'
import { Team } from '@/models/Team'

export default class Organisation extends Model {
  id: number | null = null
  slug: string = ''
  name: string = ''

  owner_id: number | null = null
  owner: User | null = null

  events: ConventionEvent[] = []

  teams: Team[] = []
  members: User[] = []
  invitations: object[] = []
  roles: object[] = []

  public fetchTeams() {
    return axios
      .get(`/api/partner/organisations/${this.id}/teams`)
      .then((response) => {
        this.teams = response.data
        return response
      })
  }

  public fetchMembers() {
    return axios
      .get(`/api/partner/organisations/${this.id}/members`)
      .then((response) => {
        this.members = response.data
        return response
      })
  }

  public fetchInvitations() {
    return axios
      .get(`/api/partner/organisations/${this.id}/invitations`)
      .then((response) => {
        this.invitations = response.data
        return response
      })
  }

  public fetchRoles() {
    return axios
      .get(`/api/partner/organisations/${this.id}/roles`)
      .then((response) => {
        this.roles = response.data
        return response
      })
  }

  public inviteMember(email: string) {
    return axios
      .post(`/api/partner/organisations/${this.id}/invitations`, {
        email,
      })
      .then((response) => {
        this.invitations.push(response.data)
        return response
      })
  }
}
