<template>
  <div class="w-[85vw] md:w-[593px]">
    <div
      class="mx-auto flex h-[360px] w-full flex-col items-center gap-6 overflow-scroll px-4 py-8 sm:px-6 lg:px-8"
    >
      <h4 class="text-2xl font-bold text-black">Upload Picture</h4>
      <div class="flex w-full flex-col items-start gap-6">
        <label class="text-base font-bold text-paragraph">
          Upload Pictures
        </label>
        <form-drop-file-input
          v-model="form.pictures"
          class="h-40 w-full"
          image-aspect-ratio="1"
          image-resize
          multiple
          image-type
        />
      </div>
    </div>

    <div class="w-full border-t border-gray-500">
      <div
        class="mx-auto my-4 flex w-full justify-between px-4 sm:px-6 md:max-w-[406px] md:px-0"
      >
        <button
          class="py-4 text-base font-bold text-blue-primary"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          class="rounded-lg bg-blue-primary px-8 py-4 text-base font-bold text-text-white md:px-12"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3'
import { useModal } from '@/utils/modal'

import InputField from '@/components/input/InputField.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'

const modalHandler = useModal()

const closeModal = () => {
  modalHandler.closeAll()
}

const form = useForm({
  pictures: null,
})
</script>

<style lang="scss" scoped></style>
