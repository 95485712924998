<template>
  <li class="flex flex-col">
    <div
      class="flex items-center gap-3 py-2 pl-1 focus-within:!bg-slate-200 hover:bg-slate-100"
      @click.self="input.focus()"
    >
      <button
        :class="[
          'flex h-6 w-6 shrink-0 items-center justify-center rounded-full border',
          task.completed
            ? 'border-blue bg-blue text-white hover:border-blue-600 hover:bg-blue-600'
            : 'border-gray-500 text-gray-500 hover:border-blue hover:text-blue',
        ]"
        :title="task.completed ? 'Mark as incomplete' : 'Mark as complete'"
        @click="task.completed = !task.completed"
      >
        <font-awesome-icon
          class="h-4 w-4"
          icon="check"
        />
      </button>
      <app-input
        ref="input"
        v-model="task.name"
        :class="{
          'text-gray-800': task.completed,
        }"
        placeholder="Task name"
        @keydown.tab.exact.prevent="emit('move', 'right')"
        @keydown.shift.tab.exact.prevent="emit('move', 'left')"
        @keydown.enter.exact.prevent="emit('add', 'below')"
        @keydown.shift.enter.exact.prevent="emit('add', 'above')"
      />

      <div class="flex w-fit shrink-0 items-center gap-4">
        <button
          class="-mr-4 flex w-fit select-none items-center text-slate-500"
          title="Add subtask"
          @click="
            () => {
              task.subtasks.push({
                name: '',
                subtasks: [],
                showSubtasks: false,
                completed: false,
                selected: false,
              } as Task)
              task.showSubtasks = true
            }
          "
        >
          <font-awesome-icon
            class="h-3 w-3"
            icon="plus"
          />
        </button>
        <button
          class="relative flex h-6 w-6 shrink-0 items-center justify-center gap-1"
          title="Show subtasks"
          @click="task.showSubtasks = !task.showSubtasks"
        >
          <span
            v-if="task?.subtasks?.length"
            class="absolute -top-[10px] left-[12px] rounded-full border border-slate-200 bg-white px-[5px] text-xs text-slate-500"
          >
            {{ task?.subtasks?.length }}
          </span>
          <svg
            class="h-4 w-4 shrink-0"
            fill="grey"
            height="16"
            viewBox="0 0 512 512"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 128h416c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H16C7.2 32 0 39.2 0 48v64c0 8.8 7.2 16 16 16zm480 80H80c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16zm-64 176H16c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16z"
            />
          </svg>
        </button>
        <form-field-select-user
          v-model:selected="task.asignee"
          :label="false"
          mini-mode
          show-button-by-default
          single-select
          title="Assignee"
        />
        <form-field-date-picker
          v-if="task?.release_date"
          v-model="task.release_date"
          mini-mode
          title="Select date"
          variant="gray"
        />
        <button
          class="-mt-1 h-fit w-fit"
          title="Edit task"
          @click="emit('open')"
        >
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            class="h-4 w-4 border-gray-500 text-gray-500"
          />
        </button>
        <button
          title="Remove task"
          @click="emit('remove')"
        >
          <font-awesome-icon
            class="h-5 w-5 border-gray-500 text-gray-500"
            icon="times"
          />
        </button>
      </div>
    </div>

    <task-list
      v-if="task.showSubtasks"
      ref="list"
      :level="level + 1"
      :tasks="task.subtasks"
      class="ml-8"
      @move-task-to-parent="emit('addTask', $event)"
    />
  </li>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import AppInput from '@/components/input/AppInput.vue'
import FormFieldSelectUser from '@/components/input/FormFieldSelectUser.vue'
import FormFieldDatePicker from '@/components/input/FormFieldDatePicker.vue'
import { onBeforeMount, ref } from 'vue'
import Task from '@/models/Task'
import TaskList from '@/components/forms/task/TaskList.vue'

const emit = defineEmits(['remove', 'open', 'add', 'move', 'addTask'])

const props = defineProps({
  task: {
    type: [Object, Task],
    required: true,
  },
  level: {
    type: Number,
    default: 0,
  },
})
const input = ref()
const list = ref()

onBeforeMount(() => {
  if (props.level === 0) {
    props.task.showSubtasks = true
  }
})

defineExpose({ input, list })
</script>

<style lang="scss" scoped></style>
