<template>
  <teleport to="body">
    <transition
      name="fade"
      @enter="innerModalOpen = true"
      @after-leave="onModalClosed"
    >
      <div
        v-if="outerModalOpen"
        :class="{
          'bg-black bg-opacity-25': !backdropDisabled,
        }"
        :data-modal-id="$.uid"
        class="fixed inset-0 z-modal flex cursor-pointer select-none items-center justify-center"
        @click.self="onBackdropClick"
      >
        <transition
          enter-active-class=""
          enter-from-class="-translate-y-[25px] opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class=""
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="-translate-y-[25px] opacity-0"
          @leave="outerModalOpen = false"
        >
          <div
            v-if="innerModalOpen"
            :class="modalClass"
            class="flex min-h-[150px] min-w-[200px] max-w-[90%] cursor-auto flex-col overflow-hidden rounded-lg bg-white duration-300 ease-in-out"
          >
            <div
              class="whitespace-nowrap border-b bg-gray-100 py-2 pl-3 pr-6 text-center"
            >
              <slot name="header">
                {{ header }}
              </slot>
            </div>
            <slot>
              <div
                v-if="message"
                class="flex grow items-center px-3 py-2"
              >
                {{ message }}
              </div>
              <div
                v-if="$slots.inputs"
                class="grow px-3 py-2"
              >
                <template
                  v-for="(input, index) in inputs"
                  :key="index"
                >
                  <form-field-input
                    v-model="input.value"
                    :label="input.label ?? null"
                    :layout="input.layout ?? 'col'"
                    :placeholder="input.placeholder ?? null"
                    :variant="input.variant ?? 'gray'"
                  />
                </template>
              </div>
            </slot>
            <div
              v-if="secondaryButton || primaryButton"
              class="flex justify-end gap-2 px-2 py-2"
            >
              <v-button
                v-if="secondaryButton"
                class="rounded-lg text-sm"
                variant="secondary"
                @click="emit('click:secondary')"
              >
                {{ secondaryButton }}
              </v-button>
              <v-button
                v-if="primaryButton"
                :variant="variant"
                class="rounded-lg text-sm"
                @click="emit('click:primary')"
              >
                {{ primaryButton }}
              </v-button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import VButton from '@/components/buttons/VButton.vue'

const emit = defineEmits([
  'update:open',
  'close',
  'closed',
  'click:primary',
  'click:secondary',
])
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  modalClass: {
    type: String,
    default: '',
  },
  backdropDisabled: {
    type: Boolean,
    default: false,
  },
  header: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
  primaryButton: {
    type: String,
    default: '',
  },
  secondaryButton: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'danger'].includes(value),
  },
  inputs: {
    type: Array,
    default: () => [],
  },
})

const innerModalOpen = ref(false)
const outerModalOpen = ref(false)

function onModalClosed() {
  emit('closed')
}

function onBackdropClick() {
  emit('close', 'backdrop')
}

onMounted(() => {
  if (props.open) {
    outerModalOpen.value = true
    if (!props.backdropDisabled) document.body.style.overflow = 'hidden'
  }
})

watch(
  () => props.open,
  (value) => {
    if (value) {
      outerModalOpen.value = true
      if (!props.backdropDisabled) document.body.style.overflow = 'hidden'
    } else {
      innerModalOpen.value = false
      if (!props.backdropDisabled) document.body.style.overflow = 'auto'
    }
  }
)
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: background-color 0.25s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  background-color: rgba(0, 0, 0, 0);
}
</style>
