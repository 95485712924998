<template>
  <div class="relative flex flex-col">
    <label
      :class="labelClass"
      :for="$.uid"
      class="select-none pl-1 font-heading text-base"
    >
      {{ label }}
    </label>
    <div
      :id="$.uid"
      :class="selectClass"
      class="relative cursor-pointer select-none"
      @click="toggleDropdown"
    >
      <div class="flex items-center justify-between">
        <span>{{ selectedOption.label || placeholder }}</span>
        <chevron-down-icon
          :class="dropdownVisible ? 'rotate-180' : ''"
          aria-hidden="true"
          class="ml-2 h-5 w-5 text-gray-400"
        />
      </div>
      <div
        v-if="dropdownVisible"
        class="absolute z-10 mt-4 max-h-[150px] w-full overflow-scroll bg-white shadow-lg"
      >
        <div
          v-for="option in options"
          :key="option.value"
          :disabled="option.disabled"
          class="cursor-pointer p-2 hover:text-blue-primary"
          @click.stop="selectOption(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
    <validation-error :error="error" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import ValidationError from '@/components/ui/ValidationError.vue'

import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  labelClass: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number, null],
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  error: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectFirst: {
    type: Boolean,
    default: false,
  },
  underline: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
})

onMounted(() => {
  if (
    props.selectFirst &&
    props.options.length > 0 &&
    (props.modelValue === null || props.modelValue === 0)
  ) {
    emit('update:modelValue', props.options[0].value)
  }
})

const dropdownVisible = ref(false)
const selectedOption = computed(
  () => props.options.find((option) => option.value === props.modelValue) || {}
)

const selectClass = computed(() => {
  if (props.underline)
    return 'rounded-none block py-2.5 px-0 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer'
  else return 'border-bottom-1-5 py-2 px-3'
})

const toggleDropdown = () => {
  if (!props.disabled) {
    dropdownVisible.value = !dropdownVisible.value
  }
}

const selectOption = (option) => {
  if (!option.disabled) {
    emit('update:modelValue', option.value)
    dropdownVisible.value = false
  }
}
</script>

<style scoped></style>
