<template>
  <modal-dialog-layout :title="title">
    <div class="relative">
      <div
        v-if="loading"
        class="absolute inset-0 z-topmost flex min-h-[400px] items-center justify-center bg-white"
      >
        <app-spinner />
      </div>
      <vue-picture-cropper
        v-if="imageFile"
        :img="imageFile"
        :options="{
          dragMode: 'none',
          aspectRatio: ratio,
          autoCrop: true,
          autoCropArea: 1,
          background: false,
        }"
      />
    </div>
    <template #footer>
      <div class="flex gap-3 p-2">
        <v-button
          size="md"
          variant="secondary"
          @click="emit('close')"
        >
          <font-awesome-icon
            class="mr-2"
            icon="chevron-left"
          />
          Cancel
        </v-button>
        <v-button
          class="ml-auto"
          size="md"
          variant="secondary"
          without-border
          @click="reset"
        >
          Reset
        </v-button>
        <v-button
          size="md"
          variant="primary"
          @click="confirm"
        >
          Upload
        </v-button>
      </div>
    </template>
  </modal-dialog-layout>
</template>

<script lang="ts" setup>
import ModalDialogLayout from '@/components/modal/layout/ModalDialogLayout.vue'
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { onMounted, ref } from 'vue'
import VButton from '@/components/buttons/VButton.vue'
import AppSpinner from '@/components/ui/AppSpinner.vue'

const emit = defineEmits(['close', 'save'])

const props = defineProps({
  title: {
    type: String,
    default: 'Upload Image',
  },
  file: {
    type: File,
    required: true,
  },
  ratio: {
    type: Number,
    default: () => undefined,
  },
})

const imageFile = ref<string>('')
const loading = ref<boolean>(false)

function setFile(file: File) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    imageFile.value = String(reader.result)
  }
}

function reset() {
  cropper?.reset()
}

function confirm() {
  loading.value = true
  requestAnimationFrame(async () => {
    if (!cropper) return
    const base64 = cropper.getDataURL()
    const blob: Blob | null = await cropper.getBlob()
    if (!blob) return

    const file = await cropper.getFile({
      fileName: 'file',
    })

    emit('save', {
      dataUrl: base64,
      blobUrl: URL.createObjectURL(blob),
      file: file,
    })
    emit('close')
  })
}

onMounted(() => {
  if (props.file) {
    setFile(props.file)
  }
})
</script>

<style scoped></style>
