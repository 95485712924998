import {Model} from "@/models/Model";
import User from "@/models/User";

export default class Task extends Model {
    id: number | null = null;
    completed: boolean = false;
    name: string = '';
    description: string = '';
    status: string = '';
    created_at: string = '';
    updated_at: string = '';

    parentable_id: number | null = null;
    parentable_type: string = '';

    assignable_id: number | null = null;
    assignable_type: string = '';

    subtasks: Task[] = [];
    parent: Task | null = null;
    assignee: User | null = null;
}