<template>
  <!--
        teleport will move this code to body instead of placing inside parent component,
        this is necessary to avoid multiple modals overlapping issues
     -->
  <teleport to="body">
    <transition :name="animation">
      <div
        v-if="isPopupOpen"
        class="fixed inset-0 z-[35] flex flex-col ease-in-out"
        :class="[
          backdropDisabled
            ? props.backdropClass
            : doubleTeleport // if double teleport is enabled, then this will be transparent and backdrop will be in the other teleport
              ? 'inset-0'
              : 'bg-black bg-opacity-25 ' + props.backdropClass,
        ]"
        @click.self="backdropDisabled ? '' : emit('close')"
      >
        <!--
                double teleport is used to avoid multiple modals overlapping issues
                when using multiple modals in the same page
                This is the actual backdrop with opacity
        -->
        <teleport
          v-if="doubleTeleport"
          to="body"
        >
          <div
            v-if="!backdropDisabled && doubleTeleport"
            :class="[
              backdropClass
                ? props.backdropClass
                : 'inset-0 bg-black bg-opacity-25',
            ]"
            class="fixed inset-0 z-[50]"
          />
        </teleport>
        <!-- TODO: refactor this -->
        <div
          :class="[
            props.direction !== 'custom' &&
              !wide &&
              type !== 'Activity Details' &&
              type !== 'Event List'
              ? 'max-w-full sm:max-w-md md:w-full md:max-w-xl lg:w-full lg:max-w-screen-md xl:max-w-screen-lg'
              : '',
            wide && type !== 'Activity Details' && type !== 'Event List'
              ? 'max-w-full md:max-w-3xl lg:w-full lg:max-w-4xl xl:max-w-6xl'
              : '',
            type === 'Activity Details'
              ? 'top-0 max-h-[100vh] w-full max-w-[960px] overflow-scroll'
              : popupClasses,
            props.class,
            'z-[60] h-full',
            wide && type === 'Event List'
              ? 'w-full sm:w-[95%] md:max-w-[840px]'
              : '',
          ]"
        >
          <slot />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import { computed, watchEffect } from 'vue'

const emit = defineEmits(['close'])

const props = defineProps({
  isPopupOpen: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: 'custom',
  },
  class: {
    type: String,
    default: '',
  },
  backdropClass: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  backdropDisabled: {
    type: Boolean,
    default: false,
  },
  animation: {
    type: String,
    default: 'none',
  },
  popupAnimation: {
    type: String,
    default: 'none',
  },
  wide: {
    type: Boolean,
    default: false,
  },
  doubleTeleport: {
    type: Boolean,
    default: false,
  },
})

const popupClasses = computed(() => {
  if (props.direction === 'left') {
    return 'fixed inset-y-0 left-0'
  } else if (props.direction === 'right') {
    return 'fixed bottom-0 top-16 right-0'
  } else if (props.direction === 'top') {
    return 'fixed top-16 bottom-0'
  } else if (props.direction === 'bottom') {
    return 'fixed inset-x-0 bottom-0'
  } else if (props.direction === 'center') {
    return 'absolute left-1/2 -translate-x-1/2'
  } else if (props.direction === 'custom') {
    return 'absolute'
  } else {
    throw new Error('Invalid modal direction ' + props.direction)
  }
})

const disableScroll = () => {
  document.body.style.overflow = 'hidden'
  document.body.style.height = '100vh'
}

const enableScroll = () => {
  document.body.style.overflow = ''
  document.body.style.height = ''
}

watchEffect(() => {
  if (props.isPopupOpen) {
    disableScroll()
  } else {
    enableScroll()
  }
})
</script>

<style scoped>
.slide-enter-active {
  transition: all 250ms ease;
}

.slide-leave-active {
  transition: all 100ms ease;
}

.slide-leave-to,
.slide-enter-from {
  transform: translateX(100%);
}

.slide-leave-from,
.slide-enter-to {
  transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 150ms ease-out;
}

.slide-up-enter-from {
  transform: translateY(100%);
}

.slide-up-leave-to {
  transform: translateY(100%);
}
</style>
