import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, ComputedRef, MaybeRefOrGetter, ref, watch } from 'vue'
import axios from 'axios'

interface TableColumn {
  /**
   * The column's label visible in the table header.
   */
  label: string
  /**
   * The column's field name for referencing the value in the row.
   */
  field: string
  /**
   * Makes the column sortable.
   */
  sortable?: boolean
  /**
   * Makes the column filterable.
   */
  filterable?: boolean
}

interface TableOptions {
  queryKey?: any[]
  queryFn?: (params: any) => Promise<any>
  baseUrl: string
  columns?: TableColumn[]
  filters?: Record<string, any>
  sort?: string
  computedFilters?: ComputedRef<Record<string, any>>
  perPage?: number
  enabled?: MaybeRefOrGetter<boolean>
}

/**
 * TODO: rename to useInfiniteList because it's used not only for tables
 * @param queryKey
 * @param queryFn
 * @param baseUrl
 * @param columns
 * @param initialSort
 * @param initialFilters
 * @param computedFilters
 * @param perPage
 * @param enabled
 */
export function useInfiniteTable({
  queryKey,
  queryFn,
  baseUrl,
  columns,
  sort: initialSort,
  filters: initialFilters,
  perPage,
  enabled,
}: TableOptions) {
  const sort = ref<string | null>(initialSort ?? 'id.asc')
  const filters = ref<object>(initialFilters ?? {})

  const queryClient = useQueryClient()

  const availableFiltersCache = ref<any>(undefined)
  const metaCache = ref<any>(undefined)

  const { isLoading, isFetching, data, isError, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      enabled: enabled ?? true,
      queryKey: ['list', queryKey ?? baseUrl, sort, filters],
      queryFn: async ({ pageParam = 1, queryKey }) => {
        const [_name, _key, sort, filters] = queryKey
        const params = {
          ...(filters as Record<string, any>),
          page: pageParam,
          per_page: perPage ?? 12,
          sort,
        }
        console.log(params)
        const { data } = await (queryFn
          ? queryFn(params)
          : axios.get(baseUrl, {
              params,
            }))

        if (data.meta) metaCache.value = data.meta
        if (data.filters) availableFiltersCache.value = data.filters

        return data
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        if (!lastPage.meta) return 2
        return lastPage.meta.last_page > lastPage.meta.current_page
          ? lastPage.meta.current_page + 1
          : undefined
      },
    })

  const items = computed(
    () => data.value?.pages.flatMap((page) => page.data ?? page) ?? []
  )

  // watch(() => data.value, (pages) => {
  //   console.log(pages)
  // }, {deep: true})
  //
  // watch(() => data.value?.pages?.[0]?.filters, (filters) => {
  //   console.log(filters)
  //   if (filters) availableFiltersCache.value = filters
  // })
  //
  // watch(() => data.value?.pages?.at(-1)?.meta, (meta) => {
  //   console.log(meta)
  //   if (meta) metaCache.value = meta
  // })

  return {
    loading: isLoading,
    fetching: isFetching,
    error: isError,
    data,
    items,
    columns,
    sort,
    filters,
    hasNextPage,
    total: computed(() => metaCache.value?.total ?? 0),
    fetchNextPage: () => {
      if (isLoading.value || isFetching.value) return
      if (hasNextPage.value) fetchNextPage().then((r) => r)
    },
    refetch: () =>
      queryClient.invalidateQueries({
        queryKey: [baseUrl, sort, filters],
      }),
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: [baseUrl],
      }),
    availableFilters: computed(() => data.value?.pages?.[0]?.filters ?? {}),
  }
}
