import { Model } from '@/models/Model'

export default class Image extends Model {
  id: number | null = null
  path: string = ''
  preload_url: string | null = null
  md_path: string | null = null
  sm_path: string | null = null
  xs_path: string | null = null
  file: File | null = null
  alt: string = ''
  caption: string = ''
  creator_credit: string = ''
  dimensions: {
    width: number
    height: number
  } = {
    width: 0,
    height: 0
  }

  public static fromFile(file: File): Image {
    const image = new Image()
    image.file = file
    image.path = URL.createObjectURL(file)
    image.alt = file.name
    return image
  }
}
