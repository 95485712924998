<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <form-field-header-input class="w-full">
        <template #input>
          <form-field-input
            v-model="form.name"
            :error="form.errors.name"
            class="grow"
            input-class="font-bold"
            placeholder="Enter Raffle Name"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <div class="flex flex-col gap-6">
      <form-input-group-layout>
        <input-layout
          :error="form.errors.caption"
          label="Caption"
          layout="grid"
          variant="gray"
        >
          <form-field-input
            v-model="form.caption"
            placeholder="Caption"
          />
        </input-layout>
        <input-layout
          :error="form.errors.description"
          label="Description"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.description"
            placeholder="Description"
            rows="3"
          />
        </input-layout>
        <input-layout
          :error="form.errors.images"
          label="Images"
          layout="grid"
          variant="gray"
        >
          <form-field-image
            v-model="form.images"
            class="h-16"
            multiple
          />
        </input-layout>
        <input-layout
          :error="form.errors.minimum_raffle_tickets"
          label="Minimum Raffle Tickets"
          layout="grid"
          optional
          variant="gray"
        >
          <form-field-input
            v-model="form.minimum_raffle_tickets"
            placeholder="Minimum Raffle Tickets"
            type="number"
          />
        </input-layout>
        <input-layout
          :error="form.errors.maximum_raffle_tickets"
          label="Maximum Raffle Tickets"
          layout="grid"
          optional
          variant="gray"
        >
          <form-field-input
            v-model="form.maximum_raffle_tickets"
            placeholder="Maximum Raffle Tickets"
            type="number"
          />
        </input-layout>
        <input-layout
          :error="form.errors.quantity"
          label="Winners Quantity"
          layout="grid"
          variant="gray"
        >
          <form-field-input
            v-model="form.quantity"
            placeholder="Winners Quantity"
            type="number"
          />
        </input-layout>
      </form-input-group-layout>
      <template v-if="form.winners?.length">
        <hr/>
        <form-input-group-layout label="Winners">
          <ul class="flex flex-col divide-y rounded-lg border">
            <li
              v-for="winner in form.winners"
              :key="winner.id"
              class="flex justify-between px-2 py-1 hover:bg-gray-100"
            >
              <p>{{ winner.name }}</p>
            </li>
          </ul>
        </form-input-group-layout>
      </template>
      <hr/>
      <form-input-group-layout label="Used Tickets">
        <div class="sticky top-0 bg-white py-1">
          <form-field-input-search
            v-model="userQuery"
            placeholder="Search a user"
          />
        </div>
        <small>
          {{ form.total_tickets_count }} tickets used by
          {{ form.users.length }} users
        </small>
        <ul class="flex flex-col divide-y rounded-lg border">
          <li
            v-for="user in form.users
              .slice()
              .filter((user) =>
                user.name.toLowerCase().includes(userQuery.toLowerCase())
              )
              .sort((a, b) => b.raffle_tickets_count - a.raffle_tickets_count)"
            :key="user.id"
            class="flex justify-between px-2 py-1 hover:bg-gray-100"
            @click="resourceModal.openEdit('user', user.id)"
          >
            <p>
              <span class="text-sm">{{ user.raffle_tickets_count }} x</span>
              {{ user.name }}
            </p>
            <button
              v-if="form.quantity > form.winners.length"
              @click.stop="
                axios
                  .post(
                    `/api/partner/conventions/${selectedEvent.id}/raffles/${form.raffle_id}/prizes/${form.id}/winners`,
                    {
                      user_id: user.id,
                    }
                  )
                  .then(() => {
                    form.winners.push(user)
                  })
              "
            >
              <font-awesome-icon icon="gift" />
            </button>
          </li>
        </ul>
      </form-input-group-layout>
    </div>
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import RafflePrize from '@/models/RafflePrize'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldImage from '@/components/input/FormFieldImage.vue'
import FormFieldInputSearch from '@/components/input/FormFieldInputSearch.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ref } from 'vue'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import useConventionList from '@/utils/composables/conventionList'
import axios from 'axios'

const props = defineProps({
  form: {
    type: [Object, RafflePrize],
    required: true,
  },
})

const { selected: selectedEvent } = useConventionList()

const userQuery = ref('')

const resourceModal = useResourceModal()
</script>

<style scoped></style>