import { isReadonly, ref, Ref } from 'vue'
import axios from 'axios'
import { EventActivity } from '@/models/EventActivity'
import useCachedResource from '@/utils/composables/cache'
import useAuth from '@/utils/composables/auth'
import { useModal } from '@/utils/modal'

const attendingActivities = ref<EventActivity[]>([])
const activityDetails = ref<EventActivity | null>(null)

export function useAttending(): {
  attending: Ref<number[] | null>
  attendingActivities: Ref<EventActivity[]>
  isAttending: (id: number) => boolean
  toggleAttending: (activity: EventActivity) => Promise<any>
  rsvp: (activity: EventActivity) => Promise<any>
  unrsvp: (activity: EventActivity) => Promise<any>
  fetchUserSchedule: (conventionEventId: number) => Promise<any>
  openModal: (activity: EventActivity) => void
} {
  const { isAuthenticated, id, user } = useAuth()
  const modalHandler = useModal()

  const resource = useCachedResource<number[]>(
    'attending',
    () => axios.get('/api/user/attending'),
    {
      force: true,
      enabled: isAuthenticated,
    }
  )

  const rsvp = (activity: EventActivity): Promise<any> => {
    if (!activity.id || !resource.data) throw new Error('Invalid activity')
    if (resource.data.value?.includes(activity.id))
      return new Promise((resolve) => resolve(true))
    resource.data.value?.push(activity.id)
    attendingActivities.value.push(activity)
    if (resource.data.value) resource.save(resource.data.value)

    if (
      !isReadonly(activity.attendee_count) &&
      !isReadonly(activity.attendees)
    ) {
      activity.attendee_count = activity.attendee_count
        ? activity.attendee_count + 1
        : 1
      if (user.value) activity.attendees?.push(user.value)
    }

    return axios.post(`/api/activities/${activity.id}/attend`)
  }

  const unrsvp = (activity: EventActivity): Promise<any> => {
    if (!activity.id || !resource.data.value)
      throw new Error('Invalid activity')
    if (!resource.data.value?.includes(activity.id))
      return new Promise((resolve) => resolve(true))
    resource.data.value = resource.data.value?.filter((i) => i !== activity.id)
    attendingActivities.value.splice(
      attendingActivities.value.findIndex((i) => i.id === activity.id),
      1
    )
    resource.save(resource.data.value)

    if (
      !isReadonly(activity.attendee_count) &&
      !isReadonly(activity.attendees)
    ) {
      activity.attendee_count = activity.attendee_count
        ? activity.attendee_count - 1
        : 0
      activity.attendees = activity.attendees?.filter((u) => u.id !== id)
    }

    return axios.delete(`/api/activities/${activity.id}/attend`)
  }

  return {
    attending: resource.data,
    attendingActivities: attendingActivities as Ref<EventActivity[]>,
    isAttending: (id: number) => {
      return resource.data.value?.includes(id) || false
    },
    toggleAttending: (activity: EventActivity): Promise<any> => {
      if (!activity.id || !resource.data.value)
        throw new Error('Invalid activity')
      if (resource.data.value?.includes(activity.id)) {
        return unrsvp(activity)
      } else {
        return rsvp(activity)
      }
    },
    rsvp,
    unrsvp,
    fetchUserSchedule: (conventionEventId: number) => {
      return axios
        .get<EventActivity[]>(`/api/user/attending`, {
          params: { convention: conventionEventId },
        })
        .then((response) => {
          attendingActivities.value = response.data
        })
    },
    openModal: (activity: EventActivity) => {
      const _activity = ref(activity)
      const _loading = ref(true)
      modalHandler.openType('activityDetail', {
        props: { activity: _activity, loading: _loading },
      })
      axios
        .get(`/api/activities/${activity.id}`)
        .then((response) => {
          _activity.value = response.data
          console.log(_activity.value.location)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          _loading.value = false
        })
    },
  }
}
