<template>
  <div :class="['flex flex-col', wrapperClass]">
    <!-- Label for File Input -->
    <label
      v-if="label"
      :for="inputId"
      class="mb-2 text-sm font-bold"
    >
      {{ label }}
    </label>

    <!-- File Input -->
    <input
      :id="inputId"
      ref="fileInput"
      type="file"
      :multiple="!singleFile"
      hidden
      @change="handleFileChange"
    />

    <!-- Trigger Button -->
    <button
      class="flex items-center gap-1 self-start rounded border border-dashed p-2 text-gray-500"
      @click="triggerFileInput"
    >
      <font-awesome-icon
        class="h-5 w-5"
        icon="plus"
      />
    </button>

    <!-- Display Selected Files -->
    <div
      v-if="displayFiles"
      class="mt-2"
    >
      <ul>
        <li
          v-for="(file, index) in selectedFiles"
          :key="index"
          class="flex items-center justify-between"
        >
          <span
            class="cursor-pointer text-sm"
            @click="viewFile(file)"
          >
            {{ file.name }}
          </span>
          <button
            class="text-red-500"
            @click="removeFile(index)"
          >
            <font-awesome-icon
              class="h-5 w-5 border-gray-500 text-gray-500"
              icon="times"
            />
          </button>
        </li>
      </ul>
    </div>

    <!-- Error Message -->
    <div
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  wrapperClass: {
    type: String,
    default: null,
  },
  displayFiles: {
    type: Boolean,
    default: false,
  },
  singleFile: {
    type: Boolean,
    default: false,
  },
  error: {
    type: [Boolean, String],
    default: null,
  },
  maxFileSize: {
    type: Number,
    default: 2 * 1024 * 1024, // 2MB default limit
  },
})
const fileInput = ref(null)
const selectedFiles = ref([])

const inputId = `file-input-${Math.random().toString(36).substr(2, 9)}`

function triggerFileInput() {
  fileInput.value.click()
}

function handleFileChange(event) {
  const files = Array.from(event.target.files)

  if (props.singleFile) {
    // Если выбран режим одного файла, заменяем все предыдущие файлы
    const file = files[0]
    if (file && file.size <= props.maxFileSize) {
      selectedFiles.value = [file]
    } else if (file) {
      alert(
        `File "${file.name}" is too large. Maximum size is ${
          props.maxFileSize / 1024 / 1024
        } MB.`
      )
    }
  } else {
    // Если выбран режим множественного выбора, добавляем новые файлы к существующим
    files.forEach((file) => {
      if (file.size <= props.maxFileSize) {
        if (!selectedFiles.value.some((f) => f.name === file.name)) {
          selectedFiles.value.push(file)
        }
      } else {
        alert(
          `File "${file.name}" is too large. Maximum size is ${
            props.maxFileSize / 1024 / 1024
          } MB.`
        )
      }
    })
  }

  emit('update:modelValue', selectedFiles.value)
}

function removeFile(index) {
  selectedFiles.value.splice(index, 1)
  emit('update:modelValue', selectedFiles.value)
}

function viewFile(file) {
  console.log('View file function called', file)

  if (file.type.startsWith('image/')) {
    // Display image
    const imageUrl = URL.createObjectURL(file)
    window.open(imageUrl, '_blank')
    URL.revokeObjectURL(imageUrl) // Cleanup
  } else if (file.type.startsWith('text/')) {
    // Display text
    const reader = new FileReader()
    reader.onload = (e) => {
      const textData = e.target.result
      const newWindow = window.open()
      newWindow.document.write(`<pre>${textData}</pre>`)
    }
    reader.readAsText(file)
  } else if (file.type === 'application/pdf') {
    // Display PDF
    const pdfUrl = URL.createObjectURL(file)
    window.open(pdfUrl, '_blank')
    URL.revokeObjectURL(pdfUrl) // Cleanup
  } else if (
    [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(file.type)
  ) {
    // Handle docx and xlsx files
    const fileUrl = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileUrl
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(fileUrl) // Cleanup
  }
}
</script>

<style scoped></style>
