import Organisation from '@/models/Organisation'
import axios from 'axios'
import useCachedResource from '@/utils/composables/cache'
import useConventionList from '@/utils/composables/conventionList'
import { computed } from 'vue'

export default function useOrganisation() {
  const conventions = useConventionList()
  const resource = useCachedResource<Organisation[]>(
    'user-organisations',
    () => axios.get<Organisation[]>('/api/partner/organisations'),
    {
      baseClass: Organisation,
    }
  )

  const selected = computed<Organisation | undefined>({
    get() {
      return resource.data.value?.find(
        (organisation) =>
          organisation.id === conventions.selected.value?.organisation_id
      )
    },
    set(organisation: Organisation | undefined) {
      throw new Error('Not implemented')
    },
  })

  return {
    ...resource,
    selected,
  }
}
