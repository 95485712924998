import { Model } from './Model'

export default class RafflePrize extends Model {
  id: number | null = null
  raffle_id: number | null = null
  name: string = ''
  description: string = ''
  images: object[] = []
  minimum_raffle_tickets: number = 0
  maximum_raffle_tickets: number = 0
  quantity: number = 1
  winners_count: number = 0

  total_tickets_count: number = 0
  users: object[] = []
  winners: object[] = []
}
