<template>
  <ul class="flex flex-col">
    <task-list-item
      v-for="(task, index) in tasks"
      :key="task.id ?? task._id"
      ref="taskElements"
      :data-index="index"
      :level="level"
      :task="task"
      @add="addTask(index, $event)"
      @move="moveTask(index, $event)"
      @open="
        taskModal.openEdit('task', task.id, {
          onSaved: (response) => {
            tasks[index] = response.data
          },
        })
      "
      @remove="tasks.splice(index, 1)"
      @add-task="addTask(index, 'below', $event)"
    />
  </ul>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import Task from '@/models/Task'
import TaskListItem from '@/components/forms/task/TaskListItem.vue'

const emit = defineEmits(['update:tasks', 'moveTaskToParent'])

const props = defineProps({
  tasks: {
    type: Array<Task>,
    required: true,
  },
  level: {
    type: Number,
    default: 0,
  },
})
const taskModal = useResourceModal()
const taskElements = ref()

function getTaskElement(index: number) {
  return taskElements.value.find((t) => t.$el.dataset.index == index)
}

function addTask(
  index: number,
  position: 'above' | 'below',
  task: Task | null = null
) {
  console.log('addTask', index, position, task)
  const newIndex = index + (position === 'below' ? 1 : 0)
  props.tasks?.splice(
    newIndex,
    0,
    task ?? {
      _id: Math.random().toString(36).substr(2, 9),
      name: '',
      description: '',
      completed: false,
      release_date: null,
      assignee: null,
      status: 'To do',
      subtasks: [],
    }
  )

  requestAnimationFrame(() => {
    getTaskElement(newIndex)?.input.focus()
  })
}

function moveTask(index: number, direction: 'left' | 'right') {
  if (direction === 'right') {
    if (index === 0) return // If this is the first task, it remains in place

    const previousTask = props.tasks[index - 1]
    const task = props.tasks[index]
    previousTask.subtasks.push(task) // Add a task to the subtasks of the previous task
    props.tasks.splice(index, 1) // Remove the task from the current task list

    previousTask.showSubtasks = true // Show the subtasks of the previous task
    requestAnimationFrame(() => {
      getTaskElement(index - 1)
        ?.list.taskElements.at(-1)
        .input.focus()
    })
  } else {
    if (props.level === 0) return // If this is the top level, it remains in place

    const task = props.tasks[index] // The task to be moved

    // remove this task from the current task list
    props.tasks.splice(index, 1)

    // add all tasks after this tasks to this task's subtasks

    // remove all tasks after this task from the current task list
    const subtasks = props.tasks.splice(index)
    subtasks.forEach((subtask) => {
      task.subtasks.push(subtask)
    })

    emit('moveTaskToParent', task) // Add the task to the previous task list
  }
}

defineExpose({ taskElements })
</script>

<style lang="scss" scoped></style>
