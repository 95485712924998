import { Component, inject, markRaw } from 'vue'
import ThreadPostModal from '@/components/modal/ThreadPostModal.vue'
import ActivityDetailModalView from '@/components/view/ActivityDetailModalView.vue'
import DialogModalView from '@/components/view/DialogModalView.vue'
import UploadImages from '@/shared/components/group/sections/elements/UploadImages.vue'
import CreateAlbum from '@/shared/components/group/sections/elements/CreateAlbum.vue'
import CreateMeetupModal from '@/shared/components/group/sections/elements/CreateMeetupModal.vue'
import MemberForm from '@/shared/components/group/sections/elements/MemberForm.vue'
import PhotoViewer from '@/shared/components/group/sections/elements/PhotoViewer.vue'
import ThreadModal from '@/shared/components/group/sections/elements/ThreadModal.vue'
import EditRulesForm from '@/shared/components/group/sections/elements/EditRulesForm.vue'
import EditGroupForm from '@/shared/components/group/sections/elements/EditGroupForm.vue'
import PreferenceModal from '@/shared/components/event/sections/elements/PreferenceModal.vue'
import { EventActivity } from '@/models/EventActivity'

export type ModalEventCallback = (
  payload: any[],
  close: () => void,
  props: Record<string, any>
) => void

export interface ModalOptions {
  /**
   * Show the backdrop behind the modal. Defaults to true.
   */
  backdrop?: boolean

  /**
   * Events to listen to from the modal component
   * Note: onSave, onDelete, onClose, onClosed are reserved events, you need to access them from the options object instead.
   */
  events?: Record<string, ModalEventCallback>

  /**
   * Pass props to the modal component
   */
  props?: Record<string, unknown>

  /**
   * Called when the modal is closing. Return false to prevent the modal from closing or return a promise to delay closing until the promise is resolved.
   * @param reason
   */
  onClose?: (reason: string) => boolean | Promise<unknown>
  /**
   * Called when the modal is fully closed and removed from the DOM.
   */
  onClosed?: () => void

  onDelete?: () => boolean | Promise<unknown>

  /**
   * TODO: check if this is still needed
   * i used that for the confirm dialogs, but i think we can just use the events
   */
  onPrimary?: () => boolean | Promise<unknown>
  onSecondary?: () => boolean | Promise<unknown>
}

const modalTypes: {
  [key: string]: {
    component: () => Component
    options?: ModalOptions
  }
} = {
  threadPost: {
    component: () => ThreadPostModal,
    options: {
      props: {
        position: 'center',
        size: 'lg',
      },
    },
  },
  activityDetail: {
    component: () => ActivityDetailModalView,
    options: {
      props: {
        position: 'center',
        size: 'max-md',
      },
    },
  },
  'success-dialog': {
    component: () => DialogModalView,
    options: {
      props: {
        position: 'center-center',
        size: 'base',
        type: 'success',
        hideHeader: true,
        hideBorder: true,
        backdropClickDisabled: true,
      },
    },
  },
  'confirm-dialog': {
    component: () => DialogModalView,
    options: {
      props: {
        position: 'center-center',
        size: 'base',
        type: 'confirm',
        hideHeader: true,
        hideBorder: true,
        primary: 'Confirm',
        secondary: 'Cancel',
      },
    },
  },
  'email-invite-dialog': {
    component: () => DialogModalView,
    options: {
      props: {
        position: 'center-center',
        size: 'base',
        type: 'email-invite',
        hideHeader: true,
        hideBorder: true,
        primary: 'Send',
      },
    },
  },
  'link-copy-dialog': {
    component: () => DialogModalView,
    options: {
      props: {
        position: 'center-center',
        size: 'auto',
        type: 'link-copy',
        hideHeader: true,
        hideBorder: true,
        primary: 'Copy',
      },
    },
  },
  'image-upload-dialog': {
    component: () => UploadImages,
    options: {
      props: {
        position: 'center-center',
        size: 'auto',
        type: 'image-upload',
        hideHeader: true,
        hideBorder: true,
      },
    },
  },
  'album-create-dialog': {
    component: () => CreateAlbum,
    options: {
      props: {
        edit: true,
        position: 'center-center',
        size: 'auto',
        type: 'image-upload',
        hideHeader: true,
        hideBorder: true,
      },
    },
  },
  'meetup-create': {
    component: () => CreateMeetupModal,
    options: {
      props: {
        position: 'center',
        size: 'xl',
        type: 'confirm',
        hideHeader: true,
        hideBorder: true,
        form: EventActivity.make().form({
          only: [
            'name',
            'description',
            'banner',
            'tags',
            'convention_event_id',
            'location_type',
            'location_id',
            'latitude',
            'longitude',
            'location_directions',
            'event_day',
            'starts_at',
            'ends_at',
          ],
        }),
      },
    },
  },

  'member-request-dialog': {
    component: () => MemberForm,
    options: {
      props: {
        position: 'center-center',
        size: 'auto',
        type: 'image-upload',
        hideHeader: true,
        hideBorder: true,
      },
    },
  },
  'photo-viewer-dialog': {
    component: () => PhotoViewer,
    options: {
      props: {
        position: 'center-center',
        size: 'auto',
        type: 'confirm',
        hideHeader: true,
        hideBorder: true,
      },
    },
  },
  'edit-rules-dialog': {
    component: () => EditRulesForm,
    options: {
      props: {
        position: 'center-center',
        size: 'auto',
        type: 'confirm',
        hideHeader: true,
        hideBorder: true,
      },
    },
  },
  'group-edit-dialog': {
    component: () => EditGroupForm,
    options: {
      props: {
        position: 'center',
        size: 'xl',
        type: 'confirm',
        hideHeader: true,
        hideBorder: true,
      },
    },
  }, 
  'event-preference-dialog': {
    component: () => PreferenceModal,
    options: {
      props: {
        position: 'center-center',
        size: 'auto',
        type: 'confirm',
        hideHeader: true,
        hideBorder: true,
      },
    },
  },

  'thread': {
    component: () => ThreadModal,
    options: {
      props: {
        position: 'center',
        size: 'auto',
        type: 'confirm',
        hideHeader: true,
        hideBorder: true,
      },
    },
  },
}

interface Modal {
  id: number
  type?: string
  open: boolean
  component?: Component
}

type ModalType = Modal & ModalOptions

export function useModal() {
  const modalStore = inject('modalStore') as {
    modals: ModalType[]
  }

  // Build the modal and render it to the DOM
  return {
    /**
     * Array of modals in the DOM. Should not be modified directly.
     */
    modals: modalStore.modals as ModalType[],

    /**
     * Create a new modal and render it to the DOM
     * @param component
     * @param options
     */
    open(component: Component, options: ModalOptions = {}): number {
      if (options.events?.onClose) {
        throw new Error(
          'onClose is a reserved callback, please use ModalOptions.onClose instead.'
        )
      }
      const modal: ModalType = {
        // random number
        id: Math.floor(Math.random() * 10000000),
        open: true,
        component: markRaw(component),
        backdrop: options.backdrop ?? true,
        props: options.props ?? {},
        events: options.events ?? {},
        onClose: options.onClose,
        onClosed: options.onClosed,
        onPrimary: options.onPrimary,
        onSecondary: options.onSecondary,
      }
      modalStore.modals.push(modal)
      return modal.id
    },

    openType(type: string, options: ModalOptions = {}): number {
      options.props = { ...modalTypes[type].options?.props, ...options.props }
      return this.open(modalTypes[type].component(), options)
    },

    openMiddle(component: Component, options: ModalOptions = {}): number {
      if (!options.props) options.props = {}
      options.props.position = 'center-center'
      return this.open(component, options)
    },

    openDialog(options: ModalOptions = {}, component?: Component): number {
      const modal: ModalType = {
        // random number
        id: Math.floor(Math.random() * 10000000),
        open: true,
        component: component,
        type: 'dialog',
        backdrop: options.backdrop ?? true,
        props: options.props ?? {},
        events: options.events ?? {},
        onClose: options.onClose,
        onClosed: options.onClosed,
        onPrimary: options.onPrimary,
        onSecondary: options.onSecondary,
      }
      modalStore.modals.push(modal)
      return modal.id
    },

    /**
     * Soft close the modal. This will trigger the onClose callback.
     * @param id
     * @param reason
     */
    close(id: number, reason: string = 'reason') {
      const modal = modalStore.modals.find((m) => m.id === id) as ModalType
      if (modal) {
        if (modal.onClose) {
          const result = modal.onClose?.(reason)
          if (result instanceof Promise) {
            result.then(() => {
              modal.onClosed?.()
              modal.open = false
            })
            return
          } else {
            if (!result) {
              return
            }
          }
        }
        modal.onClosed?.()
        modal.open = false
      }
    },

    closeAll() {
      modalStore.modals.forEach((m) => {
        this.close(m.id)
      })
    },

    /**
     * Hard Remove the modal from the DOM
     * This is called automatically after the modal is closed. But you can call it manually to immediately remove the modal from the DOM.
     * @param id
     */
    remove(id: number) {
      modalStore.modals.splice(
        modalStore.modals.findIndex((m) => m.id === id),
        1
      )
    },
  }
}
