<template>
  <teleport
    :disabled="position === 'overlay'"
    to="body"
  >
    <transition
      name="fade"
      @enter="innerModalOpen = true"
      @after-leave="onModalClosed"
    >
      <div
        v-if="outerModalOpen"
        :class="containerClass"
        :data-modal-id="$.uid"
        class="fixed inset-0 z-modal"
        @mouseup.self="onBackdropClick"
      >
        <transition
          :enter-from-class="enterFromClass"
          :enter-to-class="enterToClass"
          :leave-from-class="leaveFromClass"
          :leave-to-class="leaveToClass"
          enter-active-class=""
          leave-active-class=""
          @leave="outerModalOpen = false"
        >
          <template v-if="innerModalOpen">
            <slot v-if="position === 'overlay'" />
            <div
              v-else
              ref="modal"
              :class="modalClass"
              class="cursor-auto overflow-scroll duration-300 ease-in-out"
            >
              <slot />
            </div>
          </template>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
import {
  computed,
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
} from 'vue'
import { useMagicKeys, whenever } from '@vueuse/core'
import { Modal } from '@/types/Modal'

/**
 * A customizable modal component that can be used to display content on top of the current page.
 * Can
 */

const emit = defineEmits(['update:open', 'close', 'closed'])

defineOptions({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Modal',
  inheritAttrs: false,
})

const props = defineProps({
  /**
   * Whether the modal is open or not. You can use `v-model` to bind this value.
   * Automatically controlled by modal handlers.
   * @default false
   */
  open: {
    type: Boolean,
    default: false,
  },

  /**
   * Controls whether the backdrop is disabled or not.
   */
  backdropDisabled: {
    type: Boolean,
    default: false,
  },

  backdropClickDisabled: {
    type: Boolean,
    default: false,
  },

  class: {
    type: String,
    default: '',
  },

  /**
   * The position of the modal on screen.
   * @values left, right, center, center-center
   * @default right
   */
  position: {
    type: String,
    default: 'right',
    validator: (value: string) => {
      return ['left', 'right', 'center', 'center-center', 'overlay'].includes(
        value
      )
    },
  },

  /**
   * The size of the modal (width).
   * @values sm, base, md, lg, xl
   * @default md
   */
  size: {
    type: String,
    default: 'md',
  },

  /**
   * The background color of the modal.
   * @default bg-white
   */
  background: {
    type: String,
    default: 'bg-white',
  },

  /**
   * The rounding of the modal.
   * @values none, md, lg
   */
  rounded: {
    type: String,
    default: 'md',
    validator: (value: string) => {
      return ['none', 'md', 'lg'].includes(value)
    },
  },
})

function closeModal() {
  console.log('close')
  emit('update:open', false)
  emit('close')
}

provide('close', closeModal)

function onBackdropClick() {
  if (!props.backdropClickDisabled) closeModal()
}

const keys = useMagicKeys()
whenever(keys.escape, () => {
  if (props.open && modalDepth.value === 0) closeModal()
})

const modal = ref(null)
const currentInstance = getCurrentInstance()
const outerModalOpen = ref(false)
const innerModalOpen = ref(false)
const modalStore = inject('modalStore') as any

const enterFromClass = computed(() => {
  if (['center-center', 'overlay'].includes(props.position)) return 'opacity-0'
  const cl = 'transform translate-y-full'
  if (props.position === 'left') return cl + ' md:-translate-x-full'
  if (props.position === 'right') return cl + ' md:translate-x-full'
  return cl
})

const enterToClass = computed(() => {
  if (['center-center', 'overlay'].includes(props.position))
    return 'opacity-100'
  const cl = 'transform'
  if (props.position === 'left') return cl + ' md:translate-x-0'
  if (props.position === 'right') return cl + ' md:translate-x-0'
  return cl
})

const leaveFromClass = computed(() => {
  if (['center-center', 'overlay'].includes(props.position))
    return 'opacity-100'
  const cl = 'transform'
  if (props.position === 'left') return cl + ' md:translate-x-0'
  if (props.position === 'right') return cl + ' md:translate-x-0'
  return cl
})

const leaveToClass = computed(() => {
  if (['center-center', 'overlay'].includes(props.position)) return 'opacity-0'
  const cl = 'transform translate-y-full'
  if (props.position === 'left') return cl + ' md:-translate-x-full'
  if (props.position === 'right') return cl + ' md:translate-x-full'
  return cl
})

const modalOrder = computed(() => {
  if (!currentInstance?.uid) return 0
  return (
    modalStore.openModals.find(
      (modal: Modal) => modal.id === currentInstance.uid
    )?.order ?? 0
  )
})

/**
 * The depth of the modal when there are multiple modals open.
 * 0 - the modal is the topmost modal
 * 1 - the modal is the second topmost modal
 */
const modalDepth = computed<number>(() => {
  return modalStore.openModals.length - modalOrder.value - 1
})

/**
 * The offset of the modal when there are multiple modals open.
 * This is used to calculate the position of the modal.
 */
const modalOffset = computed<number>(() => {
  return (
    modalStore.openModals.filter((m: Modal) => m.position === props.position)
      .length -
    modalOrder.value -
    1
  )
})

const modalOffsetClass = computed(() => {
  // don't apply offset to center-center and overlay modals
  if (['center-center', 'overlay'].includes(props.position)) return ''

  if (modalOffset.value === 0) {
    if (props.position === 'center') {
      return 'mt-6'
    } else return 'mt-6 md:mt-0 md:translate-y-0'
  }

  const yOffset = modalOffset.value > 1 ? 40 : modalOffset.value > 0 ? 10 : 0
  const scale = modalOffset.value > 1 ? 90 : modalOffset.value > 0 ? 95 : 1
  const xOffset = modalOffset.value * 50

  if (props.position === 'center') {
    return `duration-300 -translate-y-[${yOffset}px] scale-${scale} ease-in-out`
  } else
    return `duration-300 -translate-y-[${yOffset}px] scale-${scale} md:scale-100 ease-in-out md:-translate-x-[${xOffset}px] md:translate-y-0`
})

const sizeGetter = computed(() => {
  const modals = modalStore.openModals.filter(
    (m: Modal) => m.position === props.position
  )
  if (modals.length === 1) return props.size
  else return modals[0].size
})

const modalSize = computed(() => {
  switch (sizeGetter.value) {
    case 'sm':
      return 'md:w-1/4'
    case 'base':
      return 'md:w-2/6'
    case 'md':
      return 'md:w-3/6'
    case 'lg':
      return 'md:w-4/6'
    case 'xl':
      return 'md:w-5/6'
    case 'auto':
      return ''
    case 'max-md':
      return 'md:max-w-[915px]'
    default:
      return props.size
  }
})

const modalRounding = computed(() => {
  switch (props.rounded) {
    case 'none':
      return 'rounded-none'
    case 'md':
    case 'lg':
      if (props.position === 'center-center') {
        return `rounded-${props.rounded}`
      } else if (props.position === 'center') {
        return 'rounded-t-xl md:rounded-t-' + props.rounded
      } else if (props.position === 'left') {
        return `rounded-t-xl md:rounded-r-${props.rounded} md:rounded-l-none`
      } else if (props.position === 'right') {
        return `rounded-t-xl md:rounded-l-${props.rounded} md:rounded-r-none`
      }
      return `rounded-${props.rounded}`
    default:
      return props.rounded
  }
})

const modalClass = computed(() => {
  if (props.class !== '') {
    return props.class
  }

  return [
    props.size === 'auto' ? '' : 'w-full',
    modalSize.value,
    props.background,
    modalRounding.value,
    modalOffsetClass.value,
    props.position === 'center-center' ? 'max-h-[90%] flex flex-col' : '',
    // sm classes
  ].join(' ')
})

const containerDirection = computed(() => {
  if (props.position === 'center-center') return 'items-center justify-center'
  if (props.position === 'center') return 'items-stretch justify-center'
  if (props.position === 'left') return 'items-stretch justify-start'
  if (props.position === 'right') return 'items-stretch justify-end'
  if (props.position === 'overlay') return 'items-start justify-center'
  return ''
})

const containerClass = computed(() => {
  return [
    'flex',
    containerDirection.value,
    props.backdropClickDisabled ? '' : 'cursor-pointer',
    props.backdropDisabled
      ? ''
      : props.position === 'overlay'
        ? 'bg-black bg-opacity-10'
        : 'bg-black bg-opacity-25',
  ]
})

onMounted(() => {
  if (props.open) {
    modalStore.openModals.push({
      id: currentInstance?.uid,
      order: modalStore.openModals.length,
      position: props.position,
      size: props.size,
    })
    outerModalOpen.value = true
    if (!props.backdropDisabled) document.body.style.overflow = 'hidden'
  }
})

watch(
  () => props.open,
  (value) => {
    if (value) {
      modalStore.openModals.push({
        id: currentInstance?.uid,
        order: modalStore.openModals.length,
        position: props.position,
      })
      outerModalOpen.value = true
      if (!props.backdropDisabled) document.body.style.overflow = 'hidden'
    } else {
      modalStore.openModals = modalStore.openModals.filter(
        (modal: Modal) => modal.id !== currentInstance?.uid
      )
      innerModalOpen.value = false
      if (modalStore.openModals.length === 0)
        if (!props.backdropDisabled) document.body.style.overflow = 'auto'
    }
  }
)

function onModalClosed() {
  emit('closed')
}

onUnmounted(() => {
  modalStore.openModals = modalStore.openModals.filter(
    (modal: Modal) => modal.id !== currentInstance?.uid
  )
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: background-color 0.4s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  background-color: rgba(0, 0, 0, 0);
}
</style>
