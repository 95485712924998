<template>
  <div
    ref="dropdownContainer"
    class="relative"
  >
    <button
      ref="toggleButton"
      :class="buttonStyle"
      class="flex h-full w-full items-center justify-center text-sm transition-colors duration-100"
      @click="
        () => {
          resizeHandler()
          isOpen = !isOpen
        }
      "
    >
      <slot
        :is-open="isOpen"
        name="button"
      >
        Toggle
      </slot>
    </button>
    <transition name="fade-appear">
      <div
        v-if="isOpen"
        :class="{
          'left-0 top-full mt-2': position === 'top-left',
          'right-0 top-full mt-2': position === 'top-right',
          'bottom-full left-0 mb-2': position === 'bottom-left',
          'bottom-full right-0 mb-2': position === 'bottom-right',
        }"
        class="absolute z-floating min-w-full"
      >
        <div
          :class="{
            '-top-4 left-1': position === 'top-left',
            '-top-4 right-1': position === 'top-right',
            '-bottom-4 left-1': position === 'bottom-left',
            '-bottom-4 right-1': position === 'bottom-right',
            'border-b-gray-200': position.startsWith('top'),
            'border-t-gray-200': position.startsWith('bottom'),
          }"
          class="absolute z-50 h-0 w-0 rounded-lg border-transparent bg-transparent"
        />
        <slot
          :toggle="() => (isOpen = !isOpen)"
          name="content"
        >
          <div
            :class="{
              'bg-slate-200': variant === 'gray',
              'bg-gray-200': variant === 'default',
            }"
            class="flex min-w-full flex-col gap-0.5 overflow-hidden rounded bg-opacity-90 shadow backdrop-blur"
          >
            <slot
              :toggle="() => (isOpen = !isOpen)"
              name="buttons"
            >
              <button class="h-5 w-full">btn</button>
              <button class="h-5 w-full">btn</button>
              <button class="h-5 w-full">btn</button>
            </slot>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import VButton from '@/components/buttons/VButton.vue'
import getScrollParent from '@/utils/scroll'
import { onClickOutside } from '@vueuse/core'

defineOptions({
  name: 'DropdownButton',
})

/**
 * Button with dropdown menu
 */

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'default',
  },
})

const dropdownContainer = ref<HTMLElement | null>(null)
const toggleButton = ref<InstanceType<typeof VButton> | null>(null)
const isOpen = ref(false)
const position = ref('top-left')
const scrollParent = ref<Node | null>(null)
onClickOutside(dropdownContainer, () => (isOpen.value = false), {})

function resizeHandler() {
  if (!toggleButton.value) return
  const { top, left, width, height } =
    toggleButton.value.getBoundingClientRect()
  const { innerWidth, innerHeight } = window

  if (top + height > innerHeight / 2) {
    if (left + width > innerWidth / 2) {
      position.value = 'bottom-right'
    } else {
      position.value = 'bottom-left'
    }
  } else {
    if (left + width > innerWidth / 2) {
      position.value = 'top-right'
    } else {
      position.value = 'top-left'
    }
  }
}

const buttonStyle = computed(() => {
  if (props.variant === 'default') {
    return [
      'text-gray-500',
      {
        'bg-gray-200': isOpen.value,
        'bg-transparent': !isOpen.value,
        'rounded-full': props.rounded,
        'rounded px-2': !props.rounded,
      },
    ]
  }
  return ['bg-slate-200 rounded-full text-gray-700 !w-8 !h-8 shrink-0']
})

onMounted(() => {
  if (!toggleButton.value) return
  window.addEventListener('resize', resizeHandler)
  scrollParent.value = getScrollParent(toggleButton.value)
  scrollParent.value?.addEventListener('scroll', resizeHandler)
  requestAnimationFrame(() => {
    resizeHandler()
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler)
  scrollParent.value?.removeEventListener('scroll', resizeHandler)
})
</script>

<style scoped>
.fade-appear-enter-active,
.fade-appear-leave-active {
  transition: all 0.15s ease-in-out;
}

.fade-appear-enter-from,
.fade-appear-leave-to {
  transform: translateY(-3px);
  opacity: 0;
}
</style>
