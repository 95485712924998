<template>
  <input-layout
    v-for="attribute in attributes"
    :key="attribute.id"
    :label="attribute.label"
    layout="grid"
    variant="gray">
    <form-field-input
        v-if="attribute.type === 'string'"
      :error="errors?.[attribute.name]"
      :model-value="getAttributeValue(attribute.name)"
      @update:model-value="onInput(attribute, $event)" />
    <form-field-select-game
        v-else-if="attribute.type === 'game'"
      :error="errors?.[attribute.name]"
      :selected="getAttributeValue(attribute.name)"
      :single-select="!attribute.multiple"
      @update:selected="onInput(attribute, $event)" />
  </input-layout>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldSelectGame from '@/components/input/FormFieldSelectGame.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  categoryId: {
    type: [String, Number],
    required: true,
  },
  modelValue: {
    type: Object,
    default: () => undefined
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
})

const attributes = ref([])

const fetchAttributes = async () => {
  const { data } = await axios.get(
    `/api/partner/categories/${props.categoryId}/attributes`
  )
  attributes.value = data
}

onMounted(() => {
  if (props.categoryId) {
    fetchAttributes()
  }
})

watch(
  () => props.categoryId,
  (newValue) => {
    if (newValue) {
      fetchAttributes()
    } else {
      attributes.value = []
    }
  }
)

function getAttributeValue(name: string) {
  return props.modelValue?.[name]?.value
}

function onInput(attribute, e) {
  const value = e
  const obj = props.modelValue ?? {}

  if (Object.prototype.hasOwnProperty.call(obj, attribute.name)) {
    if (!obj[attribute.name]) {
      obj[attribute.name] = {
        value,
        attribute_id: attribute.id,
      }
    }
    obj[attribute.name].value = value
  } else {
    obj[attribute.name] = {
      value,
      attribute_id: attribute.id,
    }
  }
  emit('update:modelValue', obj)
}
</script>

<style scoped></style>
