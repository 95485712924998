import { Model } from '@/models/Model'
import Location from '@/models/Location'

export class VendorSpot extends Model {
  id: number | null = null
  name: string = ''
  convention_event_id: number | null = null
  convention_event: unknown | null = null
  location_directions: string = ''
  location_id: number | null = null
  location_type: string = ''
  location: Location = new Location()
  schedule: Array<unknown> = []
}
