<template>
  <svg
    fill="none"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4648 1.16797H21.0348C19.5532 1.16797 18.6665 2.05464 18.6665 3.5363V6.9663C18.6665 8.44797 19.5532 9.33464 21.0348 9.33464H24.4648C25.9465 9.33464 26.8332 8.44797 26.8332 6.9663V3.5363C26.8332 2.05464 25.9465 1.16797 24.4648 1.16797ZM24.7215 5.02964C24.5815 5.16964 24.3948 5.23964 24.2082 5.23964C24.0215 5.23964 23.8348 5.16964 23.6948 5.02964L23.4848 4.81964V7.43297C23.4848 7.8413 23.1582 8.16797 22.7498 8.16797C22.3415 8.16797 22.0148 7.8413 22.0148 7.43297V4.81964L21.8048 5.02964C21.5248 5.30964 21.0582 5.30964 20.7782 5.02964C20.4982 4.74964 20.4982 4.28297 20.7782 4.00297L22.2365 2.54464C22.2948 2.4863 22.3765 2.43964 22.4582 2.40464C22.4815 2.39297 22.5048 2.39297 22.5282 2.3813C22.5865 2.35797 22.6448 2.3463 22.7148 2.3463H22.7848C22.8665 2.3463 22.9365 2.35797 23.0182 2.39297H23.0415C23.1232 2.42797 23.1932 2.47464 23.2515 2.53297C23.2632 2.54464 23.2632 2.54464 23.2748 2.54464L24.7332 4.00297C25.0132 4.28297 25.0132 4.74964 24.7215 5.02964Z"
      fill="currentColor"
    />
    <path
      d="M10.5001 12.1119C12.0336 12.1119 13.2767 10.8688 13.2767 9.33526C13.2767 7.80175 12.0336 6.55859 10.5001 6.55859C8.96654 6.55859 7.72339 7.80175 7.72339 9.33526C7.72339 10.8688 8.96654 12.1119 10.5001 12.1119Z"
      fill="currentColor"
    />
    <path
      d="M24.4649 9.33594H23.9166V14.7143L23.7649 14.5859C22.8549 13.8043 21.3849 13.8043 20.4749 14.5859L15.6216 18.7509C14.7116 19.5326 13.2416 19.5326 12.3316 18.7509L11.9349 18.4243C11.1066 17.7009 9.78825 17.6309 8.85492 18.2609L4.49159 21.1893C4.23492 20.5359 4.08325 19.7776 4.08325 18.8909V9.11427C4.08325 5.82427 5.82159 4.08594 9.11159 4.08594H18.6666V3.5376C18.6666 3.07094 18.7483 2.67427 18.9349 2.33594H9.11159C4.86492 2.33594 2.33325 4.8676 2.33325 9.11427V18.8909C2.33325 20.1626 2.55492 21.2709 2.98659 22.2043C3.98992 24.4209 6.13659 25.6693 9.11159 25.6693H18.8883C23.1349 25.6693 25.6666 23.1376 25.6666 18.8909V9.0676C25.3283 9.25427 24.9316 9.33594 24.4649 9.33594Z"
      fill="currentColor"
    />
  </svg>
</template>
