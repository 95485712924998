import { Model } from '@/models/Model'
import Address from '@/models/Address'
import ILocation from '@/models/ILocation'

export default class Venue extends Model implements ILocation {
  id: number | null = null
  name: string = ''
  short_name: string = ''
  slug: string = ''
  address_id: number | null = null
  address: Address | null = null

  get locationType(): string {
    return 'venue'
  }

  get parentId(): number | null {
    return null;
  }
}
