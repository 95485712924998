<template>
  <modal-dialog-layout title="Import File">
    <div class="flex min-h-[600px] flex-col">
      <div
        v-if="!loaded"
        class="flex grow flex-col items-center justify-center gap-8"
      >
        <app-spinner />
        <small class="text-xs text-paragraph">
          Loading your import progress
        </small>
      </div>
      <template v-else>
        <import-progress
          :duplicates="1"
          :errors="status.errors"
          :messages="status.messages"
          :processed="status.processed"
          :progress="status.percentage"
          :total="status.total"
        />
      </template>
    </div>
  </modal-dialog-layout>
</template>

<script lang="ts" setup>
import ModalDialogLayout from '@/components/modal/layout/ModalDialogLayout.vue'
import { onMounted, reactive, ref } from 'vue'
import AppSpinner from '@/components/ui/AppSpinner.vue'
import axios from 'axios'
import ImportProgress from '@/components/ui/ImportProgress.vue'
import { useEcho } from '@/utils/echo'

const props = defineProps({
  dataImportId: {
    type: Number,
    required: true,
  },
})

const echo = useEcho()
const status = reactive({
  status: null,
  percentage: 0,
  total: 0,
  processed: 0,
  duplicates: 0,
  errors: 0,
  messages: [
    {
      thread_id: 'import-started',
      type: 'info',
      body: 'Import Started',
    },
  ],
})
const loaded = ref(false)

onMounted(() => {
  axios
    .get(`/api/partner/import-log/${props.dataImportId}`)
    .then((response) => {
      Object.assign(status, {
        status: response.data.status,
        percentage: response.data.percentage,
        total: response.data.total,
        processed: response.data.processed,
        duplicates: response.data.duplicates,
        errors: response.data.errors,
      })
      status.messages = status.messages.concat(response.data.messages)
      if (response.data.finished_at === null) {
        echo
          .private(`data-imports.${props.dataImportId}`)
          .listen('.ImportProgress', (e) => {
            Object.assign(status, {
              status: e.status ?? status.status,
              percentage: e.percentage ?? status.percentage,
              total: e.total ?? status.total,
              processed: e.processed ?? status.processed,
              duplicates: e.duplicates ?? status.duplicates,
              errors: e.errors ?? status.errors,
            })

            if (e.messages) {
              status.messages = status.messages.concat(e.messages)
            }
          })
      }
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      loaded.value = true
    })
})
</script>

<style scoped></style>