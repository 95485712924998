<template>
  <!--TODO: if you have problems with layout, it's possibly because of h-0 -->
  <div
    class="flex items-stretch"
    :class="[
      reverseOrder ? 'flex-col-reverse' : 'flex-col',
      fullPage ? 'h-0' : 'h-full',
    ]"
  >
    <template v-if="loading && messages.length === 0">
      <slot name="loading">
        <div class="flex grow flex-col items-center justify-center gap-3">
          <app-spinner class="h-6 w-6" />
          <small class="text-xs">Loading chat...</small>
        </div>
      </slot>
    </template>

    <template v-else>
      <div class="relative grow">
        <slot
          v-if="messages.length === 0"
          name="empty-messages"
        >
          <div
            class="absolute top-1/2 flex w-full -translate-y-1/2 transform justify-center p-2 text-center"
          >
            <small
              class="text-slat-600 rounded-full bg-gray-300 px-2 py-1 text-xs"
            >
              {{ emptyMessage }}
            </small>
          </div>
        </slot>
        <TransitionGroup
          v-else
          id="messages-list"
          ref="listElement"
          name="list"
          tag="ul"
          class="flex"
          :class="[
            fullPage ? 'absolute inset-0 overflow-y-auto' : 'relative',
            reverseMessages ? 'flex-col-reverse' : 'flex-col',
          ]"
          @scroll="handleScroll"
        >
          <template
            v-for="message in messages.slice()"
            :key="message.id"
          >
            <slot
              name="message"
              :message="message"
            >
              <li
                :data-id="message.id"
                class="flex gap-2"
                :class="{
                  'flex-row-reverse place-self-end':
                    message?.sender_id === user.id,
                }"
              >
                <background-image-component
                  :src="message.sender.avatar.path"
                  class="h-6 w-6 cursor-pointer overflow-hidden rounded-full bg-cover"
                  @click="router.visit(`/user/${message.sender.id}`)"
                >
                  <div
                    v-if="message.sender.blocked"
                    class="h-full w-full backdrop-blur-sm backdrop-grayscale"
                  />
                </background-image-component>
                <div
                  class="mb-2 flex max-w-[80%] flex-wrap items-end rounded-lg p-2"
                  :class="[
                    message.sender_id === user.id
                      ? 'bg-ultra-light-blue'
                      : 'bg-gray-200',
                  ]"
                >
                  <span
                    :class="{
                      'blur-sm': message.sender.blocked,
                    }"
                  >
                    {{ message.body }}
                  </span>
                  <small
                    class="ml-2 grow select-none text-end text-xs text-slate-700"
                  >
                    {{
                      DateTime.fromISO(message.created_at).toLocaleString(
                        DateTime.TIME_SIMPLE
                      )
                    }}
                  </small>
                </div>
              </li>
            </slot>
          </template>
          <li
            v-if="loading"
            class="flex justify-center py-2"
          >
            <svg
              class="origin-center animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path
                d="M136,32V64a8,8,0,0,1-16,0V32a8,8,0,0,1,16,0Zm88,88H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.09,47.6a8,8,0,0,0-11.31,11.31l22.62,22.63a8,8,0,0,0,11.32-11.32ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184ZM77.09,167.6,54.46,190.22a8,8,0,0,0,11.32,11.32L88.4,178.91A8,8,0,0,0,77.09,167.6ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM65.78,54.46A8,8,0,0,0,54.46,65.78L77.09,88.4A8,8,0,0,0,88.4,77.09Z"
              />
            </svg>
          </li>
          <li
            v-if="reachedEnd"
            class="flex justify-center py-2"
          >
            <small class="text-xs text-gray-500">No more messages</small>
          </li>
        </TransitionGroup>
      </div>
      <slot
        name="input"
        :sending="sending"
        :send-function="send"
      >
        <div
          class="flex items-center overflow-hidden rounded-full border border-slate-600"
        >
          <button
            v-if="false"
            autofocus
            :disabled="sending"
            class="flex items-center justify-center p-3 pl-4 text-gray-500 hover:bg-gray-100 hover:text-gray-600 active:bg-gray-200 active:text-gray-700 disabled:text-gray-200 disabled:hover:bg-transparent"
          >
            <font-awesome-icon
              icon="paperclip"
              class="h-6 w-6"
            />
          </button>
          <input
            v-model="text"
            type="text"
            :disabled="sending"
            class="flex grow resize-none appearance-none items-center bg-transparent px-2 outline-none"
            placeholder="Type your message here..."
            @keydown.enter="sendMessage"
          />
          <button
            :disabled="sending || text.length === 0"
            :class="{
              'text-gray-500 hover:bg-gray-100 hover:text-gray-600 active:bg-gray-200 active:text-gray-700':
                text.length === 0,
              'text-blue-primary hover:bg-blue-100 hover:text-blue-neon active:bg-blue-200 active:text-blue-muted':
                text.length > 0,
              'disabled:text-gray-200 disabled:hover:bg-transparent': !sending,
            }"
            class="flex items-center justify-center p-3 pr-4 transition-colors duration-100"
            @click="sendMessage"
          >
            <svg
              v-if="sending"
              class="origin-center animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path
                d="M136,32V64a8,8,0,0,1-16,0V32a8,8,0,0,1,16,0Zm88,88H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.09,47.6a8,8,0,0,0-11.31,11.31l22.62,22.63a8,8,0,0,0,11.32-11.32ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184ZM77.09,167.6,54.46,190.22a8,8,0,0,0,11.32,11.32L88.4,178.91A8,8,0,0,0,77.09,167.6ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM65.78,54.46A8,8,0,0,0,54.46,65.78L77.09,88.4A8,8,0,0,0,88.4,77.09Z"
              />
            </svg>
            <font-awesome-icon
              v-else
              icon="paper-plane"
              class="h-6 w-6"
            />
          </button>
        </div>
      </slot>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import { DateTime } from 'luxon'
import { useEcho } from '@/utils/echo'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import { router } from '@inertiajs/vue3'
import AppSpinner from '@/components/ui/AppSpinner.vue'
import useAuth from '@/utils/composables/auth.js'

const props = defineProps({
  apiUrl: {
    type: String,
    required: true,
  },
  channel: {
    type: String,
    required: true,
  },
  emptyMessage: {
    type: String,
    default: 'No messages yet',
  },
  reverseOrder: {
    type: Boolean,
    default: false,
  },
  reverseMessages: {
    type: Boolean,
    default: false,
  },
  fullPage: {
    type: Boolean,
    default: false,
  },
  commentReplies: {
    type: Boolean,
    default: false,
  },
  highlightedMessageId: {
    type: Number,
    default: null,
  },
})

watch(
  () => props.channel,
  (newVal, oldVal) => {
    console.log('channel changed')
    if (newVal !== oldVal) {
      echo.leaveChannel(oldVal)
      loading.value = true
      reachedEnd.value = false
      messages.value = []
      joinChannel()
    }
  }
)

const loading = ref(true)
const reachedEnd = ref(false)
const sending = ref(false)
const messages = ref([])
const text = ref('')

const store = inject('store')
const { user } = useAuth()
const listElement = ref(null)
const echo = useEcho()

const onlineUsers = ref([])

onMounted(() => {
  joinChannel()
})

onBeforeUnmount(() => {
  console.log('leaving channel')
  echo.leave(props.channel)
})

function joinChannel() {
  axios.get(props.apiUrl).then((response) => {
    messages.value = response.data
    loading.value = false

    if (props.highlightedMessageId) {
      console.log('highlighting message', props.highlightedMessageId)
      requestAnimationFrame(() => {
        const el = document.querySelector(
          `#messages-list li[data-id="${props.highlightedMessageId}"]`
        )
        console.log(el)
        el.classList.add('pulse-highlight')
        setTimeout(() => {
          el.classList.remove('pulse-highlight')
        }, 5000)
        el.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        })
      })
    }
  })

  echo
    .join(props.channel)
    .here((users) => (onlineUsers.value = users))
    .joining((user) => onlineUsers.value.push(user))
    .leaving(
      (user) =>
        (onlineUsers.value = onlineUsers.value.filter((u) => u.id !== user.id))
    )
    .listen('.MessageSent', (e) => {
      console.log(e)
      if (e.reply_to_id && props.commentReplies) {
        const message = searchMessageTree(messages.value, e.reply_to_id)
        if (message) {
          if (!message.replies) {
            message.replies = []
          }
          message.replies.push(e)
        }
      } else {
        messages.value.unshift(e)
      }
    })
    .listen('.RatingChange', (e) => {
      const message = searchMessageTree(messages.value, e.messageId)
      if (message) {
        message.rating = e.rating
      }
    })
}

function searchMessageTree(messages, id) {
  for (const message of messages) {
    if (message.id === id) {
      return message
    }
    if (message.replies) {
      const found = searchMessageTree(message.replies, id)
      if (found) {
        return found
      }
    }
  }
  return null
}

function searchReplyTree(message, id) {
  if (message.id === id) {
    return message
  }
  if (message.replies) {
    for (const reply of message.replies) {
      const found = searchReplyTree(reply, id)
      if (found) {
        return found
      }
    }
  }
  return null
}

function handleScroll(e) {
  if (loading.value) return
  if (reachedEnd.value) return
  const scroll =
    e.target.scrollHeight - e.target.clientHeight + e.target.scrollTop
  if (scroll <= 1000) {
    loading.value = true
    console.log(messages.value.at(-1))
    axios
      .get(props.apiUrl + '?before=' + messages.value.at(-1).id)
      .then((response) => {
        // save the current scroll position
        const scrollPosition = e.target.scrollHeight - e.target.scrollTop

        if (response.data.length === 0) {
          reachedEnd.value = true
        }

        // restore the scroll position
        requestAnimationFrame(() => {
          messages.value.push(...response.data)
          e.target.scrollTop = e.target.scrollHeight - scrollPosition
          loading.value = false
        })
      })
  }
}

function sendMessage() {
  if (text.value.trim() === '') return
  send({
    body: text.value.trim(),
  }).then(() => {
    text.value = ''
  })
}

function send(data) {
  sending.value = true
  return axios
    .post(props.apiUrl, data, {
      headers: {
        // pass our socket id along so we only get the message back if it's not from us
        'X-Socket-Id': echo.socketId(),
      },
    })
    .then((response) => {
      messages.value.unshift(response.data)
      sending.value = false
      return response
    })
}
</script>

<style scoped>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.25s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
