<template>
  <modal-buttons-layout
    disable-bottom
    primary-button="Post"
    secondary-button="Cancel"
    title="Create a Post"
    @save="
      form.post(`/api/conventions/${conventionId}/posts`, {
        forceFormData: true,
      }).then(() => {
        emit('create')
        emit('close')
      })
    ">
    <div class="sticky top-0 grid grid-flow-col border-b bg-white">
      <button
        v-for="type in Object.values(PostType)"
        :key="type"
        :class="[
          type === form.type
            ? 'border-gray-900 text-black'
            : 'border-transparent text-gray-700',
        ]"
        class="flex items-center justify-center border-b-2 py-3 text-base capitalize"
        @click="form.type = type">
        <font-awesome-icon :icon="postTypeProps[type].icon" class="mr-2 h-5" />
        {{ postTypeProps[type].title }}
      </button>
    </div>
    <template v-if="form.type === PostType.Post">
      <div class="flex grow flex-col items-center justify-center">
        <div
          class="animate-move-background flex flex-col items-center justify-center bg-gradient-to-r from-blue-primary via-pink to-purple-700 bg-[length:200%] bg-clip-text text-transparent">
          <h1 class="text-4xl font-bold">Threads & Posts</h1>
          <p class="font-bold">coming soon...</p>
        </div>
      </div>
    </template>
    <template v-else-if="form.type == PostType.Images">
      <div class="flex grow flex-col gap-3 p-5">
        <form-field-input
          v-model="form.title"
          placeholder="Title"
          variant="gray" />
        <input
          ref="fileInput"
          accept="image/*"
          class="hidden"
          multiple
          type="file"
          @change="addImages" />
        <div
          v-if="!form.images.length"
          class="flex h-80 items-center justify-center gap-3 rounded bg-gray-100"
          @click="fileInput?.click()">
          <p class="text-base">
            Drag and drop images or
            <button class="rounded-full border border-black px-2 py-1">
              Upload
            </button>
          </p>
        </div>
        <div v-else class="flex flex-col gap-3 rounded bg-gray-100 p-3">
          <div class="flex h-40 gap-3 overflow-x-auto">
            <div
              v-for="(image, index) in form.images"
              :key="image.path + index"
              class="group relative aspect-square h-full"
              @click="_selectedImageIndex = index">
              <img
                :class="[
                  index === _selectedImageIndex
                    ? 'border-blue-neon'
                    : 'border-transparent',
                ]"
                :src="image.path"
                alt="Image"
                class="h-full w-full rounded border-2 object-cover" />
              <button
                class="absolute right-1 top-1 hidden h-6 w-6 items-center justify-center rounded-full bg-white group-hover:flex"
                @click.stop="form.images.splice(index, 1)">
                <font-awesome-icon :icon="['fas', 'times']" class="h-4 w-4" />
              </button>
            </div>
            <div class="aspect-square h-full">
              <button
                class="flex h-full w-full flex-col items-center justify-center gap-2 rounded border border-gray-400 bg-white text-gray-600"
                @click="fileInput?.click()">
                <font-awesome-icon :icon="['fas', 'plus']" class="h-5" />
                Add Image
              </button>
            </div>
          </div>
          <div v-if="selectedImage" class="grid grid-cols-2 gap-3">
            <div
              :style="{
                backgroundImage: `url(${selectedImage.path})`,
              }"
              class="h-80 rounded bg-gray-300 bg-contain bg-center bg-no-repeat"></div>
            <div class="flex flex-col">
              <form-field-input
                v-model="selectedImage.caption"
                placeholder="Caption"
                variant="dark-gray" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import { computed, PropType, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import useAxiosForm from '@/utils/form'
import Image from '@/models/Image'

const emit = defineEmits(['close', 'create'])

const props = defineProps({
  postType: {
    type: String as PropType<PostType>,
    default: 'images',
  },
  conventionId: {
    type: [String, Number],
    required: true,
  },
})

enum PostType {
  Post = 'post',
  Images = 'images',
}

const postTypeProps: {
  [key in PostType]: { title: string; icon: string }
} = {
  post: {
    title: 'Post',
    icon: 'square-pen',
  },
  images: {
    title: 'Images',
    icon: 'image',
  },
}

const _selectedImageIndex = ref<number>(0)
const fileInput = ref<HTMLInputElement | undefined>()

const form = useAxiosForm({
  title: '',
  type: props.postType,
  images: [] as Image[],
})

const selectedImage = computed(() => {
  return form.images[_selectedImageIndex.value!]
})

function addImages(event: Event) {
  const target = event.target as HTMLInputElement
  if (target.files) {
    form.images.push(
      ...Array.from(target.files).map((file) => {
        return Image.fromFile(file)
      })
    )
  }
  fileInput.value!.value = ''
}
</script>

<style scoped></style>
