import { reactive, watch } from 'vue'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import { Model } from '@/models/Model'

interface LaravelValidationErrorResponse {
  errors: Record<string, string[]>
  message: string
}

function buildFormDataRecursive(
  formData: FormData,
  data: any,
  parentKey: string | null = null
) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormDataRecursive(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    if (parentKey === null) throw new Error('parentKey is null')
    if (typeof data === 'boolean') {
      formData.append(parentKey, data ? '1' : '0')
    } else {
      const value = data == null ? '' : data

      formData.append(parentKey, value)
    }
  }
}

/**
 * Recursively Compare two objects and return the difference
 * @param a
 * @param b
 */
export function diff(a: any, b: any): any {
  if (a === b) return {}

  if (a && !b) return a
  if (!a && b) return b

  if (typeof a === 'object' && typeof b === 'object') {
    const keys = Object.keys(a)
    const keysB = Object.keys(b)
    const diffObject: any = {}

    keys.forEach((key) => {
      if (b[key] === undefined) {
        diffObject[key] = a[key]
      } else {
        const d = diff(a[key], b[key])
        if (Object.keys(d).length > 0) {
          diffObject[key] = d
        }
      }
    })

    keysB.forEach((key) => {
      if (a[key] === undefined) {
        diffObject[key] = b[key]
      }
    })

    return diffObject
  }

  return a
}

export function only(a: any, keys: string[]): any {
  const obj: any = {}
  keys.forEach((key) => {
    if (a[key]) obj[key] = a[key]
  })
  return obj
}

export function isDifferent(a: any, b: any): boolean {
  return Object.keys(diff(a, b)).length > 0
}

function hasFile(data: any): boolean {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    return Object.keys(data).some((key) => {
      return hasFile(data[key])
    })
  } else {
    return data instanceof File
  }
}

export function buildFormData(data: any) {
  const formData = new FormData()

  buildFormDataRecursive(formData, data)

  return formData
}

interface RequestConfig extends AxiosRequestConfig {
  forceFormData?: boolean
  resetDefaults?: boolean
  resetDefaultsFromResponse?: boolean
}

export interface FormProps<TForm extends Record<string, unknown> | Model> {
  isPreloading: boolean
  isPreloadError: boolean
  preloadError: string
  preloadCode: number
  isFormOpen: boolean
  formAction: string
  formType: string
  isDirty: boolean
  errors: Partial<Record<keyof TForm, string>>
  hasErrors: boolean
  processing: boolean

  openForm(reset?: object, type?: string, action?: string): void

  openEditForm(
    prefetch: Promise<any>,
    type?: string,
    action?: string
  ): Promise<any>

  closeForm(callback?: () => Promise<any>): void

  data(): TForm

  dataCopy(): TForm

  formData(): FormData

  defaults(): this

  defaults(field: keyof TForm, value: string): this

  defaults(fields: Record<keyof TForm, string>): this

  reset(...fields: (keyof TForm)[]): this

  resetData(newData: any): void

  fillResourceData(fill: any): void

  fillData(newData: any): void

  clearErrors(...fields: string[]): void

  setError(fieldOrFields: object, maybeValue?: string): void

  submit(
    method: string,
    url: string,
    options?: Partial<RequestConfig>
  ): Promise<AxiosResponse>

  get(url: string, options?: Partial<RequestConfig>): Promise<AxiosResponse>

  post(url: string, options?: Partial<RequestConfig>): Promise<AxiosResponse>

  put(url: string, options?: Partial<RequestConfig>): Promise<AxiosResponse>

  patch(url: string, options?: Partial<RequestConfig>): Promise<AxiosResponse>

  delete(url: string, options?: Partial<RequestConfig>): Promise<AxiosResponse>
}

export type Form<TForm extends Record<string, unknown>> = TForm &
  FormProps<TForm>

export type ModelForm<TModel extends Model> = TModel & FormProps<TModel>

export function useModelForm<TModel extends Model>(
  data: TModel
): ModelForm<TModel> {
  return useAxiosForm(cloneDeep(data))
}

export default function useAxiosForm<TForm extends Record<string, unknown>>(
  data: TForm | (() => TForm)
): Form<TForm> {
  // const rememberKey = typeof rememberKeyOrData === 'string' ? rememberKeyOrData : null
  // const data = typeof rememberKeyOrData === 'string' ? maybeData : rememberKeyOrData
  // const restored = rememberKey
  //     ? (router.restore(rememberKey) as { data: TForm; errors: Record<keyof TForm, string> })
  //     : null
  // let cancelToken = null
  // let recentlySuccessfulTimeoutId = null
  // let transform = (data) => data

  // initial data that will be used to reset the form
  // this data should not be changed as it shows the original state of the form
  let defaults = typeof data === 'object' ? cloneDeep(data) : cloneDeep(data())

  // prefilled data that will be used to fill the form
  // this data is used to fill the form when opening the form
  let resourceDefaults =
    typeof data === 'object' ? cloneDeep(data) : cloneDeep(data())

  const form = reactive<Form<TForm>>({
    isPreloading: false,
    isPreloadError: false,
    preloadError: '',
    preloadCode: 0,
    isFormOpen: false,
    formAction: '',
    formType: '',
    isDirty: false,
    errors: {},
    hasErrors: false,
    processing: false,
    // progress: null,
    // wasSuccessful: false,
    // recentlySuccessful: false,

    openForm(fill = undefined, type = '', action = 'create') {
      this.isPreloading = true
      this.isPreloadError = false
      this.preloadError = ''
      this.preloadCode = 0
      this.isFormOpen = true
      this.formType = type
      this.formAction = action

      this.reset()
      if (fill) {
        this.fillResourceData(fill)
      }

      this.isPreloading = false

      return this
    },
    openEditForm(
      prefetch: Promise<any>,
      type = '',
      action = 'edit'
    ): Promise<any> {
      this.isPreloading = true
      this.isPreloadError = false
      this.preloadError = ''
      this.preloadCode = 0
      this.isFormOpen = true
      this.formType = type
      this.formAction = action
      this.reset()
      return prefetch
        .then((response) => {
          this.fillResourceData(response.data)
          Object.keys(resourceDefaults).forEach((key) => {
            if (!isEqual(resourceDefaults[key], this[key])) {
              console.log(key, resourceDefaults[key], this[key])
            }
          })
          return response
        })
        .catch((error) => {
          console.error('here error')
          this.isPreloadError = true
          this.preloadError = error.message ?? ''
          console.log('code', error.response?.status)
          this.preloadCode = error.response?.status ?? 0
          return error
        })
        .finally(() => {
          this.isPreloading = false
        })
    },

    closeForm(callback: (() => Promise<AxiosResponse>) | null = null) {
      if (callback) {
        callback()
          .then((response) => {
            this.isFormOpen = false
            this.formType = ''
            this.formAction = ''
          })
          .catch((error: AxiosError<LaravelValidationErrorResponse>) => {
            if (error.response?.status === 422) {
              const dataErrors = error.response.data.errors
              const errs: Record<string, any> = {}
              for (const key in dataErrors) {
                errs[key] = dataErrors[key][0]
              }
              this.errors = errs
            }
          })
      } else {
        this.isFormOpen = false
        this.formType = ''
        this.formAction = ''
      }

      return this
    },

    data() {
      return (Object.keys(defaults) as Array<keyof TForm>).reduce(
        (carry, key) => {
          carry[key] = this[key]
          return carry
        },
        {} as Partial<TForm>
      ) as TForm
    },
    dataCopy() {
      return cloneDeep(this.data())
    },
    formData() {
      return buildFormData(this.data())
    },

    defaults(
      fieldOrFields?: keyof TForm | Record<keyof TForm, string>,
      maybeValue?: string
    ) {
      if (typeof data === 'function') {
        throw new Error(
          'You cannot call `defaults()` when using a function to define your form data.'
        )
      }

      if (typeof fieldOrFields === 'undefined') {
        defaults = this.data()
      } else {
        defaults = Object.assign(
          {},
          cloneDeep(defaults),
          typeof fieldOrFields === 'string'
            ? { [fieldOrFields]: maybeValue }
            : fieldOrFields
        )
      }

      this.resetData(defaults)

      return this
    },
    reset(...fields: string[]) {
      const resolvedData =
        typeof data === 'object' ? cloneDeep(defaults) : cloneDeep(data())
      const clonedData = cloneDeep(resolvedData)
      if (fields.length === 0) {
        defaults = clonedData
        Object.assign(this, resolvedData)
      } else {
        Object.keys(resolvedData)
          .filter((key) => fields.includes(key))
          .forEach((key) => {
            defaults[key] = clonedData[key]
            this[key] = resolvedData[key]
          })
      }

      return this
    },
    resetData(newData: any) {
      Object.keys(defaults).forEach((key) => {
        delete this[key]
      })
      defaults = cloneDeep(newData)
      Object.keys(newData).forEach((key) => {
        this[key] = cloneDeep(newData[key])
      })
    },

    /**
     * Fill resource data with new data
     * This is used to prefill the form with data from the resource
     * This will leave defaults untouched
     * @param fill
     */
    fillResourceData(fill: any) {
      Object.keys(resourceDefaults).forEach((key) => {
        delete this[key]
      })
      resourceDefaults = cloneDeep(fill)
      Object.keys(fill).forEach((key) => {
        this[key] = cloneDeep(fill[key])
      })
    },

    /**
     * Fill form data with new data
     * This will leave the resource data and defaults untouched
     * @param newData
     */
    fillData(newData: any) {
      Object.keys(newData).forEach((key) => {
        this[key] = cloneDeep(newData[key])
      })
    },

    setError(
      fieldOrFields: keyof TForm | Record<keyof TForm, string>,
      maybeValue?: string
    ) {
      const obj =
        typeof fieldOrFields === 'string'
          ? { [fieldOrFields]: maybeValue }
          : fieldOrFields

      const errs = {}
      Object.keys(obj).forEach((key) => {
        const parts = key.split('.')
        let current = errs
        parts.forEach((part, index) => {
          if (index === parts.length - 1) {
            current[part] = obj[key]
          } else {
            current[part] = current[part] || {}
            current = current[part]
          }
        })
      })

      // create a tree from the dot notation
      Object.assign(this.errors, errs)

      this.hasErrors = Object.keys(this.errors).length > 0

      return this
    },
    clearErrors(...fields: string[]) {
      this.errors = Object.keys(this.errors).reduce(
        (carry, field) => ({
          ...carry,
          ...(fields.length > 0 && !fields.includes(field)
            ? { [field]: this.errors[field] }
            : {}),
        }),
        {}
      )

      this.hasErrors = Object.keys(this.errors).length > 0

      return this
    },
    submit(method: string, url: string, options: RequestConfig = {}) {
      this.processing = true

      const raw = this.dataCopy()

      // if forceFormData is not set, check if data has a file
      if (options.forceFormData === undefined) {
        options.forceFormData = hasFile(raw)
        if (options.forceFormData) {
          console.debug(
            '👨‍💻 Found file in data, forcing FormData. Disable this behavior by setting forceFormData to true or false.'
          )
        }
      }

      const data = options.forceFormData ? buildFormData(raw) : raw

      if (options.forceFormData) {
        options.params = {
          ...options.params,
          _method: method,
        }
        method = 'post'
      }

      return axios({
        method: method,
        url: url,
        data: data,
        ...options,
      })
        .catch((err) => {
          if (err.response?.status === 422) {
            const dataErrors = err.response.data.errors
            const errs: Record<string, any> = {}
            for (const key in dataErrors) {
              errs[key] = dataErrors[key][0]
            }
            this.errors = errs as any
          }
          throw err
        })
        .then((res) => {
          this.defaults()
          this.reset()
          if (options.resetDefaults) {
            this.fillResourceData(res.data)
          }
          return res
        })
        .finally(() => {
          this.processing = false
        })
    },
    get(url: string, options?: RequestConfig) {
      return this.submit('get', url, options)
    },
    post(url: string, options?: RequestConfig) {
      return this.submit('post', url, options)
    },
    put(url: string, options?: RequestConfig) {
      return this.submit('put', url, options)
    },
    patch(url: string, options?: RequestConfig) {
      return this.submit('patch', url, options)
    },
    delete(url: string, options?: RequestConfig) {
      return this.submit('delete', url, options)
    },
    rdefaults() {
      return resourceDefaults
    },
    test() {
      return Object.keys(resourceDefaults).filter((key) => {
        return !isEqual(resourceDefaults[key], this[key])
      })
    },
    ...cloneDeep(resourceDefaults),
  })

  watch(
    () => form,
    (newValue) => {
      let dirty = false
      Object.keys(resourceDefaults).forEach((key) => {
        dirty = dirty || !isEqual(form[key], resourceDefaults[key])
      })
      form.isDirty = dirty
    },
    { immediate: true, deep: true }
  )

  return form
}
