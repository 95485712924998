export type Rules = {
  required?: boolean
  min?: number
  max?: number
  between?: [number, number]
  email?: boolean
  phone?: boolean
  url?: boolean
  regex?: RegExp
}

export function validate(
  input: string | number | null,
  rules: Rules
): string | null {
  for (const ruleKey of Object.keys(rules)) {
    if (!rules[ruleKey]) {
      continue
    }
    const ruleValue = rules[ruleKey]
    switch (ruleKey) {
      case 'required':
        if (!input || (typeof input === 'string' && input.trim() === '')) {
          return 'This field is required'
        }
        break
      case 'min':
        if (input) {
          if (typeof input === 'string' && input.length < ruleValue) {
            return `This field must be at least ${ruleValue} characters`
          }
          if (typeof input === 'number' && input < ruleValue) {
            return `This field must be at least ${ruleValue}`
          }
        }
        break
      case 'max':
        if (input) {
          if (typeof input === 'string' && input.length > ruleValue) {
            return `This field must be at most ${ruleValue} characters`
          }
          if (typeof input === 'number' && input > ruleValue) {
            return `This field must be at most ${ruleValue}`
          }
        }
        break
      case 'between':
        if (input) {
          const [min, max] = ruleValue
          if (
            typeof input === 'string' &&
            (input.length < min || input.length > max)
          ) {
            return `This field must be between ${min} and ${max} characters`
          }
          if (typeof input === 'number' && (input < min || input > max)) {
            return `This field must be between ${min} and ${max}`
          }
        }
        break
      case 'email':
        if (input && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input as string)) {
          return 'Invalid email address'
        }
        break
      case 'url':
        if (
          input &&
          !/^(https?:\/\/)?[^\s@]+\.[^\s@]+$/.test(input as string)
        ) {
          return 'Invalid URL'
        }
        break
      case 'phone':
        if (
          input &&
          !/^\+?[0-9]{1,4}[\s-]?[0-9]{1,15}$/.test(input as string)
        ) {
          return 'Invalid phone number'
        }
        break
      case 'regex':
        if (input && !ruleValue.test(input as string)) {
          return 'Invalid input'
        }
        break
      default:
        console.warn(
          `Unknown rule key '${ruleKey}', please add it to rules or use regex validation`
        )
    }
  }
  return null
}
