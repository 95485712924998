<template>
  <modal-submenu-layout
    :sections="[
      {
        label: 'General',
        value: 'general',
      },
      {
        label: 'Details',
        value: 'details',
      },
      {
        label: 'Branding',
        value: 'branding',
      },
      {
        label: 'Sessions',
        value: 'sessions',
      },
      {
        label: 'Comments',
        value: 'comments',
      },
    ]"
    disable-bottom
  >
    <template #top>
      <form-field-header-input class="w-full">
        <template #input>
          <form-field-input
            v-model="form.name"
            :error="form.errors.name"
            class="grow"
            input-class="font-bold"
            placeholder="Enter Game Name"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <template #general>
      <input-layout
        :error="form.errors.parent_game"
        label="Parent Game (if applicable)"
        layout="grid"
        variant="gray"
      >
        <form-field-select-game
          v-model:selected="form.parent_game"
          class="grow"
          input-class="font-bold"
          single-select
          variant="gray"
        />
      </input-layout>
      <input-layout
        :error="form.errors.description"
        label="Description"
        layout="grid"
        variant="gray"
      >
        <form-field-input-textarea
          v-model="form.description"
          layout="grid"
          placeholder="Vendor description"
          rows="3"
          variant="gray"
        />
      </input-layout>
      <input-layout
        :error="form.errors.genre_tags"
        for-id="vendor-label"
        label="Genre"
        layout="grid"
        variant="gray"
      >
        <form-field-select-tag
          v-model:selected="form.genre_tags"
          type="game"
          variant="gray"
        />
      </input-layout>
    </template>
    <template #details>
      <form-input-group-heading>Details</form-input-group-heading>
      <input-layout
        label="Play time"
        layout="grid"
        variant="gray"
      >
        <div class="flex items-center gap-3">
          <form-field-input-duration
            v-model="form.min_minutes"
            :error="form.errors.min_minutes"
            placeholder="Min"
            variant="gray"
            wrapper-class="w-24"
          />
          -
          <form-field-input-duration
            v-model="form.max_minutes"
            :error="form.errors.max_minutes"
            placeholder="Max"
            variant="gray"
            wrapper-class="w-24"
          />
        </div>
      </input-layout>
      <input-layout
        label="Availability Date"
        layout="grid"
        variant="gray"
      >
        <form-field-date-picker
          v-model="form.release_date"
          :error="form.errors.release_date"
          variant="gray"
        />
      </input-layout>
      <input-layout
        label="Minimum Age"
        layout="grid"
        variant="gray"
      >
        <form-field-input
          v-model="form.min_age"
          :error="form.errors.min_age"
          :rules="{ min: 0 }"
          min="0"
          placeholder="Enter minimum age"
          type="number"
          variant="gray"
        />
      </input-layout>
      <input-layout
        label="Number of players"
        layout="grid"
        variant="gray"
      >
        <div class="flex items-center gap-3">
          <form-field-input
            v-model="form.min_players"
            :error="form.errors.min_players"
            :rules="{ min: 0 }"
            min="0"
            placeholder="Min"
            type="number"
            variant="gray"
            width-by-content
            wrapper-class="max-w-[80px]"
          />
          -
          <form-field-input
            v-model="form.max_players"
            :error="form.errors.max_players"
            :rules="{ min: form.min_players ?? 0 }"
            min="0"
            placeholder="Max"
            type="number"
            variant="gray"
            width-by-content
            wrapper-class="max-w-[80px]"
          />
        </div>
      </input-layout>
    </template>
    <template #branding>
      <form-input-group-heading>Branding</form-input-group-heading>
      <form-field-image-type-collection
        :config="[
          {
            label: 'Thumb',
            name: 'thumbnail',
            width: 1080,
            height: 1080,
          },
          {
            label: 'Banner',
            name: 'banner',
            width: 1500,
            height: 500,
          },
          {
            label: 'Social',
            name: 'social',
            width: 1200,
            height: 630,
          },
        ]"
        :images="{
          thumbnail: form.thumbnail,
          banner: form.banner,
          social: form.social,
        }"
        @update:images="
          (images) => {
            form.thumbnail = images.thumbnail
            form.banner = images.banner
            form.social = images.social
          }
        "
      />
    </template>
    <template #sessions>
      <form-input-group-heading>Sessions</form-input-group-heading>
      <button
        @click="
          resourceModalHandler.openNew('activity', {
            urlParams: {
              convention_event_id: selectedEvent?.id,
            },
            payload: {
              type: {
                name: 'game',
              },
              attributes: {
                games: {
                  value: [{ id: form.id }],
                },
              },
            },
          })
        "
      >
        add session
      </button>
    </template>
  </modal-submenu-layout>
</template>

<script setup>
import ModalSubmenuLayout from '@/components/modal/layout/ModalSubmenuLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import FormFieldImageTypeCollection from '@/components/input/FormFieldImageTypeCollection.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import FormInputGroupHeading from '@/components/ui/FormInputGroupHeading.vue'
import FormFieldSelectGame from '@/components/input/FormFieldSelectGame.vue'
import FormFieldInputDuration from '@/components/input/FormFieldInputDuration.vue'
import FormFieldDatePicker from '@/components/input/FormFieldDatePicker.vue'
import Game from '@/models/Game'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import useConventionList from '@/utils/composables/conventionList'

const props = defineProps({
  form: {
    type: [Game, Object],
    required: true,
  },
})
const resourceModalHandler = useResourceModal()
const { selected: selectedEvent } = useConventionList()
</script>

<style scoped></style>
