<template>
  <li
    :data-id="comment.id"
    class="flex flex-col border-gray-200 py-4 first:pt-0 last:border-b-0 last:pb-0"
  >
    <div class="flex flex-row gap-3">
      <div class="flex shrink-0 flex-col items-center gap-5">
        <background-image-component
          :src="comment.sender?.avatar?.path"
          class="h-10 w-10 cursor-pointer overflow-hidden rounded-full bg-contain"
          @click="router.visit('/user/' + comment.sender.id)"
        >
          <div
            v-if="comment.sender.blocked"
            class="h-full w-full backdrop-blur-sm backdrop-grayscale"
          />
        </background-image-component>
        <div
          v-if="comment.replies?.length || isReplyToOpen"
          class="w-[3px] grow rounded-full bg-gray-100"
        />
      </div>
      <div class="flex grow flex-col gap-3">
        <!-- Text -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-row items-center gap-2">
            <span
              :class="{
                'blur-sm': comment.sender.blocked,
              }"
              class="cursor-pointer text-sm font-bold"
              @click="router.visit('/users/' + comment.sender.id)"
            >
              {{ comment.sender.first_name ?? '@' + comment.sender.username }}
            </span>
            <span class="text-sm text-gray-400">• {{ relativeTime }}</span>
          </div>
          <div
            v-if="comment.sender.blocked && !forceVisible"
            class="text-sm text-slate-700"
          >
            This comment is hidden because you blocked this user.
            <span
              class="cursor-pointer text-blue-neon underline"
              @click="forceVisible = true"
            >
              Do you want to see it anyway?
            </span>
          </div>
          <div
            v-else
            class="text-sm text-slate-700"
          >
            <template
              v-for="(string, index) in parsedStrings"
              :key="index"
            >
              <Link
                v-if="string.link"
                :class="string.class"
                :href="string.link"
              >
                {{ string.text }}
              </Link>
              <span
                v-else
                :class="string.class"
              >
                {{ string.text }}
              </span>
            </template>
          </div>
        </div>

        <!-- Actions -->
        <div class="flex items-center gap-6">
          <!-- voting -->
          <div
            v-if="!disableVoting"
            class="flex flex-row items-center gap-2"
          >
            <upvote-button
              :voted="comment.vote === 1"
              @click="upvote"
            />
            <div class="flex h-full items-center justify-center">
              <transition
                mode="out-in"
                name="flip"
              >
                <div
                  :key="comment.rating"
                  :class="{
                    'text-gray-400': comment.vote === 0,
                    'text-blue-neon': comment.vote === 1,
                    'text-[#D82525]': comment.vote === -1,
                  }"
                  class="text-xs"
                >
                  {{ displayValue(comment.rating) }}
                </div>
              </transition>
            </div>
            <upvote-button
              :voted="comment.vote === -1"
              downvote
              @click="downvote"
            />
          </div>

          <!-- replies -->
          <button
            v-if="!disableComments && comment.replies?.length"
            :class="{
              'text-blue-neon': repliesOpen,
              'text-gray-400': !repliesOpen,
            }"
            class="flex items-center gap-2 text-xs"
            @click="repliesOpen = !repliesOpen"
          >
            <svg
              fill="none"
              height="12"
              viewBox="0 0 12 12"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.26644 10.6189L3.15542 9.90773L3.16195 9.90272C3.34729 9.75443 3.44081 9.67959 3.54515 9.62633C3.63876 9.5785 3.73858 9.54367 3.84155 9.5225C3.95762 9.4987 4.07852 9.4987 4.32121 9.4987H9.38514C10.0373 9.4987 10.3637 9.4987 10.613 9.37165C10.8325 9.25982 11.0111 9.08115 11.1229 8.86164C11.25 8.61232 11.25 8.28624 11.25 7.63413V3.1969C11.25 2.54478 11.25 2.21823 11.1229 1.96891C11.0111 1.74939 10.8322 1.57104 10.6126 1.45919C10.3631 1.33203 10.0368 1.33203 9.38345 1.33203H2.61678C1.96339 1.33203 1.63645 1.33203 1.38688 1.45919C1.16736 1.57104 0.989009 1.74939 0.877161 1.96891C0.75 2.21848 0.75 2.54542 0.75 3.19881V9.89023C0.75 10.5119 0.75 10.8226 0.877435 10.9823C0.988263 11.1211 1.15626 11.2019 1.3339 11.2017C1.53817 11.2015 1.78101 11.0073 2.26644 10.6189Z"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ comment.replies_count }}</span>
          </button>

          <!-- reply -->
          <button
            v-if="!disableReplies"
            :class="{
              'text-gray-400 hover:text-gray-600': !isReplyToOpen,
              'text-blue-neon': isReplyToOpen,
            }"
            class="text-xs"
            @click="toggleReplyTo"
          >
            <font-awesome-icon
              class="h-3 w-3"
              icon="reply"
            />
            Reply
          </button>
        </div>

        <div
          v-if="repliesOpen"
          class="flex flex-col"
        >
          <ul class="flex w-full flex-col gap-3">
            <activity-comment
              v-for="reply in comment.replies"
              :key="reply.id"
              :active-reply-id="getActiveReplyId"
              :comment="reply"
              :root="false"
              @reply-to-id="updateReplyToId"
            />
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="isReplyToOpen"
      class="sticky bottom-0 flex gap-4"
    >
      <comment-form
        v-model:text="replyText"
        :sending="sending"
        autofocus
        class="w-full shadow-2xl"
        show-cancel
        @cancel="closeReplyTo"
        @send="sendReply"
      />
    </div>
  </li>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import UpvoteButton from '@/components/buttons/UpvoteButton.vue'
import { DateTime } from 'luxon'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import axios from 'axios'
import CommentForm from '@/components/input/CommentForm.vue'
import { useEcho } from '@/utils/echo'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import { Link, router } from '@inertiajs/vue3'

const emit = defineEmits(['replyToId'])

const props = defineProps({
  comment: {
    type: Object,
    required: true,
  },
  disableVoting: {
    type: Boolean,
    default: false,
  },
  disableComments: {
    type: Boolean,
    default: false,
  },
  disableReplies: {
    type: Boolean,
    default: false,
  },
  root: {
    type: Boolean,
    default: true,
  },
  activeReplyId: {
    type: Number,
    default: null,
  },
  rules: {
    type: Array,
    required: false,
    default: () => [
      {
        regex: /@([a-zA-Z0-9_.-]+)/g,
        class: 'text-blue-neon',
        dropdown: 'users',
        link: (match) => `/user/@${match[1]}`,
      },
    ],
  },
})

const repliesLoading = ref(false)
const forceVisible = ref(false)

function toggleReplies() {
  repliesOpen.value = !repliesOpen.value
  repliesLoading.value = true
  if (repliesOpen.value) {
    axios
      .get(`/api/comments/${props.comment.id}/replies`)
      .then((res) => {
        console.log(res.data)
        props.comment.replies = res.data
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        repliesLoading.value = false
      })
  }
}

const replyText = ref(``)
const replyToOpen = ref(null)
const repliesOpen = ref(true)
const echo = useEcho()
let calculationTimeout = null
const relativeTime = ref(null)
onMounted(() => {
  calculateRelativeTime()
})

onBeforeUnmount(() => {
  clearTimeout(calculationTimeout.value)
})

const body = computed(() => {
  return props.comment.body.replace(
    /@([a-zA-Z0-9_]+)/g,
    '<span class="text-blue-neon">$&</span>'
  )
})

const parsedStrings = computed(() => {
  let text = props.comment.body

  if (text === '') return []
  // Parse text into array of strings based on rules
  const strings = []
  let last = 0
  for (const rule of props.rules) {
    const matches = text.matchAll(rule.regex)
    for (const match of matches) {
      if (text.slice(last, match.index) !== '')
        strings.push({
          text: text.slice(last, match.index),
          class: '',
        })
      strings.push({
        text: match[0],
        class: rule.class,
        replace: rule.replace,
        dropdown: rule.dropdown,
        link: rule.link(match),
      })
      last = match.index + match[0].length
    }
  }
  if (last !== text.length)
    strings.push({
      text: text.slice(last),
      class: '',
    })
  return strings
})

const sending = ref(false)

const getActiveReplyId = computed(() => {
  if (props.root) {
    return replyToOpen.value
  } else {
    return props.activeReplyId
  }
})
const isReplyToOpen = computed(() => {
  if (props.root) {
    if (replyToOpen.value) {
      return replyToOpen.value === props.comment.id
    }
  } else {
    if (props.activeReplyId) {
      return props.activeReplyId === props.comment.id
    }
  }
  return false
})

function updateReplyToId(id) {
  if (props.root) {
    replyToOpen.value = id
  } else {
    emit('replyToId', id)
  }
}

function toggleReplyTo() {
  if (isReplyToOpen.value) {
    if (getActiveReplyId.value === props.comment.id) {
      closeReplyTo()
    } else {
      openReplyTo()
    }
  } else {
    openReplyTo()
  }
}

function closeReplyTo() {
  if (props.root) {
    console.log('close root')
    replyToOpen.value = null
  } else {
    console.log('close reply')
    emit('replyToId', null)
  }
}

function openReplyTo(event) {
  if (props.root) {
    console.log('open root')
    replyToOpen.value = props.comment.id
  } else {
    console.log('open reply')
    emit('replyToId', props.comment.id)
  }
}

function sendReply(data) {
  sending.value = true
  axios
    .post(`/api/comments/${props.comment.id}/replies`, data, {
      headers: {
        // Include the socket ID so the server can exclude the sender from the broadcast
        'X-Socket-ID': echo.socketId(),
      },
    })
    .then((response) => {
      if (!props.comment.replies) {
        props.comment.replies = []
      }
      props.comment.replies.push(response.data)
      closeReplyTo()
      props.comment.replies_count++
      repliesOpen.value = true
      replyText.value = ``
    })
    .finally(() => {
      sending.value = false
    })
}

function calculateRelativeTime() {
  const datetime = DateTime.fromISO(props.comment.created_at)
  const diff = DateTime.now().diff(datetime)
  relativeTime.value = datetime.toRelative()

  if (diff.as('seconds') < 60) {
    calculationTimeout = setTimeout(calculateRelativeTime, 1000)
  } else if (diff.as('minutes') < 60) {
    calculationTimeout = setTimeout(calculateRelativeTime, 1000 * 60)
  } else if (diff.as('hours') < 24) {
    calculationTimeout = setTimeout(calculateRelativeTime, 1000 * 60 * 60)
  } else {
    calculationTimeout = setTimeout(calculateRelativeTime, 1000 * 60 * 60 * 24)
  }
}

function displayValue(number) {
  if (number > 1000000) {
    return `${(number / 1000000).toFixed(1)}m`
  } else if (number > 1000) {
    return `${(number / 1000).toFixed(1)}k`
  }

  return number
}

function upvote() {
  if (props.comment.vote === 1) {
    props.comment.rating -= 1
  } else if (props.comment.vote === -1) {
    props.comment.rating += 2
  } else {
    props.comment.rating += 1
  }
  props.comment.vote = props.comment.vote === 1 ? 0 : 1
  axios
    .post(
      `/api/comments/${props.comment.id}/upvote`,
      {},
      {
        headers: {
          // Include the socket ID so the server can exclude the sender from the broadcast
          'X-Socket-ID': echo.socketId(),
        },
      }
    )
    .then((response) => {
      props.comment.rating = response.data.rating
      props.comment.vote = response.data.vote
    })
}

function downvote() {
  if (props.comment.vote === 1) {
    props.comment.rating -= 2
  } else if (props.comment.vote === -1) {
    props.comment.rating += 1
  } else {
    props.comment.rating -= 1
  }
  props.comment.vote = props.comment.vote === -1 ? 0 : -1
  axios
    .post(
      `/api/comments/${props.comment.id}/downvote`,
      {},
      {
        headers: {
          // Include the socket ID so the server can exclude the sender from the broadcast
          'X-Socket-ID': echo.socketId(),
        },
      }
    )
    .then((response) => {
      props.comment.rating = response.data.rating
      props.comment.vote = response.data.vote
    })
}
</script>

<style scoped>
.flip-enter-active,
.flip-leave-active {
  transition: all 0.15s ease;
  backface-visibility: hidden;
}

/*.flip-enter-to,*/
/*.flip-leave-from {*/
/*  transform: rotateX(0deg);*/
/*}*/

.flip-enter-from,
.flip-leave-to {
  transform: rotateX(-100deg);
}
</style>
