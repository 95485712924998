<template>
  <div
    ref="container"
    class="flex h-16 w-full gap-2"
  >
    <div
      v-for="image in config"
      :key="image.name"
      class="flex h-full cursor-pointer overflow-hidden rounded bg-gray-100 bg-cover bg-center"
      :style="{
        width: getImageWidth(image) + 'px',
        backgroundImage: images[image.name]?.path
          ? 'url(' + images[image.name]?.path + ')'
          : '',
      }"
      @click="onImageClick($event, image)"
    >
      <div
        v-if="!images[image.name]?.path"
        class="m-1 flex grow flex-col items-center justify-center rounded border border-dashed border-blue-neon"
      >
        <h1 class="text-xs font-bold">
          {{ image.label }}
        </h1>
        <small
          :class="[getImageWidth(image) < 100 ? 'text-[10px]' : 'text-xs']"
          class="whitespace-nowrap"
        >
          {{ image.width }} x {{ image.height }}
        </small>
      </div>
      <div
        v-else
        class="flex grow flex-col items-center justify-center bg-gray-100 bg-opacity-75 opacity-0 duration-75 hover:opacity-100"
      >
        <font-awesome-icon
          class="text-2xl text-dark-grey"
          icon="trash"
        />
      </div>
      <input
        type="file"
        class="hidden"
        @change="updateFile($event, image)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const emit = defineEmits(['update:images'])


















































defineOptions({
  name: 'FormFieldImageTypeCollection',
})


















































/**
 * Image uploader for different image types.
 * @usage <form-field-image-type-collection
 *  :config="[
 *    {
 *      label: 'Logo',
 *      name: 'logo', // this is the key of the image object
 *      width: 100,
 *      height: 100,
 *    },
 *    {
 *      label: 'Cover',
 *      name: 'cover',
 *      width: 100,
 *      height: 50,
 *    },
 *    {
 *      label: 'Something',
 *      name: 'something',
 *      width: 1000,
 *      height: 400,
 *    }
 *  ]"
 *  // pass the images as single object
 *  :images="{
 *    logo: logoImageVariable,
 *    cover: coverImageVariable,
 *    something: somethingImageVariable,
 *  }
 *  // when the images are updated, the event will be emitted with all images
 *  v-on:update:images="({logo, cover, something}) => {
 *   logoImageVariable = logo
 *   coverImageVariable = cover
 *   somethingImageVariable = something
 *  }"
 * />
 * @example {
 *   "title": "Image Type Collection",
 *   "attrs": {
 *     "config": [
 *      {
 *       "label": "Logo",
 *       "name": "logo",
 *       "width": 100,
 *       "height": 100
 *      },
 *      {
 *       "label": "Cover",
 *       "name": "cover",
 *       "width": 100,
 *       "height": 50
 *      },
 *      {
 *       "label": "Something",
 *       "name": "something",
 *       "width": 1000,
 *       "height": 400
 *      }
 *     ],
 *     "images": {
 *      "logo": {"path":"https://picsum.photos/100/100?random=1"},
 *      "cover": {"path":"https://picsum.photos/100/50?random=1"},
 *      "something": {"path":"https://picsum.photos/1000/400?random=1"}
 *     }
 *   }
 * }
 */


const props = defineProps({
  /**
   * The config for the images.
   * 'label' is used for the title of the image.
   * 'name' is the key of the image object, used to track changes and emit the event.
   * 'width' and 'height' are used to calculate the width of the image container.
   * @example [
   *  {
   *   label: 'Logo',
   *   name: 'logo', // this is the key of the image object
   *   width: 100,
   *   height: 100,
   *  }
   * ]
   */
  config: {
    type: Array,
    default: () => [
      {
        label: 'Logo',
        name: 'logo',
        width: 100,
        height: 100,
      },
    ],
  },
  /**
   * The images object.
   * object key is the name of the image.
   * @example {
   *  logo: logoImageVariable,
   *  cover: coverImageVariable,
   *  something: somethingImageVariable,
   * }
   */
  images: {
    type: Object,
    default: () => ({
      logo: null,
    }),
  },
  /**
   * Use this to fill the container with the images.
   */
  fill: {
    type: Boolean,
    default: false,
  },
})

const container = ref(null)
const { width, height } = useElementSize(container)

function onImageClick(e, image) {
  if (props.images[image.name]?.path) {
    emit('update:images', {
      ...props.images,
      [image.name]: null,
    })
    e.currentTarget.querySelector('input').value = null
    return
  }
  e.currentTarget.querySelector('input').click()
}

function updateFile(e, image) {
  const img = {
    file: e.target.files[0],
    path: URL.createObjectURL(e.target.files[0]),
  }
  console.log('updateFile', img, image)

  emit('update:images', {
    ...props.images,
    [image.name]: img,
  })
}

const imageWidthSum = computed(() => {
  return props.config.reduce((acc, image) => {
    return acc + image.width
  }, 0)
})

function getImageWidth(image) {
  if (props.fill) {
    return (image.width / imageWidthSum.value) * width.value
  }
  return (image.width / image.height) * height.value
}
</script>

<style scoped></style>