import { ref, Ref } from 'vue'

export function useLoading(defaultState: boolean = true): {
  loading: Ref<boolean>
  failed: Ref<boolean>
  watchPromise: <T>(promise: Promise<T>) => Promise<T>
} {
  const loading = ref<boolean>(defaultState)
  const failed = ref<boolean>(false)

  return {
    loading,
    failed,
    watchPromise: async <T>(promise: Promise<T>): Promise<T> => {
      loading.value = true
      failed.value = false
      return promise
        .then((result) => {
          loading.value = false
          return result
        })
        .catch((error) => {
          loading.value = false
          failed.value = true
          throw error
        })
    },
  }
}

export function is_cached(src: string) {
  const image = new Image()
  image.src = src

  return image.complete
}
