<template>
  <div class="flex h-full w-full flex-col">
    <div
      ref="header"
      class="flex flex-col justify-between gap-y-4 bg-white px-3 py-4 @sm:gap-y-10 @sm:px-10 @sm:pt-10"
    >
      <div class="flex w-full flex-row justify-between">
        <h1
          class="font-heading text-xl font-semibold text-dark-grey @sm:text-[32px]"
        >
          Select an Event
        </h1>

        <button @click="emit('close')">
          <font-awesome-icon
            class="h-6 w-6 text-text-dark-100"
            icon="times"
          />
        </button>
      </div>
      <div class="flex gap-x-8 overflow-x-scroll @sm:gap-x-10">
        <h4
          v-if="current?.length"
          :class="
            activeSection === 'live'
              ? 'text-slate-950'
              : 'font-normal text-light-grey-100'
          "
          class="cursor-pointer font-heading"
          @click="scrollToSection(liveHeader, 'Live')"
        >
          Live
        </h4>
        <h4
          v-if="upcoming?.length"
          :class="
            activeSection === 'upcoming'
              ? 'text-slate-950'
              : 'font-normal text-light-grey-100'
          "
          class="cursor-pointer font-heading"
          @click="scrollToSection(upcomingHeader, 'Upcoming')"
        >
          Upcoming
        </h4>
        <h4
          v-if="past?.length"
          :class="
            activeSection === 'previous'
              ? 'text-slate-950'
              : 'font-normal text-light-grey-100'
          "
          class="cursor-pointer font-heading"
          @click="scrollToSection(previousHeader, 'Previous')"
        >
          Previous
        </h4>
      </div>
    </div>
    <div
      class="relative flex grow flex-col gap-y-10 overflow-y-auto px-3 pb-10 @sm:px-10"
      @scroll="onSectionScroll"
    >
      <div
        v-if="current?.length"
        ref="liveHeader"
        class="flex flex-col gap-y-4"
      >
        <event-presenter
          v-for="event in current"
          :key="event.id"
          :event="event"
          :viewing-event="event.id === selectedConvention?.id"
          class="mt-2"
          @view-event="viewEvent"
        />
      </div>

      <div
        v-if="upcoming?.length"
        ref="upcomingHeader"
        class="flex flex-col gap-y-4"
      >
        <h1
          v-if="current?.length"
          ref="upcomingHeader"
          class="font heading -mb-2 text-lg text-dark-grey"
        >
          Upcoming
        </h1>
        <event-presenter
          v-for="event in upcoming"
          :key="event.id"
          :event="event"
          :viewing-event="event.id === selectedConvention?.id"
          class="mt-2"
          @view-event="viewEvent"
        />
      </div>

      <div
        v-if="past?.length"
        ref="previousHeader"
        class="flex flex-col gap-y-4"
      >
        <h1
          v-if="current?.length || upcoming?.length"
          ref="previousHeader"
          class="font heading -mb-2 text-lg text-dark-grey"
        >
          Previous
        </h1>
        <event-presenter
          v-for="event in past?.slice().reverse()"
          :key="event.id"
          :event="event"
          :viewing-event="event.id === selectedConvention?.id"
          class="mt-2"
          @view-event="viewEvent"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import EventPresenter from '@/components/ui/EventPresenter.vue'
import useConventionList from '@/utils/composables/conventionList'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'

const emit = defineEmits(['close'])

const {
  list: conventions,
  selected: selectedConvention,
  current,
  upcoming,
  past,
  select: selectConvention,
} = useConventionList()

const activeSection = ref(current.value?.length ? 'live' : 'upcoming')
const viewingEventStatus = ref('')
const header = ref(null)
const liveHeader = ref(null)
const upcomingHeader = ref(null)
const previousHeader = ref(null)

function onSectionScroll(event) {
  const { scrollTop } = event.target
  const live = liveHeader.value?.offsetTop
  const upcoming = upcomingHeader.value?.offsetTop
  const previous = previousHeader.value?.offsetTop

  if (liveHeader.value && scrollTop < upcoming) {
    activeSection.value = 'live'
  } else if (upcomingHeader.value && scrollTop < previous) {
    activeSection.value = 'upcoming'
  } else {
    activeSection.value = 'previous'
  }
}

function viewEvent(event) {
  selectConvention(event)
  router.visit(`/events/${event.slug}`)
  emit('close')
}

const scrollToSection = (sectionName, viewingSection) => {
  if (sectionName) {
    sectionName.scrollIntoView({ behavior: 'smooth' })
    activeSection.value = viewingSection
  }
}
</script>

<style scoped></style>
