export function debounce(func: (...args: any) => void, wait: number, immediate = false) {
    let timeout;
    return function (...args: any) {
        const later = () => {
            timeout = null;
            if (!immediate)
                func.apply(this, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(this, args);
    };
}
