<template>
  <modal-submenu-layout
    :sections="[
      {
        label: 'General',
        value: 'general',
      },
      {
        label: 'Privacy',
        value: 'privacy',
      },
      {
        label: 'Prerequisites',
        value: 'prerequisites',
      },
      {
        label: 'Tickets',
        value: 'tickets',
      },
      {
        label: 'Prizes',
        value: 'prizes',
      },
    ]"
    disable-bottom
  >
    <template #top>
      <form-field-header-input class="w-full">
        <template #input>
          <form-field-input
            v-model="form.name"
            :error="form.errors.name"
            class="grow"
            input-class="font-bold"
            placeholder="Enter Raffle Name"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <template #general>
      <input-layout
        :error="form.errors.description"
        label="Description"
        layout="grid"
        variant="gray"
      >
        <form-field-input-textarea
          v-model="form.description"
          placeholder="Description"
          rows="3"
        />
      </input-layout>
      <input-layout
        :error="form.errors.short_name"
        label="Short Name"
        layout="grid"
        variant="gray"
      >
        <form-field-input
          v-model="form.short_name"
          placeholder="Short Name"
        />
      </input-layout>
      <input-layout
        :error="form.errors.short_name"
        label="Starts & End time"
        layout="grid"
        variant="gray"
      >
        <div class="flex grow items-center gap-3">
          <form-field-date-picker
            v-model="form.starts_at"
            class="text-base"
            placeholder="Start time"
            with-time
          />
          -
          <form-field-date-picker
            v-model="form.ends_at"
            class="text-base"
            placeholder="End time"
            variant="gray"
            with-time
          />
        </div>
      </input-layout>
      <input-layout
        :error="form.errors.short_name"
        label="Announces At"
        layout="grid"
        variant="gray"
      >
        <form-field-date-picker
          v-model="form.announces_at"
          class="text-base"
          placeholder="Announces At"
          with-time
        />
      </input-layout>
      <input-layout
        label="Branding"
        layout="col"
        variant="gray"
      >
        <form-field-image-type-collection
          :config="[
            {
              label: 'Thumbnail',
              name: 'thumbnail',
              width: 1080,
              height: 1080,
            },
            {
              label: 'Banner',
              name: 'banner',
              width: 1500,
              height: 500,
            },
            {
              label: 'Social',
              name: 'social',
              width: 1200,
              height: 630,
            },
          ]"
          :images="{
            thumbnail: form.thumbnail,
            banner: form.banner,
            social: form.social,
          }"
          @update:images="
            ({ thumbnail, banner, social }) => {
              form.thumbnail = thumbnail
              form.banner = banner
              form.social = social
            }
          "
        />
      </input-layout>
    </template>
    <template #privacy>
      <form-input-group-heading>Privacy</form-input-group-heading>
      <input-layout
        label="Open"
        layout="grid"
        variant="gray"
      >
        <div class="flex gap-3">
          <div>
            <form-field-toggle v-model="form.is_open" />
          </div>
          <small class="text-sm text-gray-700">
            <template v-if="form.is_open">
              All users can join this raffle.
            </template>
            <template v-else>Only invited users can join this raffle.</template>
          </small>
        </div>
      </input-layout>
    </template>
    <template #prerequisites>
      <form-input-group-heading>Prerequisites</form-input-group-heading>
    </template>
    <template #tickets>
      <form-input-group-heading>Tickets</form-input-group-heading>
      <input-layout
        label="Enable Fankind Tickets"
        layout="grid"
        variant="gray"
      >
        <div class="flex gap-3">
          <div>
            <form-field-toggle v-model="form.is_global_sources_enabled" />
          </div>
          <small class="text-sm text-gray-700">
            <template v-if="form.is_global_sources_enabled">
              Allow users to spend their Fankind tickets on this raffle prizes.
            </template>
            <template v-else>
              Users can only spend tickets earned from selected sources on this
              raffle prizes.
            </template>
          </small>
        </div>
      </input-layout>
      <input-layout
        label="Ticket Sources"
        layout="col"
        variant="gray"
      >
        <div class="flex flex-col gap-3">
          <small class="text-sm text-gray-700">
            Select the sources where users can earn tickets from.
          </small>
          <div
            v-if="form.is_global_sources_enabled"
            key="fankind-source"
            class="flex justify-between rounded-lg bg-gradient-to-b from-blue-primary from-50% to-blue-900 px-2 py-1 text-white"
          >
            Fankind Tickets
          </div>
          <div class="flex flex-col divide-y rounded-lg border">
            <div
              v-for="source in form.ticket_sources"
              :key="source.id"
              class="flex justify-between px-2 py-1 hover:bg-gray-100"
              @click="
                resourceModal.openEdit('ticket-source', source.id, {
                  urlParams: {
                    convention_event_id: selectedEvent.id,
                    raffle_id: form.id,
                  },
                  onSaved: (response) => {
                    const index = form.ticket_sources.findIndex(
                      (item) => item.id === source.id
                    )
                    form.ticket_sources[index] = response.data
                  },
                })
              "
            >
              <span class="line-clamp-1">
                {{ source.name }}
              </span>
              <button
                @click.stop="
                  axios
                    .delete(
                      `/api/partner/conventions/${selectedEvent.id}/raffles/${form.id}/sources/${source.id}`
                    )
                    .then(() => {
                      const index = form.ticket_sources.findIndex(
                        (item) => item.id === source.id
                      )
                      form.ticket_sources.splice(index, 1)
                    })
                "
              >
                <font-awesome-icon :icon="['far', 'trash-can']" />
              </button>
            </div>
            <button
              key="add"
              class="flex w-full items-center justify-center px-2 py-1 hover:bg-gray-100"
              @click="
                resourceModal.openNew('ticket-source', {
                  urlParams: {
                    convention_event_id: selectedEvent.id,
                    raffle_id: form.id,
                  },
                  onSaved: (response) => {
                    form.ticket_sources.push(response.data)
                  },
                })
              "
            >
              <font-awesome-icon icon="plus" />
              <span class="ml-2">Add Ticket Source</span>
            </button>
          </div>
        </div>
      </input-layout>
    </template>
    <template #prizes>
      <form-input-group-heading>Prizes</form-input-group-heading>
      <div class="flex flex-col divide-y rounded-lg border">
        <div
          v-for="prize in form.prizes"
          :key="prize.id"
          class="flex justify-between px-2 py-1 hover:bg-gray-100"
          @click="
            resourceModal.openEdit('raffle-prize', prize.id, {
              urlParams: {
                convention_event_id: selectedEvent.id,
                raffle_id: form.id,
              },
              onSaved: (response) => {
                const index = form.prizes.findIndex(
                  (item) => item.id === source.id
                )
                form.prizes[index] = response.data
              },
            })
          "
        >
          <span class="line-clamp-1">
            {{ prize.name }}
          </span>
          <div class="flex gap-3 items-center">
            <span class="text-xs">
              {{ prize.winners_count }} /
              {{ prize.quantity }}
            </span>
            <button
              @click.stop="
                axios
                  .delete(
                    `/api/partner/conventions/${selectedEvent.id}/raffles/${form.id}/prizes/${prize.id}`
                  )
                  .then(() => {
                    const index = form.prizes.findIndex(
                      (item) => item.id === prize.id
                    )
                    form.prizes.splice(index, 1)
                  })
              "
            >
              <font-awesome-icon :icon="['far', 'trash-can']" />
            </button>
          </div>
        </div>
        <button
          class="flex w-full items-center justify-center px-2 py-1 hover:bg-gray-100"
          @click="
            resourceModal.openNew('raffle-prize', {
              urlParams: {
                convention_event_id: selectedEvent.id,
                raffle_id: form.id,
              },
              onSaved: (response) => {
                form.prizes.push(response.data)
              },
            })
          "
        >
          <font-awesome-icon icon="plus" />
          <span class="ml-2">Add Prize</span>
        </button>
      </div>
    </template>
  </modal-submenu-layout>
</template>

<script lang="ts" setup>
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import Raffle from '@/models/Raffle'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import ModalSubmenuLayout from '@/components/modal/layout/ModalSubmenuLayout.vue'
import FormInputGroupHeading from '@/components/ui/FormInputGroupHeading.vue'
import FormFieldToggle from '@/components/input/FormFieldToggle.vue'
import { ref } from 'vue'
import FormFieldDatePicker from '@/components/input/FormFieldDatePicker.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import useConventionList from '@/utils/composables/conventionList'
import axios from 'axios'
import FormFieldImageTypeCollection from '@/components/input/FormFieldImageTypeCollection.vue'

const props = defineProps({
  form: {
    type: [Object, Raffle],
    required: true,
  },
})

const resourceModal = useResourceModal()
const { selected: selectedEvent } = useConventionList()

const test = ref(false)

function beforeLeave(el) {
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped></style>