<template>
  <div
    ref="mapRef"
    class="map-container cursor-pointer hover:shadow-lg"
  />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import "ol/ol.css";
import { Feature, Map, View } from "ol";
import { fromLonLat, transform } from "ol/proj";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Style, Circle, Fill, Stroke } from "ol/style";
import { Point } from "ol/geom";

const props = defineProps({
    longitude: {
        type: [Number,String],
        required: true,
    },
    latitude: {
        type: [Number,String],
        required: true,
    },
    disableControls: {
        type: Boolean,
        default: false,
    },
});
const mapRef = ref(null);
let map;

onMounted(() => {
    map = new Map({
        // the map will be created using the 'map-root' ref
        target: mapRef.value,
        layers: [
            // adding a background tiled layer
            new TileLayer({
                source: new OSM(), // tiles are served by OpenStreetMap
            }),
        ],

        // the map view will initially show the whole world
        view: new View({
            zoom: 16,
            center: fromLonLat([props.longitude, props.latitude]),
            constrainResolution: true,
        }),
    });

    if (props.disableControls) {
        map.getControls().forEach(function (control) {
            map.removeControl(control)
        });

        map.getInteractions().forEach(function (interaction) {
            interaction.setActive(false);
        });
    }

    let marker = new Feature({
        geometry: new Point(fromLonLat([props.longitude, props.latitude])),
    });

    let markerSource = new VectorSource({
        features: [marker],
    });

    let markerLayer = new VectorLayer({
        source: markerSource,
        style: new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({ color: "red" }),
                stroke: new Stroke({ color: "white", width: 2 }),
            }),
        }),
    });

    map.addLayer(markerLayer);
});

watch(
    [() => props.latitude, () => props.longitude],
    ([newLat, newLon], [oldLat, oldLon]) => {
      if (newLat !== oldLat || newLon !== oldLon) {
        const newCenter = fromLonLat([newLon, newLat]);
        map.getView().setCenter(newCenter); // Update map center

        // Update marker position
        const marker = new Feature({
          geometry: new Point(newCenter),
        });

        const markerLayer = map.getLayers().getArray().find(layer => layer instanceof VectorLayer);
        if (markerLayer) {
          const markerSource = markerLayer.getSource();
          markerSource.clear(); // Remove previous marker
          markerSource.addFeature(marker); // Add new marker
        }
      }
    }
);
</script>

<style scoped>
.map-container {
    border-radius: 8px;
    overflow: hidden;
}

.map-container div canvas {
    border-radius: 8px;
}
</style>
