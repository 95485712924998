<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <form-field-header-input class="w-full">
        <template #input>
          <div
            ref="container"
            :class="
              showMiddleInput && !form.middle_name?.length ? 'gap-2' : 'gap-0'
            "
            class="group/header flex grow items-center"
            @click="showMiddleInput = true"
            @click.stop
          >
            <form-field-input
              v-model="form.first_name"
              :for-id="$.uid"
              placeholder="First"
              variant="gray"
              width-by-content
              wrapper-class="!px-1"
            />
            <form-field-input
              v-if="showMiddleInput"
              v-model="form.middle_name"
              placeholder="Middle"
              variant="gray"
              width-by-content
              wrapper-class="!px-1"
              @blur="isMiddleInputFocused = false"
              @focus="isMiddleInputFocused = true"
            />
            <form-field-input
              v-model="form.last_name"
              placeholder="Last"
              variant="gray"
              width-by-content
              wrapper-class="!px-1"
            />
          </div>
        </template>
      </form-field-header-input>
    </template>

    <div class="flex flex-col gap-4 pt-6">
      <form-input-group-layout>
        <div class="grid grid-cols-input-field items-start gap-3">
          <form-field-image
            v-model="form.avatar"
            all-squared
            class="h-[88px] w-[88px]"
          />
          <form-field-input-textarea
            v-model="form.description"
            placeholder="Bio"
            rows="3"
            variant="gray"
          />
        </div>
        <input-layout
          label="User caption"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.caption"
            placeholder="Caption"
            variant="gray"
          />
        </input-layout>
        <input-layout
          label="Profession tags"
          layout="grid"
          variant="gray"
        >
          <form-field-select-tag
            v-model:selected="form.profession_tags"
            type="profession"
            variant="gray"
          />
        </input-layout>
        <input-layout
          label="Role type"
          layout="grid"
          variant="gray"
        >
          <form-field-select-tag
            v-model:selected="form.role"
            single-select
            type="profession"
            variant="gray"
          />
        </input-layout>
      </form-input-group-layout>

      <hr />

      <form-input-group-layout label="Contact Info">
        <form-field-input-contact-detail
          v-model="form.phone_numbers"
          label="Phone Number"
          type="phone"
        />
        <form-field-input-contact-detail
          v-model="form.emails"
          label="Email"
          type="email"
        />
        <form-field-input-contact-detail
          v-model="form.socials"
          label="Online"
          type="social"
        />
      </form-input-group-layout>

      <hr />

      <form-input-group-layout label="Preferences">
        <input-layout
          label="Activity tags"
          layout="grid"
          variant="gray"
        >
          <form-field-select-tag
            v-model:selected="form.activity_tags"
            type="activity"
            variant="gray"
          />
        </input-layout>
        <input-layout
          label="Topic tags"
          layout="grid"
          variant="gray"
        >
          <form-field-select-tag
            v-model:selected="form.topic_tags"
            type="topic"
            variant="gray"
          />
        </input-layout>
        <input-layout
          label="Fandom tags"
          layout="grid"
          variant="gray"
        >
          <form-field-select-tag
            v-model:selected="form.fandom_tags"
            type="fandom"
            variant="gray"
          />
        </input-layout>
      </form-input-group-layout>

      <hr />

      <form-input-group-layout label="Representatives">
        <form-field-select-users-with-role v-model="form.representatives">
          <template #user-info="{ user }">
            <div class="flex h-full flex-col justify-between">
              <h3 class="text-base font-semibold">
                {{ user.name }}
              </h3>
              <p class="text-sm">
                {{ user.email }}
              </p>
            </div>
          </template>
        </form-field-select-users-with-role>
      </form-input-group-layout>

      <hr />

      <form-input-group-layout label="Clients">
        <form-field-select-users-with-role
          v-model="form.clients"
          type-of-users="clients"
        >
          <template #user-info="{ user }">
            <div class="flex h-full flex-col justify-between">
              <h3 class="text-base font-semibold">
                {{ user.name }}
              </h3>
              <p class="text-sm">
                {{ user.email }}
              </p>
            </div>
          </template>
        </form-field-select-users-with-role>
      </form-input-group-layout>
    </div>
  </modal-buttons-layout>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldImage from '@/components/input/FormFieldImage.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import FormFieldInputContactDetail from '@/components/input/FormFieldInputContactDetail.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import User from '@/models/User'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormFieldSelectUsersWithRole from '@/components/input/FormFieldSelectUsersWithRole.vue'

const props = defineProps({
  form: {
    type: [User, Object],
    required: true,
  },
})

const container = ref(null)
const showMiddleInput = ref(props.form.middle_name?.length > 0)

const isMiddleInputFocused = ref(false)

watch(
  () => props.form.middle_name,
  (newValue) => {
    if (newValue?.length > 0 || isMiddleInputFocused.value) {
      showMiddleInput.value = true
    }
  },
  { immediate: true }
)

const handleClickOutside = (event) => {
  if (
    container.value &&
    !container.value.contains(event.target) &&
    !props.form.middle_name?.length &&
    !isMiddleInputFocused.value
  ) {
    showMiddleInput.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<style scoped></style>
