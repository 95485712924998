<template>
  <div class="relative flex flex-col justify-end bg-white">
    <div class="flex flex-col gap-10 pb-6">
      <div class="bg-slate-200 p-2">
        <form-drop-file-input
          v-model="form.banner"
          :error="form.errors.banner"
          class="mx-auto h-[176px] w-full cursor-pointer border-text-white bg-slate-200 text-slate-400"
          cta-always-visible
          icon-class="text-slate-400"
          image-type
        />
      </div>
      <div class="flex w-full max-w-[760px] flex-col self-center">
        <div class="flex flex-col gap-14">
          <div class="relative flex flex-col gap-3">
            <h1
              class="font-roboto mb-4 select-none text-center text-2xl font-bold text-very-dark-blue md:text-3xl"
            >
              Create Activity
            </h1>
            <div class="flex w-full flex-col gap-8">
              <input-layout
                hint="Max 245 characters"
                label="Title"
              >
                <form-field-input
                  v-model="form.name"
                  :error="form.errors.name"
                  :rules="{ required: true, max: 245, min: 10 }"
                  placeholder="Give an awesome name"
                />
              </input-layout>

              <input-layout label="Description">
                <form-field-input
                  v-model="form.description"
                  :error="form.errors.description"
                  :rules="{ required: true }"
                  placeholder="Tell us about your meetup"
                  type="input"
                />
              </input-layout>

              <!-- HighlightedTags Section -->
              <tag-selector
                v-model="form.tags"
                :highlighted-tags="[]"
                @update:query="tagSearchQuery = $event"
              />
            </div>

            <form-select
              v-if="eventList?.length"
              :model-value="form.convention_event_id"
              :error="form.errors.convention_event_id"
              :options="eventList.map((e) => ({ value: e?.id, label: e.name }))"
              label="Convention"
              label-class="text-base text-paragraph font-bold"
              select-first
              @update:model-value="
                selectConventionEvent($event)
              "
            />
          </div>
        </div>

        <div
          v-if="form.convention_event_id"
          class="relative flex flex-col gap-3"
        >
          <h2 class="select-none text-xl text-dark-grey">Meetup Location</h2>
          <div class="flex flex-row gap-5">
            <button
              :class="[
                form.location_type === 'map_point'
                  ? 'bg-dark-blue text-white'
                  : 'cursor-pointer bg-transparent text-light-grey',
              ]"
              class="z-[2] rounded-lg border px-9 py-3 text-center text-lg text-light-grey duration-100 hover:bg-dark-blue hover:text-white"
              @click="form.location_type = 'map_point'"
            >
              Map Pin
            </button>
            <button
              v-show="
                selectedConventionEvent?.venues &&
                selectedConventionEvent.venues?.length > 0
              "
              :class="[
                form.location_type !== 'map_point'
                  ? 'bg-dark-blue text-white'
                  : 'cursor-pointer bg-transparent text-light-grey',
              ]"
              class="z-[2] rounded-lg border px-9 py-3 text-center text-lg text-light-grey duration-100 hover:bg-dark-blue hover:text-white"
              @click="form.location_type = 'venue'"
            >
              Venue
            </button>
          </div>

          <section
            class="flex flex-col gap-8 rounded-lg bg-slate-100 px-4 py-3 md:p-6"
          >
            <template v-if="form.location_type === 'map_point'">
              <div
                v-if="form.latitude && form.longitude"
                class="flex w-full flex-row gap-6"
              >
                <div class="flex w-full flex-col">
                  <input-field
                    v-model="form.latitude"
                    :error="form.errors.latitude"
                    label="Latitude"
                    placeholder="Set your latitude"
                  />
                </div>
                <div class="flex w-full flex-col">
                  <input-field
                    v-model="form.longitude"
                    :error="form.errors.longitude"
                    label="Longitude"
                    placeholder="Set your longitude"
                  />
                </div>
              </div>
              <div class="flex flex-row items-center gap-2">
                <div class="h-px w-full bg-slate-400" />
                <div class="w-fit whitespace-nowrap text-sm text-slate-400">
                  or set pin on map
                </div>
                <div class="h-px w-full bg-slate-400" />
              </div>
              <div
                v-if="showMapPopup === true"
                class="fixed bottom-0 left-0 right-0 top-0 z-30 items-center bg-white"
              >
                <div class="block flex h-[100%] max-h-[100%] w-full flex-col">
                  <div
                    class="relative relative flex w-full flex-col items-center gap-4 p-2 md:p-8"
                  >
                    <div
                      class="flex w-full flex-row items-start justify-between"
                    >
                      <div class="hidden md:flex" />
                      <div class="select-none text-left md:text-center">
                        <h4 class="text-2xl text-dark-grey">
                          Set the location
                        </h4>
                        <p class="text-base text-light-grey-100">
                          To select a location use this pin and select a spot
                        </p>
                      </div>
                      <button
                        class="cursor-pointer p-2 text-slate-500 hover:text-black"
                        @click="showMapPopup = false"
                      >
                        <font-awesome-icon icon="xmark" />
                      </button>
                    </div>
                    <div
                      class="relative flex h-72 w-full items-end justify-center md:h-[500px]"
                    >
                      <map-selector
                        :latitude="form.latitude"
                        :longitude="form.longitude"
                        class="absolute inset-0 z-10"
                        @update="
                          ({ latitude, longitude }) => {
                            form.latitude = latitude
                            form.longitude = longitude
                          }
                        "
                      />
                      <button
                        class="z-20 mb-10 rounded-full bg-dark-blue px-8 py-3 text-white hover:bg-black"
                        @click="showMapPopup = false"
                      >
                        Set this location
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <map-preview
                v-if="!showMapPopup"
                :latitude="
                  form.latitude ?? selectedConventionEvent?.latitude ?? 37.7749
                "
                :longitude="
                  form.longitude ??
                  selectedConventionEvent?.longitude ??
                  -122.4194
                "
                class="h-40 w-full"
                disable-controls
                @click="showMapPopup = true"
              />
            </template>
            <!-- Venue Section -->
            <template
              v-else-if="
                selectedConventionEvent?.combinedLocations &&
                selectedConventionEvent.combinedLocations?.length > 0
              "
            >
              <form-field-select-item
                v-if="selectedConventionEvent.combinedLocations?.length > 0"
                v-model:selected="form.location"
                :error="form.errors.location_id"
                :suggestions="
                  selectedConventionEvent.combinedLocationsSortedByParent
                "
                key-by="id"
                label="Venue"
                search-by="name"
                single-select
                variant="default"
                @update:selected="
                  (loc: ILocation) => {
                    form.location_id = loc?.id ?? null
                    form.location_type = loc?.locationType ?? null
                  }
                "
              >
                <template #item="{ item }">
                  <span v-if="item.locationType === 'venue_space'">---</span>
                  {{ item.name }}
                </template>
              </form-field-select-item>
            </template>
            <div class="flex flex-col">
              <label
                class="text-base"
                for="title"
              >
                Tips for finding us
              </label>
              <input
                id="location_directions"
                v-model="form.location_directions"
                class="border-bottom-1-5 relative flex flex-row overflow-hidden bg-transparent focus:outline-none"
                placeholder="Enter"
                type="text"
              />
              <span class="mt-1 text-xs text-gray-400">
                Share specific info on where to meet at the venue
              </span>
              <div
                v-if="form.errors.location_directions"
                class="text-red-500"
              >
                {{ form.errors.location_directions }}
              </div>
            </div>
          </section>
        </div>

        <div class="flex flex-col gap-3">
          <h2 class="select-none text-xl text-dark-grey">Meetup Date & Time</h2>
          <div
            v-if="selectedConventionEvent?.days?.length"
            class="flex w-full flex-row flex-nowrap gap-4 overflow-x-auto"
          >
            <template
              v-for="day in selectedConventionEvent.days"
              :key="day?.id"
            >
              <button
                :class="{
                  'bg-dark-blue text-white': form.event_day?.id === day?.id,
                  'bg-transparent text-light-grey':
                    form.event_day?.id !== day?.id,
                }"
                class="flex flex-col items-center whitespace-nowrap rounded-lg border px-9 py-3 text-lg hover:bg-dark-blue hover:text-white"
                @click="selectDay(day)"
              >
                <span class="font-heading text-sm">
                  {{
                    DateTime.fromISO(day.date, { setZone: true }).toFormat(
                      'ccc'
                    )
                  }}
                  {{
                    DateTime.fromISO(day.date, { setZone: true }).toFormat('dd')
                  }}
                </span>
                <h5 class="font-heading text-xl">
                  {{ day.caption }}
                </h5>
              </button>
            </template>
          </div>
          <section
            class="flex flex-col gap-8 rounded-lg bg-slate-100 px-4 py-3 md:p-6"
          >
            <div class="flex flex-col gap-5">
              <div
                v-if="form.event_day"
                class="flex w-full flex-row gap-16"
              >
                <form-select
                  v-model="form.starts_at"
                  :error="form.errors.starts_at"
                  :options="
                    availableStartTimes.map((t) => ({
                      value: t?.time,
                      label: t.readable,
                    }))
                  "
                  class="w-40"
                  label="Starts at"
                  placeholder="Select time"
                  underline
                />

                <form-select
                  v-model="form.ends_at"
                  :error="form.errors.ends_at"
                  :options="
                    availableEndTimes.map((t) => ({
                      value: t?.time,
                      label: t.readable,
                    }))
                  "
                  class="w-40"
                  label="Ends at"
                  placeholder="Select time"
                  underline
                />
              </div>
              <small
                v-if="selectedConventionEvent && !isInSameTimezone"
                class="flex w-fit flex-col rounded-lg bg-tangerine px-4 py-2 text-white"
              >
                <div>
                  Please set the time for
                  <span class="font-bold">
                    {{ selectedConventionEvent.timezone }}
                  </span>
                  ,
                </div>
                <div class="opacity-80">
                  {{ selectedConventionEvent.name }} is in that timezone.
                </div>
              </small>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MapSelector from '../../input/MapSelector.vue'
import TagSelector from '../../input/TagSelector.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'
import FormSelect from '../../input/FormSelect.vue'
import { DateTime } from 'luxon'
import InputField from '@/components/input/InputField.vue'
import MapPreview from '@/components/ui/MapPreview.vue'
import { ModelForm } from '@/utils/form'
import { EventActivity } from '@/models/EventActivity'
import useConventionList from '@/utils/composables/conventionList'
import ConventionEvent from '@/models/ConventionEvent'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import ILocation from '@/models/ILocation'

const emit = defineEmits(['closeModal'])

const props = defineProps({
  form: {
    type: Object as PropType<ModelForm<EventActivity>>,
    required: true,
  },
})

const {
  selected: selectedEvent,
  list: eventList,
  onLoaded: onEventsLoaded,
  fetch: fetchEvent,
} = useConventionList()

onMounted(() => {
  onEventsLoaded().then(() => {
    if (selectedEvent.value?.id) selectConventionEvent(selectedEvent.value.id)
  })
})

// popup to select map location
const showMapPopup = ref(false)

const isInSameTimezone = computed(() => {
  if (!props.form?.event_day?.date) return false
  return (
    DateTime.now().offset ===
    DateTime.fromISO(props.form?.event_day?.date, {
      setZone: true,
    }).offset
  )
})

const selectedConventionEvent = computed<ConventionEvent | undefined>(() => {
  return eventList.value?.find((e) => e?.id === props.form?.convention_event_id)
})

const availableStartTimes = computed(() => {
  if (!selectedConventionEvent.value) {
    return []
  }

  if (!props.form?.event_day) {
    return []
  }

  let startDate = DateTime.fromISO(props.form.event_day.start_time).toUTC()
  let endDate = DateTime.fromISO(
    props.form.ends_at ?? props.form.event_day.end_time
  ).toUTC()
  endDate.set({ minute: endDate.minute - 15 })

  return getTimesSpan(
    startDate,
    endDate,
    selectedConventionEvent.value.timezone
  )
})

const availableEndTimes = computed(() => {
  if (!selectedConventionEvent.value) {
    return []
  }

  if (!props.form?.event_day) {
    return []
  }

  let startDate = DateTime.fromISO(
    props.form.starts_at ?? props.form.event_day.start_time
  )
  let endDate = DateTime.fromISO(props.form.event_day.end_time)
  startDate.set({ minute: startDate.minute + 15 })

  return getTimesSpan(
    startDate,
    endDate,
    selectedConventionEvent.value.timezone
  )
})

function selectConventionEvent(id: number) {
  props.form.convention_event_id = id
  fetchEvent(id, {
    params: {
      with: ['venues', 'spaces'],
    },
  }).then(() => {
    if (!selectedConventionEvent.value) return
    props.form.latitude = selectedConventionEvent.value.latitude
    props.form.longitude = selectedConventionEvent.value.longitude
    console.log(props.form.latitude, props.form.longitude)
    props.form.location_id =
      selectedConventionEvent.value.venues?.[0]?.id ?? null
    props.form.event_day = selectedConventionEvent.value.days?.[0] ?? null

    // default to next available start time >= 8am today
    // if not available, default to the first available time
    // if no available time, default to null
    props.form.starts_at =
      availableStartTimes.value?.filter(
        (t) => t.datetime >= DateTime.now() && t.datetime.hour >= 8
      )?.[0]?.time ??
      availableStartTimes.value?.[0]?.time ??
      null
    props.form.ends_at = null

    props.form.location_type = 'venue'
    requestAnimationFrame(() => {
      props.form.location_type = 'map_point'
    })
  })
}

function selectDay(day) {
  props.form.event_day = day
  // update start/end times based on that day
  updateDate()
}

function updateDate() {
  if (!props.form?.event_day || !selectedConventionEvent.value) return
  let date = DateTime.fromISO(props.form.event_day.date, {
    zone: selectedConventionEvent.value?.timezone,
  })

  // convert date from utc to local time
  // this is because the date picker is in local time
  // to avoid day jumps, we need to convert the date to local time
  // before we set the time
  if (props.form.starts_at) {
    let starts_at = DateTime.fromISO(props.form.starts_at, {
      zone: 'UTC',
    }).setZone(selectedConventionEvent.value.timezone)
    props.form.starts_at = starts_at
      .set({
        year: date.year,
        month: date.month,
        day: date.day,
      })
      .toUTC()
      .toString()
  }
  if (props.form.ends_at) {
    let ends_at = DateTime.fromISO(props.form.ends_at, { zone: 'UTC' }).setZone(
      selectedConventionEvent.value.timezone
    )
    props.form.ends_at = ends_at
      .set({
        year: date.year,
        month: date.month,
        day: date.day,
      })
      .toUTC()
      .toString()
  }
}

function getTimesSpan(start_date: DateTime, end_date: DateTime, zone: string) {
  let times = [] as { datetime: DateTime; time: string; readable: string }[]

  while (start_date <= end_date) {
    times.push({
      datetime: start_date,
      time: start_date.toUTC().toISO() ?? '',
      readable: start_date.setZone(zone).toLocaleString(DateTime.TIME_SIMPLE),
    })
    // Add 15 minutes to the start time
    start_date = start_date.plus({ minutes: 15 })
  }

  return times
}
</script>

<style scoped></style>
