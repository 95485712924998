<template>
  <ul v-if="!loading || users.length">
    <li
      v-for="user in users"
      :key="user.id"
      class="flex items-center gap-3 px-2.5 hover:backdrop-brightness-90"
      @click="emit('select', user)"
    >
      <slot :user="user">
        <image-component
          :src="user.avatar"
          class="h-8 w-8 rounded-full"
        />
        <div>
          <div>{{ user.name }}</div>
          <div class="text-slate-700">
            @{{ user.username }}
          </div>
        </div>
      </slot>
    </li>
  </ul>
  <div v-else>
    <div
      v-if="loading"
      class="flex justify-center"
    >
      <app-spinner class="h-6 w-6" />
    </div>
    <div
      v-else
      class="text-center"
    >
      No users found
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import { debounce } from '@/utils/timing'
import AppSpinner from '@/components/ui/AppSpinner.vue'

const emit = defineEmits(['select'])

const props = defineProps({
  query: {
    type: String,
    required: true,
  },
})

const loading = ref(true)

watch(
  () => props.query,
  () => {
    findUsers()
  }
)
onMounted(() => {
  findUsers()
})

const users = ref([])
const findUsers = debounce(() => {
  console.log('here')
  if (props.query.length < 1) {
    return
  }
  let query = props.query.startsWith('@') ? props.query.slice(1) : props.query
  loading.value = true
  axios
    .get(`/api/autocomplete/users?query=${query}`)
    .then((response) => (users.value = response.data))
    .finally(() => (loading.value = false))
}, 500)
</script>

<style scoped></style>
