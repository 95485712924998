<template>
  <form-field-select-group
    :model-value="multiple ? modelValue?.map((d) => d.id) : modelValue?.id"
    :options="days.map((d) => ({ ...d, value: d.id }))"
    @update:model-value="select(days.find((d) => d.id === $event))"
  >
    <template #item="{ item }">
      <h1 class="line-clamp-1 select-none text-base font-normal">
        {{ item.name }}
      </h1>
      <small
        class="select-none whitespace-nowrap text-sm font-normal opacity-75"
      >
        {{ DateTime.fromISO(item.date).toFormat('ccc, LLL d') }}
      </small>
    </template>
  </form-field-select-group>
</template>

<script setup>
import { DateTime } from 'luxon'
import FormFieldSelectGroup from '@/components/input/FormFieldSelectGroup.vue'
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  /**
   * Array of days to display
   * either days or eventId must be provided. If both are provided, days will be used
   */
  days: {
    type: Array,
    default: () => undefined,
  },
  /**
   * The event id to fetch days from
   */
  eventId: {
    type: [Number, String],
    default: () => undefined,
  },
  /**
   * The selected day id
   */
  modelValue: {
    type: [Object, Array],
    default: null,
  },
  /**
   * Multiple days can be selected
   */
  multiple: {
    type: Boolean,
    default: false,
  },
})

const _days = ref([])
const days = computed(() => {
  if (props.days) {
    return props.days
  }
  if (props.eventId) {
    return _days.value
  }
  return []
})

function select(day) {
  if (props.multiple) {
    const selected = props.modelValue || []
    const index = selected.findIndex((d) => d.id === day.id)
    if (index === -1) {
      selected.push(day)
    } else {
      selected.splice(index, 1)
    }
    console.log(selected)
    emit('update:modelValue', selected)
  } else {
    emit('update:modelValue', day)
  }
}

onMounted(() => {
  if (props.eventId) {
    axios
      .get(`/api/autocomplete/events/${props.eventId}/days`)
      .then((res) => {
        _days.value = res.data
      })
      .catch((err) => {
        console.error(err)
      })
  }
})
</script>

<style scoped></style>
