import {Model} from "@/models/Model";
import Image from "@/models/Image";

export default class Tag extends Model {
    id: number | null = null;
    type: string = 'user';
    name: string = '';

    color_name: string = '';
    color: string = '';

    image: Image | null = null;
}