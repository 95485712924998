<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <form-field-header-input class="w-full" @delete="emit('delete')">
        <template #input>
          <input-field
            v-model="form.name"
            :error="form.errors.name"
            class="grow"
            placeholder="Add Venue Name"
            type="text"
            variant="gray" />
        </template>
      </form-field-header-input>
    </template>
    <form-input-group-layout>
      <input-layout label="Type" layout="grid" variant="gray">
        <form-field-select-item
          :disabled="form.type === 'type'"
          :selected="
            form.type
              ? types.find((type) => type.value === form.type) ?? {
                  label: form.type,
                  value: form.type,
                }
              : undefined
          "
          :suggestions="types"
          key-by="value"
          search-by="label"
          single-select
          @update:selected="form.type = $event?.value" />
      </input-layout>
      <template v-if="form.type !== 'type'">
        <input-layout label="Parent Category" layout="grid" variant="gray">
          <form-field-select-category
            v-model:selected="form.parent"
            :multiple="false"
            :type="form.type" />
        </input-layout>
        <form-input-group-heading>Attributes</form-input-group-heading>
        <form-field-input-category-attributes
          v-model="form.attributes"
          :errors="form.errors.attributes"
          :category-id="form.id" />
      </template>
      <pre>{{form.attributes}}</pre>
    </form-input-group-layout>
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import InputField from '@/components/input/InputField.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import Category from '@/models/Category'
import FormFieldSelectCategory from '@/components/input/FormFieldSelectCategory.vue'
import { ModelForm } from '@/utils/form'
import { PropType } from 'vue'
import FormFieldInputCategoryAttributes from '@/components/input/FormFieldInputCategoryAttributes.vue'
import FormInputGroupHeading from '@/components/ui/FormInputGroupHeading.vue'
import ButtonPlus from "@/components/buttons/ButtonPlus.vue";

const props = defineProps({
  form: {
    type: Object as PropType<ModelForm<Category>>,
    required: true,
  },
})

const types = [
  { label: 'Activity', value: 'activity' },
  { label: 'Vendor', value: 'vendor' },
  { label: 'Product', value: 'product' },
]
</script>

<style scoped></style>
