<template>
  <div class="min-h-[16px] shrink-0">
    <transition-group
      class="relative flex h-full w-full gap-3 overflow-x-scroll"
      enter-active-class="transition-all ease-in-out duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-all ease-in-out duration-300 absolute"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      move-class="transition-transform duration-300"
      tag="div"
    >
      <div
        v-if="
          (multiple && (max === 0 || imageCount < max)) ||
          (!multiple && imageCount === 0)
        "
        key="add-image"
        :class="{
          'aspect-square': !width && !height,
        }"
        class="h-full shrink-0 cursor-pointer rounded bg-gray-100 p-1 hover:bg-gray-200"
        @click="filePicker?.click()"
      >
        <div
          class="flex h-full w-full flex-col items-center justify-center gap-2 rounded border border-dashed border-blue-neon"
        >
          <font-awesome-icon
            class="text-blue-neon"
            icon="arrow-up-from-bracket"
          />
          <small
            v-if="width && height"
            class="whitespace-nowrap px-2 text-[10px]"
          >
            {{ width }} x {{ height }}
          </small>
        </div>
      </div>
      <div
        v-for="(file, index) in fileArray"
        :key="file.path"
        class="shrink-0 overflow-hidden rounded"
      >
        <div class="relative h-full w-full">
          <image-component
            :class="[allSquared ? 'aspect-square' : 'min-w-16']"
            :src="file.path"
            class="h-full object-cover object-center"
          />
          <div
            class="absolute inset-0 bg-dark-menu bg-opacity-0 opacity-0 duration-100 hover:bg-opacity-50 hover:opacity-100"
          >
            <button
              class="flex h-full w-full items-center justify-center"
              @click="removeFile(index)"
            >
              <font-awesome-icon
                class="text-white"
                icon="trash"
              />
            </button>
          </div>
        </div>
      </div>
    </transition-group>
    <input
      ref="filePicker"
      :accept="accept"
      :multiple="multiple"
      class="hidden"
      type="file"
      @change="onFileInput"
    />
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed, ref } from 'vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
/**
 * Image (or image array) uploader component.
 * @usage <form-field-image v-model="image" />
 * @usage <form-field-image v-model="images" multiple />
 * @example {
 *   "title": "Single image",
 *   "attrs": {
 *    "class": "h-16",
 *    "modelValue": {"path": "https://picsum.photos/1600/800?random=1"}
 *   }
 * }
 * @example {
 *  "title": "Multiple images",
 *  "attrs": {
 *   "class": "h-16",
 *   "multiple": true,
 *   "modelValue": [
 *    {"path": "https://picsum.photos/1600/800?random=1"},
 *    {"path": "https://picsum.photos/1600/800?random=2"},
 *    {"path": "https://picsum.photos/1600/800?random=3"}
 *   ]
 *  }
 * }
 */


const emit = defineEmits(['update:modelValue'])


















































defineOptions({
  name: 'FormFieldImage',
})


















































const props = defineProps({
  /**
   * Image or image array.
   * @model
   * @example {"path": "https://picsum.photos/1600/800?random=1"}
   * @example [
   *  {"path": "https://picsum.photos/1600/800?random=1"},
   *  {"path": "https://picsum.photos/1600/800?random=2"}
   * ]
   */
  modelValue: {
    type: [Object, Array],
    default: () => null,
  },
  /**
   * Allow multiple images.
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * Accepted file types.
   */
  accept: {
    type: String,
    default: 'image/*',
  },
  /**
   * Maximum number of images.
   */
  max: {
    type: Number,
    default: 0,
  },
  /**
   * Force all images to display squared.
   */
  allSquared: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [String, Number],
    default: () => undefined,
  },
  height: {
    type: [String, Number],
    default: () => undefined,
  },
})

const filePicker = ref(null)

const fileArray = computed(() => {
  if (props.multiple) {
    return props.modelValue
  }

  return props.modelValue ? [props.modelValue] : []
})

const imageCount = computed(() => {
  return fileArray.value.length
})

function loadFile(file) {
  return {
    file,
    path: URL.createObjectURL(file),
  }
}

function onFileInput(event) {
  const files = event.target.files

  if (props.multiple) {
    const newImages = []

    for (let i = 0; i < files.length; i++) {
      newImages.push(loadFile(files[i]))
    }

    emit('update:modelValue', [...props.modelValue, ...newImages])
  } else {
    emit('update:modelValue', loadFile(files[0]))
  }

  event.target.value = null
}

function removeFile(index) {
  if (props.multiple) {
    const newImages = [...props.modelValue]
    newImages.splice(index, 1)
    emit('update:modelValue', newImages)
  } else {
    emit('update:modelValue', null)
  }
}
</script>

<style scoped></style>
