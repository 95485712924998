<template>
  <div v-if="isVisible">
    <div
      class="flex flex-row items-center justify-between px-2 py-1 border-b"
      @click="isExpanded = !isExpanded"
    >
      Internal information (hidden for non-admins)
      <!-- chevron to expand -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        :style="'transform: rotate(' + (isExpanded ? 180 : 0) + 'deg)'"
        aria-hidden="true"
        class="ml-2 h-5 w-5 text-gray-400"
      >
        <path
          fill-rule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div
      v-if="isExpanded"
      class="bg-gray-800 text-white p-2 overflow-auto"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import {computed, inject, ref} from "vue";

const store = inject('store')

const adminEmails = ["nazar@hiddenplanetproductions.com", "mitch@fankind.app"] // TODO: remove after actual roles are implemented

const isVisible = computed(() => adminEmails.includes(store.currentUser?.email))
const isExpanded = ref(false)
</script>

<style scoped>

</style>
