<template>
  <modal-dialog-layout :title="title">
    <div
      :class="{
        'px-10': type === 'success' || type === 'email-invite' || type === 'confirm',
        'px-8': type === 'link-copy',
      }"
      class="flex flex-col items-center justify-center gap-12 py-6"
    >
      <div
        :class="{
          'text-center': type === 'success' || type === 'email-invite',
        }"
        class="flex flex-col gap-2"
      >
        <h1
          :class="{
            'font-bold text-slate-800': type === 'success',
            'line-clamp-1 font-semibold text-slate-500': type === 'link-copy',
          }"
          class="font-sans text-2xl"
        >
          {{ title }}
        </h1>
        <p
          :class="{
            'font-semibold text-slate-400': type === 'success',
            'font-regular text-slate-400': type === 'link-copy',
          }"
          class="text-base"
        >
          {{ subtitle }}
        </p>
        <template v-if="type === 'email-invite'">
          <form-field-input
            v-model="email"
            label="Email"
            placeholder="Enter email of the person you want to invite"
            type="email"
          />
        </template>
      </div>
      <div
        :class="{
          'flex justify-between': type === 'confirm',
        }"
        class="w-full"
      >
        <template v-if="type === 'link-copy'">
          <div
            class="flex w-full items-center gap-4 rounded-xl border border-slate-300 p-1 ps-4"
          >
            <div class="flex grow items-center gap-2">
              <icon-link class="h-5 w-5" />
              <p
                class="w-0 grow overflow-hidden text-ellipsis whitespace-nowrap text-base font-semibold text-slate-400"
              >
                {{ link }}
              </p>
            </div>
            <v-button
              :variant="copied ? 'secondary' : 'primary'"
              class="shrink-0 grow-0"
              size="sm"
              @click="copyLink"
            >
              {{ copied ? 'Copied' : sharingEnabled ? 'Share' : 'Copy' }}
            </v-button>
          </div>
        </template>
        <template v-else>
          <v-button
            v-if="secondary"
            size="md"
            variant="secondary"
            @click="emit('secondary')"
          >
            {{ secondary }}
          </v-button>
          <v-button
            v-if="primary"
            size="md"
            variant="primary"
            @click="emit('primary')"
          >
            {{ primary }}
          </v-button>
        </template>
      </div>
    </div>
  </modal-dialog-layout>
</template>

<script lang="ts" setup>
import ModalDialogLayout from '@/components/modal/layout/ModalDialogLayout.vue'
import VButton from '@/components/buttons/VButton.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import { inject, ref } from 'vue'
import IconLink from '@/icons/IconLink.vue'

const emit = defineEmits(['primary', 'secondary'])

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'success',
  },
  primary: {
    type: String,
    default: () => undefined,
  },
  secondary: {
    type: String,
    default: () => undefined,
  },
  link: {
    type: String,
    default: () => undefined,
  },
  linkShareData: {
    type: Object,
    default: () => undefined,
  },
})

const email = ref('')
const sharingEnabled = inject('device_sharing_enabled')
const copied = ref(false)

function copyLink() {
  console.log(
    props.linkShareData
      ? {
          url: props.link,
          ...props.linkShareData,
        }
      : {
          title: 'Fankind',
          text: 'Join me on Fankind',
          url: props.link,
        }
  )
  if (sharingEnabled)
    navigator
      .share(
        props.linkShareData
          ? {
              url: props.link,
              ...props.linkShareData,
            }
          : {
              title: 'Fankind',
              text: 'Join me on Fankind',
              url: props.link,
            }
      )
      .catch((err) => {
        console.debug('🫡 Sharing failed with error: ' + err)
      })
  else if (props.link) {
    navigator.clipboard.writeText(props.link)
    copied.value = true
  }
}
</script>

<style scoped></style>
