<template>
  <div class="flex flex-col items-start gap-3">
    <div
      v-if="(singleSelect && selectedArray.length === 0) || !singleSelect"
      :class="[
        {
          'items-center': labels === 'inline',
          'w-full': labels === 'top' || labels === 'none',
        },
        showButtonByDefault && !adding
          ? 'flex min-h-[44px] w-fit items-center'
          : 'flex min-h-[44px] items-center',
      ]">
      <slot
        v-if="
          (!disableButton && !adding && selectedArray.length !== 0) ||
          (showButtonByDefault && !adding) ||
          miniMode
        "
        :click="onClickAdd"
        name="button">
        <button-plus
          class="flex h-7 w-7 items-center justify-center"
          @click="onClickAdd"></button-plus>
      </slot>
      <field-select-text
        v-if="
          (((singleSelect && selectedArray.length === 0) || !singleSelect) &&
            !(
              (!disableButton && !adding && selectedArray.length !== 0) ||
              (showButtonByDefault && !adding)
            )) ||
          miniMode
        "
        ref="input"
        v-model="query"
        :allow-create="allowCreate"
        :autofocus="autofocus"
        :disabled="disabled"
        :is-empty="selectedArray.length === 0"
        :key-by="keyBy"
        :loading="loadingGetter"
        :mini-mode="miniMode"
        :placeholder="placeholder"
        :search-by="searchBy"
        :suggestions="suggestionsGetter"
        :variant="variant"
        class="grow"
        @blur="adding = false"
        @create="emit('create')"
        @focus="adding = true"
        @select="onSelect">
        <template #item="{ item }">
          <slot
            :item="item"
            :selected="selectedArray"
            :single-select="singleSelect"
            name="item">
            <div
              :class="{
                'text-blue-primary': selectedArray.find(
                  (selectedItem) => selectedItem[keyBy] === item[keyBy]
                ),
              }"
              class="flex items-center gap-1 whitespace-nowrap py-2">
              <font-awesome-icon
                v-if="!singleSelect"
                :class="
                  selectedArray.find(
                    (selectedItem) => selectedItem[keyBy] === item[keyBy]
                  )
                    ? 'h-4 w-4'
                    : 'invisible h-4 w-4'
                "
                icon="check" />
              <slot :item="item" name="item-label">
                <span>{{ item[searchBy] }}</span>
              </slot>
            </div>
          </slot>
        </template>
        <template #create>
          <slot name="create" />
        </template>
      </field-select-text>
    </div>

    <div
      v-if="labels !== 'none' && selectedArray.length"
      class="flex flex-wrap gap-2">
      <template v-for="(item, index) in selectedArray" :key="index">
        <slot :item="item" :remove="() => onSelect(item)" name="selected-item">
          <button
            :class="['px-2 py-1 text-sm']"
            class="flex items-center gap-2 whitespace-nowrap rounded bg-gray-100"
            @click="onSelect(item)">
            <slot :item="item" name="selected-item-label">
              {{ item[searchBy] }}
            </slot>
            <font-awesome-icon class="h-4 w-4 text-gray-600" icon="times" />
          </button>
        </slot>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import FieldSelectText from '@/components/input/FieldSelectText.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import { useDebounceFn } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'

const emit = defineEmits([
  'update:selected',
  'update:modelValue',
  'select',
  'create',
])

defineSlots<{
  button(props: { click: () => void }): any
  item(props: { item: any; selected: any[]; singleSelect: boolean }): any
  'item-label'(props: { item: any }): any
  create(): any
  'selected-item'(props: { item: any; remove: () => void }): any
  'selected-item-label'(props: { item: any }): any
}>()

const props = withDefaults(
  defineProps<{
    autofocus?: boolean
    variant?: string
    suggestions?: any[]
    selected?: Array<any> | any
    singleSelect?: boolean
    keyBy?: string
    searchBy?: string
    labels?: string
    modelValue?: string
    placeholder?: string
    showButtonByDefault?: boolean
    disableButton?: boolean
    loading?: boolean
    allowCreate?: boolean
    disabled?: boolean
    searchFunction?: (search: string) => Promise<any>
    miniMode?: boolean
    adding?: boolean
    resourceKey?: string
    searchUrl?: string
    searchQuery?: Record<string, any>
    except?: string | number | Array<string | number>
  }>(),
  {
    autofocus: false,
    variant: 'gray',
    singleSelect: false,
    keyBy: 'value',
    searchBy: 'name',
    labels: 'top',
    placeholder: '',
    showButtonByDefault: false,
    disableButton: false,
    loading: false,
    allowCreate: false,
    disabled: false,
    miniMode: false,
    adding: false,
    suggestions: undefined,
    selected: undefined,
    searchFunction: undefined,
    resourceKey: undefined,
    searchUrl: undefined,
    searchQuery: undefined,
    except: undefined,
    modelValue: undefined,
  }
)

/**
 * Inner model value, used to sync with the input if modelValue is not provided.
 * @private
 */
const _modelValue = ref<string>('')
const _loading = ref<boolean>(false)
const _suggestions = ref<Array<any>>([])
const searchDebounce = useDebounceFn(search, 300)
const query = computed<string>({
  get() {
    return props.modelValue ?? _modelValue.value
  },
  set(value: string) {
    if (props.modelValue !== undefined) {
      emit('update:modelValue', value)
    } else {
      _modelValue.value = value
    }

    if (props.searchFunction || props.searchUrl) {
      searchDebounce(value)
    }
  },
})

const exceptQuery = computed<string | undefined>(() => {
  if (props.except) {
    if (Array.isArray(props.except)) {
      // remove duplicates and join in a string
      return props.except
        .reduce(
          (acc, cur) => {
            if (!acc.includes(cur)) {
              acc.push(cur)
            }
            return acc
          },
          [] as Array<string | number>
        )
        .join(',')
    } else {
      return props.except.toString()
    }
  } else {
    return undefined
  }
})

const searchFunctionGetter = computed<
  ((search: string) => Promise<any>) | null
>(() => {
  if (props.searchFunction) {
    return props.searchFunction
  } else if (props.searchUrl) {
    return (value: string) => {
      return axios.get(props.searchUrl!, {
        params: {
          query: value,
          except: exceptQuery.value,
          ...props.searchQuery,
        },
      })
    }
  } else {
    return null
  }
})

const suggestionsGetter = computed(() => {
  if (props.suggestions) {
    return props.suggestions
  } else {
    if (props.resourceKey) {
      return queryData.value?.data
    } else {
      return _suggestions.value
    }
  }
})

const loadingGetter = computed(() => {
  if (props.loading) {
    return props.loading
  } else {
    if (props.resourceKey) {
      return queryData.value?.isLoading
    } else {
      return _loading.value
    }
  }
})

const adding = ref(props.adding)
const input = ref(null)

const selectedArray = computed(() => {
  if (props.selected) {
    if (Array.isArray(props.selected)) {
      return props.selected
    } else {
      return [props.selected]
    }
  } else {
    return []
  }
})

function search(value: string) {
  if (props.searchFunction || props.searchUrl) {
    if (props.resourceKey) {
      queryEnabled.value = !queryData.value.isFetched
    } else if (props.searchFunction) {
      _loading.value = true
      props
        .searchFunction(value)
        .then((res) => {
          _suggestions.value = res.data
        })
        .finally(() => {
          _loading.value = false
        })
    }
  }
}

function onSelect(item) {
  if (props.disabled) return
  emit('select', item)
  console.log('here',item?.locationType)
  if (!props.selected) {
    if (props.singleSelect) emit('update:selected', item)
    else emit('update:selected', [item])
  } else {
    if (props.singleSelect) {
      if (props.selected?.[props.keyBy] === item[props.keyBy]) {
        emit('update:selected', null)
      } else {
        emit('update:selected', item)
      }
    } else {
      const index = props.selected.findIndex(
        (selectedItem) => selectedItem[props.keyBy] === item[props.keyBy]
      )
      if (index === -1) {
        emit('update:selected', [...props.selected, item])
      } else {
        const newSelected = [...props.selected]
        newSelected.splice(index, 1)
        emit('update:selected', newSelected)
      }
    }
  }
}

function onClickAdd() {
  adding.value = true
  requestAnimationFrame(() => {
    input.value?.focus()
  })
}

const queryEnabled = ref<boolean>(!props.disabled)
const isQueryEnabled = computed(() => {
  return queryEnabled.value
})
const queryData = ref<any>()

onMounted(() => {
  if (props.resourceKey) {
    if (!searchFunctionGetter.value)
      throw new Error(
        'searchFunction or searchUrl is required when using resourceKey'
      )
    else {
      queryData.value = useQuery({
        enabled: isQueryEnabled,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        gcTime: 5 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
        retry: false,
        queryKey: [props.resourceKey, query],
        queryFn: async () => {
          let res = await searchFunctionGetter.value(query.value)
          queryEnabled.value = false
          return res
        },
        select: (data) => data.data,
      })
    }
  }

  if (searchFunctionGetter.value && !props.disabled) {
    search('')
  }
})

watch(
  () => props.disabled,
  (value) => {
    if (value) {
      search(query.value)
    } else {
      adding.value = false
    }
  }
)
</script>

<style scoped></style>
