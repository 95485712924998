<template>
  <modal-dialog-layout title="Import from Yelp">
    <div class="flex flex-col p-2">
      <form-field-input-search
        v-model="query"
        placeholder="Search for a business"
        @search="onSearch"
      />
      <div class="h-[500px]">
        <div
          v-if="!query.length"
          class="flex h-full flex-col items-center justify-center"
        >
          <brand-icon
            brand="yelp"
            class="h-16 text-blue-primary"
          />
          <h1 class="mt-2">Search for a business</h1>
          <p class="mt-2 text-sm">
            Add your favorite businesses from Yelp to your vendor list.
          </p>
        </div>
        <div
          v-else-if="loading || !results?.length"
          class="flex h-full flex-col items-center justify-center"
        >
          <app-spinner v-if="loading" />
          <div
            v-else
            class="text-center"
          >
            <brand-icon
              brand="yelp"
              class="h-16 text-blue-primary"
            />
            <h1 class="mt-2">No results found</h1>
            <p class="mt-2 text-sm">Try searching for something else.</p>
          </div>
        </div>
        <ul
          v-else
          class="flex flex-col gap-2 pt-3"
        >
          <li
            v-for="result in results"
            :key="result.id"
            class="flex gap-3"
          >
            <image-component
              :src="result.image_url"
              class="h-20 w-20 rounded object-cover"
            />
            <div class="flex flex-col gap-1">
              <div class="flex items-center gap-3">
                {{ result.name }}
                <span class="text-xs text-gray-500">
                  <font-awesome-icon
                    class="text-yellow-500"
                    icon="star"
                  />
                  ({{ result.rating }})
                </span>
                <span class="text-xs text-gray-500">
                  {{ result.price }}
                </span>
                <a
                  :href="result.url"
                  class="text-xs text-blue-primary"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View on Yelp
                  <brand-icon
                    brand="yelp"
                    class="ml-1 inline h-4"
                  />
                </a>
              </div>
              <div class="flex gap-2">
                <span
                  v-for="category in result.categories"
                  :key="category.alias"
                  class="rounded-full bg-black px-1.5 text-xs text-white"
                >
                  {{ category.title }}
                </span>
              </div>
              <div class="flex gap-3">
                <span class="text-xs text-gray-800">
                  {{ result.display_phone }}
                </span>
                <span class="text-xs text-gray-800">
                  {{ result.location.display_address.join(', ') }}
                </span>
              </div>
            </div>
            <div class="ml-auto flex items-center">
              <v-button
                class="rounded-full"
                size="md"
                variant="primary"
                @click="
                  axios
                    .post(
                      `/api/partner/conventions/${selectedEvent?.id}/yelp/import`,
                      { yelp_id: result.id }
                    )
                    .then((response) => {
                      emit('close')
                      resourceModal.openEdit('vendor', response.data.id)
                    })
                "
              >
                <font-awesome-icon icon="file-upload" />
                Add
              </v-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </modal-dialog-layout>
</template>

<script lang="ts" setup>
import ModalDialogLayout from '@/components/modal/layout/ModalDialogLayout.vue'
import FormFieldInputSearch from '@/components/input/FormFieldInputSearch.vue'
import { ref } from 'vue'
import BrandIcon from '@/components/ui/BrandIcon.vue'
import AppSpinner from '@/components/ui/AppSpinner.vue'
import axios from 'axios'
import useConventionList from '@/utils/composables/conventionList'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VButton from '@/components/buttons/VButton.vue'
import useResourceModal from '@/utils/composables/resourceModalHandler'

const emit = defineEmits(['close'])

const resourceModal = useResourceModal()

const query = ref('')
const loading = ref(false)
const results = ref([])
const { selected: selectedEvent } = useConventionList()

function onSearch(value: string) {
  loading.value = true
  if (!value.length) {
    loading.value = false
    results.value = []
    return
  }
  axios
    .get(`/api/autocomplete/events/${selectedEvent.value?.id}/yelp?q=${value}`)
    .then((response) => {
      results.value = response.data
      loading.value = false
    })
    .catch((error) => {
      console.log(error)
      loading.value = false
    })
}
</script>

<style scoped></style>
