import { Model } from '@/models/Model'
import Vendor from '@/models/Vendor'
import Image from '@/models/Image'

export default class VendorProduct extends Model {
  id: number | null = null
  name: string = ''
  description: string = ''
  price: number = 0
  currency: string = 'USD'
  serial_number: string = ''
  quantity: number = 0
  vendor: Vendor | null = null

  thumbnail: Image | null = null
  images: Array<Image> = []
  group: object | null = null
  categories: Array<unknown> = []
  recommended_products: VendorProduct[] | undefined = undefined
}
