<template>
  <div
    class="grid h-0 min-h-[600px] grid-cols-2 gap-5 overflow-hidden px-5 py-2"
  >
    <div>
      <div class="flex items-center justify-between py-5">
        <h1 class="text-2xl">Data Import Summary</h1>
        <span>
          <animate-number
            :duration="500"
            :value="Math.min(Math.max(Math.round(progress), 0), 100)"
          />
          %
        </span>
      </div>
      <div class="grid min-h-0 grow grid-cols-2">
        <div class="flex flex-col gap-2 text-paragraph">
          <div
            v-if="total"
            class="flex items-center gap-3"
          >
            <font-awesome-icon
              class="h-5 w-5 p-1 text-green"
              icon="check"
            />
            Total Records: {{ total }}
          </div>
          <div
            v-if="processed"
            class="flex items-center gap-3"
          >
            <font-awesome-icon
              class="h-5 w-5 p-1 text-green"
              icon="check"
            />
            Processed: {{ processed }}
          </div>
          <div
            v-if="duplicates"
            class="flex items-center gap-3"
          >
            <font-awesome-icon
              class="h-5 w-5 rounded-full bg-yellow p-1 text-white"
              icon="exclamation"
            />
            Duplicates: {{ duplicates }}
          </div>
          <div
            v-if="errors"
            class="flex items-center gap-3"
          >
            <font-awesome-icon
              class="h-5 w-5 rounded-full bg-red-800 p-1 text-white"
              icon="exclamation"
            />
            Errors: {{ errors }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex h-full flex-col gap-2 overflow-y-auto rounded-lg bg-gray-200 px-2 py-2"
    >
      <div
        v-for="thread in threads"
        :key="thread.id"
        :class="[
          thread.messages.find((msg) => msg.type === 'error')
            ? 'bg-red-300'
            : thread.messages.find((msg) => msg.type === 'warning')
              ? 'bg-orange-200'
              : thread.type === 'ai'
                ? 'bg-blue-neon text-white'
                : 'bg-gray-200',
        ]"
        class="flex flex-col rounded-md px-2 py-1 text-gray-900"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-1">
            <icon-sparks v-if="thread.type === 'ai'" class="h-4"/>
            <h6 class="line-clamp-1 font-medium">
              {{ thread.title }}
            </h6>
          </div>
          <div class="flex items-center gap-3">
            <font-awesome-icon
              v-if="thread.messages.find((msg) => msg.type === 'warning')"
              class="h-3 w-3 rounded-full bg-yellow-500 p-1 text-white"
              icon="exclamation"
            />

            <font-awesome-icon
              v-if="thread.messages.find((msg) => msg.type === 'error')"
              class="h-4 w-4 rounded-full bg-red-800 p-0.5 text-white"
              icon="times"
            />
            <font-awesome-icon
              v-else-if="thread.messages.find((msg) => msg.type === 'finished')"
              class="h-4 w-4 text-green-800"
              icon="check"
            />
            <font-awesome-icon
              v-else-if="thread.messages.find((msg) => msg.type === 'started')"
              class="h-4 w-4 animate-spin text-gray-800"
              icon="spinner"
            />
            <button
              v-if="thread.messages.length"
              @click="
                expanded.includes(thread.id)
                  ? expanded.splice(expanded.indexOf(thread.id), 1)
                  : expanded.push(thread.id)
              "
            >
              <font-awesome-icon
                :class="{
                  'rotate-180 transform': expanded.includes(thread.id),
                }"
                class="h-4 w-4 duration-100"
                icon="chevron-down"
              />
            </button>
          </div>
        </div>
        <div
          v-if="expanded.includes(thread.id)"
          class="flex flex-col gap-1"
        >
          <div
            v-for="(message, index) in thread.messages"
            :key="`thread-${index}-message-${index}`"
            :class="{
              'flex items-center gap-1 bg-blue-neon px-2 py-1 text-xs font-bold text-white':
                message.type === 'ai',
              'text-red-800': message.type === 'error',
              'text-yellow-600': message.type === 'warning',
              'text-green-700': message.type === 'finished',
            }"
            class="rounded-full text-sm"
          >
            <icon-sparks
              v-if="message.type === 'ai'"
              class="h-4 w-4 text-white"
            />
            {{ message.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AnimateNumber from '@/components/ui/AnimateNumber.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed, ref } from 'vue'
import IconSparks from '@/icons/IconSparks.vue'

const props = defineProps({
  progress: {
    type: [Number, String],
    required: true,
  },
  total: {
    type: [Number, String],
    required: true,
  },
  processed: {
    type: [Number, String],
    required: true,
  },
  duplicates: {
    type: [Number, String],
    required: true,
  },
  errors: {
    type: [Number, String],
    required: true,
  },
  messages: {
    type: Array,
    required: true,
  },
})

const expanded = ref([])

const threads = computed(() => {
  return props.messages?.reduce((acc, message) => {
    const { thread_id: threadId, type, body } = message
    const thread = acc?.find((thread) => thread.id === threadId)
    if (thread) {
      thread.messages.push({ type, message: body })
    } else {
      acc.push({
        id: threadId,
        title: body,
        type: type,
        messages: [],
      })
    }
    return acc
  }, [])
})
</script>

<style scoped></style>
