<template>
  <tag-list-modal-view
    v-model:open="tagModalOpen"
    :max="max"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
  />
  <input-layout>
    <template #label>
      <div class="flex items-center justify-between gap-2">
        <span>{{ title ?? `Fandoms & Topics` }}</span>
        <div
          v-if="max"
          class="rounded-full bg-black px-2 text-sm text-white"
        >
          {{ modelValue?.length }} / {{ max }}
        </div>
      </div>
    </template>
    <div
      v-if="modelValue?.length"
      class="mb-2 flex flex-row flex-wrap gap-4 pt-6 text-space-blue"
    >
      <h5
        v-for="tag in modelValue"
        :key="tag.id"
        class="select-none gap-1 rounded bg-gray-100 px-2 py-1 font-heading text-sm hover:bg-gray-200"
        @click.stop="
          emit(
            'update:modelValue',
            modelValue.filter((t) => tag.id !== t.id)
          )
        "
      >
        {{ tag.name }}
        <font-awesome-icon
          class="text-black"
          icon="xmark"
        />
      </h5>
    </div>
    <form-field-input
      :variant="variant"
      class="cursor-pointer"
      model-value=""
      placeholder="Search for fandoms and topics"
      @click="tagModalOpen = true"
    />
  </input-layout>
</template>

<script setup>
import { ref } from 'vue'
import TagListModalView from '@/components/view/TagListModalView.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  highlightedTags: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Array,
    required: true,
  },
  max: {
    type: Number,
    default: 10,
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
  tagSearchDisabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'default',
  },
})

const tagModalOpen = ref(false)

function isSelected(tagId) {
  return props.modelValue.map((tag) => tag.id).includes(tagId)
}
</script>
