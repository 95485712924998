<template>
  <form-field-input
    :model-value="modelValue ?? _modelValue"
    :variant="variant"
    @update:model-value="modelValueGetter = $event"
  >
    <template #icon>
      <button
        class="flex h-5 w-5 shrink-0 items-center justify-center"
        @click="
          !modelValueGetter?.length
            ? emit('search', modelValue)
            : (modelValueGetter = '')
        "
      >
        <font-awesome-icon
          :class="[
            variant === 'searchbar' ? 'text-gray-600' : 'text-slate-800',
          ]"
          :icon="!modelValueGetter?.length ? 'search' : 'times'"
        />
      </button>
    </template>
  </form-field-input>
</template>

<script lang="ts" setup>
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useDebounceFn } from '@vueuse/core'
import { InputVariant } from '@/types/designVariants'
import { computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue', 'search'])

const props = withDefaults(
  defineProps<{
    variant: InputVariant
    modelValue?: string | number | null
  }>(),
  {
    variant: 'searchbar',
    modelValue: null,
  }
)

const _modelValue = ref<string | number | null>('')

const modelValueGetter = computed({
  get: () => props.modelValue ?? _modelValue.value,
  set: (value: string | number | null) => {
    _modelValue.value = value
    emit('update:modelValue', value)
    searchDebounce(value as string)
  },
})

const searchDebounce = useDebounceFn((value: string) => {
  emit('search', value)
}, 500)
</script>

<style scoped></style>
