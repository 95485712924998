<template>
  <svg
    color="currentColor"
    fill="none"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15C12.8747 15 15 12.949 15 8C15 12.949 17.1104 15 22 15C17.1104 15 15 17.1104 15 22C15 17.1104 12.8747 15 8 15Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="1.5"
    ></path>
    <path
      d="M2 6.5C5.13376 6.5 6.5 5.18153 6.5 2C6.5 5.18153 7.85669 6.5 11 6.5C7.85669 6.5 6.5 7.85669 6.5 11C6.5 7.85669 5.13376 6.5 2 6.5Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="1.5"
    ></path>
  </svg>
</template>
