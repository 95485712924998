<template>
  <div class="grid grid-cols-2 gap-3">
    <form-field-input
      :error="errors?.latitude"
      :model-value="latitude"
      :rules="{
        between: [-90, 90],
      }"
      placeholder="Latitude"
      type="number"
      variant="gray"
      @update:model-value="$emit('update:latitude', $event)"
    />
    <form-field-input
      :error="errors?.longitude"
      :model-value="longitude"
      :rules="{
        between: [-180, 180],
      }"
      placeholder="Longitude"
      type="number"
      variant="gray"
      @update:model-value="$emit('update:longitude', $event)"
    />
    <map-selector
      :latitude="latitude"
      :longitude="longitude"
      class="relative col-span-2 h-32"
      unlimited-zoom
      @update="
        ({ latitude, longitude }) => {
          emit('update:latitude', latitude)
          emit('update:longitude', longitude)
        }
      "
    />
  </div>
</template>

<script lang="ts" setup>
import MapSelector from '@/components/input/MapSelector.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'

const emit = defineEmits(['update:latitude', 'update:longitude'])

const props = defineProps({
  latitude: {
    type: [Number, String],
    default: 0,
  },
  longitude: {
    type: [Number, String],
    default: 0,
  },
  errors: {
    type: Object,
    default: null,
  },
})
</script>

<style scoped></style>