<template>
  <modal-list-layout
    :items="products"
    title="Manage Products"
    disable-bottom
    back-button
    secondary-button="Back"
    @cancel="emit('close')"
  >
    <template #list-item="{ item }">
      <div
        class="flex items-center gap-4 rounded-lg border bg-white py-0.5 pl-0.5 pr-2"
      >
        <image-component
          :src="item.thumbnail?.path ?? 'https://via.placeholder.com/56'"
          class="h-14 w-14 rounded-lg object-cover object-center"
        />
        <div class="flex flex-col">
          <h1 class="text-base font-medium text-paragraph">
            {{ item.name }}
          </h1>
          <small class="text-sm text-gray-600">${{ item.price }}</small>
        </div>
        <div class="ml-auto flex gap-6">
          <button
            @click="
              productModal.open(
                axios.get(
                  `/api/partner/vendors/${vendor.id}/products/${item.id}`
                )
              )
            "
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7422 16.6665H16.6681"
                stroke="#BFC6CD"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.9748 6.58824L6.37078 16.2306C6.09278 16.5097 5.71507 16.6666 5.32113 16.6666H4.07756C3.6669 16.6666 3.33398 16.3307 3.33398 15.9201V14.6656C3.33398 14.2737 3.48926 13.8978 3.76581 13.6201L13.3723 3.97533C15.603 2.10896 17.8338 4.72186 15.9748 6.58824Z"
                stroke="#BFC6CD"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.4531 5.04419L14.9848 7.57585"
                stroke="#BFC6CD"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="
              modalHandler.openDialog({
                props: {
                  header: 'Delete Product',
                  message: 'Are you sure you want to delete this product?',
                  primaryButton: 'Delete',
                  secondaryButton: 'Cancel',
                  variant: 'danger',
                },
                onPrimary: () => {
                  return axios
                    .delete(
                      `/api/partner/vendors/${vendor.id}/products/${item.id}`
                    )
                    .then(() => {
                      const index = products.findIndex(
                        (product) => product.id === item.id
                      )
                      products.splice(index, 1)
                    })
                },
              })
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.4375 4.3125H8.25C8.35313 4.3125 8.4375 4.22812 8.4375 4.125V4.3125H15.5625V4.125C15.5625 4.22812 15.6469 4.3125 15.75 4.3125H15.5625V6H17.25V4.125C17.25 3.29766 16.5773 2.625 15.75 2.625H8.25C7.42266 2.625 6.75 3.29766 6.75 4.125V6H8.4375V4.3125ZM20.25 6H3.75C3.33516 6 3 6.33516 3 6.75V7.5C3 7.60313 3.08437 7.6875 3.1875 7.6875H4.60312L5.18203 19.9453C5.21953 20.7445 5.88047 21.375 6.67969 21.375H17.3203C18.1219 21.375 18.7805 20.7469 18.818 19.9453L19.3969 7.6875H20.8125C20.9156 7.6875 21 7.60313 21 7.5V6.75C21 6.33516 20.6648 6 20.25 6ZM17.1398 19.6875H6.86016L6.29297 7.6875H17.707L17.1398 19.6875Z"
                fill="#BFC6CD"
              />
              <path
                d="M10.7071 9.79289C10.5196 9.60536 10.2652 9.5 10 9.5C9.73478 9.5 9.48043 9.60536 9.29289 9.79289C9.10536 9.98043 9 10.2348 9 10.5V16.5C9 16.7652 9.10536 17.0196 9.29289 17.2071C9.48043 17.3946 9.73478 17.5 10 17.5C10.2652 17.5 10.5196 17.3946 10.7071 17.2071C10.8946 17.0196 11 16.7652 11 16.5V10.5C11 10.2348 10.8946 9.98043 10.7071 9.79289Z"
                fill="#BFC6CD"
              />
              <path
                d="M14.7071 9.79289C14.5196 9.60536 14.2652 9.5 14 9.5C13.7348 9.5 13.4804 9.60536 13.2929 9.79289C13.1054 9.98043 13 10.2348 13 10.5V16.5C13 16.7652 13.1054 17.0196 13.2929 17.2071C13.4804 17.3946 13.7348 17.5 14 17.5C14.2652 17.5 14.5196 17.3946 14.7071 17.2071C14.8946 17.0196 15 16.7652 15 16.5V10.5C15 10.2348 14.8946 9.98043 14.7071 9.79289Z"
                fill="#BFC6CD"
              />
            </svg>
          </button>
        </div>
      </div>
    </template>
    <template #action-buttons>
      <dropdown-button class="h-8">
        <template #button>
          <font-awesome-icon icon="plus" />
        </template>
        <template #buttons>
          <button
            class="whitespace-nowrap px-2 py-1 transition-colors hover:bg-gray-100"
            @click="
              productModal.open(
                axios.post(`/api/partner/vendors/${vendor.id}/products`),
                true
              )
            "
          >
            <font-awesome-icon icon="plus" />
            Add Product
          </button>
        </template>
      </dropdown-button>
    </template>
  </modal-list-layout>
</template>

<script setup>
import ModalListLayout from '@/components/modal/layout/ModalListLayout.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import DropdownButton from '@/components/buttons/DropdownButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useModalForm from '@/utils/modalForm'
import VendorProduct from '@/models/VendorProduct'
import ProductFormView from '@/components/view/ProductFormView.vue'
import axios from 'axios'
import { useModal } from '@/utils/modal'

const emit = defineEmits(['close'])

const props = defineProps({
  products: {
    type: Array,
    required: true,
  },
  vendor: {
    type: Object,
    required: true,
  },
})

const modalHandler = useModal()

const productModal = useModalForm(
  { ...new VendorProduct() },
  {
    component: () => ProductFormView,
    onDelete: () => {
      return axios.delete(
        `/api/partner/vendors/${props.vendor.id}/products/${productModal.form.id}`
      )
    },
    onClose: () => {
      return axios
        .post(
          `/api/partner/vendors/${props.vendor.id}/products/${productModal.form.id}?_method=PUT`,
          productModal.form.formData()
        )
        .then((res) => {
          const index = props.products.findIndex(
            (product) => product.id === res.data.id
          )
          if (index === -1) props.products.push(res.data)
          else props.products[index] = res.data
        })
    },
  }
)
</script>

<style scoped></style>