<template>
  <div class="flex flex-col py-3 space-y-4">
    <attendee-mini-presenter
      v-for="user in users.slice().sort(sortUsers)"
      :key="user.id"
      :attendee="user"
    />
  </div>
</template>

<script setup>
import AttendeeMiniPresenter from "./AttendeeMiniPresenter.vue";

const props = defineProps({
    users: {
        type: Array,
        required: true,
    },
    avatarImage: {
        type: Boolean,
        default: true,
    },
});

// sorts users based on existence of different properties
// first go users with all properties (avatar, tag, caption
// then users without tag
// then users without caption
// then remaining users
function sortUsers(a, b) {
    if (a.avatar && a.tag && a.caption && !b.avatar && !b.tag && !b.caption) {
        return -1;
    }

    if (!a.avatar && !a.tag && !a.caption && b.avatar && b.tag && b.caption) {
        return 1;
    }

    if (a.avatar && a.tag && !b.avatar && !b.tag) {
        return -1;
    }

    if (!a.avatar && !a.tag && b.avatar && b.tag) {
        return 1;
    }

    if (a.avatar && !b.avatar) {
        return -1;
    }

    if (!a.avatar && b.avatar) {
        return 1;
    }

    if (a.tag && !b.tag) {
        return -1;
    }

    if (!a.tag && b.tag) {
        return 1;
    }

    if (a.caption && !b.caption) {
        return -1;
    }

    if (!a.caption && b.caption) {
        return 1;
    }

    return 0;
}
</script>
