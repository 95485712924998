<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <merge-selector-modal
        v-model:open="merge"
        :form-field-select-item-component="FormFieldSelectVenueStation"
        :source-id="form.id"
        :select-attrs="{
          conventionEventId: form.convention_event?.id,
        }"
        resource-modal-type="venue-station"
        source-type="venue_station"
      />
      <form-field-header-input
        allow-merge
        class="w-full"
        @merge="merge = true"
        @delete="emit('delete')"
      >
        <template #input>
          <form-field-input
            v-model="form.name"
            class="grow"
            placeholder="Enter station name"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <div class="flex flex-col gap-4 pt-6">
      <form-input-group-layout>
        <input-layout
          label="Event"
          layout="grid"
          variant="gray"
        >
          <form-field-select-convention
            :selected="form.convention_event"
            single-select
            @update:selected="
              (event) => {
                if (event?.id !== form.convention_event?.id) {
                  form.venue = null
                  form.venue_space = null
                }
                form.convention_event = event
              }
            "
          />
        </input-layout>
        <input-layout
          label="Venue"
          layout="grid"
          variant="gray"
        >
          <form-field-select-venue
            :selected="form.venue"
            :convention-event-id="form.convention_event?.id"
            :disabled="!form.convention_event?.id"
            @update:selected="
              (venue) => {
                if (venue?.id !== form.venue?.id) {
                  form.venue_space = null
                }
                form.venue = venue
              }
            "
          />
        </input-layout>
        <input-layout
          label="Venue Space"
          layout="grid"
          variant="gray"
        >
          <form-field-select-venue-space
            v-model:selected="form.venue_space"
            :convention-event-id="form.convention_event?.id"
            :disabled="!form.venue?.id"
            :venue-id="form.venue?.id"
          />
        </input-layout>
        <input-layout
          label="Description"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.description"
            :rows="3"
            placeholder="Enter description"
            variant="gray"
          />
        </input-layout>
        <input-layout
          label="Description"
          layout="grid"
          variant="gray"
        >
          <form-field-select-map
            v-model:latitude="form.latitude"
            v-model:longitude="form.longitude"
          />
        </input-layout>
      </form-input-group-layout>
    </div>
  </modal-buttons-layout>
</template>

<script lang="ts" setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import VenueStation from '@/models/VenueStation'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldSelectVenue from '@/components/input/FormFieldSelectVenue.vue'
import FormFieldSelectVenueSpace from '@/components/input/FormFieldSelectVenueSpace.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import FormFieldSelectMap from '@/components/input/FormFieldSelectMap.vue'
import FormFieldSelectConvention from '@/components/input/FormFieldSelectConvention.vue'
import {ref} from "vue";
import FormFieldSelectVendor from "@/components/input/FormFieldSelectVendor.vue";
import MergeSelectorModal from "@/components/modal/MergeSelectorModal.vue";
import FormFieldSelectVenueStation from "@/components/input/FormFieldSelectVenueStation.vue";

const emit = defineEmits(['delete', 'search'])

const props = defineProps({
  form: {
    type: [VenueStation, Object],
    required: true,
  },
})

const merge = ref(false)
</script>

<style scoped></style>