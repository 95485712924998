<template>
  <button
    class="text-white"
    @click="open = true"
  >
    <avatar-image
      :image="user?.avatar"
      class="!h-8 !w-8 rounded-full bg-cover bg-center"
    />
  </button>
  <transition name="fade-appear">
    <div
      v-if="open"
      ref="dropdown"
      :class="dropdownClass"
      class="absolute right-0 flex w-[280px] flex-col gap-6 rounded-lg bg-white px-2 py-4 text-black shadow"
    >
      <div class="flex flex-col gap-3">
        <Link
          :href="`/users/${user?.id}`"
          class="group flex items-center gap-3 rounded px-2 py-1 transition-colors duration-75 hover:bg-gray-100"
        >
          <div
            :style="'background-image: url(' + user?.avatar?.path + ')'"
            class="h-10 w-10 rounded-full bg-white bg-cover bg-center"
          />
          <div class="flex flex-col gap-1">
            <h1 class="text-base font-medium leading-none">
              {{ user?.first_name }}
              {{ user?.last_name }}
            </h1>
            <span
              class="text-sm leading-none text-blue-primary group-hover:underline"
            >
              View Profile
            </span>
          </div>
        </Link>
        <hr class="mx-2 my-1" />
        <Link
          class="flex items-center gap-2 rounded px-2 py-1 text-sm text-gray-800 transition-colors duration-75 hover:bg-gray-100 hover:text-gray-900"
          href="/raffles"
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 5V7M15 11V13M15 17V19M5 5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V10C3.53043 10 4.03914 10.2107 4.41421 10.5858C4.78929 10.9609 5 11.4696 5 12C5 12.5304 4.78929 13.0391 4.41421 13.4142C4.03914 13.7893 3.53043 14 3 14V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V14C20.4696 14 19.9609 13.7893 19.5858 13.4142C19.2107 13.0391 19 12.5304 19 12C19 11.4696 19.2107 10.9609 19.5858 10.5858C19.9609 10.2107 20.4696 10 21 10V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5Z"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.8"
            />
          </svg>
          Raffles
        </Link>
        <Link
          :href="`/users/${user?.id}/settings`"
          class="flex items-center gap-2 rounded px-2 py-1 text-sm text-gray-800 transition-colors duration-75 hover:bg-gray-100 hover:text-gray-900"
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.675 4.317C13.249 2.561 10.751 2.561 10.325 4.317C10.049 5.452 8.749 5.99 7.753 5.382C6.209 4.442 4.443 6.209 5.383 7.752C5.5243 7.98375 5.60889 8.24559 5.62987 8.51621C5.65085 8.78683 5.60764 9.05859 5.50375 9.30935C5.39985 9.56011 5.23822 9.7828 5.032 9.95929C4.82578 10.1358 4.5808 10.2611 4.317 10.325C2.561 10.751 2.561 13.249 4.317 13.675C4.58056 13.7391 4.82529 13.8645 5.03127 14.0409C5.23726 14.2174 5.3987 14.44 5.50247 14.6906C5.60624 14.9412 5.64942 15.2128 5.62848 15.4832C5.60755 15.7537 5.5231 16.0153 5.382 16.247C4.442 17.791 6.209 19.557 7.752 18.617C7.98375 18.4757 8.24559 18.3911 8.51621 18.3701C8.78683 18.3491 9.05859 18.3924 9.30935 18.4963C9.56011 18.6001 9.7828 18.7618 9.95929 18.968C10.1358 19.1742 10.2611 19.4192 10.325 19.683C10.751 21.439 13.249 21.439 13.675 19.683C13.7391 19.4194 13.8645 19.1747 14.0409 18.9687C14.2174 18.7627 14.44 18.6013 14.6906 18.4975C14.9412 18.3938 15.2128 18.3506 15.4832 18.3715C15.7537 18.3924 16.0153 18.4769 16.247 18.618C17.791 19.558 19.557 17.791 18.617 16.248C18.4757 16.0162 18.3911 15.7544 18.3701 15.4838C18.3491 15.2132 18.3924 14.9414 18.4963 14.6907C18.6001 14.4399 18.7618 14.2172 18.968 14.0407C19.1742 13.8642 19.4192 13.7389 19.683 13.675C21.439 13.249 21.439 10.751 19.683 10.325C19.4194 10.2609 19.1747 10.1355 18.9687 9.95905C18.7627 9.78258 18.6013 9.55999 18.4975 9.30938C18.3938 9.05877 18.3506 8.78721 18.3715 8.51677C18.3924 8.24634 18.4769 7.98466 18.618 7.753C19.558 6.209 17.791 4.443 16.248 5.383C16.0162 5.5243 15.7544 5.60889 15.4838 5.62987C15.2132 5.65085 14.9414 5.60764 14.6907 5.50375C14.4399 5.39985 14.2172 5.23822 14.0407 5.032C13.8642 4.82578 13.7389 4.5808 13.675 4.317Z"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              d="M14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213Z"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          Settings
        </Link>
        <a
          class="flex items-center gap-2 rounded px-2 py-1 text-sm text-gray-800 transition-colors duration-75 hover:bg-gray-100 hover:text-gray-900"
          href="https://forms.gle/bR9uvtptq4t6VX948"
          rel="noopener noreferrer"
          target="_blank"
          @click="supportDogOpen = true"
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.10156 10.2031C6.13227 10.2031 5.34375 10.9916 5.34375 11.9609V16.7266C5.34375 17.6959 6.13227 18.4844 7.10156 18.4844C8.07086 18.4844 8.85938 17.6959 8.85938 16.7266V11.9609C8.85938 10.9916 8.07086 10.2031 7.10156 10.2031Z"
              fill="currentColor"
            />
            <path
              d="M4.17188 11.9623C4.17188 11.7932 4.19418 11.63 4.2218 11.4688C3.51562 11.697 3 12.3529 3 13.1341V15.556C3 16.3372 3.51562 16.9932 4.2218 17.2214C4.19418 17.0602 4.17188 16.8969 4.17188 16.7279V11.9623Z"
              fill="currentColor"
            />
            <path
              d="M16.4766 10.2031C15.5073 10.2031 14.7188 10.9916 14.7188 11.9609V16.7266C14.7188 17.6959 15.5073 18.4844 16.4766 18.4844C16.6527 18.4844 16.8196 18.4505 16.9802 18.402C16.762 19.125 16.0979 19.6562 15.3047 19.6562H13.4389C13.1962 18.9757 12.552 18.4844 11.7891 18.4844C10.8198 18.4844 10.0312 19.2729 10.0312 20.2422C10.0312 21.2115 10.8198 22 11.7891 22C12.552 22 13.1962 21.5087 13.4389 20.8281H15.3047C16.92 20.8281 18.2344 19.5138 18.2344 17.8984V16.7266V11.9609C18.2344 10.9916 17.4459 10.2031 16.4766 10.2031Z"
              fill="currentColor"
            />
            <path
              d="M19.3555 11.4688C19.3831 11.63 19.4054 11.7932 19.4054 11.9623V16.7279C19.4054 16.8969 19.3831 17.0602 19.3555 17.2214C20.0616 16.9932 20.5773 16.3372 20.5773 15.556V13.1341C20.5773 12.3529 20.0616 11.697 19.3555 11.4688Z"
              fill="currentColor"
            />
            <path
              d="M11.7891 2C6.9425 2 3 5.9425 3 10.7891V10.804C3.3491 10.5397 3.75301 10.3463 4.19871 10.2597C4.47258 6.30668 7.76773 3.17188 11.7891 3.17188C15.8104 3.17188 19.1055 6.30668 19.3794 10.2597C19.8251 10.3463 20.229 10.5397 20.5781 10.804V10.7891C20.5781 5.9425 16.6356 2 11.7891 2Z"
              fill="currentColor"
            />
          </svg>
          Support
        </a>
        <hr />
        <div
          class="flex items-center gap-2 rounded px-2 py-1 text-sm text-gray-800 transition-colors duration-75 hover:bg-gray-100 hover:text-gray-900"
          @click="logout"
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2241 15.8916C14.9879 15.8916 14.7613 15.9855 14.5942 16.1526C14.4272 16.3196 14.3333 16.5462 14.3333 16.7825V18.9528C14.333 19.2864 14.2004 19.6062 13.9646 19.8421C13.7287 20.078 13.4089 20.2107 13.0754 20.2111H5.03964C4.70606 20.2107 4.38627 20.078 4.15044 19.8421C3.9146 19.6062 3.78198 19.2864 3.78169 18.9528V6.04001C3.78198 5.70644 3.9146 5.38661 4.15044 5.1507C4.38627 4.91479 4.70606 4.78208 5.03964 4.78169H13.0754C13.4089 4.78208 13.7287 4.91479 13.9646 5.1507C14.2004 5.38661 14.333 5.70644 14.3333 6.04001V7.91598C14.3333 8.15225 14.4272 8.37884 14.5942 8.5459C14.7613 8.71297 14.9879 8.80682 15.2241 8.80682C15.4604 8.80682 15.687 8.71297 15.8541 8.5459C16.0211 8.37884 16.115 8.15225 16.115 7.91598V6.04001C16.114 5.23411 15.7935 4.4615 15.2236 3.89161C14.6538 3.32172 13.8813 3.00108 13.0754 3H5.03964C4.23374 3.00108 3.46117 3.32172 2.89135 3.89161C2.32153 4.4615 2.00098 5.23411 2 6.04001V18.9528C2.00098 19.7587 2.32153 20.5313 2.89135 21.1012C3.46117 21.6711 4.23374 21.9917 5.03964 21.9928H13.0754C13.8813 21.9917 14.6538 21.6711 15.2236 21.1012C15.7935 20.5313 16.114 19.7587 16.115 18.9528V16.7825C16.115 16.5462 16.0211 16.3196 15.8541 16.1526C15.687 15.9855 15.4604 15.8916 15.2241 15.8916Z"
              fill="currentColor"
            />
            <path
              d="M22.4237 11.8469L19.2535 8.67881C19.0885 8.51394 18.8655 8.4203 18.6324 8.41801C18.3992 8.41572 18.1744 8.50496 18.0063 8.66656C17.6462 9.01288 17.6548 9.59935 18.0081 9.95272L19.6588 11.6045H11.9381C11.7019 11.6045 11.4753 11.6983 11.3082 11.8654C11.1411 12.0325 11.0473 12.2591 11.0473 12.4953C11.0473 12.7316 11.1411 12.9582 11.3082 13.1253C11.4753 13.2923 11.7019 13.3862 11.9381 13.3862H19.6588L17.9922 15.0528C17.8251 15.2199 17.7312 15.4465 17.7311 15.6828C17.7311 15.9191 17.8249 16.1457 17.992 16.3128C18.159 16.4799 18.3856 16.5738 18.6219 16.5738C18.8582 16.5739 19.0849 16.48 19.252 16.313L22.4237 13.1427C22.5089 13.0577 22.5765 12.9567 22.6227 12.8455C22.6688 12.7343 22.6925 12.6151 22.6925 12.4948C22.6925 12.3744 22.6688 12.2552 22.6227 12.1441C22.5765 12.0329 22.5089 11.9319 22.4237 11.8469Z"
              fill="currentColor"
            />
          </svg>

          Log Out
        </div>
      </div>
    </div>
  </transition>
  <confirm-modal
    :is-popup-open="supportDogOpen"
    primary-button-text="Woof woof!"
    secondary-button-text="Woof."
    @cancel="supportDogOpen = false"
    @close="supportDogOpen = false"
    @confirm="supportDogOpen = false"
  >
    <template #title>
      <div class="text-2xl font-bold">Thank you for contacting us!</div>
    </template>
    <img
      alt="Support Dog"
      src="/images/backgrounds/support_dog.jpg"
    />
    <h1>We will get back to you as soon as possible.</h1>
    <p>In the meantime, please enjoy this picture of a support dog.</p>
  </confirm-modal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import useAuth from '@/utils/composables/auth'
import ConfirmModal from '@/components/modal/ConfirmModal.vue'
import AvatarImage from '@/components/ui/AvatarImage.vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  dropdownClass: {
    type: String,
    default: '',
  },
})

const { user, logout } = useAuth()

const dropdown = ref(null)
const open = ref(false)
const supportDogOpen = ref(false)

onClickOutside(dropdown, () => {
  open.value = false
})
</script>
