<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M12 16.8809C13.0609 16.8809 14.0783 16.4594 14.8284 15.7093C15.5786 14.9591 16 13.9417 16 12.8809C16 11.82 15.5786 10.8026 14.8284 10.0524C14.0783 9.30229 13.0609 8.88086 12 8.88086C10.9391 8.88086 9.92172 9.30229 9.17157 10.0524C8.42143 10.8026 8 11.82 8 12.8809C8 13.9417 8.42143 14.9591 9.17157 15.7093C9.92172 16.4594 10.9391 16.8809 12 16.8809Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 16.8809V8.88086C3 7.55478 3.52678 6.28301 4.46447 5.34533C5.40215 4.40764 6.67392 3.88086 8 3.88086H16C17.3261 3.88086 18.5979 4.40764 19.5355 5.34533C20.4732 6.28301 21 7.55478 21 8.88086V16.8809C21 18.2069 20.4732 19.4787 19.5355 20.4164C18.5979 21.3541 17.3261 21.8809 16 21.8809H8C6.67392 21.8809 5.40215 21.3541 4.46447 20.4164C3.52678 19.4787 3 18.2069 3 16.8809Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M17.5 7.39088L17.51 7.37988"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
