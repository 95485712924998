<template>
  <modal-buttons-layout
    :loading="form.isPreloading"
    disable-bottom
    title="Edit Product"
  >
    <template #top>
      <merge-selector-modal
        v-model:open="merge"
        :form-field-select-item-component="FormFieldSelectVendorProduct"
        :source-id="form.id"
        resource-modal-type="product"
        source-type="vendor_product"
      />
      <form-field-header-input
        allow-merge
        class="w-full"
        @delete="emit('delete')"
        @merge="merge = true"
      >
        <template #input>
          <form-field-input
            v-model="form.name"
            :error="form.errors.name"
            class="grow"
            input-class="font-bold"
            placeholder="Enter Product Name"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <div class="flex flex-col gap-6">
      <form-input-group-layout>
        <input-layout
          :error="form.errors.vendor"
          label="Vendor"
          layout="grid"
          variant="gray"
        >
          <form-field-select-vendor
            v-model:selected="form.vendor"
            class="w-full"
            variant="gray"
          />
        </input-layout>
        <input-layout
          :error="form.errors.description"
          label="Description"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.description"
            placeholder="Product Description"
            rows="3"
            variant="gray"
          />
        </input-layout>
        <input-layout
          :error="form.errors.price"
          label="Price"
          layout="grid"
          variant="gray"
        >
          <form-field-input-price
            v-model="form.price"
            v-model:currency="form.currency"
            class="w-48"
            placeholder="Price"
            variant="gray"
          />
        </input-layout>
        <input-layout
          :error="form.errors.serial_number"
          label="SKU"
          layout="grid"
          variant="gray"
        >
          <form-field-input
            v-model="form.serial_number"
            class="w-48"
            placeholder="SKU / Serial Number"
            type="number"
            variant="gray"
          />
        </input-layout>
        <input-layout
          :error="form.errors.quantity || form.errors.currency"
          label="Quantity"
          layout="grid"
          variant="gray"
        >
          <form-field-input
            v-model="form.quantity"
            class="w-16"
            placeholder="Qty"
            type="number"
            variant="gray"
          />
        </input-layout>
        <input-layout
          :error="form.errors.images"
          label="Images"
          layout="grid"
          variant="gray"
        >
          <form-field-image
            v-model="form.images"
            class="h-16"
            height="1200"
            multiple
            width="1080"
          />
        </input-layout>
      </form-input-group-layout>
      <hr />
      <form-input-group-layout label="Categories and Tags">
        <input-layout
          label="Group"
          layout="grid"
          variant="gray"
        >
          <form-field-select-category
            v-model:selected="form.group"
            class="w-full"
            disable-button
            labels="top"
            type="vendor"
          />
        </input-layout>
        <input-layout
          label="Categories"
          layout="grid"
          variant="gray"
        >
          <form-field-select-category
            v-model:selected="form.categories"
            class="w-full"
            disable-button
            labels="top"
            multiple
            type="product"
          />
        </input-layout>
        <input-layout
          label="Fandom Tags"
          layout="grid"
          variant="gray"
        >
          <form-field-select-tag
            v-model:selected="form.tags"
            class="w-full"
            labels="inline"
            type="fandom"
          />
        </input-layout>
      </form-input-group-layout>
      <hr />
      <form-input-group-layout label="Product Attributes" />
    </div>
  </modal-buttons-layout>
</template>

<script setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import { ref } from 'vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import VendorProduct from '@/models/VendorProduct'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import FormFieldInputPrice from '@/components/input/FormFieldInputPrice.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldImage from '@/components/input/FormFieldImage.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormFieldSelectCategory from '@/components/input/FormFieldSelectCategory.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import FormFieldSelectVendor from '@/components/input/FormFieldSelectVendor.vue'
import MergeSelectorModal from '@/components/modal/MergeSelectorModal.vue'
import FormFieldSelectVendorProduct from '@/components/input/FormFieldSelectVendorProduct.vue'

const emit = defineEmits(['delete'])

const props = defineProps({
  form: {
    type: [VendorProduct, Object],
    required: true,
  },
})

const merge = ref(false)
const test = ref([])
</script>

<style scoped></style>
