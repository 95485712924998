<template>
  <modal-submenu-layout
    :sections="[
      {
        label: 'General',
        value: 'general',
      },
      {
        label: 'Preferences',
        value: 'preferences',
      },
      {
        label: 'Branding',
        value: 'branding',
      },
      {
        label: 'Personnel',
        value: 'personnel',
      },
      {
        label: 'Contact Info',
        value: 'contact-info',
      },
      {
        label: 'Addresses',
        value: 'addresses',
      },
      {
        label: 'Products',
        value: 'products',
      },
      {
        label: 'Events',
        value: 'events',
      },
      {
        label: 'Comments',
        value: 'comments',
      },
    ]"
    disable-bottom
  >
    <template #top>
      <merge-selector-modal
        v-model:open="merge"
        :form-field-select-item-component="FormFieldSelectVendor"
        :source-id="form.id"
        resource-modal-type="vendor"
        source-type="vendor"
      />
      <form-field-header-input
        allow-merge
        class="w-full"
        @delete="emit('delete')"
        @merge="merge = true"
      >
        <template #input>
          <form-field-input
            v-model="form.name"
            :error="form.errors?.name"
            class="grow"
            input-class="font-bold"
            placeholder="Enter Vendor Name"
            variant="gray"
          />
        </template>
        <template #actions="{ toggle }">
          <button
            class="flex items-center gap-1 whitespace-nowrap px-2 py-1 text-sm hover:bg-gray-300"
            @click="modalHandler.openMiddle(YelpImporter)"
          >
            <brand-icon brand="yelp" />
            Yelp
          </button>
        </template>
      </form-field-header-input>
    </template>
    <template #general>
      <input-layout
        :error="form.errors?.type"
        for-id="vendor-label"
        label="Vendor type"
        layout="grid"
        variant="gray"
      >
        <form-field-select-tag
          v-model:selected="form.type"
          single-select
          type="vendor"
          variant="gray"
        />
      </input-layout>
      <input-layout
        :error="form.errors?.description"
        label="Description"
        layout="grid"
        variant="gray"
      >
        <form-field-input-textarea
          v-model="form.description"
          layout="grid"
          placeholder="Vendor description"
          rows="3"
          variant="gray"
        />
      </input-layout>
    </template>
    <template #preferences>
      <form-input-group-heading>Preferences</form-input-group-heading>
      <input-layout
        :error="form.errors?.activity_tags"
        label="Activity tags"
        layout="grid"
        variant="gray"
      >
        <form-field-select-tag
          v-model:selected="form.activity_tags"
          layout="grid"
          type="activity"
        />
      </input-layout>
      <input-layout
        :error="form.errors?.topic_tags"
        label="Topic tags"
        layout="grid"
        variant="gray"
      >
        <form-field-select-tag
          v-model:selected="form.topic_tags"
          type="topic"
          variant="gray"
        />
      </input-layout>
      <input-layout
        :error="form.errors?.fandom_tags"
        label="Fandom tags"
        layout="grid"
        variant="gray"
      >
        <form-field-select-tag
          v-model:selected="form.fandom_tags"
          type="fandom"
          variant="gray"
        />
      </input-layout>
    </template>
    <template #branding>
      <form-input-group-heading>Branding</form-input-group-heading>
      <form-field-image-type-collection
        :config="[
          {
            label: 'Thumb',
            name: 'thumbnail',
            width: 1080,
            height: 1080,
          },
          {
            label: 'Transparent Logo',
            name: 'logo',
            width: 1080,
            height: 540,
          },
          {
            label: 'Banner',
            name: 'banner',
            width: 1500,
            height: 500,
          },
          {
            label: 'Social',
            name: 'social',
            width: 1200,
            height: 630,
          },
        ]"
        :images="{
          thumbnail: form.thumbnail,
          logo: form.logo,
          banner: form.banner,
          social: form.social,
        }"
        @update:images="
          (images) => {
            form.thumbnail = images.thumbnail
            form.logo = images.logo
            form.banner = images.banner
            form.social = images.social
          }
        "
      />
    </template>
    <template #personnel>
      <form-input-group-heading>Personnel</form-input-group-heading>
    </template>
    <template #contact-info>
      <form-input-group-heading>Contact Info</form-input-group-heading>
      <form-field-input-contact-detail
        v-if="state === 'form'"
        v-model="form.phone_numbers"
        label="Phone Number"
        type="phone"
      />
      <form-field-input-contact-detail
        v-if="state === 'form'"
        v-model="form.emails"
        label="Email"
        type="email"
      />
      <form-field-input-contact-detail
        v-if="state === 'form'"
        v-model="form.socials"
        :default-labels="[
          'Website',
          'Facebook',
          'Twitter',
          'Instagram',
          'LinkedIn',
        ]"
        label="Social"
        type="social"
      />
    </template>
    <template #addresses>
      <form-input-group-heading>Addresses</form-input-group-heading>
      <address-list-form
        v-if="state === 'form'"
        :form="form"
        coordinates
      />
    </template>
    <template #products>
      <form-input-group-heading>Products</form-input-group-heading>
      <input-layout
        :error="form.errors?.price_low || form.errors?.price_high"
        label="Price range"
        layout="grid"
        variant="gray"
      >
        <div class="flex items-center gap-3">
          <form-field-input
            v-model="form.price_low"
            label="Price range"
            layout="grid"
            placeholder="low"
            variant="gray"
            wrapper-class="w-24"
          />
          <span class="text-sm">-</span>
          <form-field-input
            v-model="form.price_high"
            layout="grid"
            placeholder="high"
            variant="gray"
            wrapper-class="w-24"
          />
        </div>
      </input-layout>
      <div class="grid grid-cols-input-field gap-3">
        <form-label variant="gray">Product Groups</form-label>
        <div class="flex flex-col gap-2">
          <div
            v-for="group in productGroups"
            :key="group.value"
            class="grid grid-cols-input-field gap-3 rounded px-2 py-1 duration-100 hover:bg-gray-100"
            @click="
              () => {
                modalHandler.open(ProductListFormView, {
                  props: {
                    selectedFilters: {
                      group: group.value,
                    },
                    filters: [
                      {
                        name: 'group',
                        unselected: 'All',
                        unselected_value: 'all',
                        null_option: 'Unassigned',
                        null_option_value: 'unassigned',
                        options: form.categories.map((c) => ({
                          label: c.name,
                          value: c.id,
                        })),
                        filter: (item, value) => {
                          if (value === 'all') return true
                          return (
                            item.group?.id === value ||
                            (value === 'unassigned' && !item.group)
                          )
                        },
                      },
                    ],
                    products: form.products,
                    vendor: {
                      id: form.id,
                      name: form.name,
                    },
                  },
                })
              }
            "
          >
            <form-label
              class="my-auto"
              variant="gray"
            >
              {{ group.name }}
            </form-label>
            <div
              class="flex h-10 items-center justify-end gap-2 overflow-hidden"
            >
              <button-plus
                class="aspect-square h-7 w-9"
                @click.stop="
                  productModal.open(
                    axios.post(`/api/partner/vendors/${form.id}/products`, {
                      group:
                        group.value !== 'unassigned' && group.value !== 'all'
                          ? { id: group.value }
                          : null,
                    }),
                    true
                  )
                "
              />
              <div
                class="flex h-10 shrink grow-0 flex-wrap justify-end gap-1 overflow-hidden"
              >
                <div
                  v-for="product in group.products.slice(0, 4)"
                  :key="product.id"
                  @click.stop="
                    productModal.open(
                      axios.get(
                        `/api/partner/vendors/${form.id}/products/${product.id}`
                      )
                    )
                  "
                >
                  <image-component
                    :src="product.thumbnail?.path"
                    class="h-10 w-10 rounded bg-gray-100 object-cover"
                  />
                </div>
              </div>
              <small class="w-14 text-end text-sm">
                <template v-if="!group.products.length">No items</template>
                <template v-else>
                  {{ group.products.length }}
                  {{ pluralize(group.products.length, 'item', 'items') }}
                </template>
              </small>
            </div>
          </div>
          <form-field-select-category
            v-model:selected="form.categories"
            class="grow"
            labels="none"
            multiple
            show-button-by-default
            type="vendor"
          >
            <template #button="{ click }">
              <button
                class="py-2 text-sm text-gray-600"
                @click="click"
              >
                Add category
              </button>
            </template>
          </form-field-select-category>
        </div>
      </div>
    </template>
    <template #events>
      <form-input-group-heading>
        Convention Events & Locations
      </form-input-group-heading>
      <input-layout
        label="Convention Event"
        layout="grid"
        variant="gray"
      >
        <form-field-select-convention
          v-model:selected="form.events"
          disable-button
          labels="none"
          variant="gray"
        />
      </input-layout>
      <input-layout
        v-for="event in form.events"
        :key="event.id"
        :label="event.name"
        layout="grid"
        variant="gray"
      >
        <form-field-select-event-spot
          :event="event"
          @create="
            eventSpotModal.open(
              axios.post(`/api/partner/vendors/${form.id}/spots`, {
                convention_event: { id: event.id },
              }),
              true
            )
          "
          @open="
            eventSpotModal.open(
              axios.get(`/api/partner/vendors/${form.id}/spots/${$event}`)
            )
          "
        />
      </input-layout>
    </template>
  </modal-submenu-layout>
</template>

<script setup>
import ModalSubmenuLayout from '@/components/modal/layout/ModalSubmenuLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import FormFieldImageTypeCollection from '@/components/input/FormFieldImageTypeCollection.vue'
import FormFieldInputContactDetail from '@/components/input/FormFieldInputContactDetail.vue'
import AddressListForm from '@/components/input/AddressListForm.vue'
import FormFieldSelectConvention from '@/components/input/FormFieldSelectConvention.vue'
import { computed, ref } from 'vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldSelectEventSpot from '@/components/input/FormFieldSelectEventSpot.vue'
import useModalForm from '@/utils/modalForm'
import { VendorSpot } from '@/models/VendorSpot'
import VendorSpotFormView from '@/components/view/VendorSpotFormView.vue'
import FormLabel from '@/components/ui/FormLabel.vue'
import Vendor from '@/models/Vendor'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import FormFieldSelectCategory from '@/components/input/FormFieldSelectCategory.vue'
import { pluralize } from '@/utils/string'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import VendorProduct from '@/models/VendorProduct'
import ProductFormView from '@/components/view/ProductFormView.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import FormInputGroupHeading from '@/components/ui/FormInputGroupHeading.vue'
import axios from 'axios'
import { useModal } from '@/utils/modal'
import ProductListFormView from '@/components/view/ProductListFormView.vue'
import BrandIcon from '@/components/ui/BrandIcon.vue'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import YelpImporter from '@/components/modal/YelpImporter.vue'
import MergeSelectorModal from '@/components/modal/MergeSelectorModal.vue'
import FormFieldSelectVendor from '@/components/input/FormFieldSelectVendor.vue'

const emit = defineEmits(['delete'])

const props = defineProps({
  form: {
    type: [Vendor, Object],
    required: true,
  },
  state: {
    type: String,
    default: 'form',
  },
})

const modalHandler = useModal()
const resourceModal = useResourceModal()
const merge = ref(false)

const productGroups = computed(() => {
  if (props.state !== 'form') {
    return []
  }
  return [
    {
      name: 'All',
      value: 'all',
      products: props.form.products,
    },
    {
      name: 'Unassigned',
      value: 'unassigned',
      products: props.form.products.filter((product) => !product.category_id),
    },
    ...props.form.categories.map((category) => {
      return {
        name: category.name,
        value: category.id,
        products: props.form.products.filter(
          (product) => product.group?.id === category.id
        ),
      }
    }),
  ]
})

const eventSpotModal = useModalForm(new VendorSpot(), {
  component: () => VendorSpotFormView,
  onClose: () => {
    return axios
      .post(
        `/api/partner/vendors/${props.form.id}/spots/${eventSpotModal.form.id}?_method=PUT`,
        eventSpotModal.form.formData()
      )
      .then((res) => {
        // update the spot in the form or add it
        const index = props.form.events.findIndex(
          (event) => event.id === res.data.convention_event.id
        )
        if (index > -1) {
          if (!props.form.events[index].vendor_spots) {
            props.form.events[index].vendor_spots = []
          }
          const spotIndex = props.form.events[index].vendor_spots.findIndex(
            (spot) => spot.id === res.data.id
          )
          if (spotIndex > -1) {
            props.form.events[index].vendor_spots[spotIndex] = res.data
          } else {
            props.form.events[index].vendor_spots.push(res.data)
          }
        }

        // check if location is address
        // if so add it to addresses
        if (res.data.location.type === 'address') {
          const index = props.form.addresses.findIndex(
            (address) => address.id === res.data.location.address.id
          )
          if (index === -1) {
            props.form.addresses.push(res.data.location.address)
          } else {
            props.form.addresses[index] = res.data.location.address
          }
        }
      })
  },
  onDelete: () => {
    return axios.delete(
      `/api/partner/vendors/${props.form.id}/spots/${eventSpotModal.form.id}`
    )
  },
})

const productModal = useModalForm(
  { ...new VendorProduct() },
  {
    component: () => ProductFormView,
    onDelete: () => {
      return axios.delete(
        `/api/partner/vendors/${props.form.id}/products/${productModal.form.id}`
      )
    },
    onClose: () => {
      return axios
        .post(
          `/api/partner/vendors/${props.form.id}/products/${productModal.form.id}?_method=PUT`,
          productModal.form.formData()
        )
        .then((res) => {
          // update the product in the form or add it
          const index = props.form.products.findIndex(
            (product) => product.id === res.data.id
          )
          if (index > -1) {
            props.form.products[index] = res.data
          } else {
            props.form.products.push(res.data)
          }
        })
    },
  }
)
</script>

<style scoped></style>
