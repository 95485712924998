<template>
  <template v-if="showMenu">
    <home-menu
      v-if="isHome"
      :is-auth="isAuthenticated"
      class="fixed top-0"
    />
    <template v-else>
      <site-menu
        v-if="user"
        :disabled="disabled"
        class="sticky @container top-0"
      />
      <guest-menu v-else />
    </template>
  </template>
  <div
    :class="[
      props.variant === 'dark' ? 'bg-slate-900 text-slate-200' : 'bg-white'
    ]"
    class="z-content flex flex-col grow"
  >
    <slot />
    <site-footer
      v-if="!hideFooter"
      class="mt-auto"
      :show-logo="showLogo"
      :variant="variant"
    />
  </div>
  <modal-handler />
</template>

<script setup>
import SiteMenu from './SiteMenu.vue'
import GuestMenu from './GuestMenu.vue'
import HomeMenu from './HomeMenu.vue'
import SiteFooter from './SiteFooter.vue'
import ModalHandler from '@/components/modal/ModalHandler.vue'
import useAuth from '@/utils/composables/auth'
import { inject } from 'vue'

defineOptions({
  name: 'StandardLayout',
})

const props = defineProps({
  showLogo: {
    type: Boolean,
    default: true,
  },
  showMenu: {
    type: Boolean,
    default: true,
  },
  isHome: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'light',
    validator: (value) => ['light', 'dark'].includes(value),
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const { user, isAuthenticated } = useAuth()
</script>

<style scoped></style>
