<template>
  <div class="flex h-full flex-col">
    <div
      :class="[
        scrollPercentage > 0 ? 'bg-gray-100' : 'border-transparent bg-white',
        $slots.top ? 'block' : disableBottom ? 'grid' : 'grid md:flex',
        {
          'md:hidden': !props.title,
        },
      ]"
      class="inset-x-0 top-0 z-10 flex grid-cols-3 flex-row items-center justify-between border-b px-2 py-4 transition-colors md:px-4"
    >
      <slot name="top">
        <button
          v-if="secondaryButton !== '' || backButton"
          :class="{
            'md:hidden': !disableBottom,
          }"
          :disabled="secondaryButtonDisabled"
          class="block text-start text-sm font-semibold text-blue-primary disabled:text-gray-700 md:text-base"
          style="grid-column: 1 / span 1"
          @click="onSecondaryButtonClick"
        >
          <font-awesome-icon
            v-if="backButton"
            icon="chevron-left"
          />
          {{ secondaryButton }}
        </button>
        <h1
          v-if="title"
          class="md:text-dark-100 text-center text-sm font-semibold md:whitespace-nowrap md:text-base md:font-bold"
          style="grid-column: 2 / span 1"
        >
          {{ title }}
        </h1>
        <button
          v-if="primaryButton !== ''"
          :class="[disableBottom ? 'block' : 'block md:hidden']"
          :disabled="primaryButtonDisabled"
          class="text-end text-sm font-semibold text-blue-primary disabled:text-gray-700 md:text-base"
          style="grid-column: 3 / span 1"
          @click="onPrimaryButtonClick"
        >
          {{ primaryButton }}
        </button>
        <div
          :class="[disableBottom ? 'md:flex' : 'hidden md:flex']"
          class="grow justify-end"
        >
          <slot name="action-buttons" />
        </div>
      </slot>
    </div>

    <slot
      :scroll-percentage="scrollPercentage"
      :scroll-pixel="scrollPixel"
      name="before-content"
    />

    <div
      ref="container"
      class="relative flex grow flex-col overflow-x-auto pb-32"
      @scroll="onScroll"
    >
      <div
        v-if="loading"
        class="flex grow items-center justify-center"
      >
        <app-spinner />
      </div>
      <div
        v-else-if="error"
        class="flex grow flex-col items-center justify-center gap-2 text-gray-600"
      >
        <font-awesome-icon
          class="text-5xl"
          icon="exclamation-triangle"
        />
        <template v-if="errorCode === 404">
          <h1 class="text-xl font-semibold">Not found</h1>
          <p class="w-2/3 text-center text-sm md:w-1/2">
            We couldn't find the content you're looking for. Please try again
            later. If the problem persists, please contact support.
          </p>
        </template>
        <template v-else>
          <h1 class="text-xl font-semibold">Something went wrong</h1>
          <p class="w-2/3 text-center text-sm md:w-1/2">
            We're having trouble loading the content. Please try again later. If
            the problem persists, please contact support.
          </p>
          <small
            v-if="errorMessage !== ''"
            class="mt-5 w-2/3 text-center text-xs text-gray-400 md:w-1/2"
          >
            {{ errorMessage }}
          </small>
        </template>
      </div>
      <slot
        v-else
        :scroll-percentage="scrollPercentage"
        :scroll-pixel="scrollPixel"
      >
        Content
      </slot>
    </div>

    <slot name="after-content" />

    <div
      v-if="!disableBottom"
      :class="[
        scrollPercentage < 99 ? 'border-t bg-gray-100' : 'bg-white',
        $slots['action-buttons'] ? 'block' : 'hidden md:block',
      ]"
      class="sticky bottom-0 z-10 bg-opacity-90 backdrop-blur transition-colors"
    >
      <slot name="bottom">
        <div class="hidden grid-cols-2 px-8 py-4 md:grid">
          <div class="text-start">
            <v-button
              v-if="secondaryButton !== ''"
              :bold="buttonsBold"
              :disabled="secondaryButtonDisabled"
              :without-border="buttonsWithoutBorder"
              class="text-bold rounded-full"
              variant="secondary"
              @click="onSecondaryButtonClick"
            >
              <font-awesome-icon
                v-if="backButton"
                icon="chevron-left"
              />
              {{ secondaryButton }}
            </v-button>
          </div>
          <div class="text-end">
            <v-button
              v-if="primaryButton !== ''"
              :bold="buttonsBold"
              :disabled="primaryButtonDisabled"
              :without-border="buttonsWithoutBorder"
              class="text-bold rounded-full"
              variant="primary"
              @click="onPrimaryButtonClick"
            >
              {{ primaryButton }}
            </v-button>
          </div>
        </div>
        <div class="flex justify-between px-8 py-4 md:hidden">
          <slot name="action-buttons" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VButton from '@/components/buttons/VButton.vue'
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AppSpinner from '@/components/ui/AppSpinner.vue'

const emit = defineEmits(['save', 'cancel', 'scroll'])

const props = defineProps({
  title: {
    type: String,
    default: 'Title',
  },
  primaryButton: {
    type: String,
    default: '',
  },
  secondaryButton: {
    type: String,
    default: '',
  },
  primaryButtonDisabled: {
    type: Boolean,
    default: false,
  },
  secondaryButtonDisabled: {
    type: Boolean,
    default: false,
  },
  buttonsWithoutBorder: {
    type: Boolean,
    default: false,
  },
  buttonsBold: {
    type: Boolean,
    default: false,
  },
  backButton: {
    type: Boolean,
    default: false,
  },
  disableBottom: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  errorCode: {
    type: Number,
    default: 0,
  },
})

const container = ref(null)
const scrollPercentage = ref(0)
const scrollPixel = ref(0)

function onScroll(e) {
  emit('scroll', e)
  const { scrollTop, scrollHeight, clientHeight } = e.target
  scrollPixel.value = scrollTop
  scrollPercentage.value = Math.floor(
    (scrollTop / (scrollHeight - clientHeight)) * 100
  )
}

function onPrimaryButtonClick() {
  emit('save')
}

function onSecondaryButtonClick() {
  emit('cancel')
}

defineExpose({
  container,
  scrollPercentage,
  scrollPixel,
})
</script>

<style scoped></style>