<template>
  <dropdown-button
    :rounded="miniMode"
    :class="[
      withTime ? 'w-[210px]' : 'w-[150px]',
      miniMode ? (modelValue ? 'w-[90px]' : 'w-fit ') : '',
    ]"
  >
    <template #button="{ isOpen }">
      <button
        :class="[
          miniMode
            ? modelValue
              ? ''
              : 'rounded-full border border-dashed px-2 py-1.5 text-gray-500 duration-100'
            : 'btn btn-primary flex grow items-center justify-between py-1',
        ]"
      >
        <template v-if="modelValue">
          {{
            DateTime.fromISO(modelValue).toLocaleString({
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            })
          }}
          <span v-if="withTime">
            {{
              DateTime.fromISO(modelValue).toLocaleString({
                hour: '2-digit',
                minute: '2-digit',
              })
            }}
          </span>
        </template>
        <span
          v-else-if="!miniMode"
          class="text-gray-600"
        >Select date</span>
        <button
          v-if="nullable && modelValue"
          class="btn btn-icon btn-sm"
          @click="emit('update:modelValue', '')"
        >
          <font-awesome-icon icon="times" />
        </button>
        <font-awesome-icon
          v-else
          :class="[
            'text-gray-700',
            miniMode ? (modelValue ? 'hidden' : 'h-4 w-4') : '',
          ]"
          icon="calendar-alt"
        />
      </button>
    </template>
    <template #buttons>
      <div class="px-1 pb-1">
        <calendar-selector
          :variant="variant"
          :model-value="modelValueGetter"
          class="w-[300px]"
          @update:model-value="updateDate"
        />
        <form-field-select-time
          v-if="withTime"
          :model-value="DateTime.fromISO(modelValue).toFormat('HH:mm')"
          class="w-full max-w-[120px]"
          label="Time"
          layout="row"
          :variant="variant"
          @update:model-value="updateTime"
        />
      </div>
    </template>
  </dropdown-button>
</template>

<script setup>
import DropdownButton from '@/components/buttons/DropdownButton.vue'
import CalendarSelector from '@/components/widgets/CalendarSelector.vue'
import { DateTime } from 'luxon'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FormFieldSelectTime from '@/components/input/FormFieldSelectTime.vue'
import { computed } from 'vue'
/**
 * Simple date picker that uses a dropdown button to display a calendar selector.
 * @usage <form-field-date-picker v-model="date" />
 * @example {
 *   "title": "Default",
 *   "description": "Default usage of the date picker.",
 *   "attrs": {
 *    "modelValue": "2021-09-01"
 *   }
 * }
 */


const emit = defineEmits(['update:modelValue'])


















































defineOptions({
  name: 'FormFieldDatePicker',
})


















































const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  withTime: {
    type: Boolean,
    default: false,
  },
  timezone: {
    type: String,
    default: 'UTC',
  },
  nullable: {
    type: Boolean,
    default: false,
  },
  /**
   * The variant of the input field.
   * @values default, outline, minimal, gray
   */
  variant: {
    type: String,
    default: null, // default, outline, minimal, gray
    validator: (value) => {
      return [
        'default',
        'outline',
        'minimal',
        'gray',
        'minimal-gray',
        'searchbar',
      ].includes(value)
    },
  },
  miniMode: {
    type: Boolean,
    default: false,
  },
})

const modelValueGetter = computed(() => {
  if (props.modelValue && props.modelValue !== '') return props.modelValue
  else return DateTime.now().toISODate()
})

/**
 * Update only the time part of the model value
 * @param time
 */
function updateTime(time) {
  const datetime = DateTime.fromISO(props.modelValue)
  const updated = datetime.set({
    hour: time.split(':')[0],
    minute: time.split(':')[1],
  })
  emit('update:modelValue', updated.setZone(props.timezone).toISO())
}

/**
 * Update only the date part of the model value
 * @param date
 */
function updateDate(date) {
  let datetime
  if (props.modelValue) datetime = DateTime.fromISO(props.modelValue)
  else datetime = DateTime.now()
  const updateDate = DateTime.fromISO(date)
  const updated = datetime.set({
    year: updateDate.year,
    month: updateDate.month,
    day: updateDate.day,
  })
  console.log('update', updated.setZone(props.timezone).toISO())
  emit('update:modelValue', updated.setZone(props.timezone).toISO())
}
</script>

<style scoped></style>
