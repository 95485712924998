<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <merge-selector-modal
        v-model:open="merge"
        :form-field-select-item-component="FormFieldSelectVenueSpace"
        :select-attrs="{
          conventionEventId: form.convention_event?.id,
        }"
        :source-id="form.id"
        resource-modal-type="venue-space"
        source-type="venue_space"
      />
      <form-field-header-input
        allow-merge
        class="w-full"
        @delete="emit('delete')"
        @merge="merge = true"
      >
        <template #input>
          <form-field-input
            v-model="form.name"
            class="grow"
            placeholder="Enter venue space name"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <div class="flex flex-col gap-4 pt-6">
      <form-input-group-layout>
        <input-layout
          :error="form.errors.convention_event_id"
          label="Event"
          layout="grid"
          variant="gray"
        >
          <form-field-select-convention
            :selected="form.convention_event"
            single-select
            @update:selected="
              (event) => {
                if (event?.id !== form.convention_event?.id) {
                  form.venue = null
                }
                form.convention_event = event
              }
            "
          />
        </input-layout>
        <input-layout
          :error="form.errors.venue?.id"
          label="Venue"
          layout="grid"
          variant="gray"
        >
          <form-field-select-venue
            v-model:selected="form.venue"
            :convention-event-id="form.convention_event?.id"
            :disabled="!form.convention_event?.id"
          />
        </input-layout>
        <input-layout
          :error="form.errors.type"
          label="Type"
          layout="grid"
          variant="gray"
        >
          <form-field-select-text
            v-model="form.type"
            :suggestions="[
              {
                name: 'Room',
                value: 'room',
              },
              {
                name: 'Hall',
                value: 'hall',
              },
              {
                name: 'Auditorium',
                value: 'auditorium',
              },
              {
                name: 'Theater',
                value: 'theater',
              },
              {
                name: 'Stadium',
                value: 'stadium',
              },
              {
                name: 'Arena',
                value: 'arena',
              },
              {
                name: 'Field',
                value: 'field',
              },
              {
                name: 'Court',
                value: 'court',
              },
            ]"
            variant="gray"
            @select="form.type = $event.name"
          />
        </input-layout>
        <input-layout
          label="Description"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.description"
            :rows="3"
            placeholder="Enter venue space description"
            variant="gray"
          />
        </input-layout>
        <input-layout
          label="Location info"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.location_info"
            :rows="3"
            placeholder="Enter venue space location info"
            variant="gray"
          />
        </input-layout>
        <input-layout
          label="Map Spot"
          layout="grid"
          variant="gray"
        >
          <form-field-select-map
            v-model:latitude="form.latitude"
            v-model:longitude="form.longitude"
          />
        </input-layout>
      </form-input-group-layout>
      <hr />
      <form-input-group-layout label="Stations">
        <input-layout
          label="Station"
          layout="grid"
          variant="gray"
        >
          <div class="flex flex-col gap-2">
            <div
              v-if="form.stations.length"
              class="flex flex-col gap-2 rounded border"
            >
              <div
                v-for="station in form.stations"
                :key="station.id"
                class="flex items-center gap-2 px-2 py-0.5 hover:bg-gray-100"
              >
                <p class="grow">
                  {{ station.name }}
                </p>
                <div class="shrink-0">
                  <button
                    class="text-sm"
                    @click="
                      venueStationForm.open(
                        axios.get(
                          `/api/partner/conventions/${form.convention_event.id}/stations/${station.id}`
                        )
                      )
                    "
                  >
                    <font-awesome-icon icon="pen-to-square" />
                  </button>
                </div>
              </div>
            </div>
            <button-plus
              @click="
                venueStationForm.open(
                  axios.post(
                    `/api/partner/conventions/${form.convention_event.id}/stations`,
                    {
                      venue_space_id: form.id,
                    }
                  ),
                  true
                )
              "
            >
              <template #text>Add Station</template>
            </button-plus>
          </div>
        </input-layout>
      </form-input-group-layout>
    </div>
  </modal-buttons-layout>
</template>

<script setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import VenueSpace from '@/models/VenueSpace'
import FormFieldSelectVenue from '@/components/input/FormFieldSelectVenue.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormFieldSelectConvention from '@/components/input/FormFieldSelectConvention.vue'
import FormFieldSelectText from '@/components/input/FormFieldSelectText.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import ButtonPlus from '@/components/buttons/ButtonPlus.vue'
import useModalForm from '@/utils/modalForm'
import VenueStation from '@/models/VenueStation'
import VenueStationFormView from '@/components/view/VenueStationFormView.vue'
import axios from 'axios'
import FormFieldSelectMap from '@/components/input/FormFieldSelectMap.vue'
import { ref } from 'vue'
import MergeSelectorModal from '@/components/modal/MergeSelectorModal.vue'
import FormFieldSelectVenueSpace from '@/components/input/FormFieldSelectVenueSpace.vue'

const emit = defineEmits(['delete', 'search'])

const props = defineProps({
  form: {
    type: [Object, VenueSpace],
    required: true,
  },
})

const merge = ref(false)

const venueStationForm = useModalForm(
  { ...new VenueStation() },
  {
    component: () => VenueStationFormView,
    onDelete: () => {
      return axios
        .delete(
          `/api/partner/conventions/${venueStationForm.form.convention_event.id}/stations/${venueStationForm.form.id}`
        )
        .then(() => {
          props.form.stations = props.form.stations.filter((station) => {
            return station.id !== venueStationForm.form.id
          })
        })
    },
    onClose: () => {
      return axios
        .post(
          `/api/partner/conventions/${venueStationForm.form.convention_event.id}/stations/${venueStationForm.form.id}?_method=PUT`,
          venueStationForm.form.formData()
        )
        .then((res) => {
          props.form.stations = props.form.stations.map((station) => {
            if (station.id === res.data.id) {
              return res.data
            }
            return station
          })
        })
    },
  }
)
</script>

<style scoped></style>
