<template>
  <input-wrapper
    :variant="variant"
    :empty="!modelValue?.length"
  >
    <app-input
      type="number"
      placeholder="Price"
      min="0"
      :model-value="modelValue"
      @update:model-value="emit('update:modelValue', $event)"
    />
    <div class="mx-2 h-5 w-[2px] shrink-0 rounded-full bg-gray-500" />
    <form-field-select-text
      variant="minimal"
      :model-value="currency"
      :suggestions="[
        {
          name: 'USD',
          value: 'USD',
        },
        {
          name: 'EUR',
          value: 'EUR',
        },
        {
          name: 'GBP',
          value: 'GBP',
        },
      ]"
      @update:model-value="emit('update:currency', $event)"
      @select="emit('update:currency', $event.name)"
    />
  </input-wrapper>
</template>

<script setup>
import InputWrapper from '@/components/ui/InputWrapper.vue'
import AppInput from '@/components/input/AppInput.vue'
import FormFieldSelectText from '@/components/input/FormFieldSelectText.vue'
/**
 * Input field for a price with a dropdown list of currencies
 * @usage <form-field-input-price v-model="modelValue" v-model:currency="currency" />
 * @example {
 *  "title": "Default",
 *  "description": "",
 *  "attrs": {
 *  "modelValue": "123",
 *  "currency": "USD",
 *  "variant": "gray"
 *  }
 * }
 */


const emit = defineEmits(['update:modelValue', 'update:currency'])


















































defineOptions({
  name: 'FormFieldInputPrice',
})


















































const props = defineProps({
  /**
   * The value of the input
   * @model
   */
  modelValue: {
    type: [String, Number],
    default: null,
  },
  /**
   * The currency of the price
   */
  currency: {
    type: String,
    default: 'USD',
  },
  variant: {
    type: String,
    default: 'default',
  },
})
</script>

<style scoped></style>