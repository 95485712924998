<template>
  <modal position="overlay">
    <div class="mt-20 w-full max-w-[90%] duration-300">
      <component
        :is="formFieldSelectItemComponent"
        :except="sourceId"
        autofocus
        class="w-full"
        labels="none"
        single-select
        v-bind="selectAttrs"
        variant="gray"
        @select="onSelect" />
    </div>
  </modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/modal/Modal.vue'
import { useModal } from '@/utils/modal'
import ModelDiffModal from '@/components/modal/ModelDiffModal.vue'

const props = defineProps({
  formFieldSelectItemComponent: {
    type: Object,
    required: true,
  },
  sourceId: {
    type: Number,
    default: () => null
  },
  sourceType: {
    type: String,
    required: true,
  },
  resourceModalType: {
    type: String,
    required: true,
  },
  selectAttrs: {
    type: Object,
    default: () => ({}),
  },
})

const modalHandler = useModal()

function onSelect(value: any) {
  modalHandler.closeAll()
  modalHandler.open(ModelDiffModal, {
    props: {
      position: 'center',
      size: 'lg',
      type: props.sourceType,
      sourceId: props.sourceId,
      targetId: value.id,
      resourceModalType: props.resourceModalType,
    },
  })
}
</script>

<style scoped></style>
