<template>
  <modal-buttons-layout
    disable-bottom
    size="base"
    title="Edit task"
  >
    <template #top>
      <div class="flex w-full items-center gap-2">
        <form-field-input
          v-model="form.name"
          class="grow"
          input-class="font-bold"
          placeholder="Enter Name"
          variant="gray"
        />
        <button
          :class="[
            'flex h-6 w-6 shrink-0 items-center justify-center rounded-full border',
            form.completed
              ? 'border-blue bg-blue text-white hover:border-blue-600 hover:bg-blue-600'
              : 'border-gray-500 text-gray-500 hover:border-blue hover:text-blue',
          ]"
          @click="emit('toggleTask')"
        >
          <font-awesome-icon
            class="h-4 w-4"
            icon="check"
          />
        </button>
      </div>
    </template>
    <div class="flex flex-col gap-4 py-2">
      <form-input-group-layout class="border-b pb-6">
        <input-layout
          :error="form.errors.status"
          class="max-w-20"
          label="Status"
          layout="grid"
          variant="gray"
        >
          <form-field-select-text
            v-model="form.status"
            :suggestions="statusOptions"
            disable-filtering
            variant="gray"
            wrapper-class="w-[120px]"
            @select="form.status = $event"
          />
        </input-layout>
        <input-layout
          :error="form.errors.groups"
          label="Team"
          layout="grid"
          variant="gray"
        >
          <form-field-select-user-group
            v-model:selected="form.groups"
            show-button-by-default
          />
        </input-layout>
        <input-layout
          :error="form.errors.asignee"
          label="Asignee"
          layout="grid"
          variant="gray"
        >
          <form-field-select-user
            v-model:selected="form.asignee"
            show-button-by-default
            single-select
          />
        </input-layout>
        <input-layout
          :error="form.errors.collaborators"
          label="Collaborators"
          layout="grid"
          variant="gray"
        >
          <form-field-select-user
            v-model:selected="form.collaborators"
            show-button-by-default
          />
        </input-layout>

        <input-layout
          label="Due date"
          layout="grid"
          variant="gray"
        >
          <form-field-date-picker
            v-model="form.release_date"
            :error="form.errors.release_date"
            variant="gray"
          />
        </input-layout>

        <input-layout
          :error="form.errors.description"
          label="Description"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.description"
            rows="2"
          />
        </input-layout>
      </form-input-group-layout>

      <form-input-group-layout class="border-b pb-6">
        <h3 class="text-sm font-bold">Subtasks</h3>
        <task-list :tasks="props.form.subtasks" />
      </form-input-group-layout>

      <form-input-group-layout class="border-b pb-6">
        <form-field-file-input
          :max-file-size="5 * 1024 * 1024"
          :single-file="false"
          display-files
          label="Files"
          @update:model-value="handleFileUpload"
        />
      </form-input-group-layout>

      <form-input-group-layout>
        <task-comments
          v-if="form && selectedEvent"
          :convention="selectedEvent"
          :task="form"
          wrapper-class="mb-10 ml-6 mr-14 mt-4"
        />
      </form-input-group-layout>
    </div>
  </modal-buttons-layout>
</template>

<script setup>
import { onMounted } from 'vue'

import TaskList from '@/components/forms/task/TaskList.vue'
import TaskComments from '@/components/widgets/TaskComments.vue'
import useConventionList from '@/utils/composables/conventionList'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormFieldFileInput from '@/components/input/FormFieldFileInput.vue'

import FormFieldSelectUser from '@/components/input/FormFieldSelectUser.vue'
import FormFieldSelectText from '@/components/input/FormFieldSelectText.vue'
import FormFieldSelectUserGroup from '@/components/input/FormFieldSelectUserGroup.vue'

import FormFieldDatePicker from '@/components/input/FormFieldDatePicker.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'

import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['toggleTask'])
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
})

const statusOptions = [
  { name: 'To do', value: 'To do' },
  { name: 'Blocked', value: 'Blocked' },
  { name: 'Done', value: 'Done' },
]

onMounted(() => {
  props.form.subtasks = props.form.subtasks ? props.form.subtasks : []
})

const taskModal = useResourceModal()

function editSubtask(subtask) {
  taskModal.openEdit('task', subtask.id, {
    onSaved: (response) => {
      const index = props.form.subtasks.findIndex(
        (item) => item.id === subtask.id
      )
      if (index !== -1) {
        props.form.subtasks[index] = response.data
      }
    },
  })
}

const handleFileUpload = (file) => {
  if (file) {
    if (props.form.files) {
      props.form.files.push(file)
    } else {
      props.form.files = [file]
    }
  }
}

const { selected: selectedEvent } = useConventionList()
</script>

<style lang="scss" scoped></style>
