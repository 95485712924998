<template>
  <!--    <pre>-->
  <!--  Display: {{ _modelValue }}-->
  <!--  Value: {{ modelValue }}-->
  <!--  to24: {{ to24hFormat('01:00am') }}-->
  <!--  to12: {{ to12hFormat('19:00') }}-->
  <!--  invalid: {{ invalid }}-->
  <!--  highlightedKey: {{ highlightedKey }}-->
  <!--  {{ roundToLowestStep(highlightedKey) }}-->
  <!--    </pre>-->
  <form-field-select-text
    :highlighted-key="roundToLowestStep(highlightedKey)"
    :invalid="invalid"
    :model-value="_modelValue"
    :suggestions="times"
    disable-filtering
    key-by="value"
    use-grouping
    wrapper-class="w-[120px]"
    :variant="variant"
    @blur="onBlur"
    @focus="onFocus"
    @select="onSelect"
    @update:highlighted-key="highlightedKey = $event"
    @update:model-value="onInput"
  >
    <template #item="{ item }">
      <div class="flex flex-col py-2 leading-none">
        <div class="flex">
          {{ item.name }}
          <font-awesome-icon
            v-if="
              item.value ===
                DateTime.fromISO(modelValue, { setZone: true }).toISO({
                  suppressMilliseconds: true,
                })
            "
            class="ml-auto"
            icon="check"
          />
        </div>
        <small
          v-if="showDateLabels"
          class="text-xs text-gray-600"
        >
          {{ item.date }}
        </small>
      </div>
    </template>
  </form-field-select-text>
</template>

<script setup>
import FormFieldSelectText from '@/components/input/FormFieldSelectText.vue'
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/**
 * Time input field with a dropdown list of suggestions. The value is in 24h format.
 * @usage <form-field-select-time v-model="modelValue" />
 * @example {
 *  "title": "Default",
 *  "description": "",
 *  "attrs": {
 *   "modelValue": "01:00"
 *  }
 * }
 */


const emit = defineEmits(['update:modelValue'])


















































defineOptions({
  name: 'FormFieldSelectTime',
})


















































const props = defineProps({
  /**
   * The value of the input in 24h format
   * @model
   */
  modelValue: {
    type: [String, null],
    default: null,
  },
  /**
   * Time step for suggestions in minutes
   */
  step: {
    type: Number,
    default: 15,
  },

  /**
   * Set input value format
   * 24h format limited to only 24 hours for single selector,
   * if you need to select time also for next day, consider using datetime format
   *
   */
  format: {
    type: String,
    default: '24h',
    validator: (value) => ['24h', 'datetime'].includes(value),
  },

  /**
   * Fix the date for datetime format.
   */
  fixedDate: {
    type: String,
    default: null,
  },

  /**
   * Lower limit the time range for datetime format.
   */
  minTime: {
    type: [String, null],
    default: null,
  },
  /**
   * Upper limit the time range for datetime format.
   */
  maxTime: {
    type: [String, null],
    default: null,
  },
  /**
   * Target time for datetime format.
   * This is used to highlight previous and next days correctly.
   * Should be in range between minTime and maxTime.
   * If not provided center of the range is used.
   */
  targetTime: {
    type: [String],
    default: null,
  },
  showDateLabels: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: null, // default, outline, minimal, gray
  },
})

/**
 * The value to show in the input in 12h format with am/pm
 * The actual value is in 24h format
 * @type {Ref<null>}
 */
const _modelValue = ref(to12hFormat(props.modelValue, props.format))
const invalid = ref(false)
const highlightedKey = ref(null)

function onFocus(e) {
  console.log('onfocus', e.target)
  highlightedKey.value = props.modelValue
  console.log(e.target)
  e.target.setSelectionRange(0, e.target.value.length)
}

/**
 * The suggestions to show in the dropdown
 */
const times = computed(() => {
  const times = []
  if (props.format === 'datetime' && props.minTime && props.maxTime) {
    const minTime = DateTime.fromISO(props.minTime, { setZone: true })
    const maxTime = DateTime.fromISO(props.maxTime, { setZone: true })
    const diff = maxTime.diff(minTime, 'minutes').toObject().minutes
    const steps = diff / props.step
    let currentDay = null
    const targetDate = (
      props.targetTime
        ? DateTime.fromISO(props.targetTime, { setZone: true })
        : minTime.plus({ minutes: diff / 2 })
    ).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    for (let i = 0; i < steps; i++) {
      const time = minTime.plus({ minutes: i * props.step })
      const date = time.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      if (currentDay !== date.toFormat('yyyy-MM-dd')) {
        currentDay = date.toFormat('yyyy-MM-dd')
        const offset = date.diff(targetDate, 'days').toObject().days
        times.push({
          name:
            offset === 1
              ? 'Next day'
              : offset === -1
              ? 'Previous day'
              : offset === 0
              ? 'Selected day'
              : date.toFormat('ccc, LLL d'),
          value: time.toISO({
            suppressMilliseconds: true,
          }),
          sticky: true,
        })
      }
      times.push({
        name: time.toFormat('hh:mma').toLowerCase(),
        date: time.toFormat('ccc, LLL d'),
        value: time.setZone(targetDate.zone).toISO({
          suppressMilliseconds: true,
        }),
      })
    }
  } else {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += props.step) {
        const hour = i < 10 ? `0${i}` : i
        const minute = j < 10 ? `0${j}` : j

        // name in am/pm format
        // value in 24h format
        times.push({
          name: to12hFormat(`${hour}:${minute}`),
          value: `${hour}:${minute}`,
        })
      }
    }
  }
  return times
})

function roundToLowestStep(time) {
  if (!time) return null
  if (props.format === 'datetime') {
    // round to lowest step
    const date = DateTime.fromISO(time, { setZone: true })
    const roundedDate = date.set({
      minute: Math.round(date.minute / props.step) * props.step,
    })
    return roundedDate.toISO({
      suppressMilliseconds: true,
    })
  }
  let [hour, minute] = time.split(':')
  let roundedMinute = Math.round(minute / props.step) * props.step
  if (roundedMinute === 60) {
    roundedMinute = 0
    hour++
  }
  if (roundedMinute < 10) roundedMinute = `${roundedMinute}0`
  return `${hour}:${roundedMinute}`
}

function onInput(value) {
  invalid.value = false
  highlightedKey.value = parseInputTime(value)
}

function onSelect(e) {
  invalid.value = false
  highlightedKey.value = null
  _modelValue.value = e.name
  emit('update:modelValue', e.value)
}

function onBlur() {
  if (invalid.value) {
    _modelValue.value = ''
    requestAnimationFrame(() => {
      _modelValue.value = to12hFormat(props.modelValue, props.format)
    })
    invalid.value = false
  } else if (highlightedKey.value) {
    emit('update:modelValue', highlightedKey.value)
    _modelValue.value = ''
    requestAnimationFrame(() => {
      _modelValue.value = to12hFormat(props.modelValue, props.format)
    })
  }
  document.activeElement?.blur()
}

function parseInputTime(value) {
  let nums = value.replace(/[^0-9]/g, '')
  if (nums.length > 4) {
    invalid.value = true
    return null
  }
  if (nums[0] > 2) nums = `0${nums}`
  const hasAM = ['am', 'a'].some((am) => value.includes(am))
  const hasPM = ['pm', 'p'].some((pm) => value.includes(pm))
  if (hasAM && hasPM) {
    invalid.value = true
    return null
  }
  const hour = nums.slice(0, 2)
  if (hour > 12 && (hasAM || hasPM)) {
    invalid.value = true
    return null
  }
  let minute = nums.slice(2, 4)

  //fill minutes with 0 if missing
  if (minute.length === 1) minute = `${minute}0`

  const hour24 =
    hasAM || hasPM ? (hasPM && hour < 12 ? parseInt(hour) + 12 : hour) : hour
  if (hour24 > 23 || minute > 59) {
    invalid.value = true
    return null
  }
  const time = `${prependZero(hour24)}:${minute.length ? minute : '00'}`
  if (props.format === 'datetime') {
    const date = DateTime.fromISO(
      props.fixedDate ?? props.targetTime ?? props.modelValue,
      {
        setZone: true,
      }
    )
    const [hour, minute] = time.split(':')
    const newDate = date.set({ hour, minute })
    if (
      (props.minTime &&
        newDate < DateTime.fromISO(props.minTime, { setZone: true })) ||
      (props.maxTime &&
        newDate > DateTime.fromISO(props.maxTime, { setZone: true }))
    ) {
      invalid.value = true
      return null
    }
    return newDate.toISO({
      suppressMilliseconds: true,
    })
  } else return time
}

function prependZero(value) {
  return value.length < 2 && value < 10 ? `0${value}` : value
}

function to12hFormat(time, format = '24h') {
  if (!time) return null
  if (format === 'datetime') {
    const date = DateTime.fromISO(time, { setZone: true })
    return date.toFormat('hh:mma').toLowerCase()
  }
  // convert from 24h to 12h format
  const [hour, minute] = time.split(':')
  const hour12 = parseInt(hour) > 12 ? parseInt(hour) - 12 : parseInt(hour)
  const isPM = hour > 12
  return `${hour12 < 1 ? '12' : hour12}:${minute}${isPM ? 'pm' : 'am'}`
}
</script>

<style scoped></style>
