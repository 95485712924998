<template>
  <modal-buttons-layout
    :title="null"
    back-button
    disable-bottom
    secondary-button="Back"
    @cancel="emit('close')"
  >
    <activity-detail
      :activity="activity"
      :loading="loading"
      class="grow"
    />
  </modal-buttons-layout>
</template>

<script setup lang="ts">
import ActivityDetail from "@/views/www/ActivityDetail.vue";
import ModalButtonsLayout from "@/components/modal/layout/ModalButtonsLayout.vue";

const emit = defineEmits(['close'])

const props = defineProps({
  activity: {
    type: Object,
    default: () => null,
  },
  loading: {
    type: Boolean,
    default: false,
  }
})
</script>

<style scoped>

</style>