import { Model } from '@/models/Model'
import { VendorSpot } from '@/models/VendorSpot'
import { DateTime } from 'luxon'
import Image from '@/models/Image'
import { TODO } from '@/types/todo'
import EventDay from '@/models/EventDay'
import { FeaturedItemType } from '@/models/FeaturedItem'
import Venue from '@/models/Venue'
import VenueSpace from '@/models/VenueSpace'
import ILocation from '@/models/ILocation'
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query'
import axios from 'axios'
import SpecialGuest from '@/models/SpecialGuest'
import { computed, Ref } from 'vue'
import ThreadPost from '@/models/ThreadPost'
import { EventActivity } from '@/models/EventActivity'
import { DefaultApiParams } from '@/utils/api'

export interface ConventionPalette {
  title: string
  primary: string
  secondary: string
  highlight: string
}

export const palettes: Record<string, ConventionPalette> = {
  dragoncon: {
    title: 'DragonCon',
    primary: '#4C0D66',
    secondary: '#7D15A8',
    highlight: '#FFF05F',
  },
  nycc: {
    title: 'NYCC',
    primary: '#D93831',
    secondary: '#821D19',
    highlight: '#3272CA',
  },
  sdcc: {
    title: 'SDCC',
    primary: '#204AFF',
    secondary: '#243479',
    highlight: '#FFFD01',
  },
  lacc: {
    title: 'LACC',
    primary: '#63A6DE',
    secondary: '#558CC0',
    highlight: '#C4487C',
  },
  wondercon: {
    title: 'WonderCon',
    primary: '#F09E38',
    secondary: '#995C10',
    highlight: '#0E101E',
  },
  ala: {
    title: 'ALA',
    primary: '#8E2825',
    secondary: '#460A0A',
    highlight: '#DEC849',
  },
  'anime-expo': {
    title: 'Anime Expo',
    primary: '#DB4B3E',
    secondary: '#A8372D',
    highlight: '#6096DC',
  },
  'phoenix-fan-fusion': {
    title: 'Phoenix Fan Fusion',
    primary: '#ED1C24',
    secondary: '#B7141A',
    highlight: '#FED917',
  },
}

export default class ConventionEvent extends Model {
  id: number | null = null
  slug: string = ''
  convention_id: number | null = null
  organisation_id: number | null = null
  name: string = ''
  description: string = ''
  founding_year: number | null = null

  days: EventDay[] = []

  location: TODO | null = null

  thumbnail: Image | null = null
  banner: Image | null = null
  logo: Image | null = null

  starts_at: string = ''
  ends_at: string = ''
  timezone: string = ''

  palette_name: string = ''

  vendor_spots: Array<VendorSpot> = []
  guest_count: number | null = null
  guests_count: number | null = null
  meetups_count: number | null = null
  groups_count: number | null = null

  latitude: number | null | undefined
  longitude: number | null | undefined

  @Model.propertyType(Venue, { isArray: true })
  venues: Venue[] = []
  @Model.propertyType(VenueSpace, { isArray: true })
  spaces: VenueSpace[] = []

  featured: FeaturedItemType[] = []

  public get palette(): null | ConventionPalette {
    return palettes[this.palette_name] ?? null
  }

  public get startsAt() {
    return DateTime.fromISO(this.starts_at)
  }

  public get endsAt() {
    return DateTime.fromISO(this.ends_at)
  }

  public get combinedLocations(): ILocation[] {
    return (this.venues as ILocation[]).concat(this.spaces)
  }

  /**
   * Get combined locations sorted by parent
   * Venue spaces will be after its parent venue
   */
  public get combinedLocationsSortedByParent(): ILocation[] {
    const locations: ILocation[] = []

    for (const venue of this.venues) {
      locations.push(venue)
      for (const space of this.spaces) {
        if (space.parentId === venue.id) {
          locations.push(space)
          for (const station of space.stations) {
            if (station.parentId === space.id) {
              locations.push(station)
            }
          }
        }
      }
    }

    return locations
  }

  static queryGuests(
    convention: Ref<ConventionEvent | undefined>,
    filters: {
      profession?: string
    } = {}
  ): UseQueryReturnType<SpecialGuest[], unknown> {
    return useQuery<SpecialGuest[]>({
      queryKey: ['events', convention.value?.id, 'guests', filters],
      queryFn: async () =>
        (
          await axios.get(`/api/conventions/${convention.value?.id}/guests`, {
            params: {
              ...filters,
            },
          })
        ).data,
      enabled: computed(() => {
        return !!convention.value?.id
      }),
      placeholderData: Array.from({ length: 10 }).map(() => new SpecialGuest()),
    })
  }

  static queryActivities(
    convention: Ref<ConventionEvent | undefined>,
    filters: {
      type?: string
    } & DefaultApiParams = {}
  ): UseQueryReturnType<EventActivity[], unknown> {
    return useQuery<EventActivity[]>({
      queryKey: ['events', convention.value?.id, 'activities', filters],
      queryFn: async () =>
        (
          await axios.get(
            `/api/conventions/${convention.value?.id}/activities`,
            {
              params: {
                ...filters,
              },
            }
          )
        ).data,
      enabled: computed(() => {
        return !!convention.value?.id
      }),
      placeholderData: Array.from({ length: 10 }).map(
        () => new EventActivity()
      ),
    })
  }

  static queryPosts(
    selectedEvent: Ref<ConventionEvent | undefined>,
    filters: { type?: string } & DefaultApiParams = {}
  ) {
    return useQuery<ThreadPost[]>({
      queryKey: ['events', selectedEvent.value?.id, 'posts', filters],
      queryFn: async () =>
        (
          await axios.get(`/api/conventions/${selectedEvent.value?.id}/posts`, {
            params: {
              ...filters,
            },
          })
        ).data,
      enabled: computed(() => {
        return !!selectedEvent.value?.id
      }),
      placeholderData: Array.from({ length: 10 }).map(() => new ThreadPost()),
    })
  }
}
