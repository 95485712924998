import { Model } from "@/models/Model";
import UserGroup from "@/models/UserGroup";
import User from "@/models/User";
import Image from "@/models/Image";
import Tag from "@/models/Tag";

export default class SpecialGuest extends Model {
  id: number | null = null;
  type: string = 'user';
  name: string = '';

  guest: UserGroup | User | null = null;
  avatar: Image | null = null;

  caption: string = '';
  bio: string = '';
  profession_tags: unknown[] = [];
  profession: Tag | null = null;
}