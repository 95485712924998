<template>
  <div class="grid grid-cols-input-field gap-3">
    <template
      v-for="(address, index) in form.addresses"
      :key="index"
    >
      <form-label variant="gray">
        {{ address.name?.length ? address.name : 'Address ' + (index + 1) }}
      </form-label>
      <div
        v-if="index !== editIndex"
        class="grid grid-cols-2 rounded p-1 duration-100 hover:bg-gray-100"
        @click="edit(index)"
      >
        <address-presenter :address="address" />
        <map-preview
          v-if="coordinates"
          :latitude="address.latitude"
          :longitude="address.longitude"
          disable-controls
        />
      </div>
      <address-form
        v-else
        :coordinates="coordinates"
        :form="address"
        @delete="form.addresses.splice(index, 1)"
        @save="editIndex = null"
      />
    </template>
    <template
      v-if="form.addresses.length === 0 || (editIndex === null && creatingNew)"
    >
      <form-label variant="gray">Add a new address</form-label>
      <address-form
        coordinates
        :disable-close="form.addresses.length === 0"
        :form="newAddress"
        disable-delete
        @delete="creatingNew = false"
        @save="create"
      />
    </template>
    <div v-else>
      <button
        class="flex h-10 w-10 items-center justify-center rounded border border-dashed border-gray-500 duration-100 hover:bg-gray-100"
        @click="creatingNew = true"
      >
        <font-awesome-icon
          class="h-5 w-5 text-gray-500"
          icon="plus"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import FormLabel from '@/components/ui/FormLabel.vue'
import AddressForm from '@/components/input/AddressForm.vue'
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AddressPresenter from '@/components/ui/AddressPresenter.vue'
import MapPreview from '@/components/ui/MapPreview.vue'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  coordinates: {
    type: Boolean,
    default: false,
  },
})

function edit(index) {
  editIndex.value = index
  creatingNew.value = false
}

function create() {
  props.form.addresses.push(newAddress.value)
  newAddress.value = {
    name: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zip: '',
  }
  creatingNew.value = false
  editIndex.value = null
}

function save() {
  editIndex.value = null
  creatingNew.value = false
}

const editIndex = ref(null)
const creatingNew = ref(false)
const newAddress = ref({
  name: '',
  street: '',
  city: '',
  state: '',
  country: '',
  zip: '',
})
</script>

<style scoped></style>
