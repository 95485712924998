import { ComputedRef, ref, Ref, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { AxiosResponse } from 'axios'
import { createEventHook } from '@vueuse/core'
import { Model } from '@/models/Model'

interface CachedResource<T> {
  data: Ref<T | null>
  loading: Ref<boolean>
  loaded: Ref<boolean>
  error: Ref<boolean>
  lastFetched: number | null
  refresh: (replace?: boolean) => Promise<AxiosResponse<T>>
}

interface CachedResourceCallback<T> {
  onLoaded: () => Promise<T>
  onErrored: () => Promise<void>
  save: (data: T | null | undefined) => void
}

export type CachedResourceWithCallback<T> = CachedResource<T> &
  CachedResourceCallback<T>

const cache: Record<string, CachedResource<any>> = {}

/**
 * Fetch a resource from the API and cache it to local storage
 * If the resource is already in the cache, it will be returned immediately
 * @param key
 * @param fetch
 * @param options
 */
export default function useCachedResource<T>(
  key: string,
  fetch: () => Promise<AxiosResponse<T>>,
  options: {
    force?: boolean
    baseClass?: { new (): Model }
    enabled?: ComputedRef<boolean>
    replace?: boolean
  } = {}
): CachedResourceWithCallback<T> {
  const logger = useLogger()
  const loadedEventHook = createEventHook<T>()
  const erroredEventHook = createEventHook<void>()
  const save = (data: T | null | undefined) => {
    if (!data) localStorage.removeItem(key)
    else localStorage.setItem(key, JSON.stringify(data))
  }

  // If the resource not in the cache, try to load it from local storage
  if (!cache[key]) {
    const localData = localStorage.getItem(key) || 'null'
    const data = JSON.parse(localData === 'undefined' ? 'null' : localData)
    let activeRequest: Promise<AxiosResponse<T>> | null = null

    const dataFromObject = (data: T | null) => {
      if (data) {
        if (!options.baseClass) return data
        if (Array.isArray(data))
          return Model.fromDataArray(data, options.baseClass ?? null)
        return Model.fromData(data, options.baseClass ?? null)
      }
      return null
    }

    cache[key] = {
      data: ref(dataFromObject(data)),
      loading: ref(false),
      loaded: ref(!!data),
      error: ref(false),
      lastFetched: null,
      refresh: function (replace: boolean = false) {
        if (this.loading.value) {
          if (!activeRequest) {
            throw new Error(
              'Loading is already in progress but no active request found'
            )
          }
          return activeRequest
        }
        this.loading.value = true
        logger.debug('Refreshing', key)
        activeRequest = fetch()
          .then((response) => {
            logger.debug('Fetched', key)
            const data = response.data
            // merge data to the existing data
            if (this.data.value && !replace) {
              if (Array.isArray(data)) {
                data.forEach((item) => {
                  const existingItem = this.data.value.find(
                    (i: any) => i.id === item.id
                  )
                  if (existingItem) {
                    if (existingItem instanceof Model) {
                      existingItem.update(item)
                    } else {
                      Object.assign(existingItem, item)
                    }
                  } else {
                    this.data.value.push(item)
                  }
                })
              } else {
                Object.assign(this.data.value, data)
              }
            } else {
              this.data.value = dataFromObject(data)
            }
            this.loaded.value = true
            this.error.value = false
            this.lastFetched = Date.now()
            save(response.data)
            void loadedEventHook.trigger(data)
            return response
          })
          .catch((error) => {
            this.error.value = true
            logger.error(error)
            void erroredEventHook.trigger()
            return error
          })
          .finally(() => {
            this.loading.value = false
          })
        return activeRequest
      },
    }
  }

  const resource = cache[key]

  const update = () => {
    if (
      ((options.force && !resource.lastFetched) || !resource.loaded.value) &&
      (options.enabled ? options.enabled.value : true)
    ) {
      logger.debug('Reload', key)
      void resource.refresh(options.replace ?? false)
    } else {
      logger.debug('Using cached', key)
    }
  }

  watch(
    () => options.enabled,
    () => {
      update()
    },
    {
      immediate: true,
    }
  )

  return {
    ...resource,
    save,
    onLoaded: () => {
      return new Promise((resolve) => {
        if (resource.loaded.value) {
          resolve(resource.data.value)
        } else {
          loadedEventHook.on((data) => {
            resolve(data)
          })
        }
      })
    },
    onErrored: () => {
      return new Promise((resolve) => {
        if (resource.error.value) {
          resolve()
        } else {
          erroredEventHook.on(() => {
            resolve()
          })
        }
      })
    },
  }
}
