<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M22 12.8809C22 7.36086 17.52 2.88086 12 2.88086C6.48 2.88086 2 7.36086 2 12.8809C2 17.7209 5.44 21.7509 10 22.6809V15.8809H8V12.8809H10V10.3809C10 8.45086 11.57 6.88086 13.5 6.88086H16V9.88086H14C13.45 9.88086 13 10.3309 13 10.8809V12.8809H16V15.8809H13V22.8309C18.05 22.3309 22 18.0709 22 12.8809Z"
      fill="currentColor"
    />
  </svg>
</template>
