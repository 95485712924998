<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.5 2C8.35813 2 5 5.35813 5 9.5C5 11.1309 5.53469 12.6275 6.42344 13.8531C6.43938 13.8825 6.44188 13.9153 6.46 13.9434L11.46 21.4434C11.6919 21.7913 12.0825 22 12.5 22C12.9175 22 13.3081 21.7913 13.54 21.4434L18.54 13.9434C18.5584 13.9153 18.5606 13.8825 18.5766 13.8531C19.4653 12.6275 20 11.1309 20 9.5C20 5.35813 16.6419 2 12.5 2Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M10 9.5C10 10.8806 11.1194 12 12.5 12C13.8806 12 15 10.8806 15 9.5C15 8.11937 13.8806 7 12.5 7C11.1194 7 10 8.11937 10 9.5Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
