<template>
  <button class="p-1 text-gray-400 hover:text-gray-600">
    <svg
      width="14"
      height="13"
      viewBox="0 0 13 14"
      xmlns="http://www.w3.org/2000/svg"
      :class="{
        'rotate-180': props.downvote,
        'fill-[#D82525] text-[#D82525]': props.voted && props.downvote,
        'fill-blue-neon text-blue-neon': props.voted && !props.downvote,
        'fill-none': !props.voted,
      }"
    >
      <path
        d="M4.0099 12.7156V7.39342C4.0099 7.32899 3.95766 7.27676 3.89323 7.27676H1.01338C0.912166 7.27676 0.858966 7.1567 0.926956 7.08172L6.20534 1.26095C6.25164 1.20989 6.33188 1.20989 6.37818 1.26095L11.6566 7.08172C11.7246 7.1567 11.6714 7.27676 11.5701 7.27676H8.69029C8.62586 7.27676 8.57362 7.32899 8.57362 7.39342V12.7156C8.57362 12.7801 8.52139 12.8323 8.45696 12.8323H4.12656C4.06213 12.8323 4.0099 12.7801 4.0099 12.7156Z"
        stroke="currentColor"
      />
    </svg>
  </button>
</template>

<script setup>
defineOptions({
  name: 'UpvoteButton',
})

















































/**
 * Button with upvote/downvote icon.
 */


const props = defineProps({
  downvote: {
    type: Boolean,
    default: false,
  },
  voted: {
    type: Boolean,
    default: false,
  },
})
</script>

<style scoped></style>
